import React, { } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './persistentCartDistributorList.module.scss';
import PersistentCartSummaryItem from '../../../common/items/PersistentCartSummaryItem';

const PersistentCartDistributorList = ({ distributor }) => {
    const items = useSelector((state) =>
        state.cart.cart.lineitem.filter(
            (x) => x.offer.distributor.name === distributor.distributor.name
        )
    );
    const { t } = useTranslation();
    const baseTranslationKey = 'translation.cart.cartList.distributorList';

    return (
        <>
            <Box className={`${styles.distributorListHeader}  `}>
                <h3 >
                    {t(`${baseTranslationKey}.distributedBy`)}{' '}
                    {distributor.distributor.name}
                </h3>
            </Box>
            {items.map((item, index) => {
                return <PersistentCartSummaryItem item={item} key={`${item.id}-${index}`} />;

            })}
        </>
    );
};

PersistentCartDistributorList.propTypes = {
    distributor: PropTypes.object.isRequired,
};

export default PersistentCartDistributorList;
