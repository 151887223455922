import EmailValidator from 'email-validator';

export const validate = (shippingAddress) => {

    if (!shippingAddress.firstName) {
        return false;
    }

    if (!shippingAddress.lastName) {
        return false;
    }

    if (!shippingAddress.street1) {
        return false;
    }

    if (!shippingAddress.city) {
        return false;
    }

    if (!shippingAddress.state) {
        return false;
    }

    if (!shippingAddress.zipCode) {
        return false;
    }

    if (!shippingAddress.phoneNumber) {
        return false;
    }

    if (!EmailValidator.validate(shippingAddress.email)) {
        return false;
    }

    return true;
};