/* eslint-disable */
import React from 'react';
import enUS from './gsnLanguage';
import headerData from './headerData';

export default class Localization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            popupHidden: true,
        };
        this.localeButton = this.localeButton.bind(this);
        this.localePopUp = this.localePopUp.bind(this);
        this.togglePopUp = this.togglePopUp.bind(this);
    }

    togglePopUp() {
        this.setState({popupHidden: !this.state.popupHidden});
    }

    localeButton() {
        return (
            <div
                key="localeButton"
                className="m-navbar_localization l-centerVertically"
            >
                <a
                    tabIndex={0}
                    className="link--dark l-centerVertically is-localeToggle"
                    onClick={this.togglePopUp}
                >
                    {this.state.locale}
                    <i
                        className="MMM--icn MMM--icn_localizationGlobe l-centerVertically m-navbar_localGlobe"
                        style={{paddingLeft: '5px'}}
                    ></i>
                    <i
                        className="MMM--icn MMM--icn_down_arrow h-onDesktop"
                        style={{paddingLeft: '10px', fontSize: '11px'}}
                    ></i>
                </a>
            </div>
        );
    }

    localePopUp() {
        const localeOptions = headerData.localizationPopUp.localeOptions.map(
            (locale, index) => {
                return (
                    <React.Fragment key={index}>
                        <input
                            type="radio"
                            name="locale"
                            value={locale.value}
                            id={`locale-${index}`}
                            className="m-radioInput"
                            defaultChecked={
                                locale.checked !== undefined
                                    ? locale.checked
                                    : false
                            }
                        />
                        <label
                            className="m-radioInput_label"
                            htmlFor={`locale-${index}`}
                        >
                            {locale.localeLanguage}
                        </label>
                        <br />
                    </React.Fragment>
                );
            }
        );
        return (
            <React.Fragment>
                <div
                    className={`m-navbar_localOverlay is-localePopUp ${
                        this.state.popupHidden ? 'h-hidden' : ''
                    }`}
                >
                    <div
                        className="m-navbar_localPopup m-navbar_popup font--standard"
                        aria-modal="true"
                    >
                        <button
                            className="m-navbar-localClose m-btn--close color--silver is-close h-notOnDesktop"
                            aria-label={enUS.close}
                            onClick={this.togglePopUp}
                        >
                            <i className="MMM--icn MMM--icn_close"></i>
                        </button>
                        <div className="font--standard m-navbar_popupTitle">
                            {enUS.localePopUpTitle}
                        </div>
                        <form>
                            <div className="font--standard m-navbar_popupOptions">
                                {localeOptions}
                            </div>
                            <a
                                href={
                                    headerData.localizationPopUp.changeLocation
                                        .href
                                }
                                className="link"
                            >
                                {
                                    headerData.localizationPopUp.changeLocation
                                        .text
                                }
                            </a>
                            <button
                                type="submit"
                                value="Submit"
                                className="m-btn m-btn--red m-navbar_localeSave is-saveLocale"
                            >
                                {enUS.save}
                            </button>
                        </form>
                    </div>
                    <div
                        className="m-overlay h-onDesktop is-close"
                        onClick={this.togglePopUp}
                    ></div>
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            <React.Fragment>
                <this.localeButton />
                <this.localePopUp />
            </React.Fragment>
        );
    }
}
