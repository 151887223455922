import i18n from 'i18next';
// import {initReactI18next} from 'react-i18next';

// Depending on decisions below we may want to import these
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const options = {
    // order and from where user language should be detected
    order: [
        'htmlTag',
        // 'querystring',
        // 'cookie',
        // 'localStorage',
        // 'sessionStorage',
        // 'navigator',
        // 'path',
        // 'subdomain',
    ],

    // keys or params to lookup language from
    // lookupQuerystring: 'lng',
    // lookupCookie: 'i18next',
    // lookupLocalStorage: 'i18nextLng',
    // lookupFromPathIndex: 0,
    // lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: [],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    // cookieMinutes: 10,
    // cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    checkWhitelist: true,
};

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // We can use this if we want to move the translations out of the UI project
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // We may want to use this depending on how we/3M wants to implement changing
    // languages
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    // .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        // resources,
        debug: false,
        whitelist: ['en', 'es'],
        fallbackLng: 'en',
        ns: ['translation'],
        detection: options,
        // backend: {
        //   loadPath: '/locales/{{lng}}/{{ns}}.json'
        // },
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true,
            useSuspense: false,
        },
    });

export default i18n;
