
import React from 'react';
import { useDispatch } from 'react-redux';
import styles from './quantitySelector.module.scss';
import PropTypes from 'prop-types';
import { multiplyBD } from '../utlities';
import { tealiumLink } from '../../../redux/actions/trackingActions.js';
import {
    updateCartQuantity,
    updateDistributorAmount,
    saveCartProgress
} from '../../../redux/actions/cart/cartActions';
import Remove from '../../gsn/Remove';
import plusSign from '../../../assets/icons/add/plus_sign.svg';
import minusSign from '../../../assets/icons/minus/minus_sign.svg';
import ImageContainer from '../../common/images/image-container';
import { Box } from 'rebass';

const QuantitySelector = ({ item, quantity, handleQtyChange, availableQty, backOrderQty, error }) => {
    const dispatch = useDispatch();    

    const onHandQty = availableQty - backOrderQty;
    let limit = 500;
    let start = 1;

    if (quantity <= onHandQty && onHandQty < limit) {
        limit = onHandQty;
    } else if (quantity > onHandQty) {
        if (backOrderQty < limit)
            limit = availableQty;
        start = onHandQty + 1;
    }

    const disableSelectorMinus =
        quantity <= 1 || quantity <= start || error
            ? styles.disabled
            : '';
    const disableSelectorPlus =
        quantity >= limit || error ? styles.disabled : '';

    const handleQtyChangeLocal = (updatedQty) => {

        if(!!handleQtyChange) {
            handleQtyChange(updatedQty);
            return;
        }
        const priceChange = multiplyBD((updatedQty - quantity), item?.offer.price);

        if (updatedQty > 1) {
            dispatch(tealiumLink({
                event_name: 'Quantity Selection',
                quantity: updatedQty
            }));
        }

        dispatch(updateCartQuantity(item.id, updatedQty));
        dispatch(
            updateDistributorAmount(
                item?.offer?.distributor?.name,
                priceChange
            )
        );
        dispatch(saveCartProgress(null));
    }

    return (
        <>
            <div className={styles.selector_border}>
                <div className={`${styles.cartInput} `}>
                    <div className={`${styles.one}`}>
                        {!!item && quantity > 1  &&
                            <button className={`${styles.one} ${disableSelectorMinus}`}
                                id="one"
                                onClick={() => handleQtyChangeLocal(--quantity)}
                                disabled = {error || quantity <= start }
                            >
                                <ImageContainer
                                    src={minusSign}
                                    className={styles.minus_icon_action} />
                            </button>
                        }
                        { !!item && quantity <= 1 &&
                            <button
                                id="one"
                                className={`${styles.one}`}                               
                            >
                                <div
                                    className={styles.delete}
                                >
                                    <Remove item={item} />
                                </div>
                            </button>
                        }

                        { !item && quantity >= 1 &&
                            <button className={`${styles.one} ${disableSelectorMinus}`}
                                id="one"
                                onClick={() => handleQtyChangeLocal(--quantity)}
                                disabled = {quantity <=1 || quantity <= start || error}
                            >
                                <ImageContainer
                                    src={minusSign}
                                    className={styles.minus_icon_action} />
                            </button>
                        }
                    </div>
                    <input                        
                        value={quantity}
                        readOnly
                        id="counter-value"
                    ></input>
                    <button
                        className={`${styles.two} ${disableSelectorPlus}`}
                        onClick={() => handleQtyChangeLocal(++quantity)}
                        disabled = {quantity >= limit || error}
                    >
                        {' '}
                        <Box>
                            <span
                            >
                                <ImageContainer
                                    src={plusSign}
                                    className={styles.plus_icon_action} />
                            </span>
                        </Box>

                    </button>
                </div>
            </div>
        </>
    )
}

QuantitySelector.propTypes = {
    item: PropTypes.object,
    quantity: PropTypes.number.isRequired,
    handleQtyChange: PropTypes.func,
    availableQty: PropTypes.number.isRequired,
    backOrderQty:PropTypes.number.isRequired,
    error: PropTypes.bool,    
};
export default QuantitySelector;