/* eslint-disable max-lines */
import React, {useState,useEffect} from 'react';
import {useParams} from 'react-router-dom';
import DistributorCard from './DistributorCard';
import ShippingInput from '../shippingInfo/ShippingInput';
import styles from './DistributorInfo.module.scss';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {addToCart, selectOffer,  deselectOffer, loadOffer, clearShippingData} from '../../../../redux/actions/offerActions.js';
import {multiplyBD, checkIsWeekEnd} from '../../../../components/common/utlities'; 
import { tealiumLink, checkoutEvent } from '../../../../redux/actions/trackingActions.js';
import LazyLoader from '../../../../../public/images/lazy-preloader-clear.gif';
import Carousel from '../../../carousel';

const DistributorInfo = ({offers, amountSelected,unit,highestInventory,quantityLoading}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedOfferId = useSelector(
        (state) => state.offer.selectedOffer.id
    );
    const selectedQuantity = useSelector((state) =>
        state.offer.selectedQuantity.find(
            (x) => x.unit === unit
        )
    );
    const dropDownQuantity = useSelector((state) =>
        state.offer.selectedQuantity.find(
            (x) => x.unit === unit
        )
    );
    const zipCodeSelected = useSelector((state) =>
       state.offer.selectedZipCode
    );
    const isOffersAvailable = useSelector((state) => state.offer.isOffersAvailable);   
    const FAILED_WITH_UNIT = 'FAILED_WITH_UNIT';
    const FAILED_WITH_UNIT_AND_QUANTITY_GREATER_THAN_ONE = 'FAILED_WITH_UNIT_AND_QUANTITY_GREATER_THAN_ONE';
    const [loading, setLoading] = useState(false);
    const [isWeekEnd, setIsWeekEnd] = useState(false);
    const selectedPrice = useSelector((state) => state.offer.selectedPrice);
    const product = useSelector((state) => state.offer.products[0]);
    const {sku} = useParams();
    const subTotalAmount = selectedQuantity
        ? multiplyBD(selectedQuantity.quantity , selectedPrice)
        : 0;

    const refinementList =
        window.location.href.indexOf('#') > 0
            ? window.location.href.substr(window.location.href.indexOf('#') + 1)
            : null;
    const refinementsMap = new Map([]);
    refinementList && refinementList.trim().length > 0
        ? refinementList
              .trim()
              .split('|')
              .map((refinement) => {
                  const field = refinement.split('=');
                  refinementsMap.set(field[0], field[1]);
              })
        : [];

    const refinements = {
        code: 'refinements',
        type: 'STRING',
        value: JSON.stringify(Object.fromEntries(refinementsMap)),
    };
    const addItemToCart = (selectedOffer) => {
        dispatch(selectOffer(selectedOffer, selectedOffer.price))
        const offerField = {
            ...selectedOffer,
            offerAdditionalFields: selectedOffer.offerAdditionalFields
                ? [...selectedOffer.offerAdditionalFields, {refinements}]
                : [{refinements}],
        };
        dispatch(addToCart(selectedQuantity.quantity, product, offerField));
        dispatch(
            tealiumLink({
                event_name: 'Add to Cart',
                funnel_stage: 'Distributor Selection',
            })
        );

        dispatch(checkoutEvent('cart_add', sku, subTotalAmount, null, selectedQuantity?.quantity));

    };

    const zipCodeEnter = (zipCode) => {
        setLoading(true);
        dispatch(clearShippingData());
        dispatch(loadOffer(sku, dropDownQuantity?dropDownQuantity.quantity:1,history,false,unit,setLoading)); 
        dispatch(deselectOffer());         
    }

    useEffect(() => {
        if(checkIsWeekEnd()){
            setIsWeekEnd(true);
        }
    },[]);    

    const selectedSelector = amountSelected ? '' : styles.unselected;
    
    const distributorCardsDisabled = quantityLoading ? styles.distributorCardsDisabled :'';

    const translatedBase =
        'translation.distributorPage.quantitySelector.distributorInfo';
    const noOffersAvailable =
        t(translatedBase + '.maxQuantityAvailable') +
        ' ' +
        highestInventory +
        ', ' +
        t(translatedBase + '.tryAgain');
    return (
        <div className={`${styles.distributor_info} ${selectedSelector}`}>
            <header className={styles.distributor_info__header_row}>
                <h1 className="mds-font_header--5">
                    {t(
                        'translation.distributorPage.quantitySelector.distributorInfo.selectDistributorAndShippingOption'
                    )}
                </h1>
                <span className={styles.distributor_info__tooltip}>
                    <i className="MMM--icn MMM--icn_tooltip_black">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                    </i>
                    {t(
                        'translation.distributorPage.quantitySelector.distributorInfo.selectTooltip'
                    )}
                    .
                </span>
            </header>
            <ShippingInput zipCodeEnter ={zipCodeEnter}/>
            {quantityLoading &&
            <div className = {`${styles.distributor_info__coverSpinner}`} >
                <img src={LazyLoader} alt="loading offers..."></img>
            </div>
            }
            {offers && (
                <div className={`${styles.distributorCards} ${distributorCardsDisabled}`}>
                    {offers.map((offer, index) => {
                        let selectedOffer = selectedOfferId === offer.id;
                        const adtlDistHeading =
                            index === 3 ? (
                                <h5
                                    className={`mds-font_header--5 ${styles.additional_distributors}`}
                                >
                                    Additional Distributors
                                </h5>
                            ) : (
                                <></>
                            );
                        const isAdtlDist = index > 2;

                        // allow the first offer to be selected if there is only one offer available
                        if (offers.length == 1) {
                            selectedOffer = true;
                        }

                        return (
                            <>
                                {adtlDistHeading}
                                <DistributorCard
                                    bestCustomerScore={index === 0}
                                    adtlDistributor={isAdtlDist}
                                    name={offer.name}
                                    offer={offer}
                                    key={offer.id}
                                    zipCodeSelected={zipCodeSelected}
                                    selectedOffer={selectedOffer}
                                    loading={ loading && !!zipCodeSelected}
                                    addToCart={() => {
                                        addItemToCart(offer);
                                    }}
                                    sku={sku}
                                    isWeekEnd = {isWeekEnd}
                                />
                            </>
                        );
                    })}
                </div>
            )}
            {(!offers || offers.length == 0) ?
              loading ? 
                (<div>
                    {t('translation.distributorPage.quantitySelector.distributorInfo.loadingOffers'
                    )}
                    <div className = {`${styles.distributor_info__coverSpinner_loadingOffer}`} >
                        <img src={LazyLoader} alt="loading offers..."></img>
                    </div>
                </div>
                )
                
              :              
              ((!!selectedQuantity && selectedQuantity.quantity > 1 && isOffersAvailable === FAILED_WITH_UNIT_AND_QUANTITY_GREATER_THAN_ONE) ? (
                <div style={{marginTop:'10px'}}>
                    <span className={`${styles.distributorInfo_offerAvailability}`}>
                        {t('translation.distributorPage.quantitySelector.distributorInfo.outOfStock'
                    )}
                    </span>
                    <span className={`${styles.distributorInfo_offerAvailability_Content}`}> 
                    {noOffersAvailable}              
                    </span>
                </div>
             )
             : (isOffersAvailable === FAILED_WITH_UNIT) ? (
             <div>
                 <span className={`${styles.distributorInfo_offerAvailability}`}>
                     {t('translation.distributorPage.quantitySelector.distributorInfo.outOfStock'
                    )}
                    </span>
                 <span className={`${styles.distributorInfo_offerAvailability_Content}`}> 
                    {t('translation.distributorPage.quantitySelector.distributorInfo.noOffersAvailable'
                    )}              
                 </span>
                 <Carousel/>
             </div>) : null )
             : null }
        </div>
    );
};

DistributorInfo.propTypes = {
    offers: PropTypes.array.isRequired,
    amountSelected: PropTypes.bool.isRequired,
    unit: PropTypes.string.isRequired,
    highestInventory:PropTypes.number,
    quantityLoading:PropTypes.bool
};

export default DistributorInfo;
