import {combineReducers} from 'redux';
import cart from './cartReducer.js';
import offer from './offerReducer.js';
import signIn from './signInReducer.js';
import selectedAddress from './selectedAddressReducer.js';
import ordersHistory from './ordersHistoryReducer.js';
import errorLog from './errorLogReducer.js';
import myList from './myListReducer.js';
import orderDetails from './orderDetailsReducer';
import paymentList from './paymentListReducer';
import myAddresses from './myAddressesReducer';
import fileUpload from './fileUplaodReducer';
import messageCenter from './messageCenterReducer';
import miscellaneous from './miscellaneousReducer';


const rootReducer = combineReducers({
    cart,
    offer,
    signIn,
    selectedAddress,
    ordersHistory,
    errorLog,
    myList,
    orderDetails,
    paymentList,
    myAddresses,
    fileUpload,
    messageCenter,
    miscellaneous
});

export default rootReducer;
