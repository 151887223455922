/* eslint-disable max-lines*/
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { MdsHeader5, MdsText, MdsLink } from '../../mds';
import ImageContainer from '../images/image-container';
import {    
    removeCartLineItem,
    updateDistributorAmount,
    saveCartProgress
} from '../../../redux/actions/cart/cartActions';
import { useTranslation } from 'react-i18next';
import { tealiumLink } from '../../../redux/actions/trackingActions.js';
import { useHistory } from 'react-router-dom';
import { addBD, currencyFormatter, getBackOrderQty, getNormalizedProductURL, getLowUOM,
    getHighUOM, getNumOfEaches, getUOMInHighUom } from '../utlities';
import Modal from '../../common/modals/generic';

import styles from './singleItemSummary.module.scss';
import { excludePromoInternalDesc } from '../../../data/ExcludePromotions';
import UpsellItem from './upsellItemSummary.js';
import PromotionSummary from './promotionSummary.js';
import QuantitySelector from './QuantitySelector';

const SingleItemDescription = ({ item, setLoading }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const dropdownNumbers = [];
    const { t } = useTranslation();
    const baseTranslationKey = 'translation.common.items.singleItemSummary';
    const [displayModal, setDisplayModal] = useState(false);
    const [modalType, setModalType] = useState('remove');
    const [showPromotionAvailable, setShowPromotionAvailable] = useState(false);
    const [togglePromotionAvailable , setTogglePromotionAvailable] = useState(true);
    const [openUpsellProducts, setOpenUpsellProducts] = useState(true);
    const upsellProduct = []; 

    // this is a temp solution until we redesign upsells on the cart page
    const hideAllUpsells = true;

    const state = useSelector((state) => state.offer.upsellProducts);
    if (item.product.upsellSkus) {
        const productsList = JSON.parse(JSON.stringify(state));
        let upsellSkus = [];
        upsellSkus = item.product.upsellSkus.split(",");
        productsList.filter((product) => {
            upsellSkus.filter((productId) => {
                if( product.id === productId ) {
                    product.offers.filter((offer) => {
                        if( item.offer.distributor.name === offer.distributor.name ) {
                            const index = upsellProduct.findIndex((up) => up.id === product.id)
                            if ( index < 0 ) {
                                upsellProduct.push(product);
                            }
                        }
                    })
                }
            })
        });
    }


    let promotionApplied = "";
    const backOrderQty = parseInt(getBackOrderQty(item.offer));
    const highUOM = getHighUOM(item.product); 
    const numOfEaches = getNumOfEaches(item.product);
    const uomInHighUOM = getUOMInHighUom(item.product);
    const displayUnit = item.offer.displayUnit
    const onHandQty = item.offer.availableQuantity - backOrderQty;

    if(item?.promotions){
        item.promotions=(item.promotions||[]).filter((promotion)=>promotion?.configuration?.internalDescription!=excludePromoInternalDesc);
        promotionApplied = "Buy "+`${item?.product?.description}`+" and, Get "+`${item?.promotions[0]?.configuration?.internalDescription}`;
    }
    const freeItemQty = (item.promotions || []).reduce((total, currentItem) => {
        return total = total + currentItem.offeredQuantity;
    }, 0)

    let limit = 500;
    let start = 1;
    if (item.quantity <= onHandQty && onHandQty < limit) {
        limit = onHandQty;
    } else if (item.quantity > onHandQty) {
        if (backOrderQty < limit)
            limit = item.offer.availableQuantity;
        start = onHandQty + 1;
    }
    if (item.error) {
        dropdownNumbers.push(item.quantity);
    } else {
        start = start <= 0 ?  1 : start;
        for (let index = start; index <= limit; index++) {
            dropdownNumbers.push(index);
        }
    }

    const keyDetailsLinkHandler = (e, url) => {
        if (url) {
            dispatch(tealiumLink({
                event_name: 'Back to Product',
                funnel_stage: 'Cart Page',
            }))
            window.location.href = getNormalizedProductURL(url)
        }
        e.preventDefault();
    }


    const removeItemClickEventHandler = (e) => {
        e.preventDefault();
        setModalType('remove');
        setDisplayModal(true);
    }

    const removeItemHandler = () => {
        setLoading(true);
        dispatch(tealiumLink({
            event_name: 'Remove Product',
            funnel_stage: 'Cart Page',
        }));
        removeItemAndCall(null);
    }

    const changeBuyingOptionsHandler = (e) => {
        e.preventDefault();
        setModalType('options');
        setDisplayModal(true);
    }

    const removeItemAndCall = (callBack) => {
        const priceChange = (0 - item.lineitemPrice);
        dispatch(removeCartLineItem(item.id));
        dispatch(
            updateDistributorAmount(
                item.offer.distributor.name,
                priceChange
            )
        );
        dispatch(saveCartProgress(null, callBack, setLoading));
        addRemovedUpsellItem();
    }

    const confirmChange = (addlFields) => {
        dispatch(tealiumLink({
            event_name: 'Change Buying Option',
            funnel_stage: 'Cart Page',
        }));
        setDisplayModal(false);
        const refinements = addlFields.filter(f => f.code === process.env.REACT_APP_ORDER_DETAILS_REFINEMENT_PREFIX)
            .map(f => f.value);
        if (refinements[0]) {
            refinements[0] = '#' + Object.entries(JSON.parse(refinements[0]))
                .map(e => e[0] + '=' + e[1])
                .join('|')
        } else {
            refinements.push('');
        }

        removeItemAndCall(() => { history.push('/buyingOptions/' + item.product.id + refinements[0]); });
    }

    const cancelChange = () => {
        setDisplayModal(false);
    }

    const addRemovedUpsellItem = () => {
        state.forEach((product) => {
            if (product.id == item.product.id) {
                let isNewOffer = true;
                product.offers.forEach((offer) => {
                    if (offer.id === item.offer.id) {
                        isNewOffer = false;
                     }
                });
                isNewOffer ? product.offers.push(item.offer) : null;
            }
        });
    }

    const filterOffersBasedOnUom = (product, distributorName) => {        
        const lowUom = product.lowUom;
        const finalizedOffers = [];
        const distributorGroupedOffers = product.offers.filter((offer) => offer.distributor.name === distributorName);
        if (lowUom != null && lowUom != '') {
            if (distributorGroupedOffers.length > 1) {
                const lowUomOffers = distributorGroupedOffers.filter((offer) => offer.displayUnit == lowUom);
                lowUomOffers.length == 0 ? 
                        finalizedOffers.push(...distributorGroupedOffers) : 
                        finalizedOffers.push(...lowUomOffers);
            } else {
                finalizedOffers.push(...distributorGroupedOffers);
            }
        } else {
            finalizedOffers.push(...distributorGroupedOffers);
        }
        return finalizedOffers;
    }
    
    const imageUrl = item.product.imgURL
        ? item.product.imgURL
        : 'https://www.3m.com/3m_theme_assets/themes/3MTheme/assets/images/unicorn/NoImage.jpg';

    let unitOfMeasure = '';
    if (item.offer && item.offer.displayUnit) {
        const uom = item.offer.displayUnit;
        unitOfMeasure = uom.charAt(0).toUpperCase() + uom.toLowerCase().slice(1);
    }
    const freeItemImageUrl = item.offer.promotionDetails?.length > 0 ? 
        item.offer.promotionDetails[0].offers[0].imgUrl : imageUrl;

    const freeItemProductTitle = item.offer.promotionDetails?.length > 0 ?
        item.offer.promotionDetails[0].offers[0].productTitle : (item.product?.description ? item.product.description : null);

    const freeItemsUOM = item.offer.promotionDetails?.length > 0 ? 
        item.offer.promotionDetails[0].offers[0].displayUnit : (unitOfMeasure === '' ? 'none' : unitOfMeasure);


    let lowUOM = '';
    const findLowUOM = getLowUOM(item.product);
    lowUOM = findLowUOM.toString().charAt(0).toUpperCase() + findLowUOM.toString().toLowerCase().slice(1);
    
    return (
        <>
        <Flex className={styles.CartItem} key={item.id}>
            {displayModal &&
                <Modal
                    title={t(`${baseTranslationKey}.${modalType === 'remove'
                        ? 'removeDialog'
                        : 'changeDialog'
                        }.title`)}
                    actions={{
                        confirmEvent: () => modalType === 'remove'
                            ? removeItemHandler()
                            : confirmChange(item.offer.offerAdditionalFields),
                        confirmLabel: t(`${baseTranslationKey}.${modalType === 'remove'
                            ? 'removeDialog'
                            : 'changeDialog'
                            }.submitButton`),
                        cancelLabel: t(`${baseTranslationKey}.${modalType === 'remove'
                            ? 'removeDialog'
                            : 'changeDialog'
                            }.cancel`),
                        cancelEvent: cancelChange,
                        dismissEvent: cancelChange
                    }}
                    show={displayModal}
                >
                    <MdsText mdsType="body-bold" isInline={true}>
                        {t(`${baseTranslationKey}.${modalType === 'remove'
                            ? 'removeDialog'
                            : 'changeDialog'
                            }.message`)}
                    </MdsText>
                </Modal>
            }
            {item.promotions && item.promotions.length > 0 && !freeItemQty > 0 &&
            <div className={styles.promotionApplied} style={{margin:"0px"}}>
                <span className={styles.promotionApplied_name}>{t(
                            'translation.common.items.singleItemSummary.promotion.promotionMessage'
                        )}</span>
                <span>{promotionApplied}</span>
            </div>
            }
            <Box className={styles.ProductDetail}>
            <ImageContainer
                className={styles.CartItem__ItemImage}
                src={imageUrl}
                alt={item.product.description}
            />
            <div style={{width:"inherit"}}>
                <div className={styles.CartItem__KeyDetails}>
                    <a
                        className={`${item.id}-description`}
                        onClick={(e) => keyDetailsLinkHandler(e, item.product.productURL)}
                    >
                        {(item.product && item.product.description) ||
                            'PLACEHOLDER'}
                    </a>
                    <div className={styles.CartItem__KeyDetails__Price}>
                        <MdsHeader5>
                            {currencyFormatter.format(item.offer.price)}/
                            {unitOfMeasure.toLowerCase()}
                        </MdsHeader5>
                    </div>
                </div>
                <div className={styles.itemUomAndPromotion}>
                    <span>
                    {t(
                        'translation.common.items.singleItemSummary.unitOfMeasurement'
                    )}
                    : {displayUnit === highUOM && uomInHighUOM != 0 && findLowUOM != 0 ?
                            unitOfMeasure + " " + "(" + uomInHighUOM + " " + lowUOM + ")" : displayUnit === findLowUOM && numOfEaches != 0 ?
                                unitOfMeasure + " " + "(" + numOfEaches + ")" : unitOfMeasure}
                    </span>
                    {item.offer.promotions?.length > 0 ? 
                    <a onClick = {() => showPromotionAvailable ? 
                        setShowPromotionAvailable(false) : 
                        setShowPromotionAvailable(true)}>
                        {t(`${baseTranslationKey}.promotion.promotionAvailable`)}
                    </a> : null}
                </div>
                    <div>
                        <div className={styles.ItemQuantity}>
                            {t('translation.common.items.singleItemSummary.quantity')}:
                        <div
                            className="mds-form_element mds-form_select"
                            style={{
                                width: '10%',
                                marginLeft: 10,
                                minWidth: '4.5rem',
                            }}
                        >                           
                            <QuantitySelector 
                                 item={item}
                                 quantity = {+item.quantity}
                                 availableQty = {item.offer.availableQuantity}
                                 backOrderQty = {backOrderQty}
                                 error = {!!item?.error}
                            />
                        </div>
                    </div>
                    <div className={styles.AppliedPromotion}>
                        {freeItemQty > 0 ? (`+${freeItemQty} ${t(
                            'translation.common.items.singleItemSummary.promotion.freeItemMessage'
                        )} `) : item.promotions && item.promotions.length > 0 && !freeItemQty > 0 ? (`${t(
                            'translation.common.items.singleItemSummary.promotion.promotionMessage'
                        )}`) : ""}
                    </div>
                </div>

                <Box className={styles.bottomDetails}>
                    <div className={styles.itemLinks}>
                        <MdsLink
                            className={styles.link}
                            onClick={(e) => changeBuyingOptionsHandler(e)}
                        >
                            {t(
                                'translation.common.items.singleItemSummary.changeBuyingOptions'
                            )}
                        </MdsLink>
                        <span className={styles.linkSpan}></span>
                        <MdsLink
                            className={`${styles.link} ${item.id}-remove`}
                            onClick={(e) => removeItemClickEventHandler(e)}

                        >
                            {t(
                                'translation.common.items.singleItemSummary.remove'
                            )}
                        </MdsLink>
                    </div>
                    <Box className={styles.pricing}>
                        <Box className={styles.productTotal}>
                            <span>
                                {t(
                                    'translation.common.items.singleItemSummary.productTotal'
                                )}
                                :{' '}
                            </span>
                            <MdsText mdsType="body-bold" isInline={true}>
                                {currencyFormatter.format(addBD(0, item.lineitemPrice).toFixed(2))}
                            </MdsText>
                        </Box>
                    </Box>
                </Box>
                <MdsText msdType="body" isInline={true} className={styles.error}>
                    {item.error ? t(`translation.cart.lineError.${item.error}`) : null}
                </MdsText>
                {freeItemQty > 0 && (
                            <Flex className={styles.freeItem} key={item.id}>
                                <div className={styles.CartItem__ItemImage}>
                                    <ImageContainer
                                        src={item.offer.distributor.imgURL}
                                        alt={item.offer.distributor}
                                    />
                                    <ImageContainer
                                        src={freeItemImageUrl}
                                        alt={freeItemProductTitle}
                                    />
                                </div>
                                <div className={styles.freeItem__details}>
                                    <div className={styles.freeItem__details_header}>
                                    {t(`${baseTranslationKey}.promotion.promotion`)}{item.offer.distributor.name}
                                    </div>
                                    <div className={styles.freeItem__description}>
                                        {(freeItemProductTitle &&
                                            freeItemProductTitle) ||
                                            'PLACEHOLDER'}
                                    </div>
                                    <div className={styles.freeItem__uom}>
                                        <div className={styles.freeItemQuantity}>
                                            {t(
                                                `${baseTranslationKey}.unitOfMeasurement`
                                            )}
                                            : {freeItemsUOM.charAt(0).toUpperCase() + freeItemsUOM.toLowerCase().slice(1)}
                                        </div>
                                        <div className={styles.freeItemQuantity_free}>
                                            {t(
                                                'translation.common.items.promotionSummary.freeItemSummary.free'
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.freeItemQuantity}>
                                        {t(`${baseTranslationKey}.quantity`)}:
                                        <span style={{marginLeft:"10px"}}>{freeItemQty}</span>
                                    </div>
                                </div>
                            </Flex>
                        )}
                </div>
            </Box>
        </Flex>
        {showPromotionAvailable && item.offer.promotions?.length > 0 &&
            <div>
                <Box className={styles.upsellSectionHeader}>
                    <h3>{t(`${baseTranslationKey}.promotion.promotionAvailable`)}</h3>
                        <a onClick = {() => 
                            togglePromotionAvailable ? 
                            setTogglePromotionAvailable(false) : 
                            setTogglePromotionAvailable(true)}> 
                            {togglePromotionAvailable? 
                                (<span>{t('translation.cart.upsellCapabilities.close')}{' '} 
                                <i className="MMM--icn MMM--icn_down_arrow mix-MMM--icn_white" /></span>) : 
                                (<span>{t('translation.cart.upsellCapabilities.open')}{' '}
                                <i className="MMM--icn MMM--icn_up_arrow mix-MMM--icn_white" /></span>) }
                        </a>
                </Box>
                { togglePromotionAvailable && 
                item.offer.promotions?.map((promotion, index) => {
                    return <PromotionSummary item={item} promotion={promotion} key={index} setLoading={setLoading}/>
                })}
            </div>
        } 
        {item.product.upsellSkus && upsellProduct.length > 0 && !hideAllUpsells &&
        <div className={styles.upsellContainer}>
            <Box className={styles.upsellSectionHeader}>
                <h3>{t('translation.cart.upsellCapabilities.commonlyPurchasedTogether')}</h3>
                <a 
                onClick={() => {
                    openUpsellProducts ? setOpenUpsellProducts(false) : setOpenUpsellProducts(true);
                }}> 
                    {openUpsellProducts? 
                    (<><span className={styles.upsellToggleText}>{t('translation.cart.upsellCapabilities.close')}</span>&nbsp; 
                    <i className="MMM--icn MMM--icn_up_arrow mix-MMM--icn_white" /></>) : 
                    (<><span className={styles.upsellToggleText}>{t('translation.cart.upsellCapabilities.open')}</span>&nbsp;
                    <i className="MMM--icn MMM--icn_down_arrow mix-MMM--icn_white" /></>) }
                </a>
            </Box>
            {upsellProduct.map((product, index) => {
                return (
                    <div key={index}>
                        { filterOffersBasedOnUom(product, item.offer.distributor.name).map((offer, elem) => {
                                return (
                                    <div style={{ display: openUpsellProducts ? '' : 'none',}} key={`${product.id}-${elem}`}>
                                        <UpsellItem product={product} offer={offer} setLoading={setLoading}/>
                                    </div>
                                );
                        })}
                    </div>
                );
            })}
        </div>
        }
        </>
    );
};

SingleItemDescription.propTypes = {
    item: PropTypes.object.isRequired,
    updateCartQuantity: PropTypes.func,
    removeCartLineItem: PropTypes.func,
    setLoading: PropTypes.func,
};

export default SingleItemDescription;
