import React from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {tealiumLink} from '../../../redux/actions/trackingActions.js';
import styles from './ProductInfo.module.scss';
import {useIsMobile} from '../../../assets/utils/utilityService';
import MdsText from '../../atomics/text/mds-text';
import Banner from '../../common/banner/generic';

const Link = ({productUrl}) => {
    const trackingConstants = {
        event_name: 'Return to Product Clicked',
        funnel_stage: 'Distributor Selection',
    };

    const dispatch = useDispatch();

    return (
        <a
            href={productUrl}
            className={`mds-link_primary mds-font_body ${styles.product_info__link}`}
            onClick={() => {
                dispatch(tealiumLink(trackingConstants));
            }}
        >
            <i
                className="MMM--icn MMM--icn_lt2 mds-margin_xSmall--right"
                aria-hidden={true}
            ></i>{' '}
            Back to product information
        </a>
    );
};

Link.propTypes = {
    productUrl: PropTypes.string,
};

const Container = ({children}) => {
    return <div className={styles.product_info}>{children}</div>;
};

Container.propTypes = {
    children: PropTypes.any,
};

const Name = ({productInfo}) => {
    const isMobile = useIsMobile();
    return (
        <div className={styles.product_info__name}>
            <h1
                className={`${
                    isMobile ? 'mds-font_body' : 'mds-font_header--3'
                } mds-margin_medium--top`}
            >
                {productInfo}
            </h1>
        </div>
    );
};
Name.propTypes = {
    productInfo: PropTypes.string,
};

const Image = ({imageUrl, productInfo}) => {
    return (
        <>
        {imageUrl ? (
            <img
            src={imageUrl.replace('?boundedSize=150', '?boundedSize=310')}
            className={`mds-margin_medium--top mds-margin_large--right ${styles.product_info__image}`}
            alt={productInfo}
        ></img>
        ):null}
        </>
    );
};
Image.propTypes = {
    imageUrl: PropTypes.string,
    productInfo: PropTypes.string,
};

const Details = ({
    productInfo, 
    imageUrl, 
    headerPromo, 
    headerPromoLinkURL, 
    headerPromoLinkText, 
    headerPromoDisplayType,
    seoText
}) => {

    let displayHeaderPromo = false;
    if (headerPromo) {
        if (headerPromoDisplayType == null 
            || headerPromoDisplayType == 'distributor_selection' 
            || headerPromoDisplayType == 'all'
        ) {
            displayHeaderPromo = true;
        }
    }

    return (
        <>
        <div className={styles.product_info__detailss}>
            <Image imageUrl={imageUrl} productInfo={productInfo} />
            <Name productInfo={productInfo} />
        </div>
        { displayHeaderPromo && 
            <Banner className={styles.promoBanner} icon={String.fromCharCode(parseInt('0021', 16))}
                color="blue" show="true">
                    <MdsText
                    data-testid='productProgramAlertMsg'
                    mdsType='body-bold'
                    el='strong'
                    className={styles.msgWrapper}
                    isInline={true}>
                       {headerPromo} - <a href={headerPromoLinkURL}>{headerPromoLinkText}</a>
                    </MdsText>
            </Banner>
        }
        { seoText && 
            <MdsText
            data-testid='productDspSeoText'
            mdsType='body'
            className={styles.dspSeoText}
            isInline={true}>
               {seoText}
            </MdsText>
        }
        </>
    );
};

Details.propTypes = {
    imageUrl: PropTypes.string,
    productInfo: PropTypes.string,
    headerPromo: PropTypes.string,
    headerPromoLinkText: PropTypes.string,
    headerPromoLinkURL: PropTypes.string,
    headerPromoDisplayType: PropTypes.string,
    seoText: PropTypes.string
};

export {Link, Container, Details, Name, Image};
