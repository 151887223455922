/* eslint-disable max-len */
/* eslint-disable max-lines */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ponumber.module.scss';
import Input from '../atomics/formElements/wrappers/input';
import {updatePONumber} from '../../redux/actions/miscellaneousActions.js';
import { connect } from 'react-redux';
import i18n from 'i18next';

class PONumber extends Component {
    constructor(props) {
        super(props);            
        const poNumber = this.props.poNumber;    
        this.state = {
            poNumber: poNumber,            
            baseTranslationKey : 'translation.views.checkoutview',
        };                    
    }
    poNumberUpdate(value) {
        const poNumber = this.state.poNumber;
        poNumber['poNumber'] = value;
        this.setState({
            poNumber : poNumber
        });
        this.props.updatePONumber(poNumber);
    }

    render(){
        return(
            <div className={styles.poNumber}>
                    <Input
                        name="poNumber"
                        type="text"
                        uniqueID={'poNumber'}
                        dataTestId="poNumber"
                        fields={{
                            fieldName: i18n.t(`${this.state.baseTranslationKey}.poNumber`),
                            maxLength: "50",
                        }}
                        value={this.state.poNumber.poNumber}
                        onChange={(e) => this.poNumberUpdate(e.target.value)}                       
                    />
            </div> 
        )
    }
}


PONumber.propTypes = {
    updatePONumber: PropTypes.func,
    poNumber: PropTypes.shape({
       poNumber: PropTypes.string,
    })
}

function mapStateToProps(state) {
    return {
        poNumber: state.miscellaneous.poNumber,
    };
}

const mapDispatchToProps = {    
        updatePONumber   
};
export default connect(mapStateToProps, mapDispatchToProps)(PONumber);

