import React from 'react';
import PropTypes from 'prop-types';
// Components
import {MdsText} from '../../mds';
import CCLogo from '../images/cc-logo';
// Styling
import styles from './card-review.module.scss';
import {useTranslation} from 'react-i18next';

const CardReview = (props) => {

  const view = props.view;
  
  const cardName = props.vendor.charAt(0).toUpperCase() + props.vendor.slice(1);
  const { t } = useTranslation();
  const key = 'translation.payments'
  return (
    <>
      {view == 'short' ?
        <div className={styles.card_review}>
          <MdsText mdsType='body-bold' className={styles.card_review__ending}>{cardName} {t(`${key}.endingIn`)} {props.ending}</MdsText>
        </div>

        :

        <div className={styles.card_review}>
          <CCLogo vendor={props.vendor} className={styles.card_review__image} />
          <MdsText mdsType='body-bold' className={styles.card_review__ending}> {t(`${key}.endingIn`)} {props.ending}</MdsText>
          <MdsText mdsType='body-bold'>Expires on {props.exp}</MdsText>
        </div>
      }
    </>
  )
};

CardReview.propTypes = {
  vendor: PropTypes.string,
  ending: PropTypes.string,
  exp: PropTypes.string,
  view: PropTypes.string,
}

export default CardReview;
