import React from 'react';
import PropTypes from 'prop-types';
import headerData from './headerData';

export default class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  render() {
    const navbarHeaders = headerData.navbar.map((header, index) => {
      return (
        <li key={index} className="m-navbar_listItem">
          <a tabIndex={0} id={`navbar_link-${header.title.toLowerCase()}`} className="m-navbar_link" aria-haspopup="true"
          aria-expanded={!this.props.navHidden && index === this.props.navIndex} onClick={() => this.state.onClick(index)}>
            {header.title}
          </a>
        </li>
      );
    });
    return (
      <ul id="navOptions" role="menubar" ref={this.state.nav} >
        {navbarHeaders}
      </ul>
    );
  }
}

Navbar.propTypes = {
  navHidden: PropTypes.bool,
  navIndex: PropTypes.number,
};

Navbar.defaultProps = {
  navHidden: true,
  navIndex: -1,
};
