import React from 'react';
import * as PurchaseStates from '../../redux/reducers/reducer-helpers/purchaseStates';
import BreadcrumbData from './breadcrumbData';
import BreadcrumbElement from './breadcrumbElement';
import styles from './breadcrumb.module.scss';
import * as BreadcrumbStatus from './breadcrumbElement/breadcrumbStatus';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const breadcrumbOrder = BreadcrumbData.reduce((map, obj) => {
    map[obj.state] = obj.order;
    return map;
}, {});

const getCheckoutStatus = (currentPurchaseState, breadcrumbElementOrder) => {
    const currentOrder = breadcrumbOrder[currentPurchaseState];

    if (breadcrumbElementOrder < currentOrder) {
        return BreadcrumbStatus.COMPLETE;
    } else if (breadcrumbElementOrder === currentOrder) {
        return BreadcrumbStatus.IN_PROGRESS;
    } else {
        return BreadcrumbStatus.PENDING;
    }
};

const breadcrumb = (props) => {
    const {t} = useTranslation();
    if (props.purchaseState === PurchaseStates.CART) {
        return null;
    }

    const buildBreadCrumbElement = (
        currentPurchaseState,
        breadcrumbElementState
    ) => {
        const checkoutStatus = getCheckoutStatus(
            currentPurchaseState,
            breadcrumbElementState.order
        );

        const breadCrumbElement = (
            <BreadcrumbElement
                key={breadcrumbElementState.order}
                step={breadcrumbElementState.step}
                label={t(
                    'translation.breadcrumb.' + breadcrumbElementState.label
                )}
                purchaseState={breadcrumbElementState.state}
                breadcrumbStatus={checkoutStatus}
                handleClick={(purchaseState) =>
                    props.handleClick(purchaseState)
                }
            />
        );
        return breadCrumbElement;
    };

    const breadcrumb = (
        <div className={styles.breadcrumb}>
            {BreadcrumbData.map((obj) => {
                return buildBreadCrumbElement(props.purchaseState, obj);
            })}
        </div>
    );

    return breadcrumb;
};

breadcrumb.propTypes = {
    handleClick: PropTypes.func,
};

export default breadcrumb;
