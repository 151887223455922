import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdsText } from '../../mds';
import styles from './generic-banner.module.scss';


const Banner = (props) => {

    const {icon, timeOut, color, dismissEvent = () => {}} = props;

    if (timeOut) {
        useEffect(() => {
            const timer = setTimeout(() => {
                dismissEvent();
            }, timeOut * 1000);
            return () => clearTimeout(timer);
        }, []);
    }

    return (
        <div
            className={`${styles[`mds_banner__container__${color}`]}
                        ${styles.mds_banner__container}
                        ${props.className || ''}`}
        >
            <div className={`${styles[`mds_banner__icon_container__${color}`]}
                             ${styles.mds_banner__icon_container}`}>
                <MdsText className={styles.mds_banner__icon}>
                    {icon}
                </MdsText>
            </div>
            <MdsText className={styles.mds_banner__message}>{props.children}</MdsText>
        </div>
    )
}

Banner.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    icon: PropTypes.string,
    timeOut: PropTypes.number,
    color: PropTypes.string,
    dismissEvent: PropTypes.func
}

export default Banner;
