import { useState, useEffect } from 'react'

/**
 * useIsMobile is a hook for the current screensize being mobile
 * @param {bool} initialValue The default value of the window screen being a desktop screen
 * @return {bool} If the screensize is mobile
 */
 const useIsMobile = (initialValue = false) => {
    const [isMobile, setIsMobile] = useState(initialValue)

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setIsMobile(window.innerWidth < 768 ? true : false)
        }

        // Add event listener
        window.addEventListener('resize', handleResize)

        // Call handler right away so state gets updated with initial window size
        handleResize()

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty array ensures that effect is only run on mount

    return isMobile
}

export { useIsMobile }