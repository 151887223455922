import React from 'react';
import PropTypes from 'prop-types';

const mdsHeader3 = (props) => (
    <h3 className="mds-font_header--3">{props.children}</h3>
);

mdsHeader3.propTypes = {
    children: PropTypes.any,
};

export default mdsHeader3;
