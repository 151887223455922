/* eslint-disable max-lines*/
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { MdsHeader5 } from '../../mds';
import ImageContainer from '../images/image-container';
import { tealiumLink, checkoutEvent} from '../../../redux/actions/trackingActions.js';
import { useTranslation } from 'react-i18next';
import { getBackOrderQty, getNormalizedProductURL, currencyFormatter, multiplyBD } from '../utlities';
import { addToCart, selectOffer } from '../../../redux/actions/offerActions.js';
import { Link } from 'react-router-dom';
import { Button } from '@3mcom/mds-library/dist/index';
import styles from './promotionSummary.module.scss';
import {getRefinements} from '../../distributorPageCards/Service.js';

const promotionSummary = ({item,promotion,setLoading}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dropdownNumbers = [];
    const backOrderQty = getBackOrderQty(item.offer);
    const onHandQty = item.offer.availableQuantity - backOrderQty;
    const BUY_NOW_EVENT = 'Buy Now';
    const refinements = getRefinements();

    const keyDetailsLinkHandler = (e, url) => {
        if (url) {
            dispatch(tealiumLink({
                event_name: 'Back to Product',
            }));
            window.location.href = getNormalizedProductURL(url);
        }
        e.preventDefault();
    }

    const promotionDescription = "Buy a "+`${item.product.description}`+" and get " 
        +`${promotion.internalDescription}`+" by "+`${item.offer.distributor.name}`;

    let unitOfMeasure = '';
    if (item.offer && item.offer.displayUnit) {
        const lc = item.offer.displayUnit;
        unitOfMeasure = lc.charAt(0).toUpperCase() + lc.toLowerCase().slice(1);
    }
    let quantity;
    const dropDownHandler = (e) => {  
        quantity = parseInt(e.target.value);
    }
    
    let limit = 500;
    let start = 1;
    if (item.product.quantity <= onHandQty && onHandQty < limit) {
        limit = onHandQty;
    } else if (item.product.quantity > onHandQty) {
        if (backOrderQty < limit)
            limit = item.offer.availableQuantity;
        start = onHandQty + 1;
    }
    if (item.product.error) {
        dropdownNumbers.push(item.product.quantity);
    } else {
        for (let index = start; index <= limit; index++) {
            dropdownNumbers.push(index);
        }
    }
    const imageUrl = item.product.imgURL
        ? item.product.imgURL
        : 'https://www.3m.com/3m_theme_assets/themes/3MTheme/assets/images/unicorn/NoImage.jpg';

    const checkoutFunction = (event) => {
        setLoading(true);
        dispatch(selectOffer(item.offer, item.offer.price))
        dispatch(addToCart( quantity? quantity:1, item.product, {
            ...item.offer,
            offerAdditionalFields: item.offer.offerAdditionalFields
                ? [...item.offer.offerAdditionalFields, {...refinements}]
                : [{...refinements}],
        },setLoading))
        dispatch(
            tealiumLink({
                event_name: event
            })
        );

        const subTotalAmount = quantity
        ? multiplyBD(quantity, item.offer.price)
        : 0;

        dispatch(checkoutEvent('cart_add', item.product.id, subTotalAmount, null, quantity));

    };

    return (
        <Flex className={styles.promotionItem} key={item.offer.id}>
            <div>
                {promotionDescription}
            </div>
            <Box className={styles.ProductDetail}>
            <ImageContainer
                className={styles.promotionItem__ItemImage}
                src={imageUrl}
                alt={item.product.description}
            />
            <div style={{width:"inherit"}}>
                <div className={styles.promotionItem__KeyDetails}>
                    <a
                        className={`${item.product.id}-description`}
                        onClick={(e) => keyDetailsLinkHandler(e, item.product.productURL)}
                    >
                        {(item.product && item.product.description) ||
                            'PLACEHOLDER'}
                    </a>
                    <div className={styles.promotionItem__KeyDetails__Price}>
                        <MdsHeader5>
                            {currencyFormatter.format(item.offer.price)}/
                            {unitOfMeasure.toLowerCase()}
                        </MdsHeader5>
                    </div>
                </div>
                <div className={styles.uom}>
                    <span>
                    {t(
                        'translation.common.items.promotionSummary.unitOfMeasurement'
                    )}
                    : {unitOfMeasure}
                    </span>
                </div>
                <div className={styles.addtoCartAndQty}>
                    <div className={styles.ItemQuantity}>
                        {t('translation.common.items.promotionSummary.quantity')}:
                        <div
                            className="mds-form_element mds-form_select"
                            style={{
                                width: '10%',
                                marginLeft: 10,
                                minWidth: '4.5rem',
                            }}
                        >
                            <select
                                value={item.product.quantity}
                                className={`${item.offer.id}-select`}
                                onChange={(e) => dropDownHandler(e)}
                                aria-labelledby={`${item.offer.id}-Label ${item.offer.id}-requiredLabel`}
                                disabled={!!item.product.error}
                            >
                                {dropdownNumbers.map((x) => {
                                    return (
                                        <option
                                            key={x}
                                            value={x}
                                            style={{ padding: 30 }}
                                        >
                                            {x}
                                        </option>
                                    );
                                })}
                            </select>
                            <i className="MMM--icn MMM--icn_down_arrow mix-MMM--icn_red mds-form_element--link mds-font_bold"></i>
                        </div>
                    </div>
                    <div>
                        <Link
                            to={{
                                pathname: '/checkout',
                                state: { addedItem: item.product },
                            }}
                        >
                            <Button
                                buttonType="secondary"
                                size="medium"
                                className="promoAddToCartBtn"
                                onClick={() => {
                                    checkoutFunction(BUY_NOW_EVENT);
                                }}
                            >
                                {t(
                                    'translation.common.items.promotionSummary.addToCart'
                                )}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            </Box>
        </Flex>
    );
};

promotionSummary.proptypes = {
    item: PropTypes.object.isRequired,
    promotion: PropTypes.object.isRequired,
    setLoading: PropTypes.func,
}

export default promotionSummary;