/* eslint-disable max-lines */
/* eslint-disable max-len */
const headerData = {
  profilePopUp: {
    unauthenticated: [
      {
        href: '/checkoutLogin',
        text: 'Sign in',
      },
      {
        href: 'https://order.3m.com/store/bComUSSite/en_US/login',
        text: 'Sign in to bCom',
      }
    ],
    authenticated: {
      myAccount: [
        {
          text: 'Account Home',
          href: process.env.REACT_APP_MY_ACCT_URL
        },
        {
          text: 'Profile Settings',
          href: process.env.REACT_APP_MY_ACCT_URL + '/#/profile-settings'
        },
        {
          text: 'Login and Security',
          href: process.env.REACT_APP_MY_ACCT_URL + '/#/login-security'
        },
        {
          text: 'Communication Preferences',
          href: process.env.REACT_APP_MY_ACCT_URL + '/#/communications'
        }
      ],
      myLinks: [
        {
          text: 'Order History',
          href: '/myaccount/orderHistory'
        },
        {
          text: 'Payment Methods',
          href: '/myaccount/payments'
        },
        {
          text: 'My Lists',
          href: '/myaccount/mylist'
        },
        {
          text: 'See More',
          href: process.env.REACT_APP_MY_ACCT_URL + '/#/home'
        }
      ]
    }
  },
  localizationPopUp: {
    localeOptions: [
      {
        localeLanguage: 'English - EN',
        value: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/',
        checked: true,
      },
    ],
    changeLocation: {
      text: 'Change 3M Location',
      href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/select-location/',
    },
  },
  navbar: [
    {
      title: 'Products',
      type: 'dropdown-with-links',
      links: [
        {
          text: 'Abrasives',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/abrasives/',
        },
        {
          text: 'Adhesives, Sealants & Fillers',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/adhesives/',
        },
        {
          text: 'Advanced Materials',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/advanced-materials/',
        },
   	{
          text: 'Automotive Parts & Hardware',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/automotive-parts-hardware/',
        },
        {
          text: 'Building Materials',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/building-materials/',
        },
        {
          text: 'Cleaning Supplies',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/cleaning-supplies/',
        },
        {
          text: 'Coatings',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/coatings/',
        },
        {
          text: 'Communications',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/communications/',
        },
        {
          text: 'Compounds & Polishes',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/compounds-polishes/',
        },
        {
          text: 'Dental & Orthodontics',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/dental-orthodontics/',
        },
        {
          text: 'Electrical',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/electrical/',
        },
        {
          text: 'Electronics Components',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/electronics-components/',
        },
        {
          text: 'Films & Sheeting',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/films-sheeting/',
        },
        {
          text: 'Filtration & Seperation',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/filtration-separation/',
        },
        {
          text: 'Home',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/home/',
        },
        {
          text: 'Insulation',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/insulation/',
        },
        {
          text: 'Lab Supplies & Testing',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/lab-supplies-testing/',
        },
        {
          text: 'Labels',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/labels/',
        },
        {
          text: 'Lubricants',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/lubricants/',
        },
        {
          text: 'Medical',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/medical/',
        },
        {
          text: 'Office Supplies',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/office-supplies/',
        },
        {
          text: 'Personal Protective Equipment',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/ppe/',
        },
        {
          text: 'Signage & Marking',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/signage-marking/',
        },
        {
          text: 'Tapes',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/tapes/',
        },
        {
          text: 'Tools & Equipment',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/c/tools-equipment/',
        },
        {
          text: 'View all 3M products',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/all-3m-products/~/All-3M-Products/?N=5002385+8711017+3294857497&rt=r3',
          className: 'l-dropdown-list_view-all-products',
        },
      ],
    },
    {
      title: 'Industries',
      type: 'dropdown-with-image',
      links: [
        {
          text: 'Automotive',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/automotive-us/',
        },
        {
          text: 'Commercial Solutions',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/commercial-solutions-us/',
        },
        {
          text: 'Consumer Markets',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/consumer-us/',
        },
        {
          text: 'Design & Construction',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/design-construction-us/',
        },
        {
          text: 'Electronics',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/electronics-us/',
        },
        {
          text: 'Energy',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/energy-us/',
        },
        {
          text: 'Government',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/government-us/',
        },
        {
          text: 'Health Care',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/health-care-us/',
        },
        {
          text: 'Manufacturing',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/manufacturing-us/',
        },
        {
          text: 'Safety',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/safety-us/',
        },
        {
          text: 'Transportation',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/transportation-us/',
        },
      ],
      imageDetails: {
        imgURL: 'https://multimedia.3m.com/mws/media/1812021O/industry-feature-image.png',
        altText: 'An auto factory worker inspecting car production line.',
        details: 'At 3M, we discover and innovate in nearly every industry to help solve problems around the world.',
      },
    },
    {
      title: 'Brands',
      type: 'dropdown-with-logos',
      link1Label: 'Products',
      link2Label: 'Brand Site',
      links: [
        {
          label: 'Ace™',
          imgURL: 'https://multimedia.3m.com/mws/media/1815178O/ace-brand-logo.png',
          link1: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/all-3m-products/~/All-3M-Products/ACE/?N=5002385+8711017+8713394+3294857497&rt=r3',
          link2: 'https://www.acebrand.com/3M/en_US/ace-brand/',
        },
        {
          label: 'Command™',
          imgURL: 'https://multimedia.3m.com/mws/media/1815179O/command-brand-logo.png',
          link1: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/all-3m-products/~/All-3M-Products/Command/?N=5002385+8711017+8711749+3294857497&rt=r3',
          link2: 'https://www.command.com/3M/en_US/command/',
        },
        {
          label: 'Filterete™',
          imgURL: 'https://multimedia.3m.com/mws/media/1815180O/filtrete-brand-logo.png',
          link1: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/all-3m-products/~/All-3M-Products/Filtrete/?N=5002385+8711017+8711754+3294857497&rt=r3',
          link2: 'https://www.filtrete.com/3M/en_US/filtrete/',
        },
        {
          label: 'Futuro™',
          imgURL: 'https://multimedia.3m.com/mws/media/1815181O/futuro-brand-logo.png',
          link1: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/all-3m-products/~/All-3M-Products/FUTURO/?N=5002385+8711017+8711744+3294857497&rt=r3',
          link2: 'https://www.futuro-usa.com/3M/en_US/futuro-us/',
        },
        {
          label: '3M™ Littmann® Stethoscopes',
          imgURL: 'https://multimedia.3m.com/mws/media/1815182O/littmann-brand-logo.png',
          link1: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/all-3m-products/~/All-3M-Products/Littmann/?N=5002385+8711017+8711724+3294857497&rt=r3',
          link2: 'https://www.littmann.com/3M/en_US/littmann-stethoscopes/',
        },
        {
          label: 'Nexcare™',
          imgURL: 'https://multimedia.3m.com/mws/media/1815183O/nexcare-brand-logo.png',
          link1: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/all-3m-products/~/All-3M-Products/Nexcare/?N=5002385+8711017+8711752+3294857497&rt=r3',
          link2: 'https://www.nexcare.com/3M/en_US/nexcare/',
        },
        {
          label: 'Post-It®',
          imgURL: 'https://multimedia.3m.com/mws/media/1815184O/post-it-brand-logo.png',
          link1: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/all-3m-products/~/All-3M-Products/Post-it/?N=5002385+8711017+8711713+3294857497&rt=r3',
          link2: 'https://www.post-it.com/3M/en_US/post-it/',
        },
        {
          label: 'Scotch™',
          imgURL: 'https://multimedia.3m.com/mws/media/1815185O/scotch-brand-brand-logo.png',
          link1: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/all-3m-products/~/All-3M-Products/Scotch/?N=5002385+8711017+8711729+3294857497&rt=r3',
          link2: 'https://www.scotchbrand.com/3M/en_US/scotch-brand/',
        },
        {
          label: 'Scotch-Brite™',
          imgURL: 'https://multimedia.3m.com/mws/media/1815186O/scotch-brite-brand-logo.png',
          link1: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/all-3m-products/~/All-3M-Products/Scotch-Brite/?N=5002385+8711017+8711730+3294857497&rt=r3',
          link2: 'https://www.scotch-brite.com/3M/en_US/scotch-brite/',
        },
        {
          // eslint-disable-next-line quotes
          label: "Scotch® Painter's Tape",
          imgURL: 'https://multimedia.3m.com/mws/media/1815187O/scotch-painters-tape-brand-logo.png',
          link1: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/all-3m-products/~/All-3M-Products/ScotchBlue/?N=5002385+8711017+8711753+3294857497&rt=r3',
          link2: 'https://www.scotchblue.com/3M/en_US/scotchblue/',
        },
        {
          label: 'View all 3M brands',
          link: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/our-brands/',
        },
      ],
    },
  ],
};

export default headerData;
