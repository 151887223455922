/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import './forms.scss';

const RadioButtonWrapper = (props) => {
  const {uniqueID} = props;
  const {name, options, type, className, size} = props.fields;
  const containerClasses = (className !== undefined ? className : '')
                          + ' '
                          + (size !== undefined ? 'mds-form_radiobutton--' + size : 'mds-form_radiobutton--x-small')
                          + ' mds_cm__input_checkbox';

  // Render each radio dial option for the specified name
  const radioButtons = options.map((option, index) => {
    const {fieldName, value, defaultSelected} = option;

    const onClickEventHandler = (e) => {
      if (props.onClick) props.onClick(e, option);
    }

    return (
      <div className={`mds-form_radiobutton ${containerClasses}`} key={`${uniqueID}-${index}`}>
        <input
          type="radio"
          name={name}
          value={value}
          className={type !== undefined ? 'mds-form_radiobutton--' + type : ''}
          id={`${uniqueID}-${index}`}
          aria-labelledby={`${uniqueID}-Label`}
          defaultChecked={option.defaultSelected}
          onChange={onClickEventHandler}
        />
        <label
          htmlFor={`${uniqueID}-${index}`}
          className="mds-form_radiobuttonLabel"
          id={`${uniqueID}-Label`}
        >
          {fieldName}
        </label>
      </div>
    );
  });

  return (radioButtons);
}

RadioButtonWrapper.propTypes = {
  uniqueID: PropTypes.string,
  onClick: PropTypes.func,
  fields: PropTypes.shape({
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.exact({
      fieldName: PropTypes.string,
      value: PropTypes.string,
      defaultSelected: PropTypes.bool,
    })),
    type: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.oneOf([
      PropTypes.number,
      PropTypes.string,
    ])
  })
};

export default RadioButtonWrapper;
