
export function updateSelectedEstimatedDeliveryOption(products, offerId, deliveryOption) {
    if(products[0]){
    const offIndex = products[0].offers.findIndex((off) => off.id == offerId);  
    products[0].offers[offIndex] = {   ... products[0].offers[offIndex], selectedDeliveryOption : deliveryOption,}
    } 
    return products;
  } 
  export function clearShippingData(products) {
    const newProducts = [...products];    
    if( products[0]){
     const newOffers = products[0].offers.map((offer) => {
       return {     
       ...offer, 
       deliveryOptions: null , 
       errors: null 
       }
     }); 
     newProducts[0].offers = newOffers;
     return newProducts;   
    } 
    return products;       
  } 

  export function insertOrUpdateObjectInArray(array, object) {
    let isInArray = false;
    const newArray = array.map((item) => {
      if (item.unit !== object.unit) {
        return item;
      }
      isInArray = true;
      return {
        ...object,
      };
    });
  
    if (!isInArray) {
      return [...array, object];
    } else {
      return newArray;
    }
  }

  export function loadUpsellOffers (upsellProducts, products) {
    const index = upsellProducts.findIndex((upsellProduct) => upsellProduct.id === products[0].id);
    if (index >= 0) {
      upsellProducts[index].offers = products[0].offers;
    } else {
      upsellProducts =  upsellProducts.concat(products);
    }
    return upsellProducts;
  }

  export function removeAddedUpsellOffer (upsellProducts, product, offer) {
    const index = upsellProducts.findIndex((upsellProduct) => upsellProduct.id === product.id);
    upsellProducts[index].offers = upsellProducts[index].offers.filter((upsellOffer) => upsellOffer.id !== offer.id);
    return upsellProducts;
  }

