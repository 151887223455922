import React from 'react';
import PropTypes from 'prop-types';
import headerData from './headerData';
import NavbarLogos from './navbarLogos';

const NavbarLinks = (props) => {
    const {linkList, navHidden, navbarLinksIndex, userDetails} = props
    const { authenticated } = userDetails
    const scrollToTop = (value) => {
        linkList.current.children[value + 2].scrollTo(0, linkList.offsetTop, 1000)
    }
    const backToTopAnimation = '.backToTop {scroll-behavior: smooth}';

    const links = headerData.navbar.map((list, index) => {
        const tabIsOpen = !(navHidden || !(navbarLinksIndex === index));

        if (list.type === 'dropdown-with-logos') {
            return <NavbarLogos key={index} list={list} navbarLinksOpened={tabIsOpen} />;
        }
        if (list.type !== 'dropdown-with-logos') {
            return (
            <ul key={index} id={`${list.title.toLowerCase()}-list`} className="l-dropdown-list_container backToTop" role="menu"
            aria-hidden={!tabIsOpen} style={tabIsOpen ? {display: 'block'} : {display: 'none'}}>
                {
                list.links.map((link, listIndex) => {
                    const lastItem = list.links.length - 1 === listIndex;
                    const className = link.className !== undefined ? link.className : 'MMM--basicLink';
                    return (
                    <li key={listIndex} className={`l-dropdown-list${lastItem && list.type === 'dropdown-with-links' ? '_last-item' : ''}`}
                    role="menuitem">
                        <a href={authenticated ? link.href.replace('/3M/', '/my3M/') : link.href} className={className}>{link.text}</a>
                        {lastItem && list.type === 'dropdown-with-links' &&
                        <button className="m-btn js-backtoTopBtn" onClick={() => scrollToTop(index)}>
                            <i className="MMM--icn MMM--icn_arrowUp"></i>
                        </button>
                        }
                    </li>
                    );
                })
                }
                {list.imageDetails !== undefined && list.type === 'dropdown-with-image' &&
                <div className="l-dropdown-list_industries-image">
                    <img className="img img_stretch mix-MMM--img_fancy" src={list.imageDetails.imgURL} alt={list.imageDetails.altText} />
                    <p>{list.imageDetails.details}</p>
                </div>
                }
            </ul>
        );
        }
    });
    return (
        <React.Fragment><style>{backToTopAnimation}</style>{links}</React.Fragment>
    );
}

NavbarLinks.propTypes = {
  linkList: PropTypes.object,
  navHidden: PropTypes.bool,
  navbarLinksIndex: PropTypes.number,
  userDetails: PropTypes.object
};

NavbarLinks.defaultProps = {
  linkList: {},
  navHidden: true,
  navbarLinksIndex: -1,
  userDetails:{authenticated: false}
};

export default NavbarLinks;
