/* eslint-disable camelcase */
import * as UserDefinitions from '../../components/common/userSettings/userDefinitions.js';

// const trackingInfo = {
//     event_name: '',
//     event_category: 'MKPL',
//     event_type: 'Conversion',
//     funnel_stage: '',
// };

const track = async (trackingType, constants, state) => {

    const info = {...constants};

    for (const item in state) {
        if (state.hasOwnProperty(item)) {
            info[`${item}`] = state[`${item}`];
        }
    }
    info.logged_in = state.signIn.signInType === UserDefinitions.REGISTERED_USER;

    if (trackingType === 'link') {

        const json = JSON.stringify(info);
        if (window.utag) {
            window.utag.link({data: json});
        }

    } else if (trackingType === 'view') {

        const firePageView = setInterval(() => {
            const json = JSON.stringify(info);
            if (window.utag) {
                window.utag.view({data: json});
                clearInterval(firePageView);
            }
        }, 200);
    }

};

export {track};
