/* eslint-disable */
import React from 'react';
import {MdsText} from '../mds';
import ViewHeader from '../common/view/header';
import {getBasketId, getBasketIdShort} from '../../connectivity/localStorage'
import PropTypes from 'prop-types';
import styles from './error.module.scss';

const ServiceErrorView = (props) => {

  const { maintenanceModeActive, location } = props;
  const taxErrorMsg = !!location ? (!!location.taxErrorMsg ? location.taxErrorMsg : false) : false;
  const shippingZoneCodeError = !!location ? (!!location.shippingZoneCodeError ? location.shippingZoneCodeError : false) : false;
  let shippingZoneCodeErrorMsg;
  if(shippingZoneCodeError)
    shippingZoneCodeErrorMsg = location.shippingZoneCodeErrorMsg;
    
  let errorPageHeader = "We're sorry, an error has occurred.";
  if (taxErrorMsg) 
    errorPageHeader = "We're sorry, we have identified an issue retrieving the tax rate for your basket.";
  else if(maintenanceModeActive)
    errorPageHeader = "We're sorry, we are currently in maintenance.";

  return (
    <main 
      className={styles.error_view_container} 
      id="MarketplaceErrorPage" 
      data-error-type="generic" 
      data-error-code-short={getBasketIdShort() !==null ? getBasketIdShort() : ''}
      data-error-code-full={getBasketId() !==null ? getBasketId() : ''}
    >
      <ViewHeader title={errorPageHeader}>
        {/* Shipping zone code error message */}
        {
          !!shippingZoneCodeError && 
          (
            <p>
            Our sincere apologies for the inconvenience.&#160;<span style={{fontWeight: '800'}}>{!!shippingZoneCodeErrorMsg ? shippingZoneCodeErrorMsg + ". " : ""}
            Please try again either by removing the selected products or by changing the shipping address.</span>&#160;
            Detailed information about this error has been sent to our applications team for further investigation.
            <br />
            <br />
            If you need immediate help, our customer service team can be reached at 
            <span style={{fontWeight: '800'}}> 1-800-538-5500.</span> Please reference
            <span style={{fontWeight: '800'}}>
              {getBasketIdShort() !==null ? ` "` + getBasketIdShort() + `"` : ` "000"`}
            </span> when connected with a representative. Thanks.
            <br />
            <br />
            To go back to your shopping cart <a href="/cart">click here.</a>
            </p>
          )
        }

        {/* Maintenance Mode Message */}
        {
          !!maintenanceModeActive && 
          (
            <p>
            {process.env.REACT_APP_MKPL_MAINTENANCE_MESSAGE}
            <br />
            <br />
            <span style={{fontWeight: '800'}}>The 3M Marketplace Team</span> 
            <br />
            <br />
            </p>
          )
        }

        {/* Tax Rate Error Page */}
        {
          !!taxErrorMsg && 
          (
            <p>
            Our sincere apologies for the inconvenience. Detailed information about this error has been sent to
            our applications team for further investigation. Your shopping cart will be available when the issue 
            is resolved, and you will be able to complete your transaction. Please try again later.
            <br />
            <br />
            If you need immediate help, our customer service team can be reached at 
            <span style={{fontWeight: '800'}}> 1-800-538-5500.</span> Please reference
            <span style={{fontWeight: '800'}}>
              {getBasketIdShort() !==null ? ` "` + getBasketIdShort() + `"` : ` "000"`}
            </span> when connected with a representative. Thanks.
            <br />
            <br />
            To go back to your shopping cart <a href="/cart">click here.</a>
            </p>
          )
        }
        {/* Regular Error Page */}
        {
          !taxErrorMsg && !maintenanceModeActive && !shippingZoneCodeError && 
          (
            <p>
            Our sincere apologies for the inconvenience. Our applications team was alerted of this issue. Your 
            shopping cart will be available when the issue is resolved, and you will be able to complete your 
            transaction. Please try again later.
            <br />
            <br />
            For any further questions or concerns our customer service team can be reached 
            <span style={{fontWeight: '800'}}> 1-800-538-5500.</span> Please reference this code 
            <span style={{fontWeight: '800'}}>
              {getBasketIdShort() !==null ? ` "` + getBasketIdShort() + `"` : ` "000"`}
            </span> when connected with a representative. Thanks.
            <br />
            <br />
            To go back to your shopping cart <a href="/cart" style={{fontSize: '16px'}}>click here.</a>
            <br />
            <br />
            </p>
          )
        }
        {/* Regular Error Page */}
        {
          !taxErrorMsg && !maintenanceModeActive && !shippingZoneCodeError && getBasketId() !==null &&
          (
            <MdsText>
              <span style={{fontSize: '11px'}}>
                Full Reference ID: {getBasketId()}
              </span>
            </MdsText>
          )
        }
      </ViewHeader>
    </main>
  );
};

ServiceErrorView.propTypes = {
  taxErrorMsg: PropTypes.bool,
  location: PropTypes.object,
  maintenanceModeActive: PropTypes.bool,
}

ServiceErrorView.defaultProps = {
  taxErrorMsg: false,
  maintenanceModeActive: false,
}

export default ServiceErrorView;
