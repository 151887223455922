import React, {useEffect, useState} from 'react';
import QuantityDropdown from './quantityDropdown/QuantityDropdown.js';
import DistributorInfo from './distributorInfo/DistributorInfo.js';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {selectQuantity,deselectOffer, quantitySelected} from '../../../redux/actions/offerActions';
import styles from './QuantitySelector.module.scss';
import { tealiumLink } from '../../../redux/actions/trackingActions.js';

const QuantitySelector = ({dropdownMax, offers, unit, sku, highestInventory}) => {
    const dispatch = useDispatch();

    const amountSelected = useSelector((state) =>
        state.offer.selectedQuantity.find((x) => x.unit === unit)
    );

    const [loading, setLoading] = useState(false);
    
    const onChange = (value) => {
        setLoading(true);
        dispatch(selectQuantity(parseInt(value), unit, sku, setLoading));
        dispatch(deselectOffer());
        dispatch(tealiumLink({
            event_name: 'Quantity Selection',
            funnel_stage: 'Distributor Selection',
            unit,
            quantity: parseInt(value)
        }));
    };

    const onLoad = (value) =>{
        dispatch(quantitySelected(isSelected? amountSelected.quantity: 1, unit));
        dispatch(deselectOffer());
    }
    const isSelected = amountSelected ? amountSelected.quantity > 0 : false;

    useEffect(() => {
        // Default of 1 is selected       
         onLoad(isSelected? amountSelected.quantity: 1)
    }, [])
    
    return (
        <div className={styles.quantitySelector}>
            <QuantityDropdown
                dropdownMax={dropdownMax}
                onChange={onChange}
                defaultValue={amountSelected ? amountSelected.quantity : 1}
                highestInventory={highestInventory}
            />
            <DistributorInfo 
                offers={offers} 
                amountSelected={isSelected} 
                unit={unit} 
                highestInventory={highestInventory}
                quantityLoading={loading}
            />
        </div>
    );
};

QuantitySelector.propTypes = {
    dropdownMax: PropTypes.number.isRequired,
    offers: PropTypes.array.isRequired,
    unit: PropTypes.string.isRequired,
    sku: PropTypes.string,
    highestInventory: PropTypes.number
};

export default QuantitySelector;
