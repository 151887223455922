/* eslint-disable max-lines*/
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
{
    /* Component Generators*/
}
// import GenerateSearchButton from './generators/searchButton';
// import GenerateInvalidLabel from './generators/invalidLabel';
import GenerateRequiredLabel from './generators/requiredLabel';
import GenerateHelpText from './generators/helpText';

const SelectWrapper = (props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [interactedWith, setInteractedWith] = useState(false);
  const [hasValue, setHasValue] = useState(false);

  const selectRef = React.createRef();

  const {defaultOption = ''} = props;

  const {
    name,
    fieldName,
    selectLabel,
    required,
    value,
    disabled,
    options,
    className,
    selected,
    showLabel,
    errorLabel,
    ...otherProps
  } = props.fields;

  const onChangeEventHandler = (e) => {
    if (selectRef.current) {
      const containsValue =
          selectRef.current.value !== '' &&
          selectRef.current.value !== '_default_none';
          setHasValue(containsValue);
    }

    if (e && props.onChange) {
      props.onChange({target:{value:selectRef.current.value}});
    }
  };

  const onBlurEventHandler = (e) => {
    setIsFocused(false);
  };

  const onFocusEventHandler = (e) => {
    setIsFocused(true);
  };

  useEffect(() => {
    if (props.select) {
      select = props.select;
    }
    onChangeEventHandler();
  }, []);

  // Render options for the select component
  const optionsComponent = options.map((option, index) => {
      const {name, value} = option;
      if (index === 0) {
          return (
              <React.Fragment key={`ReactFragment-${index}`}>
                  <option
                      disabled
                      key="N/A"
                      selected
                  >
                      {defaultOption}
                  </option>
                  <option value={value} key={`${value}-${index}`}>
                      {name}
                  </option>
              </React.Fragment>
          );
      } else {
          return (
              <option value={value} key={`${value}-${index}`}>
                  {name}
              </option>
          );
      }
  });

  // Render optional field name
  let fieldComponent = null;
  if (fieldName) {
      fieldComponent = (
          <label
              htmlFor={props.uniqueID}
              className="mds-form_elementLabel--floating"
              id={`${props.uniqueID}-FloatingLabel`}
          >{`${fieldName}${required ? '*' : ''}`}</label>
      );
  }

  // Show floating label
  const floatingLabel = (showLabel ? '' : 'mds-form_elementLabel--floatingHidden');



  return (
    <div
        className={`mds-form_element mds-form_select ${
            className !== undefined ? className : ''
        }`}
    >
        {fieldComponent}
        <label
            htmlFor={props.uniqueID}
            className={
                hasValue
                    ? 'mds-form_elementLabel--floating ' + floatingLabel
                    : 'mds-form_elementLabel'
            }
            id={`${props.uniqueID}-Label`}
        >{`${selectLabel}${required ? '*' : ''}`}</label>
        <select
            name={name}
            id={props.uniqueID}
            required={required}
            aria-labelledby={`${props.uniqueID}-Label ${props.uniqueID}-requiredLabel`}
            ref={selectRef}
            onClick={onChangeEventHandler}
            onBlur={onBlurEventHandler}
            onFocus={onFocusEventHandler}
            onChange={onChangeEventHandler}
            value={selected !== undefined ? selected || value : '_default_none'}
            disabled={disabled}
            data-testid={props.dataTestId}
        >
            {optionsComponent}
        </select>
        <i className="MMM--icn MMM--icn_down_arrow mix-MMM--icn_red mds-form_element--link mds-font_bold"></i>
        <GenerateRequiredLabel fields={props.fields} requiredLabel={errorLabel} uniqueID={props.uniqueID} isSelected interactedWith hasValue {...otherProps} />
        <GenerateHelpText fields={props.fields} isSelected interactedWith hasValue {...otherProps} />
    </div>
  );
};

SelectWrapper.propTypes = {
  name: PropTypes.string,
  defaultOption: PropTypes.string,
  value: PropTypes.string,
  fieldName: PropTypes.string,
  selectLabel: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.object,
  className: PropTypes.string,
  selected: PropTypes.bool,
  showLabel: PropTypes.bool,
  fields: PropTypes.object,
  select: PropTypes.object,
  uniqueID: PropTypes.string,
  dataTestId: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default SelectWrapper;
