/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { Input } from '../../atomics/formElements';
import {useHistory} from 'react-router-dom';
import './stripe.scss';
import stripeStyles from './stripe.module.scss';
import {Checkbox} from '../../../components/mds/index.js';
import {REGISTERED_USER} from '../../../components/common/userSettings/userDefinitions';
import {
  createSetupIntent,
  setPaymentCardSuccess,
  setCardComplete,
  setCardIsProcessing,
} from '../../../redux/actions/payment/paymentActions';
import { useDispatch, useSelector, connect } from 'react-redux';
import Modal from '../../common/modals/generic';
import { continueCheckout } from '../../../redux/actions/cart/cartActions.js';
import ContentBox from '../../containers/contentBox'
import styles from '../../../components/myaccount/payments/cardDetails.module.scss'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
  format,
}) => (
    <div className={`FormRow ${stripeStyles.FormRow}`} >
      <Input uniqueID={id} className="FormRowInput"
        placeholder={placeholder} format={format ? format : null}
        onChange={onChange} value={value ? value : ''} type={type} fields={{
        required,
        fieldName: label,
        name: id
      }} />
    </div>
  );

const CreditCardChosen = ({cardBrand}) => {
  switch(cardBrand) {
    case 'visa': 
      return <img src="/images/credit-cards/visa.png" alt="Visa" aria-hidden={true}></img>
    case 'mastercard':
      return <img src="/images/credit-cards/mastercard.svg" alt="Mastercard" aria-hidden={true}></img>
    case 'amex':
      return <img src="/images/credit-cards/amex.svg" alt="Amex" aria-hidden={true}></img>
    case 'discover':
      return <img src="/images/credit-cards/discover.svg" alt="Discover" aria-hidden={true}></img>
    default:
      return <>
        <img src="/images/credit-cards/visa.png" alt="Visa"></img>
        <img src="/images/credit-cards/mastercard.svg" alt="Mastercard"></img>
        <img src="/images/credit-cards/amex.svg" alt="Amex"></img>
        <img src="/images/credit-cards/discover.svg" alt="Discover"></img>
      </>
  }
}

const SubmitButton = ({ processing, error, children, disabled }) => (

  <button id="mkpl__stripe__submit__button" data-testid='mkpl__stripe__submit__button'
    className={stripeStyles.SubmitButton}
    style={{ display: 'hidden' }}
    type="submit"
    disabled={true}
  >
  </button>
);

const ErrorMessage = ({ children }) => (
  <div role="alert">
    <span className={stripeStyles.ErrorMessageText}>{children}</span>
  </div>
);


const CardDetails = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const { t } = useTranslation();
  const purchaseState = useSelector((state) => state.cart.purchaseState);
  const stateBillingAddress = useSelector((store) => store.selectedAddress?.selectedBillingAddress)
  const baseTranslationKey = 'translation.forms.stripe.cardDetailsForm'

  const [error, setError] = useState({
    cardNumberError: null,
    cardExpiryError: null,
    cardCVVError: null,
    nameError: null,
    setupError: null
  });


  const [cardNumberComplete, setCardNumberComplete] = useState(false);
  const [cardBrand, setCardBrand] = useState('unknown')
  const [cardExpiryComplete, setcardExpiryComplete] = useState(false);
  const [cardCVVComplete, setcardCVVComplete] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    email: '',
    phone: '9999999999'
  });

  useEffect(() => {
    if (stateBillingAddress) {
      const updatedBillingDetails = {
        name: billingDetails.name,
        email: stateBillingAddress.email ?? billingDetails.email,
        phone: stateBillingAddress.phoneNumber ? '1' + stateBillingAddress.phoneNumber.replace(/[^\d]/g, "") : billingDetails.phone,
        address: {
          city: stateBillingAddress.city ?? null,
          country: 'US',
          line1: stateBillingAddress.street1 ?? null,
          line2: stateBillingAddress.street2 ?? null,
          postal_code: stateBillingAddress.zipCode ?? null,
          state: stateBillingAddress.state ?? null
        }
      }
      setBillingDetails({...updatedBillingDetails})
    }
  }, [stateBillingAddress])


  const dispatch = useDispatch();

  const showModal = () => {
    setDisplayModal(true);
  }

  const closeModal = () => {
    setDisplayModal(false);
  }

  const CvvModal = ({ onOk }) => {
    return (
      <Modal
        title={t(`${baseTranslationKey}.cvvModal.title`)}
        actions={{
          confirmEvent: onOk,
          confirmLabel: t(`${baseTranslationKey}.cvvModal.Ok`),
          dismissEvent: onOk
        }}
        show={displayModal}
      >{t(`${baseTranslationKey}.cvvModal.body`)}</Modal>
    );
  }

  const updateCardFormCompleteStatus = (cardComplete, expiryComplete, cvvComplete, name) => {
   dispatch(setCardComplete(cardComplete &&
            expiryComplete &&
            cvvComplete &&
            name &&
            name.trim() !== ''));
  }

  const onCardNumberChange = (e) => {
    let isComplete = false;
    if (e.error) {
      setError({
        ...error,
        cardNumberError: e.error.message
      });
    } else if (e.complete) {
      setError({
        ...error,
        cardNumberError: null
      });
      isComplete = true;
    }
    setCardBrand(e.brand)
    setCardNumberComplete(isComplete);
    updateCardFormCompleteStatus(isComplete, cardExpiryComplete,cardCVVComplete, billingDetails.name);

  }

  const onCardExpiryChange = (e) => {
    let isComplete = false;
    if (e.error) {
      setError({
        ...error,
        cardExpiryError: e.error.message
      });
    } else if (e.complete) {
      setError({
        ...error,
        cardExpiryError: null
      });
      isComplete = true;
    }
    setcardExpiryComplete(isComplete);
    updateCardFormCompleteStatus(cardNumberComplete, isComplete,cardCVVComplete, billingDetails.name);
  }

  const onCardCVVChange = (e) => {
    let isComplete = false;
    if (e.error) {
      setError({
        ...error,
        cardCVVError: e.error.message
      });
    } else if (e.complete) {
      setError({
        ...error,
        cardCVVError: null
      });
      isComplete = true;
    }
    setcardCVVComplete(isComplete);
    updateCardFormCompleteStatus(cardNumberComplete, cardExpiryComplete,isComplete, billingDetails.name);
  }

  const onNameChange = (e) => {
    if (!e.target.value || e.target.value === '' || e.target.value.trim() === '') {
      setError({
        ...error,
        nameError: t(`${baseTranslationKey}.cardHolder`)
      });
    } else {
      setError({
        ...error,
        nameError: null
      });
    }
    setBillingDetails({ ...billingDetails, name: e.target.value });
    updateCardFormCompleteStatus(cardNumberComplete, cardExpiryComplete,cardCVVComplete, e.target.value);
  }


  const onSaveCard = (e) => {
    setSaveCard(e.target.checked)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || processing) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const _cardNumberElement = elements.getElement(CardNumberElement);
    if (error.cardNumberError) {
      _cardNumberElement.focus();
      return;
    }

    const _cardExpiryElement = elements.getElement(CardExpiryElement);
    if (error.cardExpiryError) {
      _cardExpiryElement.focus();
      return;
    }

    const _cardCvcElement = elements.getElement(CardCvcElement);
    if (error.cardCVVError) {
      _cardCvcElement.focus();
      return;
    }

    billingDetails.email = props.stateProps.emailAddress;
    setProcessing(true);
    dispatch(setCardIsProcessing(true));

    const confirmSetupCallBack = (cardUUIDData, error) => {

      if (error) {

        !!window.decibelInsight && window.decibelInsight(
          'sendApplicationError',
          'Checkout: Stripe error - ' + JSON.stringify(error)
        );

        setProcessing(false);

        setError({
          ...error,
          setupError: t(`${baseTranslationKey}.internalError`)
        })
        dispatch(setCardIsProcessing(false));
        return;
      }
      stripe.confirmCardSetup(cardUUIDData.token, {
        payment_method: {
          type: 'card',
          card: _cardNumberElement,
          billing_details: { ...billingDetails },
          metadata: {
            cardUUID: cardUUIDData.id,
            saveCard:  saveCard 
          }
        }
      }).then(
        function (result) {
          setProcessing(false);
          dispatch(setCardIsProcessing(false));
          if (result.error) {

            console.error("Error occurred while setting up intent");

            setError({
              ...error,
              setupError: result.error.message
            });

            !!window.decibelInsight && window.decibelInsight(
              'sendApplicationError',
              'DSP: Stripe confirmCardSetup error - ' + (result?.error?.message ? result?.error?.message : 'An error occurred')
            );

            elements.getElement(CardNumberElement).focus();
            
          } else {
            setPaymentMethod(result.setupIntent.payment_method);
            dispatch(setPaymentCardSuccess(cardUUIDData.id));
            dispatch(continueCheckout(purchaseState,history));
          }
        }
      )

    }
    dispatch(createSetupIntent(confirmSetupCallBack));
  };

  return paymentMethod || props.stateProps.paymentMethod ? (
    <div className={stripeStyles.Result}>
      ...
    </div>
  ) : (
      <ContentBox title={t(`${baseTranslationKey}.newCardFromLabel`)} className={stripeStyles.subBox}>
        <form className={stripeStyles.form} id="mkpl__stripe__form" onSubmit={handleSubmit}>
          {displayModal ? <CvvModal onOk={closeModal} /> : null}
          <div className={stripeStyles.form__group}>
            <div className={stripeStyles.form__card}>
              <CardNumberElement className={`${stripeStyles.shared_form_row} ${stripeStyles.FormRowInput}`} options={{
                showIcon: false,
                placeholder: t(`${baseTranslationKey}.cardNumberLabel`),
                iconStyle: 'default'
              }} onChange={onCardNumberChange} />
              <div className={`${stripeStyles.form__card__img} ${cardBrand == 'unknown' ? stripeStyles.form__card__img__unknown : ''}`}>
                <CreditCardChosen cardBrand={cardBrand} />
              </div>
              {error.cardNumberError && <ErrorMessage>{error.cardNumberError}</ErrorMessage>}
            </div>

            <Field
              label={t(`${baseTranslationKey}.cardHolderLabel`)}
              id="name"
              type="text"
              placeholder={t(`${baseTranslationKey}.cardHolderLabel`)}
              required
              autoComplete="name"
              value={billingDetails.name}
              onChange={onNameChange}
            />
            {error.nameError && <ErrorMessage>{error.nameError}</ErrorMessage>}

            <CardExpiryElement options={{ placeholder: t(`${baseTranslationKey}.cardExpiryLabel`) }}
              className={`${stripeStyles.shared_form_row} ${stripeStyles.FormRowInputSmall}`}
              onChange={onCardExpiryChange} />
            {error.cardExpiryError && <ErrorMessage>{error.cardExpiryError}</ErrorMessage>}


            <CardCvcElement options={{ placeholder: t(`${baseTranslationKey}.cardCVVLabel`) }}
              className={`${stripeStyles.shared_form_row} ${stripeStyles.FormRowInputSmall}`}
              onChange={onCardCVVChange}
            />
            {error.cardCVVError && <ErrorMessage>{error.cardCVVError}</ErrorMessage>}
            {props.stateProps.signInType === REGISTERED_USER &&
              <Checkbox
                name="saveCCCard"
                type="checkbox"
                uniqueID="saveCCCard"
                fields={{
                  fieldName: t(`${baseTranslationKey}.saveCard`),
                  className: styles.Checkbox,
                  checked: false
                }}
                value={'certifvalue'}
                onClick={(e) => onSaveCard(e)}
              >
              </Checkbox>
            }
          </div>

          <a className={stripeStyles.link} onClick={showModal} style={{ display: 'inline' }}>{t(`${baseTranslationKey}.cvvHelpLink`)}</a>
          <SubmitButton processing={processing} error={error} disabled={!stripe}>
            {t(`${baseTranslationKey}.setupButton`)}
          </SubmitButton>

        </form>
        {error.setupError && <ErrorMessage>{error.setupError}</ErrorMessage>}
      </ContentBox>
    );
};

const CardDetailsForm = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <CardDetails stateProps={props} />
    </Elements>
  );
};


const mapStateToProps = (state) => {
  return {
    emailAddress: state.signIn.emailAddress,
    paymentMethod: state.paymentList.paymentMethod,
    signInType : state.signIn.signInType
  };
};


export default connect(mapStateToProps, null)(CardDetailsForm);
