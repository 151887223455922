import * as PurchaseStates from './reducer-helpers/purchaseStates';

export const cartInitialState = {
    cart: {
        id: '',
        lineitem: [],
        emailOptIn: false,
        certifySafety: false,
    },
    initialCalculation: true,
    distributorOrderedLineItems: [],
    purchaseState: PurchaseStates.CART,
    billing: {},
    cartOffers: [],
};

export const myListInitialState = {
    myList: [],
    isLoading: true,
};

export const myAddressesInitialState = {
    user: {
        address: [],
    }
}

export const fileUploadInitialState = {
    incident: {
        files: [],
        pendingFiles: [],
        isLoading: false,
    },
    exemptions: {
        current: [],
        pending: [],
        isLoading: false,
        error: false,
    },
    messages: {
        current: [],
        pending: [],
        isLoading: false,
        error: false,
    }
}

export const exemptionInitialState = {
    exemptions: {
        current: [],
        pending: [],
        isLoading: false,
        error: false,
    }
}

export const miscellaneousInitialState = {
    poNumber: {
        poNumber: ''
    }
}
