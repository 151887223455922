/* eslint-disable */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';

import Input from './wrappers/input';

import 'simplebar/dist/simplebar.min.css';
import './simplebar.scss';
import styles from './type-ahead.module.scss';

const TypeAhead = (props) => {
  const {
      name,
      fieldName,
      required,
      value,
      disabled,
      options,
      className,
      selected,
      showLabel,
      ...otherProps
  } = props.fields;

  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const listRef = React.createRef();

  const onChangeEventHandler = (e) => {
    setInputValue(e.target.value);
    if (props.onChange) props.onChange(e);
  };

  const onSelectEventHandler = (option, index, e) => {
    setInputValue(option.name);
    const payload = { target: { value: option.name } };
    if (props.onChange) props.onChange(payload);
    if (props.select) props.onSelect(payload);
  }

  const onClickEventHandler = (e) => {
    if (!isFocused) setIsFocused(true);
  };

  const onFocusEventHandler = (e) => {
    setIsFocused(true);
  }

  const onBlurEventHandler = (e) => {
    setIsFocused(false);
  }

  const OptionList = () => {
    let count = 0;

    const listItems = options.map((option, index) => {
      const reg = new RegExp(inputValue.toLowerCase(),"g");
      const matchedString = option.name.toLowerCase().match(reg);
      if (matchedString) count++;
      return matchedString
        ? (
          <React.Fragment key={`Reactfragment-${index}`}>
            <li
              className={styles.list_item}
              data-val={option.value}
              onClick={(e) => onSelectEventHandler(option, index, event)}
            >
              {option.name}
            </li>
          </React.Fragment>)
        : null;
    });


    return (
      <SimpleBar style={{ maxHeight: 300 }} className={`${styles.mds_typeahead__option_list} ${isFocused ? styles['mds_typeahead__option_list--focus'] : ''}`}>
      <ul>
        {count === 0 ? (
            <li
              className={styles.list_item}
            >
              No matches
            </li>
        ) : listItems}
      </ul>
      </SimpleBar>
    )
  };

  return (
    <div className={styles.mds_typeahead} onBlur={onBlurEventHandler} onFocus={onFocusEventHandler} tabIndex="-1">
      <Input
        type='text'
        fields={{
          name: 'state',
          fieldName: 'State'
        }}
        value={inputValue}
        onChange={(e) => onChangeEventHandler(e)}
        onClick={(e) => onClickEventHandler(e)}
      />
      <i
        onClick={(e) => setIsFocused(false)}
        className={`MMM--icn MMM--icn_${isFocused ? 'up' : 'down'}_arrow mix-MMM--icn_red mds-form_element--link mds-font_bold`}></i>
      {true && <OptionList />}
    </div>
  )
};

TypeAhead.propTypes = {
  fields: PropTypes.shape,
  onchange: PropTypes.func,
};

export default TypeAhead;
