import React from 'react';
import footerData from './footerData';
import CategoryLinks from './categoryLinks';
import getUserDetails from '../getUserDetails'
import './footer.scss';

const Footer = (props) => {
  const {authenticated} = getUserDetails()

  const Legal = () => {
    const legalLinks = footerData.legal.links.map((link, index) => {
      return (
        <React.Fragment key={index}>
          <a href={authenticated ? link.href.replace('/3M/', '/my3M/') : link.href} 
            className={`m-footer_legalLink ${link.extraClasses}`} title={link.text} 
            target={link.target ? link.target : '_blank'} rel="noopener noreferrer">{link.text}</a>
          {index !== footerData.legal.links.length - 1 &&
            <div className="m-footer_legalSpan">|</div>
          }
        </React.Fragment>
      );
    });
    return (
      <div className="m-footer_legal">
        <div className="m-footer_legal--logo">
          <a className="h-linkNoUnderline" href="https://www.3M.com/" target="_self">
            <img src="https://www.3m.com/3m_theme_assets/themes/3MTheme/assets/images/unicorn/Logo_mobile.png" alt="3M Logo" />
          </a>
        </div>
        <div>
          <div className="m-footer_legalLinks">
            {legalLinks}
          </div>
          <div className="m-footer_copyRight">{footerData.legal.copyright}</div>
        </div>
      </div>
    );
  };
  
  const Social = () => {
    const {title, links, disclosure} = footerData.social;
    const social = links.map((link, index) => {
      return (
        <li key={index}>
          <a className="m-footer-socialIcn" href={link.href} title={link.title} target="_blank" rel="noreferrer">
              <i className={`MMM--socialIcn ${link.icon} color--iconGray`}><span className="m-footer-socialIcn--outline"></span></i>
          </a>
        </li>
      );
    });

    return (
      <React.Fragment>
        <div className="m-footer_heading link--dark h-notOnTablet">{title}</div>
        <ul className="m-footer-social">
          {social}
        </ul>
        <div className="m-footer_disclosure">{disclosure}</div>
      </React.Fragment>
    );
  };

  return (
    <div className="m-footer">
        <CategoryLinks authenticated = {authenticated}/>
        <Legal />
        <div className="m-footer_socialContainer">
          <Social />
        </div>
    </div>
  )
}

export default Footer;
