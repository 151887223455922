/* eslint-disable max-lines */

import * as types from './actionTypes.js';
import axios from 'axios';
import {getAuthLoggedId,removeSignedUserCookiesIfAny} from '../../connectivity/cookie.js';
// import * as defaultShippingAddress from './shipping/defaultShippingAddress';
import {updateShippingAddress} from './selectedAddressActions';
import * as signInUtilities from './utilities/signInUtilities';
import * as errorLogActions from './errorLogActions';
import * as UserDefinitions from '../../components/common/userSettings/userDefinitions.js';
import {isNewUser,deleteNewUser} from '../../connectivity/localStorage'

const dummyUserToken = '7999393514';
// const defaultEmail = 'pet4@mail.com';
// const defaultVsrmId = 21;

// const vsrmIdLookup = [
//     {email: 'pet@mail.com', vsrmId: 21},
//     {email: 'samavati@live.com', vsrmId: 32},
//     {email: 'isotopian3@outlook.com', vsrmId: 33},
// ];

export function loadUserSuccess(id, emailAddress, vsrmId, address) {
    return {
        type: types.LOAD_USER_SUCCESS,
        token: dummyUserToken,
        id: id,
        emailAddress: emailAddress,
        vsrmId: vsrmId,
        address: address,
    };
}

export function loadGuestUserSuccess(id) {
    return {
        type: types.LOAD_GUEST_USER_SUCCESS,
        id: id,
    };
}

export function loadUserFailure() {
    return {
        type: types.LOAD_USER_FAILURE
    };
}

export function notSignedInUser() {
    return {
        type: types.INVALID_USER
    };
}

export function signedInUser() {
    return {
        type: types.SIGN_IN_USER
    };
}


export function loadUserRequest() {
    return {type: types.LOAD_USER_REQUEST};
}

export function createUserRequest() {
    return {type: types.CREATE_USER_REQUEST};
}

export function createUserSuccess(id,authid) {
    return {
        type: types.CREATE_USER_SUCCESS,
        id: id,
        vsrmId: authid
    };
}

export function createUserFailure() {
    return {type: types.CREATE_USER_FAILURE};
}

export function addGuestSuccess(emailAddress, userID) {
    return {
        type: types.ADD_GUEST_SUCCESS,
        emailAddress: emailAddress,
        id: userID,
    };
}

export function addGuestRequest() {
    return {type: types.ADD_GUEST_REQUEST};
}

export function signInGuest() {
    return {type: types.SIGN_IN_GUEST};
}

export function addUserShippingAddressRequest() {
    return {type: types.ADD_USER_ADDRESS_REQUEST};
}

export function addUserShippingAddressSuccess(address) {
    return {
        type: types.ADD_USER_ADDRESS_SUCCESS,
        address: address,
    };
}

export function addGuestShippingAddressRequest() {
    return {type: types.ADD_GUEST_ADDRESS_REQUEST};
}

export function addGuestShippingAddressSuccess(address) {
    return {
        type: types.ADD_GUEST_ADDRESS_SUCCESS,
        address: address,
    };
}

export function appendOrderRequest() {
    return {type: types.APPEND_ORDER_TO_USER_REQUEST};
}

export function appendOrderSuccess(orderId) {
    return {type: types.APPEND_ORDER_TO_USER_SUCCESS, order: {id: orderId}};
}

export function updateBasketIdRequest() {
    return {type: types.UPDATE_BASKET_ID_REQUEST};
}

export function updateBasketIdSuccess(basketId) {
    return {type: types.UPDATE_BASKET_ID_SUCCESS, basketId: basketId};
}

export function checkIfUserIsValid() {

        if (!getAuthLoggedId()) {
            return notSignedInUser();
        }
        return signedInUser()
}

export function signInUser(authid) {
    return function (dispatch,getState) {
        const state = getState();
        

        if(!authid || state.signIn.createUserRequest === 'INPROGRESS') {
            return
        }

        dispatch(loadUserRequest());
        const requestUrl =
            process.env.REACT_APP_API_BASE_USER_URL+'/users?vsrmId=' + authid;
        axios.defaults.withCredentials = true
        return axios
            .get(requestUrl, {
                withCredentials: true,
                headers: {
                    Auth: '1212',
                }
            })
            .then((result) => {
                const userAddress = result.data.user.address;
                const userEmail = result.data.user.email
                let convertedAddress;
                if (userAddress) {
                    convertedAddress = userAddress.map((address, index) => {
                        return {
                            id: index,
                            firstName: address.firstName,
                            lastName: address.lastName,
                            street1: address.street1,
                            street2: address.street2,
                            city: address.city,
                            state: address.state,
                            country: address.country,
                            zipCode: address.zipCode,
                            phoneNumber: address.phoneNumber? address.phoneNumber: '',
                            ext: '',
                        };
                    });
                }
                dispatch(
                    loadUserSuccess(
                        result.data.user.id,
                        userEmail,
                        authid,
                        convertedAddress
                    )
                );
            })
            .catch((error) => {
                if(error.response?.status === 307){
                    removeSignedUserCookiesIfAny();
                    window.location.href = process.env.REACT_APP_API_MKPL_RGTR_REDIRECT_URL;
                }
                else if(error.response?.status === 403){
                    removeSignedUserCookiesIfAny();
                    window.location.href = process.env.REACT_APP_API_BCOM_REDIRECT_URL;
                }
                error.response?.status != 409 ? dispatch(loadUserFailure()) : null;
            });
    };
}

export function createNewUser(authid) {
    return function (dispatch,getState) {
        
        const state = getState();

        if(!isNewUser() || state.signIn.createUserRequest) {
            return
        }
        
        dispatch(createUserRequest());
        const requestUrl =
            process.env.REACT_APP_API_BASE_USER_URL + '/users';

        const body = {
                vsrmId: authid,
        };


        return axios
            .post(requestUrl, body, {
                withCredentials: true,
                headers: {
                    Auth: '1212',
                }
            })
            .then((result) => {
                const userId = result.data.id
                deleteNewUser();
                dispatch(createUserSuccess(userId,authid))
                dispatch(signInUser(authid)
                );
            })
            .catch((error) => {
                console.log("Error on Create User"+error)
                if(error.response?.status === 307){
                    removeSignedUserCookiesIfAny();
                    window.location.href = process.env.REACT_APP_API_MKPL_RGTR_REDIRECT_URL;
                }
                else if(error.response?.status === 403){
                    removeSignedUserCookiesIfAny();
                    window.location.href = process.env.REACT_APP_API_BCOM_REDIRECT_URL;
                } else if (error.response?.status === 409) {
                    deleteNewUser();
                    dispatch(createUserFailure());
                    dispatch(signInUser(authid));
                }
                error.response?.status != 409 ? dispatch(loadUserFailure()) : null;
            });
    };
}


export function appendUserAddress(newAddress) {
    return function (dispatch, getState) {
        dispatch(addUserShippingAddressRequest());

        const state = getState();
        const signIn = state.signIn;
        const id = signIn.id;

        const appendedAddress = {
            id: signIn.address.length,
            firstName: newAddress.firstName,
            lastName: newAddress.lastName,
            street1: newAddress.street1,
            street2: newAddress.street2,
            city: newAddress.city,
            state: newAddress.state,
            country: newAddress.country,
            zipCode: newAddress.zipCode,
            phoneNumber: newAddress.phoneNumber,
            ext: newAddress.ext,
        };

        const userAddress = signIn.address.map((address) => {
            return {...address};
        });
        userAddress.push(appendedAddress);

        const requestUrl =
            process.env.REACT_APP_API_BASE_USER_URL + '/users/' + id;

        const body = {
            ...signInUtilities.buildSignInBody(signIn),
            address: userAddress,
        };

        return axios
            .put(requestUrl, body, {
                headers: {
                    Auth: '1212',
                },
            })
            .then((result) => {
                dispatch(addUserShippingAddressSuccess(appendedAddress));
                dispatch(
                    updateShippingAddress(
                        false,
                        newAddress,
                        true,
                        false,
                        userAddress.length - 1
                    )
                );
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function updateUserBasketId(basketId) {
    return function (dispatch, getState) {
        dispatch(updateBasketIdRequest());

        const state = getState();
        const signIn = state.signIn;
        const id = signIn.id;

        const requestUrl =
            process.env.REACT_APP_API_BASE_USER_URL + '/users/' + id;

        const body = {
            ...signInUtilities.buildSignInBody(signIn),
            basketId: basketId,
        };

        return axios
            .put(requestUrl, body, {
                headers: {
                    Auth: '1212',
                },
            })
            .then((result) => {
                dispatch(updateBasketIdSuccess(basketId));
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function addNewGuest(emailAddress) {
    return function (dispatch, getState) {
        dispatch(addGuestRequest());

        const requestUrl = process.env.REACT_APP_API_BASE_USER_URL + '/users';

        const body = {
            email: emailAddress,
        };

        return axios
            .post(requestUrl, body, {
                withCredentials: true,
                headers: {
                    Auth: '1212',
                },
            })
            .then((result) => {
                dispatch(addGuestSuccess(emailAddress, result.data.user.id));
            })
            .catch((error) => {
                dispatch(errorLogActions.appendError(error.toString()));
            });
    };
}

export function appendGuestAddress(newAddress) {
    return function (dispatch, getState) {
        dispatch(addGuestShippingAddressRequest());

        const state = getState();
        const signIn = state.signIn;

        const appendedAddress = {
            id: signIn.address.length,
            firstName: newAddress.firstName,
            lastName: newAddress.lastName,
            street1: newAddress.street1,
            street2: newAddress.street2,
            city: newAddress.city,
            state: newAddress.state,
            country: newAddress.country,
            zipCode: newAddress.zipCode,
            phoneNumber: newAddress.phoneNumber,
            ext: newAddress.ext,
        };

        const guestAddress = signIn.address.map((address) => {
            return {...address};
        });
        guestAddress.push(appendedAddress);

        const requestUrl = process.env.REACT_APP_API_BASE_USER_URL + '/users';

        const body = {
            ...signInUtilities.buildSignInBody(signIn),
            address: guestAddress,
            id: '',
        };

        return axios
            .post(requestUrl, body, {
                headers: {
                    Auth: '1212',
                },
            })
            .then((result) => {
                dispatch(addGuestShippingAddressSuccess(appendedAddress));
                dispatch(
                    updateShippingAddress(
                        false,
                        newAddress,
                        true,
                        false,
                        guestAddress.length - 1
                    )
                );
            })
            .catch((error) => {
                dispatch(errorLogActions.appendError(error.toString()));
            });
    };
}

export function appendOrderId(orderId) {
    return function (dispatch, getState) {
        dispatch(appendOrderRequest());

        const state = getState();
        const signIn = state.signIn;
        const id = signIn.id;

        const orders = signIn.orders.map((order) => {
            return {...order};
        });
        orders.push({id: orderId});

        const body = {
            ...signInUtilities.buildSignInBody(signIn),
            orders: orders,
        };

        if (signIn.signInType === UserDefinitions.REGISTERED_USER) {
            const requestUrl =
                process.env.REACT_APP_API_BASE_USER_URL + '/users/' + id;
            return axios
                .put(requestUrl, body, {
                    headers: {
                        Auth: '1212',
                    },
                })
                .then((result) => {
                    dispatch(appendOrderSuccess(orderId));
                })
                .catch((error) => {
                    dispatch(errorLogActions.appendError(error.toString()));
                });
        } else if (signIn.signInType === UserDefinitions.GUEST) {
            const requestUrl =
                process.env.REACT_APP_API_BASE_USER_URL + '/users';
            return axios
                .post(requestUrl, body, {
                    headers: {
                        Auth: '1212',
                    },
                })
                .then((result) => {
                    dispatch(appendOrderSuccess(orderId));
                })
                .catch((error) => {
                    dispatch(errorLogActions.appendError(error.toString()));
                });
        }
    };
}
