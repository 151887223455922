import React from 'react';
import PropTypes from 'prop-types';
const mdsHeader2a = (props) => (
    <h2 className="mds-font_header--2a">{props.children}</h2>
);
mdsHeader2a.propTypes = {
    children: PropTypes.any,
};

export default mdsHeader2a;
