/* eslint-disable max-lines */
import React,{lazy, Suspense, useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Header from './gsn/header';
import SimplifiedHeader from './gsn/header-simplified';
import Footer from './gsn/footer/footer';
import SimplifiedFooter from './gsn/footer/footer-simplified';
import { adobeTriggerView } from '../redux/actions/trackingActions';

import CheckoutView from './views/checkout.view.js';
// import Cart from './cart/Cart';

import DistributorOfferPageCards from './distributorPageCards/DistributorOfferPage.js';
import AddedToCart from './cart/addedToCart';
import CheckoutLogin from '../components/checkoutLogin';
import {useParams, useLocation} from 'react-router-dom';
import ErrorView from './views/error.view';
import RegistrationConfirmationView from './views/registrationconfirmation.view';
import ServiceErrorView from './views/serviceerror.view';
import TermsAndConditions from '../components/common/order-summary/terms/TermsAndConditions';
import ReturnPolicy from '../components/common/order-summary/terms/ReturnPolicy';
import StyleguideView from './views/styleguide.view';
import MessageControls from './myaccount/message-center/message-controls/index';
import RequireUser from './guard/require-user';
import { saveSharedBasketId, saveSharer } from '../connectivity/localStorage';
const OrderDetails = lazy(() => import('../components/orderDetails/OrderDetails'));
const OrderHistory = lazy(() => import('../components/orderHistory/orderHistory'));
const Payments = lazy(() => import('../components/myaccount/payments/payments'));
const Messages = lazy(() => import('./myaccount/message-center/messages'));
const GuestCheckOrder = lazy(() => import( '../components/guestCheckOrder/guestCheckOrder'));
const MessageCenterView = lazy(() => import( './myaccount/message-center/message-center.view'));
const Addresses = lazy(() => import( '../components/addresses/addresses'));
const MyList = lazy(() => import('./myList/MyList.js'));
const TaxExemptView  = lazy(() => import('../components/myaccount/tax-exempt/certificates.view.js'));
const MakePurchase = lazy(() => import('../components/containers/MakePurchase/MakePurchase'));

/* Takes a React component and a 'simplified' boolean to determine which header type should be returned */
const GSNWrapper = (props) => {
    // eslint-disable-next-line react/prop-types
    const {component, simplified, ignoreFooter, ignoreHeader} = props;

    if (ignoreFooter) {
        return (
            <React.Fragment>
                {simplified ? <SimplifiedHeader /> : <Header />}
                <div className="mds-wrapper">{component}</div>
            </React.Fragment>
        );
    }
    if (ignoreHeader) {
        return (
            <React.Fragment>
                <div className="mds-wrapper">{component}</div>
                {simplified ? <SimplifiedFooter /> : <Footer />}
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            {simplified ? <SimplifiedHeader /> : <Header />}
            <div className="mds-wrapper">{component}</div>
            {simplified ? <SimplifiedFooter /> : <Footer />}
        </React.Fragment>
    );
};

function GetMakePurchase() {
    const {basketID, shareBasketVsrmID} = useParams();
    if(!!shareBasketVsrmID){
    window.share_basket_vsrm_id = shareBasketVsrmID;
    saveSharer(shareBasketVsrmID);
    }
    if(!!basketID && basketID.includes('-') )
    saveSharedBasketId(basketID);
    const location = useLocation();
    return (
        <Suspense fallback={<div>Loading...</div>}>
        <GSNWrapper
            component={<MakePurchase location={location} basketId={basketID} />}
            simplified={true}
        />
        </Suspense>
    );
}

if (document.URL.match(/NewUser=Y/)) {
    localStorage.setItem('NewUser', 'Y');
}

export default function App() {
    let maintenanceMode = false;
    if(process.env.REACT_APP_MKPL_MAINTENANCE_MODE === 'true') maintenanceMode = true;

    const Routes = () => {
        const thisLocation = useLocation()?.pathname

        useEffect(() => {
            // Trigger adobe.triggerView when view changes and after DOM loads
            adobeTriggerView(thisLocation)
        }, [thisLocation])

        return (
            <Switch>
	    	{ /* Maintenance Mode Route */ }
                {maintenanceMode && <Route
	                path="/"
                    render={(props) => (
		                <GSNWrapper component={<ServiceErrorView maintenanceModeActive={maintenanceMode} />} />
                    )}
                />}
                <Route
                    path={'/guestCheckOrder'}
                    render={(props) => (
                        <GSNWrapper component={<GuestCheckOrder />} />
                    )}
                />
                <Route
                    path="/myaccount/tax-exemption"
                    render={(props) => (
                        <GSNWrapper component={<RequireUser><TaxExemptView {...props} /></RequireUser>} />
                    )}
                />
                <Route
                    path="/myaccount/message-center"
                    render={(props) => (
                        <GSNWrapper
                            component={<MessageCenterView {...props} />}
                        />
                    )}
                />
                <Route
                    path={'/myaccount/payments'}
                    render={(props) => <GSNWrapper component={<RequireUser><Payments {...props}/></RequireUser>} />}
                />

                {/* This component route needs to be deleted */}
                <Route
                    path={'/messages'}
                    render={(props) => <GSNWrapper component={<Messages />} />}
                />

                {/* This component route needs to be deleted */}
                <Route
                    path={'/message-controls'}
                    render={(props) => (
                        <GSNWrapper component={<MessageControls />} />
                    )}
                />

                <Route
                    path={'/terms'}
                    render={(props) => (
                        <GSNWrapper component={<TermsAndConditions />} />
                    )}
                />
                <Route
                    path={'/returnpolicy'}
                    render={(props) => (
                        <GSNWrapper component={<ReturnPolicy />} />
                    )}
                />
                <Route
                    path="/myaccount/mylist"
                    render={() => <GSNWrapper component={<RequireUser><MyList /></RequireUser>} />}
                />
                <Route
                    path="/buyingOptions/:sku"
                    render={({location}) => (
                        <GSNWrapper
                            ignoreFooter={false}
                            component={
                                <DistributorOfferPageCards location={location} />
                            }
                        />
                    )}
                />
                <Route
                    path="/distributorOffers/:sku"
                    render={({location}) => (
                        <GSNWrapper
                            ignoreFooter={false}
                            component={
                                <DistributorOfferPageCards location={location} />
                            }
                        />
                    )}
                />
                <Route
                    path={'/myaccount/addresses'}
                    render={(props) => <GSNWrapper component={<RequireUser><Addresses /></RequireUser>} />}
                />
                <Route
                    path={'/checkoutLogin'}
                    render={(props) => (
                        <GSNWrapper
                            ignoreHeader={true}
                            component={<CheckoutLogin location={location} />}
                        />
                    )}
                />
                <Route
                    path="/addedToCart"
                    render={({location}) => (
                        <GSNWrapper
                            component={
                                <AddedToCart item={location.state.addedItem} />
                            }
                        />
                    )}
                />
                <Route
                    exact
                    path="/checkout-test"
                    render={(props) => (
                        <GSNWrapper
                            component={<CheckoutView />}
                            simplified={true}
                        />
                    )}
                />
              {/* <Route
                    path="/cart"
                    render={(props) => <GSNWrapper component={<Cart />} />}
                /> */}
                <Route exact path={["/checkout", "/cart", "/cart/:basketID", "/cart/:basketID/:shareBasketVsrmID"]}
                    render={props => (<GetMakePurchase {...props} />)} />
                <Route
                    path="/orderDetails"
                    render={({ location }) => (
                        <GSNWrapper
                            component={<RequireUser><OrderDetails location={location} /></RequireUser>}
                        />
                    )}
                />
                <Route
                    path="/myaccount/orderHistory"
                    render={(props) => (
                        <GSNWrapper component={<RequireUser isLoading={true}><OrderHistory /> </RequireUser>} />
                    )}
                />
                <Route
                    path="/styleguide"
                    render={(props) => (
                        <GSNWrapper component={<StyleguideView {...props} />} />
                    )}
                />
                <Route
                    path="/error"
                    render={(props) => (
                        <GSNWrapper component={<ServiceErrorView {...props} />} />
                    )}
                />
                <Route
                    path="/registration-confirmation"
                    render={(props) => (
                        <GSNWrapper component={<RegistrationConfirmationView {...props} />} />
                    )}
                />
                <Route
                    render={(props) => (
                        <GSNWrapper component={<ErrorView {...props} />} />
                    )}
                />
            </Switch>
        )
    }

    return (
        <Router>
            <Routes/>
        </Router>
    )
}
