/* eslint-disable */
import * as types from '../actions/actionTypes';
import {
  fileUploadInitialState,
  exemptionInitialState,
} from './initialState';

const defaultState = {...fileUploadInitialState, ...exemptionInitialState};

import exemptStatus from '../../enums/exemption-status';

const defaultExemption = {
  url: '',
  state: 'Unknown',
  status: '',
  id: '-1',
  expiration: '',
  exemptReason: '',
}

const defaultMessage = {
  getUrl: '',
  expire: '',
  name: '',
  id: '',
}

let found;
let newArr;

const fileUploadReducer = (state = defaultState, action) => {
    switch(action.type) {
        case types.SET_TAX_EXEMPT_ERR:
            return {
              ...state,
              exemptions: {
                ...state.exemptions,
                isLoading: action.data.loading === null
                    ? state.exemptions.isLoading
                    : action.data.loading,
                error: action.data.status,
              },
            }
        case types.INCIDENT_LOAD_FILE_INITIATED:
              return {
                  ...state,
                  incident: {
                      files: [...state.incident.files],
                      pendingFiles: addUrlToFilesList(state.incident.pendingFiles, action.url),
                      isLoading: true,
                  }
              }
          case types.PUT_INCIDENT_FILE_IN_AWS_BUCKET_SUCCESS:
              return {
                  ...state,
                  incident: {
                      files: addUrlToFilesList(state.incident.files, action.url),
                      pendingFiles: [...state.incident.pendingFiles],
                      isLoading: false
                  },
              }
        case types.MESSAGE_FILE_INITIATED:
          return {
            ...state,
            messages: {
              current: [...state.messages.current],
              pending: [
                ...state.messages.pending,
                {
                  ...defaultMessage,
                  url: action.data.url,
                  file: action.data.file,
                  type: action.data.fileType,
                  name: action.data.name,
                }
              ]
            }
          }
        case types.MESSAGE_FILE_AWS_SUCCESS:
          found = state.messages.pending.find(ex => ex.name === action.data.name);
          newArr = state.messages.pending.filter(ex => ex.name !== action.data.name);
          const newMessage = {
            ...defaultMessage,
            getUrl: action.data.getUrl,
            expire: action.data.expire,
            id: action.data.id,
            name: action.data.name,
          };
          return {
            ...state,
            messages: {
              current: [
                ...state.messages.current,
                newMessage
              ],
              pending: newArr,
            },
          }
        case types.LOAD_TAX_EXEMPTS:

          const exemptions = action.data.arr
            .map(item => {
              return {
                ...defaultExemption,
                id: item.id,
                state: item.state,
                status: !item.status ? exemptStatus.INACTIVE: exemptStatus[item.status.toUpperCase()],
                exemptReason: item.exemptReason,
                expiration: item.expiration,
              }
            })
            .sort((ex, nex) => {
              if (ex.status > nex.status) return 1;
              if (ex.status < nex.status) return -1;

              if (ex.expiration > nex.expiration) return 1;
              if (ex.expiration < nex.expiration) return -1;

              if (ex.state > nex.state) return 1;
              if (ex.state < nex.state) return -1;
            });

          return {
            ...state,
            exemptions: {
              pending: [...state.exemptions.pending],
              current: exemptions,
            }
          }
        case types.TAX_EXEMPT_DOC_INITIATED:
          return {
            ...state,
            exemptions: {
              current: [...state.exemptions.current],
              pending: [
                ...state.exemptions.pending,
                {
                  ...defaultExemption,
                  url: action.data.url,
                  file: action.data.file,
                  type: action.data.fileType,
                  path: action.data.filePath,
                }
              ]
            }
          }
        case types.PUT_TAX_EXEMPT_AWS_SUCCESS:
          found = state.exemptions.pending.find(ex => ex.path === action.data.filePath);
          newArr = state.exemptions.pending.filter(ex => ex.path !== action.data.filePath);
          return {
            ...state,
            exemptions: {
              current: [
                ...state.exemptions.current,
                {
                  ...found,
                  url: action.data.url,
                }
              ],
              pending: newArr,
            },
          }
        case types.CLEAR_PENDING_EXEMPT:
          return {
            ...state,
            exemptions: {
              ...state.exemptions,
              pending: [],
              isLoading: false,
              error: false,
            }
          }
        default:
            return {
                ...state,
            }
    }
}

const addUrlToFilesList = (fileList, url) => {
    const newFileList = [...fileList];
    if (newFileList.includes(url)) {
        return newFileList;
    } else {
        newFileList.push(url);
        return newFileList;
    }
}

export default fileUploadReducer;
