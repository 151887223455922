/* eslint-disable */

import React, {useState} from 'react';
import PropTypes from 'prop-types';
// import {Flex, Box} from 'rebass';
import {Select} from '../atomics/formElements';
import Input from '../atomics/formElements/wrappers/input';
import {MdsText} from '../mds';
// import ContentBox from '../containers/contentBox';
import Banner from '../common/banner/generic';

import styles from './shippingAddressForm.module.scss';
import '../atomics/formElements/wrappers/forms.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PopUp from './popup.module';

const countryDesignations = [{name: 'United States', value: 'usa'}];
import {statesUSA} from '../../data';
import { isValidPhoneNumber, isPOBoxAddress } from '../common/utlities';
import * as commonMessages from '../common/commonMessages';
import {setCountryDesignations} from'../../connectivity/localStorage'
const ShippingAddress = (props) => {
    const [errors, setError] = useState({
        firstName: !props.formData.firstName,
        lastName: !props.formData.lastName,
        street1: !props.formData.street1,
        // street 2 is optional so no validity checks
        city: !props.formData.city,
        state: !props.formData.state||props.formData.state==='State',
        zipCode: !props.formData.zipCode,
        phoneNumber: !props.formData.phoneNumber,
        // phone extension is optional so no validity checks
    });
    setCountryDesignations(countryDesignations);
    const [changes, setChanges] = useState({
        firstName: false,
        lastName: false,
        street1: false,
        // street 2 is optional so no validity checks
        city: false,
        state: false,
        zipCode: false,
        phoneNumber: false,
        // phone extension is optional so no validity checks
    });
    const {t} = useTranslation();
    const addressValidation = useSelector((state) => state.cart && state.cart.addressValidation);
    const baseTranslationKey = 'translation.forms.shippingAddressForm';
    const addAddressToAccount = props.addAddressToAccount
        ? props.addAddressToAccount
        : false;
    const validateAddress=useSelector(state=>{
            return state?.selectedAddress?.validateAddress});

    let addressValidationError= ''
    let genericAddressValidationError = false
    const [regExPOAddressValidationError, setRegExPOAddressValidationError] = useState(false);

    if(addressValidation && addressValidation.status === false) {
        if(addressValidation.errors && addressValidation.errors.length >0 && !addressValidation.newAddress.street1) {
            addressValidationError = addressValidation.errors[0].message
            // genericAddressValidationError = true
        } else if (addressValidation.errors && addressValidation.errors.length > 0 && addressValidation.errors[0].code == 101) {
            addressValidationError = addressValidation.errors[0].message
        }

        // todo make this better as their are other shipengine error codes we may want more friendly messages for
        if (addressValidationError == 'Invalid postal_code.') {
            addressValidationError = 'The zip code you entered was invalid, please correct to continue.'
        }

        else if(addressValidation.newAddress.street1) {
            // addressValidationError+= t(
            //     `${baseTranslationKey}.fields.addressMatchFound`
            // )
            // addressValidationError+= '\n'
            addressValidationError+= addressValidation.newAddress.street1 + ', ' +
                                    (addressValidation.newAddress.street2 ? (addressValidation.newAddress.street2 + ' '): '') +
                                    addressValidation.newAddress.city + ', ' +                                    
                                    addressValidation.newAddress.state + ', '   +
                                    addressValidation.newAddress.zipCode 
        } else {
            genericAddressValidationError = true
            addressValidationError = t(
                `${baseTranslationKey}.fields.addressMatchNotFound`
            )
        }
    }

    const evaluateError = (fieldName, value) => {
        const newErrors = {
            ...errors,
        };

        switch (fieldName) {
            case 'firstName':
                newErrors.firstName = !value;
                break;
            case 'lastName':
                newErrors.lastName = !value;
                break;
            case 'street1':
                newErrors.street1=!value;
                if(!props.isForBilling && !!value && !!value.trim()) newErrors.street1 = value.length>50||checkRegExPOAddressValidation(value.trim());
                break;
            case 'city':
                newErrors.city = !value;
                break;
            case 'state':
                newErrors.state = !value || value === "State";
                break;
            case 'zipCode':
                newErrors.zipCode = !value;
                break;
            case 'phoneNumber':
                newErrors.phoneNumber = !value || !isValidPhoneNumber(value);
                break;
            default:
                break;
        }

        setError(newErrors);

        return (
            newErrors.firstName ||
            newErrors.lastName ||
            newErrors.street1 ||
            newErrors.city ||
            newErrors.state ||
            newErrors.zipCode ||
            newErrors.phoneNumber
        );
    };
    const onBlurEventHandler = (fieldName) => {
        const newChanges = {
            ...changes,
        };

        switch (fieldName) {
            case 'firstName':
                newChanges.firstName = true;
                break;
            case 'lastName':
                newChanges.lastName = true;
                break;
            case 'street1':
                newChanges.street1 = true;
                break;
            case 'city':
                newChanges.city = true;
                break;
            case 'state':
                newChanges.state = true;
                break;
            case 'zipCode':
                newChanges.zipCode = true;
                break;
            case 'phoneNumber':
                newChanges.phoneNumber = true;
                break;
            default:
                break;
        }

        setChanges(newChanges);
    };

    const onChange = (fieldName, val) => {
        const formError = evaluateError(fieldName, val);
        props.onChange(fieldName, val, formError);
    };

    const invalidPOAddressMessage = t(commonMessages.shippingPOAddressValidationError);
    const checkRegExPOAddressValidation = (value) => {
        if(isPOBoxAddress(value.trim())){ 
            setRegExPOAddressValidationError(true);
            return true;
        } else {
            setRegExPOAddressValidationError(false);
            return false;
        }
    };

    const onAddressPopupClose = (selectedAddress, clicked) => {
        if (clicked === "continue") {
            if (selectedAddress.value === "recommendedAddress") {
                // change the props data similar to shipengine address and allow to continue
                if (!!addressValidation.newAddress.street1)
                    props.formData.street1 = addressValidation.newAddress.street1;
                props.formData.street2 = !!addressValidation.newAddress.street2 ? 
                    addressValidation.newAddress.street2 : "";
                if (!!addressValidation.newAddress.city)
                    props.formData.city = addressValidation.newAddress.city;
                if (!!addressValidation.newAddress.state)
                    props.formData.state = titleCase(addressValidation.newAddress.state);
                if (!!addressValidation.newAddress.zipCode)
                    props.formData.zipCode = ((addressValidation.newAddress.zipCode).trim());
            } else if (selectedAddress.value === "enteredAddress") {
                props.formData.zipCode = ((props.formData.zipCode).trim());
            }
            props.onAddressVerification(props.formData);
        } else {
            // clicked edit - allow to edit the form, if again continue is clicked?
        }
    };

    const titleCase = (str) => {
        return str.toLowerCase().split(' ').map((word) => {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    };

    const onSubmitEvent = props.onSubmit
        ? props.onSubmit
        : (e) => e.preventDefault();
    return (
        <div className={styles.shipping_address}>
            {/* {(addressValidation && addressValidation.status === false && genericAddressValidationError === false) &&
                (
                    <MdsText style={{ color: "red", fontSize: "14px", marginBottom: "5px" }}>
                        {t(`${baseTranslationKey}.fields.addressMatchFound`)}
                    </MdsText>
                )
            } */}
            {addressValidation && addressValidation.status === false &&
                (
                    <div>
                        {(addressValidation.newAddress.zipCode || genericAddressValidationError) ?
                            <PopUp isNewAddressSelected={props.isNewAddressSelected} Shipping={addressValidation.newAddress} {...props.formData} allowEnteredAddress={genericAddressValidationError} onCloseAfterSelection={(selectedAddress, clicked) => onAddressPopupClose(selectedAddress, clicked)} /> :
                            <Banner icon={String.fromCharCode(parseInt('0021', 16))}
                                color="red" show="true">
                                <MdsText
                                    data-testid='shippingStatusErrorMsg'
                                    mdsType='body-bold'
                                    el='strong'
                                    className={styles.msgWrapper}
                                    isInline={true}>
                                    {addressValidationError}
                                </MdsText>
                            </Banner>
                        }
                    </div>
                )
            }

            <form data-testid="shippingForm" onSubmit={onSubmitEvent}>
                {/*<div className={styles['grid-row']}>
                <div className={styles['1o2']}>
                    <Select
                        uniqueID="country"
                        fields={{
                            name: 'countryDesignation',
                            disabled: true,
                            options: countryDesignations,
                            selected: 'usa',
                        }}
                    />
                </div>
            </div>*/}
                <div className={styles['grid-row']}>
                    <div className={styles['1o2']}>
                        <Input
                            name="formName"
                            type="text"
                            uniqueID={'12'}
                            dataTestId="shippingFirstName"
                            fields={{
                                fieldName: t(
                                    `${baseTranslationKey}.fields.firstName`
                                ),
                                required: true,
                                invalid: (errors.firstName&&validateAddress)||(errors.firstName&&changes.firstName),
                            }}
                            invalidLabel={t(commonMessages.requiredField)}
                            value={props.formData.firstName}
                            onChange={(e, val) =>
                                onChange('firstName', e.target.value)
                            }
                            onBlur={()=>onBlurEventHandler('firstName')}
                        />
                    </div>
                    <div className={styles['1o2']}>
                        <Input
                            name="formName"
                            type="text"
                            uniqueID={'123'}
                            dataTestId="shippingLastName"
                            fields={{
                                fieldName: t(
                                    `${baseTranslationKey}.fields.lastName`
                                ),
                                required: true,
                                invalid: (errors.lastName&&validateAddress)||(errors.lastName&&changes.lastName),
                            }}
                            invalidLabel={t(commonMessages.requiredField)}
                            value={props.formData.lastName}
                            onChange={(e) =>
                                onChange('lastName', e.target.value)
                            }
                            onBlur={()=>onBlurEventHandler('lastName')}
                        />
                    </div>
                </div>
                {/* ---*/}
                <div className={styles['grid-row']}>
                    <Input
                        className=""
                        name="formName"
                        type="text"
                        dataTestId="shippingCompany"
                        uniqueID={'company'}
                        fields={{
                            fieldName: t(
                                `${baseTranslationKey}.fields.company`
                            ),
                        }}
                        value={props.formData.companyName}
                        onChange={(e) => onChange('companyName', e.target.value)}
                    />
                </div>
                {/* ---*/}
                <div className={styles['grid-row']}>
                    <Input
                        className=""
                        name="formName"
                        type="text"
                        dataTestId="shippingStreet1"
                        uniqueID={'1234'}
                        fields={{
                            fieldName: t(
                                `${baseTranslationKey}.fields.street1`
                            ),
                            required: true,
                            invalid: regExPOAddressValidationError||(errors.street1&&validateAddress)||(errors.street1&&changes.street1),
                        }}
                        value={props.formData.street1}
                        invalidLabel={regExPOAddressValidationError?invalidPOAddressMessage:props?.formData?.street1?.length>50?t(commonMessages.maxCharacterInvalid):t(commonMessages.requiredField)}
                        onChange={(e) => onChange('street1', e.target.value)}
                        onBlur={()=>onBlurEventHandler('street1')}
                    />
                </div>
                {/* ---*/}
                <div className={styles['grid-row']}>
                    <Input
                        name="formName"
                        type="text"
                        uniqueID={'12345'}
                        dataTestId="shippingStreet2"
                        fields={{
                            fieldName: t(
                                `${baseTranslationKey}.fields.street2`
                            ),
                        }}
                        value={props.formData.street2}
                        onChange={(e) => onChange('street2', e.target.value)}
                    />
                </div>
                {/* ---*/}
                <div className={styles['grid-row']}>
                    <div className={styles['2o4']}>
                        <Input
                            name="formName"
                            type="text"
                            uniqueID={'123456'}
                            dataTestId="shippingCity"
                            fields={{
                                fieldName: t(
                                    `${baseTranslationKey}.fields.city`
                                ),
                                required: true,
                                invalid: (errors.city&&validateAddress)||(errors.city&&changes.city),
                            }}
                            invalidLabel={t(commonMessages.requiredField)}
                            value={props.formData.city}
                            onChange={(e) => onChange('city', e.target.value)}
                            onBlur={()=>onBlurEventHandler('city')}
                        />
                    </div>
                    <div className={styles['1o4']}>
                        {/*<Input
                            name="formName"
                            type="text"
                            uniqueID={'1234567'}
                            fields={{fieldName: t(`${baseTranslationKey}.fields.state`)}}
                            value={props.formData.state}
                            onChange={(e) => onChange('state', e.target.value)}
                        />*/}
                        <Select
                            uniqueID="state"
                            dataTestId="shippingState"
                            defaultOption={t( `${baseTranslationKey}.fields.state`)}
                            fields={{
                                fieldName:t( `${baseTranslationKey}.fields.state`),
                                name: 'stateDesignation',
                                disabled: false,
                                options: statesUSA,
                                selected: props.formData.state || 'State',
                                invalid: errors.state,
                                required:true,
                                errorLabel:(errors.state&&validateAddress)||(errors.state&&changes.state)?'- '+t(`${commonMessages.requiredMsg}`):undefined
                            }}
                            onBlur={()=>onBlurEventHandler('state')}
                            onChange={(e) => onChange('state', e.target.value)}
                        />
                    </div>
                    <div className={styles['1o4']}>
                        <Input
                            name="formName"
                            type="text"
                            uniqueID={'12345678'}
                            dataTestId="shippingZipcode"
                            fields={{
                                fieldName: t(
                                    `${baseTranslationKey}.fields.zipCode`
                                ),
                                required: true,
                                invalid: (errors.zipCode&&validateAddress)|| (errors.zipCode&&changes.zipCode),
                            }}
                            invalidLabel={t(commonMessages.requiredField)}
                            value={props.formData.zipCode}
                            onChange={(e) =>
                                onChange('zipCode', e.target.value)
                            }
                            onBlur={()=>onBlurEventHandler('zipCode')}
                        />
                    </div>
                </div>
                {/* ---*/}
                <div className={styles['grid-row']}>
                    <div className={styles['1o4']}>
                        <Input
                            name="formName"
                            type="text"
                            uniqueID={'phone'}
                            fields={{
                                fieldName: t(
                                    `${baseTranslationKey}.fields.phone`
                                ),
                                required: true,
                                invalid: (errors.phoneNumber&&validateAddress)|| (errors.phoneNumber&&changes.phoneNumber),
                            }}
                            invalidLabel={t(commonMessages.requiredField)}
                            value={props.formData.phoneNumber}
                            dataTestId="shippingPhone"
                            onChange={(e) => {
                                onChange('phoneNumber', e.target.value);
                            }}
                            onBlur={()=>onBlurEventHandler('phoneNumber')}
                            format="(###)-###-####"
                        />
                    </div>
                    <div className={styles['1o4']}>
                        <Input
                            name="formName"
                            type="text"
                            uniqueID={'ext'}
                            fields={{
                                fieldName: t(
                                    `${baseTranslationKey}.fields.ext`
                                ),
                            }}
                            value={props.formData.ext}
                            onChange={(e) => onChange('ext', e.target.value)}
                        />
                    </div>
                </div>
                {addAddressToAccount && (
                    <div className={styles['grid-row']}>
                        <div className={styles['2o4']}>
                            <Input
                                name="formName"
                                type="text"
                                uniqueID={'nickname'}
                                fields={{
                                    fieldName: t(
                                        `${baseTranslationKey}.fields.nickname`
                                    ),
                                }}
                                value={props.formData.nickName}
                                onChange={(e) =>
                                    onChange('nickName', e.target.value)
                                }
                            />
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
};

ShippingAddress.propTypes = {
    formData: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    addAddressToAccount: PropTypes.bool,
    onAddressVerification: PropTypes.func,
    isNewAddressSelected: PropTypes.bool,
    isForBilling: PropTypes.bool
};

export default ShippingAddress;
