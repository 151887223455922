import * as types from '../actions/actionTypes.js';
import * as shippingParameters from '../../components/editShipping/shippingParameters';

const defaultState = {
    selectedShippingAddress: null,
    selectedBillingAddress: null,
    isValidAddress: false,
    isValidBillingAddress: false,
    selectedUserAddressIndex: 0,
    selectedBillingAddressIndex: 0,
    newUserAddressSelected: false,
    newBillingAddressSelected: false,
    isSameBillingChecked: true
};

export default function selectedAddressReducer(state = defaultState, action) {
    if (action.isForBilling) {
        switch (action.type) {
            case types.UPDATE_ADDRESS:
                return {
                    ...state,
                    selectedBillingAddress: action.address,
                    isValidBillingAddress: action.isValidAddress,
                    selectedBillingAddressIndex: action.selectedUserAddressIndex,
                    newBillingAddressSelected: action.newUserAddressSelected,
                };
            case types.UPDATE_BILLING_ADDRESS_SAME:
                return {
                    ...state,
                    selectedBillingAddress: { ...state.selectedShippingAddress },
                    isValidBillingAddress: { ...state.isValidAddress },
                    selectedBillingAddressIndex: { ...state.selectedUserAddressIndex },
                    newBillingAddressSelected: { ...state.newUserAddressSelected },
                };
            case types.CLEAR_BILLING_ADDRESS_GUEST:
                return {
                    ...state,
                    selectedBillingAddress: {
                        ...shippingParameters.createBlankShippingAddress(),
                        email: state.selectedShippingAddress.email
                    },
                    isValidBillingAddress: false,
                    selectedBillingAddressIndex: -1,
                    newBillingAddressSelected: true,
                };
            case types.CLEAR_BILLING_ADDRESS_REGISTERED_USER:
                return {
                    ...state,
                    selectedBillingAddress: {
                        ...shippingParameters.createBlankShippingAddress(),
                        email: state.selectedShippingAddress.email
                    },
                    isValidBillingAddress: true,
                    selectedBillingAddressIndex: 0,
                    newBillingAddressSelected: false,
                };
            case types.UPDATE_IS_VALID_ADDRESS:
                return {
                    ...state,
                    isValidBillingAddress: action.isValidAddress,
                };
            case types.SET_NEW_ADDRESS_SELECTED:
                return {
                    ...state,
                    selectedBillingAddress: { ...state.selectedShippingAddress },
                    isValidBillingAddress: true,
                    selectedBillingAddressIndex: -1,
                    newBillingAddressSelected: true,
                };
            case types.SET_NEW_EMPTY_ADDRESS_SELECTED:
                return {
                    ...state,
                    selectedBillingAddress: shippingParameters.createBlankShippingAddress(),
                    isValidBillingAddress: false,
                    selectedBillingAddressIndex: -1,
                    newBillingAddressSelected: true,
                };
            case types.VALIDATE_ADDRESS:
                return {
                    ...state,
                    validateAddress: action.validate
                };
            case types.UPDATE_SAME_BILLING_CHECKBOX:
                return {
                    ...state,
                    isSameBillingChecked: action.isSameBillingChecked
                };
            default:
                return state;
        }
    } else {
        switch (action.type) {
            case types.UPDATE_ADDRESS:
                return {
                    ...state,
                    selectedShippingAddress: action.address,
                    isValidAddress: action.isValidAddress,
                    selectedUserAddressIndex: action.selectedUserAddressIndex,
                    newUserAddressSelected: action.newUserAddressSelected
                };

            case types.UPDATE_IS_VALID_ADDRESS:
                return {
                    ...state,
                    isValidAddress: action.isValidAddress,
                };
            case types.UPDATE_ADDRESS_RESIDENTIAL_INDICATOR:
                return {
                    ...state,
                    selectedShippingAddress: {
                        ...state.selectedShippingAddress,
                        addressResidentialIndicator: action.addressResidentialIndicator
                    }
                };
            case types.UPDATE_ZIP_CODE:
                return {
                    ...state,
                    selectedShippingAddress: {
                        ...state.selectedShippingAddress,
                        zipCode: action.zipCode
                    }
                };
            case types.SET_NEW_ADDRESS_SELECTED:
                return {
                    ...state,
                    selectedShippingAddress: shippingParameters.createBlankShippingAddress(),
                    isValidAddress: false,
                    selectedUserAddressIndex: -1,
                    newUserAddressSelected: true,
                };
            case types.VALIDATE_ADDRESS:
                return {
                    ...state,
                    validateAddress: action.validate
                };
            case types.UPDATE_SAME_BILLING_CHECKBOX:
                return {
                    ...state,
                    isSameBillingChecked: action.isSameBillingChecked
                };
            default:
                return state;
        }
    }
}
