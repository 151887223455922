/* eslint-disable max-lines*/
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { MdsHeader5, MdsText, MdsLink } from '../../mds';
import ImageContainer from '../images/image-container';
import {
    removeCartLineItem,
    updateDistributorAmount,
    saveCartProgress
} from '../../../redux/actions/cart/cartActions';
import { useTranslation } from 'react-i18next';
import { tealiumLink } from '../../../redux/actions/trackingActions.js';
import { useHistory } from 'react-router-dom';
import { currencyFormatter, getBackOrderQty, getNormalizedProductURL } from '../utlities';
import Modal from '../modals/generic';
import styles from './persistentCartSummaryItem.module.scss';
import { excludePromoInternalDesc } from '../../../data/ExcludePromotions';
import QuantitySelector from './QuantitySelector';

const PersistentCartSummaryItem = ({ item }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const baseTranslationKey = 'translation.common.items.singleItemSummary';
    const [displayModal, setDisplayModal] = useState(false);
    const [modalType, setModalType] = useState('remove');
    const backOrderQty = parseInt(getBackOrderQty(item?.offer));

    if (item?.promotions) {
        item.promotions = (item.promotions || []).filter((promotion) => promotion?.configuration?.internalDescription != excludePromoInternalDesc);
    }

    const keyDetailsLinkHandler = (e, url) => {
        if (url) {
            dispatch(tealiumLink({
                event_name: 'Back to Product',
                funnel_stage: 'Cart Page',
            }))
            window.location.href = getNormalizedProductURL(url)
        }
        e.preventDefault();
    }

    const removeItemClickEventHandler = (e) => {
        e.preventDefault();
        setModalType('remove');
        setDisplayModal(true);
    }

    const removeItemHandler = () => {
        dispatch(tealiumLink({
            event_name: 'Remove Product',
            funnel_stage: 'Cart Page',
        }));
        removeItemAndCall(null);
    }

    const removeItemAndCall = (callBack) => {
        const priceChange = (0 - item.lineitemPrice);
        dispatch(removeCartLineItem(item.id));
        dispatch(
            updateDistributorAmount(
                item.offer.distributor.name,
                priceChange
            )
        );
        dispatch(saveCartProgress(null, callBack));
    }

    const confirmChange = (addlFields) => {
        dispatch(tealiumLink({
            event_name: 'Change Buying Option',
            funnel_stage: 'Cart Page',
        }));
        setDisplayModal(false);
        const refinements = addlFields.filter(f => f.code === process.env.REACT_APP_ORDER_DETAILS_REFINEMENT_PREFIX)
            .map(f => f.value);
        if (refinements[0]) {
            refinements[0] = '#' + Object.entries(JSON.parse(refinements[0]))
                .map(e => e[0] + '=' + e[1])
                .join('|')
        } else {
            refinements.push('');
        }

        removeItemAndCall(() => { history.push('/buyingOptions/' + item.product.id + refinements[0]); });
    }

    const cancelChange = () => {
        setDisplayModal(false);
    }

    const imageUrl = item?.product?.imgURL
        ? item?.product?.imgURL
        : 'https://www.3m.com/3m_theme_assets/themes/3MTheme/assets/images/unicorn/NoImage.jpg';

    let unitOfMeasure = '';
    if (item?.offer && item?.offer.displayUnit) {
        const uom = item.offer.displayUnit;
        unitOfMeasure = uom.charAt(0).toUpperCase() + uom.toLowerCase().slice(1);
    }

    return (
        <>
            <Flex className={styles.CartItem} key={item?.id}>
                {displayModal &&
                    <Modal
                        title={t(`${baseTranslationKey}.${modalType === 'remove'
                            ? 'removeDialog'
                            : 'changeDialog'
                            }.title`)}
                        actions={{
                            confirmEvent: () => modalType === 'remove'
                                ? removeItemHandler()
                                : confirmChange(item.offer.offerAdditionalFields),
                            confirmLabel: t(`${baseTranslationKey}.${modalType === 'remove'
                                ? 'removeDialog'
                                : 'changeDialog'
                                }.submitButton`),
                            cancelLabel: t(`${baseTranslationKey}.${modalType === 'remove'
                                ? 'removeDialog'
                                : 'changeDialog'
                                }.cancel`),
                            cancelEvent: cancelChange,
                            dismissEvent: cancelChange
                        }}
                        show={displayModal}
                    >
                        <MdsText mdsType="body-bold" isInline={true}>
                            {t(`${baseTranslationKey}.${modalType === 'remove'
                                ? 'removeDialog'
                                : 'changeDialog'
                                }.message`)}
                        </MdsText>
                    </Modal>
                }
                <ImageContainer
                    className={styles.rectangle}
                    src={imageUrl}
                    alt={item?.product?.description}
                />
                <Box className={styles.ProductDetail}>
                    <div className={styles.CartItem__KeyDetails}>
                        <a
                            className={`${styles.des} ${item?.offer?.id}-description`}
                            onClick={(e) => keyDetailsLinkHandler(e, item.product.productURL)}
                        >
                            {(item?.product && item?.product?.description) ||
                                'PLACEHOLDER'}
                        </a>
                    </div>

                    <div className={styles.CartItem__KeyDetails2}>
                        <div className={styles.ItemQuantity1}

                        >
                            {t('translation.common.items.singleItemSummary.qty')}:
                            <div
                                style={{

                                    marginLeft: 5,

                                }}
                            >  <QuantitySelector 
                                    item={item}
                                    quantity = {item.quantity}
                                    availableQty = {item.offer.availableQuantity}
                                    backOrderQty = {backOrderQty}
                                    error = {!!item?.error}
                                />
                            </div>



                        </div>

                        <div className={styles.ItemQuantity}>
                            {t(
                                `${baseTranslationKey}.unitOfMeasurement`)}
                            :<br></br> {unitOfMeasure}
                        </div>

                    </div>

                    <div className={styles.CartItem__KeyDetails1} >
                        <MdsHeader5 className={styles.price}>
                            {currencyFormatter.format(item?.offer?.price)}/
                            {unitOfMeasure.toLowerCase()}
                        </MdsHeader5>
                        <MdsLink
                            className={`${item?.id}-remove`}
                            onClick={(e) => removeItemClickEventHandler(e)}

                        >
                            {t(
                                'translation.common.items.singleItemSummary.remove'
                            )}
                        </MdsLink>
                    </div>
                </Box>
            </Flex>
        </>
    );
};

PersistentCartSummaryItem.propTypes = {
    item: PropTypes.object.isRequired,
    updateCartQuantity: PropTypes.func,
    removeCartLineItem: PropTypes.func,
};

export default PersistentCartSummaryItem;
