import * as types from '../actions/actionTypes';
import {myListInitialState as defaultState} from './initialState';

const myListReducer = (state = defaultState, action) => {
    switch (action.type) {
        case types.LOAD_MY_LIST:
            return {
                ...state,
                isLoading: true,
            };
        case types.EMPTY_MY_LIST_ITEM:
            return {
                ...state,
                isLoading: false,
            };            
        case types.LOAD_MY_LIST_SUCCESS:
            return {
                ...state,
                myList: action.myList,
                isLoading: false,
            };
        case types.LOAD_MY_LIST_USER_SUCCESS:
            return {
                ...state,
                user: action.user,
            };
        case types.REMOVE_MY_LIST_ITEM:
            return {
                ...state,
                myList: removeItemFromMyList(state.myList, action.itemId),
            };
        default:
            return {
                ...state,
            };
    }
};

const removeItemFromMyList = (myList, itemId) => {
    const newMyList = [...myList];
    newMyList.splice(
        newMyList.findIndex((x) => x.id === itemId),
        1
    );
    return newMyList;
};

export default myListReducer;
