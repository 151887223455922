import React from 'react';
import PropTypes from 'prop-types';

const MDSHeader6 = (props) => {
  const classes = props.className ? props.className : '';
  return (
    <h6 className={`mds-font_header--6 ${classes}`}>{props.children}</h6>
  );
};

MDSHeader6.propTypes = {
    children: PropTypes.any,
    className: PropTypes.any,
};

export default MDSHeader6;
