
/* eslint-disable */
import React from 'react';
import styles from './shippingAddressButton.module.scss';
import PropTypes from 'prop-types';

import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { MdsButton, MdsLink, MdsText } from '../../mds';

const shippingAddressButton = (props) => {
    const { t } = useTranslation();

    const baseTranslationKey = 'translation.common.shippingAddressButton';

    const {address} = props;

    const fullStreetAddress =
        address.street2 !== null && address.street2 !== ''
            ? address.street1 + ', ' + address.street2 + ', '
            : address.street1 + ', ';

    const fullAddress =
        fullStreetAddress +
        address.city +
        ', ' +
        address.state +
        ', USA  ' +
        address.zipCode +
        '  ';

    const phoneNumber = address.phoneNumber;

    const selectedStyle = props.selected
        ? styles['edit_shipping__button--selected']
        : '';

    const isPOAddress = props.isPOAddress;

    const buttonClass = styles['edit_shipping__button'];

    const nickname = props.nickname === ''
        ? `${address.firstName} ${address.lastName}, ${address.street1}`
        : props.nickname;

    const handleRemoveLinkClick = (e) => {
        e.preventDefault();
        if (props.showRemoveModal) props.showRemoveModal();
    }

    const handleEditLinkClick = (e) => {
        e.preventDefault();
        if (props.handleEditAddressClick) props.handleEditAddressClick();
    }

    return (
        <>
        <div
            className={`${buttonClass}${selectedStyle ? ' ' + selectedStyle : ''} ${props.className?props.className:''}`}
            data-testid="clickable"
            onClick={props.handleAddressButtonClick?() => props.handleAddressButtonClick(props.index):null}
        >
            {props.showAccountAddressDetails &&
                <div className={styles.accountAddressHeaderWrapper}>
                    <div className={styles.nickname}>{nickname}</div>
                    <div className={styles.controls}>
                        <MdsLink 
                            className={styles.link}
                            onClick={(e) => handleEditLinkClick(e)}
                        >
                            {t(`${baseTranslationKey}.editLinkText`)}
                        </MdsLink>
                        <MdsLink
                            className={styles.link}
                            onClick={(e) => handleRemoveLinkClick(e)}
                        >
                            {t(`${baseTranslationKey}.removeLinkText`)}
                        </MdsLink>
                    </div>
                </div>
            }
            <div className={styles.ShippingAddressText}>{address.firstName + ' ' + address.lastName}</div>
            <div data-testid='address-container' className={styles.shipping_address__address_container}>
              <MdsText mdsType='body' isInline={true}>
                {address.street1}
                {address.street2 ? ', ' + address.street2 : ''}<em className={styles.toggle}>, </em>
              </MdsText>
              <MdsText mdsType='body' isInline={true}>
                {`${address.city}, ${address.state}`}<em className={styles.space}>, </em>{`USA ${address.zipCode} `}
              </MdsText>
              <NumberFormat
                className='mds-font mds-font_body'
                displayType={'text'}
                value={phoneNumber}
                format="###-###-####" />
            </div>
            {props.showAccountAddressDetails &&
                <div className={styles.buttons}>
                    <MdsButton
                        mdsType='tertiary'
                        className={styles.button}
                        onClick={(e) => handleEditLinkClick(e)}
                    >
                            {t(`${baseTranslationKey}.editLinkText`)}
                    </MdsButton>
                    <MdsButton
                        className={styles.button}
                        mdsType='tertiary'
                        onClick={(e) => handleRemoveLinkClick(e)}
                    >
                        {t(`${baseTranslationKey}.removeLinkText`)}
                    </MdsButton>
                </div>
            }

        </div>
        { isPOAddress && 
            <div style={{ color: 'red', fontSize: 14, margin: '0 0 20px 0'}}>
                We are sorry, we are unable to ship to P.O. Box addresses. To Proceed, Please either select a different address or add a new address
            </div>}
        </>
    );
};

shippingAddressButton.propTypes = {
    address: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
        phoneNumber: PropTypes.string,
    }),
    selected: PropTypes.bool,
    isPOAddress: PropTypes.bool,
    handleAddressButtonClick: PropTypes.func,
    index: PropTypes.number,
    nickname: PropTypes.string,
    showAccountAddressDetails: PropTypes.bool,
    className: PropTypes.string,
    showRemoveModal: PropTypes.func,
    handleEditAddressClick: PropTypes.func
};

export default shippingAddressButton;
