/* eslint-disable */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import expand from '../../../../../public/images/Expand.svg';
import collapse from '../../../../../public/images/Collapse.svg';
import styles from './message-description.module.scss';
import { MdsText, MdsButton } from '../../../mds';
import Moment from 'react-moment';
import IncidentStatus from '../incident-status';
import statuses from '../incident-status/statuses';
import { useTranslation } from 'react-i18next';

const MessageDescription = (props) => {
  const {
    product = {
      iconUrl: '',
      title: '',
      description: '',
    },
    reason = '',
    created = Date.now(),
    status = statuses.OPEN,
    closeIncident = () => {},
    disputeIncident = () => {},
    closeDispute = () => {},
    children = '',
  } = props;

  const [expanded, setExpanded] = useState(false);
  const {t} = useTranslation();

  const baseTranslationKey = 'translation.myAccount.messageCenter.messageDescription';

  const translation = {
    productTitle: t(`${baseTranslationKey}.productTitle`),
    buttons: {
      closeIncident: t(`${baseTranslationKey}.buttons.closeIncident`),
      disputeIncident: t(`${baseTranslationKey}.buttons.disputeIncident`),
      closeDispute: t(`${baseTranslationKey}.buttons.closeDispute`)
    }
  }

  const toggleDescription = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  }  

  const closeIncidentHandler = (e) => {
    e.preventDefault();
    closeIncident(e);
  }

  const disputeIncidentHandler = (e) => {
    e.preventDefault();
    disputeIncident(e);
  }

  const closeDisputeHandler = (e) => {
    e.preventDefault();
    closeDispute(e);
  }

  return (
    <div className={styles.message_description__container}>
      <div className={styles.message_description__header}>
        <div className={styles.message_description__header_left}>
          {expanded && 
            <MdsText 
              mdsType="body-bold"
              data-testid='message_description_product_title'
            >
              {translation.productTitle}
            </MdsText>}
          <div className={styles.message_description__product_container}>
            <img src={product.iconUrl} width="40px" height="40px" className={styles.message_description__product_icon}/>
            <div className={styles.message_description__product_details_container}>
              <MdsText mdsType="body-bold" className={styles.message_description__product_details_title}>{product.title}</MdsText>
              {expanded ?
                <MdsText 
                  mdsType="body" 
                  className={styles.message_description__product_details_description}
                  data-testid='message_description_product_details_description'
                >
                  {product.description}
                </MdsText> :
                <div 
                  data-testid='message_description_collapsed_incident_details'
                  className={styles.message_description__collapsed_incident_details}
                >
                  <IncidentStatus status={status}/><MdsText mdsType="body">{reason}</MdsText>
                </div>
              }
            </div>
          </div>
        </div>
        <div className={styles.message_description__header_right}>
          {(status === statuses.OPEN || status === statuses.UNDISPUTED) &&
            <>
              <MdsButton 
                mdsType="tertiary" 
                onClick={(e)=> closeIncidentHandler(e)}
                className={styles.message_description__button}
                dataTestId='message_description_close_incident_button'
              >
                {translation.buttons.closeIncident}
              </MdsButton>
              <MdsButton 
                mdsType="tertiary" 
                onClick={(e)=> disputeIncidentHandler(e)}
                className={styles.message_description__button}
                dataTestId='message_description_dispute_incident_button'
              >
                {translation.buttons.disputeIncident}
              </MdsButton>
            </>
          }
          {status === statuses.DISPUTED &&
            <MdsButton 
              mdsType="tertiary" 
              onClick={(e)=> closeDisputeHandler(e)}
              className={styles.message_description__button}
              dataTestId='message_description_close_dispute_button'
            >
              {translation.buttons.closeDispute}
            </MdsButton>
          }
        </div>
      </div>
      {expanded ?
      <>
      <div 
        className={styles.message_description__incident_details_container}
        data-testid='message_description_incident_details'
      >
        <div className={styles.message_description__incident_details_sub_container}>
          <MdsText mdsType="body-bold" className={styles.message_description__incident_details_title}>Incident Reason</MdsText>
          <MdsText mdsType="body" className={styles.message_description__incident_details_description}>{reason}</MdsText>
        </div>
        <div className={styles.message_description__incident_details_sub_container}>
          <MdsText mdsType="body-bold" className={styles.message_description__incident_details_title}>Incident Created</MdsText>
          <MdsText mdsType="body" className={styles.message_description__incident_details_description}>
            <Moment format="MMMM D, YYYY LT">{created}</Moment>
          </MdsText>
        </div>
        <div className={styles.message_description__incident_details_sub_container}>
          <MdsText mdsType="body-bold" className={styles.message_description__incident_details_title}>Incident Status</MdsText>
          <IncidentStatus status={status}/>
        </div>
      </div>
        <MdsText mdsType="body-bold" className={styles.message_description__description_title}>Incident Description</MdsText>
        <MdsText 
          mdsType="body" 
          className={styles.message_description__description_content}
          data-testid='message_description_incident_description_content'
        >
            {children}
        </MdsText>
        <img 
          className={styles.message_description__expander} 
          onClick={(e) => toggleDescription(e)} 
          src={collapse}
          data-testid='message_description_collapse_button'
        />
      </>:
        <img 
          className={styles.message_description__expander} 
          onClick={(e) => toggleDescription(e)} 
          src={expand}
          data-testid='message_description_expand_button'
        />}
    </div>
  );
};

MessageDescription.propTypes = {
  product: PropTypes.object,
  reason: PropTypes.string,
  created: PropTypes.string,
  status: PropTypes.string,
  closeIncident: PropTypes.func,
  disputeIncident: PropTypes.func,
  closeDispute: PropTypes.func,
  children: PropTypes.any,
};

export default MessageDescription;
