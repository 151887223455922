import React from 'react';
import PropTypes from 'prop-types';
const mdsHeader4 = (props) => (
    <h4 className={`mds-font_header--4 ${props.className}`}>{props.children}</h4>
);

mdsHeader4.propTypes = {
    className: PropTypes.any,
    children: PropTypes.any,
};

export default mdsHeader4;
