import React from 'react';
import PropTypes from 'prop-types';
// import {Text} from 'rebass';
import styles from './boxContentHeader.module.scss';

const BoxContentHeader = (props) => <h5 className={`mds-font_header--5 ${styles.line_height_modifier}`}>{props.children}</h5>;

BoxContentHeader.propTypes = {
  children: PropTypes.string,
};

export default BoxContentHeader;
