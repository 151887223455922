import visa from 'payment-icons/min/flat/visa.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import paypal from 'payment-icons/min/flat/paypal.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import amex from 'payment-icons/min/flat/amex.svg';
import verve from 'payment-icons/min/flat/verve.svg';
import defaultIcon from 'payment-icons/min/flat/default.svg';

const payments = {
  amex,
  default: defaultIcon,
  discover,
  mastercard,
  paypal,
  verve,
  visa
}

export default payments;
