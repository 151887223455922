import * as types from '../actions/actionTypes';

const defaultState =  {
    order: 'default',
    messageSummaryList:[
        {
            orderId: '',
            lastUpdatedDate: '',
            distributor: '',
            thread: null
        }
    ]
};

export default function messageCenterReducer(state = defaultState , action) {
    if (action.type === types.LOAD_MESSAGES_SUCCESS) {
        return {
            ...state, 
            messageSummaryList: action.messageSummaryList, 
        };
    }
    return state;
}