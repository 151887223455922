import * as types from '../actions/actionTypes.js';

const defaultState = {
    errors: [],
};

export default function errorLogReducer(state = defaultState, action) {
    if (action.type === types.APPEND_ERROR) {
        const errors = state.errors.map((error) => {
            return {...error};
        });
        errors.push(action.error);
        return {
            ...state,
            errors: errors,
        };
    }

    return state;
}