import React from 'react';
import PropTypes from 'prop-types';

const generateRequiredLabel = (props) => {
  const {required, fieldName} = props.fields;
  const {uniqueID, requiredLabel} = props;
  if (required && requiredLabel !== undefined && requiredLabel !== '') {
    return (
      <label
        htmlFor={uniqueID}
        className="mds-form_elementLabel--required"
        id={`${uniqueID}-requiredLabel`}
        aria-hidden="true"
      >{`${fieldName} ${requiredLabel}`}</label>
    );
  }
  return null;
};

generateRequiredLabel.propTypes = {
  uniqueID: PropTypes.string,
  requiredLabel: PropTypes.string,
  fields: PropTypes.object,
};

export default generateRequiredLabel;
