import React from 'react';
import PropTypes from 'prop-types';

import styles from './incident-status.module.scss';
import { MdsText } from '../../../mds';
import statuses from './statuses';
import { useTranslation } from 'react-i18next';

const IncidentStatus = (props) => {
  const {
      status = statuses.OPEN
  } = props;

  const {
      OPEN,
      CLOSED,
      DISPUTED,
      UNDISPUTED
  } = statuses;

  const {t} = useTranslation();
  const baseTranslationKey = 'translation.myAccount.messageCenter.incidentStatus';

  const translation = {
      OPEN: t(`${baseTranslationKey}.open`),
      CLOSED: t(`${baseTranslationKey}.closed`),
      DISPUTED: t(`${baseTranslationKey}.disputed`),
      UNDISPUTED: t(`${baseTranslationKey}.undisputed`),

  }

  const content = (incidentStatus) => {
      switch (incidentStatus) { 
          case CLOSED: 
                return (
                    <MdsText mdsType="body" className={styles.incident_status__green}>
                        {translation.CLOSED}
                    </MdsText>
                )
          case DISPUTED: 
                return (
                    <MdsText mdsType="body" className={styles.incident_status__red}>
                        {translation.DISPUTED}
                    </MdsText>
                )
          case UNDISPUTED: 
                return (
                    <MdsText mdsType="body" className={styles.incident_status__green}>
                        {translation.UNDISPUTED}
                    </MdsText>
                )
          case OPEN: 
                return (
                    <MdsText mdsType="body" isInline={true} className={styles.incident_status__red}>
                        {translation.OPEN}
                    </MdsText>
                )
          default:
                return (
                    <MdsText mdsType="body" isInline={true} className={styles.incident_status__red}>
                        {translation.OPEN}
                    </MdsText>
                )
      }
  }
  
  return (<>{content(status)}</>);

};

IncidentStatus.propTypes = {
    status: PropTypes.string,
};

export default IncidentStatus;
