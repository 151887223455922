import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import styles from './mds-link.module.scss';

const MdsLink = (props) => {
  const asType = props.as ? props.as : 'a';
  const {to, alt, disabled} = props;

  const onClickEventHandler = (e) => {
    if (props.onClick && !disabled) props.onClick(e);
    if (disabled) e.preventDefault();
  };

  const cls = styles.mds_button;
  const disabledClass = disabled ? styles['mds_button--disabled'] : '';
  const customClass = props.className ? props.className : '';

  return (
    <>
      {asType === 'a' && (
        <a
          className={`${cls} ${disabledClass} ${customClass}`}
          alt={alt}
          href={to}
          onClick={(e) => onClickEventHandler(e)}
        >{props.children}</a>)}
      {asType === 'link' && (
        <span
          className={`${cls} ${disabledClass} ${customClass}`}
        >
          <Link
            alt={alt}
            to={to}
            onClick={(e) => onClickEventHandler(e)}
          >{props.children}</Link>
        </span>)}
    </>
  );
};

MdsLink.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  as: PropTypes.oneOf(['a', 'link']),
  to: PropTypes.string,
  alt: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default MdsLink;
