import * as types from '../actions/actionTypes.js';
import * as UserDefinitions from '../../components/common/userSettings/userDefinitions.js';

const predefinedToken = '1212';

const defaultState = {
    signInType: UserDefinitions.UNDEFINED,
    id: '',
    basketId: '',
    token: '',
    vsrmId: null,
    emailAddress: '',
    address: [],
    paymentOptions: [],
    favorites: [],
    orders: [],
};

export default function signInReducer(state = defaultState, action) {
    switch (action.type) {
        case types.SIGN_IN_GUEST:
            return {
                ...state,
                signInType: UserDefinitions.GUEST,
            };
        case types.CREATE_USER_REQUEST:
                return {
                    ...state,
                    createUserRequest: 'INPROGRESS',
            };    
        case types.CREATE_USER_SUCCESS:
                return {
                    ...state,
                    createUserRequest: 'COMPLETE',
            };
        case types.CREATE_USER_FAILURE:
                return {
                    ...state,
                    createUserRequest: 'FAILED',
            };             
        case types.SIGN_IN_USER:
                return {
                    ...state,
                    signInType: UserDefinitions.REGISTERED_USER,
            };            
        case types.LOAD_USER_SUCCESS:
            return {
                ...state,
                signInType: UserDefinitions.REGISTERED_USER,
                token: action.token,
                id: action.id,
                vsrmId: action.vsrmId,
                emailAddress: action.emailAddress,
                address: action.address,
            };
        case types.LOAD_GUEST_USER_SUCCESS:
            return {
                signInType: UserDefinitions.GUEST,
                id: action.id,
            };            
        case types.LOAD_USER_FAILURE:
            return {
                ...state,
                signInType: UserDefinitions.UNDEFINED,
            };            
        case types.ADD_USER_ADDRESS_SUCCESS:
            const userAddress = state.address.map((address) => {
                return {...address};
            });
            userAddress.push(action.address);
            return {
                ...state,
                address: userAddress,
            };
        case types.ADD_GUEST_ADDRESS_SUCCESS:
            const guestAddress = state.address.map((address) => {
                return {...address};
            });
            guestAddress.push(action.address);
            return {
                ...state,
                address: guestAddress,
            };
        case types.ADD_GUEST_SUCCESS:
            return {
                ...state,
                token: predefinedToken,
                emailAddress: action.emailAddress,
                id: action.id,
            };
        case types.APPEND_ORDER_TO_USER_SUCCESS:
            const orders = state.orders.map((order) => {
                return {...order};
            });
            orders.push(action.order)
            return {
                ...state,
                orders: orders,
            };
        case types.UPDATE_BASKET_ID_SUCCESS:
            return {
                ...state,
                basketId: action.basketId,
            };
        default:
            return state;
    }
}
