/* eslint-disable */
import React from 'react';

import {MdsHeader2b} from '../mds';
import ViewHeader from '../common/view/header';

import styles from './registrationconfirmation.module.scss';

const registrationConfirmationView = () => {
  return (
    <main className={styles.registrationconfirmation_view_container}>
      <ViewHeader title="Account Activation">
        <MdsHeader2b>Thank you for registering on the 3M Marketplace. You will recieve an email shortly with instructions on activating your account. If you have any issues with registration please contact customer support at 1-800-538-5500.</MdsHeader2b>
      </ViewHeader>
    </main>
  );
};

export default registrationConfirmationView;