/* eslint-disable max-len */
/* eslint-disable max-lines */
const footerData = {
  categoryLinks: [
    {
      header: 'Our Company',
      links: [
        {
          text: 'About 3M',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/about-3m/',
        },
        {
          text: '3M Careers',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/careers-us/',
        },
        {
          text: 'Investor Relations',
          href: 'http://investors.3m.com/overview/default.aspx',
        },
        {
          text: 'Partners & Suppliers',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/partners-suppliers/',
        },
        {
          text: 'Sustainability',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/sustainability-us/',
        },
        {
          text: '3Mgives',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/gives-us/',
        },
        {
          text: 'Ethics & Compliance',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/ethics-compliance/',
        },
      ],
    },
    {
      header: 'News',
      links: [
        {
          text: 'News Center',
          href: 'https://news.3m.com/English/3m-news-center/default.aspx',
        },
        {
          text: 'Press Releases',
          href: 'https://news.3m.com/English/press-releases/default.aspx',
        }
      ],
    },
    {
      header: 'Regulatory',
      links: [
        {
          text: 'SDS, RDS, More Regulatory & Compliance Information',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/SDS-search/',
        },
        {
          text: 'Transport Information Search',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/transport-information-search/',
        },
        {
          text: 'CPSIA Certification Search',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/regulatory-information/cpsia-certificate-search/'
        },
        {
          text: 'Lithium Battery UN 38.3 Test Summary Search',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/lithium-battery-test-summary-report-search/'
        },
        {
          text: 'Transparency in Supply Chains and Modern Slavery Disclosures',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/suppliers-direct/supplier-requirements/contract-provisions/',
        },
        {
          text: 'US Ingredient Communication',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/product-ingredients/',
        },
      ],
    },
    {
      header: 'Help',
      links: [
        {
          text: 'Help Center',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/help-center/',
        },
        {
          text: 'Site Map',
          href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/site-map/'
        }
      ],
    },
  ],
  legal: {
    links: [
      {
        text: 'Legal',
        href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/legal-information/',
      },
      {
        text: 'Privacy',
        href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/privacy-policy/',
      },
      {
        text: 'DMCA',
        href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/dmca-copyright-policy/',
      },
      {
        text: 'Accessibility Statement',
        href: process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/company-us/accessibility-statement/',
      },
      {
        text: 'Your Privacy Choices',
        href: 'javascript:utag.gdpr.showDoNotSellPrompt()',
        target: '_self',
        extraClasses: 'js-gdprPrefs MMM--icn_privacy',
      },
      {
        text: 'Refund Policy',
        href:'/terms#returns',
      },
      {
        text: 'Terms & Conditions',
        href:'/terms'
      }
    ],
    copyright: '© 3M ' + (new Date().getFullYear()) + '. All Rights Reserved.',
  },
  social: {
    title: 'Follow us',
    links: [
      {
        icon: 'MMM--socialIcn_twitter',
        href: 'https://twitter.com/3M',
        title: 'Twitter',
      },
      {
        icon: 'MMM--socialIcn_linkedin',
        href: 'https://www.linkedin.com/company/3m/',
        title: 'LinkedIn',
      },
      {
        icon: 'MMM--socialIcn_youtube',
        href: 'https://www.youtube.com/3M',
        title: 'YouTube',
      },
      {
        icon: 'MMM--socialIcn_facebook',
        href: 'https://www.facebook.com/3M',
        title: 'Facebook',
      },
      {
        icon: 'MMM--socialIcn_instagram',
        href: 'https://www.instagram.com/3M/',
        title: 'Instagram',
      },
    ],
    disclosure: 'The brands listed above are trademarks of 3M.'
  }
};

export default footerData;
