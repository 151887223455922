import axios from 'axios';
export async function invokeGeoLocationApi(callback){
    const geoResponse = await axios
    .get(
        process.env.REACT_APP_GEO_LOCATION_URL,        
        {
            headers: {
                Auth: 1212,             
            },
            'axios-retry': {
                retries: 0
              }
        }
    ).catch(error => {
        console.log("error",error.response)
    });
    return callback(geoResponse);
}