/* eslint-disable max-lines */
import React from 'react';
import styles from './cardDetails.module.scss';
import {useTranslation} from 'react-i18next';
import CardReview from '../../common/payment/card-review';
import CCLogo from '../../common/images/cc-logo';
import PropTypes from 'prop-types';
import {MdsText} from '../../mds';
import {cardExpiry} from  '../../../redux/actions/payment/paymentActions';

import ReactTooltip from 'react-tooltip';

const cardDetails = (props) => {

    const { t } = useTranslation();
    const baseTranslationKey = 'translation.payments'

    const handleRemoveLinkClick = (e) => {
        e.preventDefault();
        if (props.removeAction) props.removeAction(props.index);
    }
    const _className  =  !!props.selected ? styles.paymentCardSelected : styles.paymentCard;

     return (
        <div className={_className} data-testid={'paymentCard'+props.index}>
            <div style={{ backgroundColor: 'white' }} className={styles.card_image}>
                <CCLogo vendor={props.card.cardType} className={styles.card_review__image} />
            </div>
            <div style={{ backgroundColor: 'white' }} className={styles.details}>
                <div>
                    <CardReview vendor={props.card.cardType} ending={props.card.last4} exp={cardExpiry(props.card)} view='short' />
                </div>
                <div>
                    <MdsText mdsType='body-light'>{props.card.firstName}</MdsText>
                </div>
                <div>
                    <MdsText mdsType='body-light'>{t(`${baseTranslationKey}.expiresOn`)} {cardExpiry(props.card)}</MdsText>
                </div>
            </div>
             {(props.removeAction) ? (
                 <div className={styles.controls}>
                     { !props.card.inUse ?
                         <a id='removeLink' style={{ cursor: 'pointer' }} onClick={(e) => handleRemoveLinkClick(e)}>
                             {t(`${baseTranslationKey}.removeLink`)}</a>
                             :
                        <>
                            <ReactTooltip id="removeTip" border={true} borderColor={'black'}
                                backgroundColor={'white'} textColor={'black'} html={true}
                                className={styles.tooltip_text} clickable={true}>
                                {t(`${baseTranslationKey}.toolTip`)}
                             </ReactTooltip>
                             <div className={styles.tooltip} data-for="removeTip" data-tip data-html={true}>?</div>
                         </>
                     }
                 </div>
             ) : null
             }
        </div>
    )
}
cardDetails.propTypes = {
    card: PropTypes.shape({
        cardType: PropTypes.string,
        last4: PropTypes.string,
        firstName: PropTypes.string,
    }),
    index: PropTypes.number,    
    removeAction: PropTypes.func,
    selected: PropTypes.bool
};

export default cardDetails;