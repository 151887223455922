/* eslint-disable max-lines */
import * as types from '../actions/actionTypes.js';
import * as helper from './reducer-helpers/offerReducer-helper.js';
import { getBackOrderQty } from '../../components/common/utlities.js';

const defaultState = {
    products: [
      {
        id: '',
        description: '',
        imgURL: '',
        offerAggregates: [],
        offers: [],
        upsellSkus: '',
      }
    ],
    errors:[],
    offerAggregateTabs: [],
    selectedQuantity: [],    
    selectedZipCode: '',       
    zipCodeStatus: '',
    networkErrorStatus: false,
    isOffersAvailable: '',
    selectedOffer: {},
    selectedPrice: 0,
    upsellProducts : [],
};
export default function offerReducer(state = defaultState, action) {
  switch (action.type) {
      case types.LOAD_OFFER_SUCCESS:
      return {
        ...state,
        products: action.offer.products,
        errors: action.offer.errors,
      };
      case types.LOAD_OFFER_AGGREGATES: 
      return {
        ...state,
        offerAggregateTabs : determineAggregates(action.offer.products[0])
      };
      case types.UPDATE_OFFER_AGGREGATES: 
      return {
        ...state,
        offerAggregateTabs : updateAggregates(action.offer.products[0], state.offerAggregateTabs)
      };
      case types.SELECT_QUANTITY:
        return {
          ...state,
          selectedQuantity: helper.insertOrUpdateObjectInArray(state.selectedQuantity, action),
        };
      case types.SELECT_ZIPCODE:
        return {
          ...state,
          selectedZipCode: action.zipCode,
          zipCodeStatus: action.zipCodeStatus 
        };  
      case types.SELECT_ESTIMATED_DELIVERY_OPTION:
        return {
          ...state,
          products: helper.updateSelectedEstimatedDeliveryOption(state.products, action.offerId, action.deliveryOption),
       };       
      case types.SELECT_OFFER:
        return {
          ...state,
          selectedOffer: {...action.offer},
          selectedPrice: action.offerPrice,
        };
      case types.UPDATE_NETWORK_ERROR_STATUS:
        return {
          ...state,
          networkErrorStatus: action.networkErrorStatus,
        }
      case types.DESELECT_OFFER:
          return {
            ...state,
            selectedOffer: {},
            selectedPrice: 0,
          };        
        case types.REMOVE_ALL_OFFER:
           return {
             ...state,
             products : removeAllOffer(state.products)
           }
        case types.CLEAR_SHIPPING_DATA:
           return {
            ...state,
            products : helper.clearShippingData(state.products),
            errors: []
          }
        case types.LOAD_UPSELL_OFFER_SUCCESS:
          return {
            ...state,
            upsellProducts: helper.loadUpsellOffers(state.upsellProducts, action.offer.products),
            errors: action.offer.errors,
          }
        case types.REMOVE_ADDED_UPSELL_OFFER:
          return {
            ...state,
            upsellProducts: helper.removeAddedUpsellOffer(state.upsellProducts, action.product, action.offer),
          }
        case types.UPDATE_IS_OFFERS_AVAILABLE:
          return {
            ...state,
            isOffersAvailable: action.isOffersAvailable,
          }
      default:
        return state;
  }
}

function updateAggregates(product, stateAggregates) {

  if (product.offerAggregates[0]) {
    const aggIndex = stateAggregates.findIndex((agg) => agg.unit == product.offerAggregates[0].unit);  
    stateAggregates[aggIndex] = {   ... stateAggregates[aggIndex],
                                   ...product.offerAggregates[0],
                                   maxQty : getMaxQty(product.offers, product.offerAggregates[0]),                                  
    }
  } 

  return sortStateAggregates(stateAggregates, product);
} 

function sortStateAggregates(stateAggregates, product) {

  if (product.offerAggregates[0]) {

    // First, filter out the items that match the product's lowUom
    const lowUomItems = stateAggregates.filter(item => item.unit === product.lowUom);
    
    // Then, filter out the items that match the product's highUom
    const highUomItems = stateAggregates.filter(item => item.unit === product.highUom);
    
    // Finally, filter out the remaining items and concatenate the three arrays in order low, high, others
    const otherItems = stateAggregates.filter(item => item.unit !== product.lowUom && item.unit !== product.highUom);
    return [...lowUomItems, ...highUomItems, ...otherItems];

  } else {

    return stateAggregates;
    
  }

}   

function determineAggregates(product) {
  const offerAggregates = product.offerAggregates;
  const offers = product.offers;
  return offerAggregates ? offerAggregates.map(agg=> { 
    return { ...agg, 
              maxQty : getMaxQty(offers, agg),
              unitMap : getUnitMap(product.productAdditionalFields, agg, offerAggregates)
           } 
    })
    : null;
}
function removeAllOffer(products){
  return products?products.map(product=>{
    return {
      ...product,
      offers:[]
    }
  }):null;
}

function getMaxQty(offers, agg) {
  return (offers.filter(offer=>offer.displayUnit === agg.unit).map(offer=>offer.availableQuantity - getBackOrderQty(offer)))
      .reduce((prev, current) => (prev > current) ? prev : current)
}

function getUnitMap(productAdditionalFields, agg, offerAggregates) {
  if (!productAdditionalFields || !agg)
    return '';
  const largestUnitOpt = productAdditionalFields.filter(field => field.code === 'high_uom' && field.value !== 'EACH')
    .map(field => field.value);
  const qtyOpt = productAdditionalFields.filter(field => field.code === 'num_low_uom_in_high_uom').map(field => field.value);
  const smallestUnitOpt = productAdditionalFields.filter(field => field.code === 'low_uom').map(field => field.value);
  const smallestUnitQtyInEachesOpt = productAdditionalFields.filter(field => field.code === 'low_uom_num_of_eaches').map(field => field.value);
  const lowUomOfferExists = smallestUnitOpt ? offerAggregates.filter(oa => oa.unit === smallestUnitOpt[0]).length > 0 : false;

  if (largestUnitOpt[0] && qtyOpt[0] && smallestUnitOpt[0] && lowUomOfferExists && largestUnitOpt[0] === agg.unit)
    return '(' + parseInt(qtyOpt[0]) + ' ' + smallestUnitOpt[0] + ')';

  if (lowUomOfferExists && smallestUnitOpt[0] && smallestUnitQtyInEachesOpt[0] && smallestUnitOpt[0] === agg.unit)
    return '(' + smallestUnitQtyInEachesOpt[0] +')';

  return '';
}