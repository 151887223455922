import React from 'react';
import PropTypes from 'prop-types';
import {filterHtmlProps} from '../../mds/helpers.util';

const getClass = (type) => {
    switch (type) {
        case 'hero': return 'mds-font_hero--text';
        case 'legal': return 'mds-font_legal';
        case 'category-title': return 'mds-font_category--title';
        case 'intro-1': return 'mds-font_intro--1';
        case 'intro-2': return 'mds-font_intro--2';
        case 'paragraph': return 'mds-font_paragraph';
        case 'body': return 'mds-font_body';
        case 'body-bold': return 'mds-font_body mds-font_bold';
        case 'body-light': return 'mds-font_body mds-font_light';
        case 'light-italic': return 'mds-font_light-italic';
        case 'bold-italic': return 'mds-font_bold-italic';
        case 'regular-italic': return 'mds-font_italic';
        case 'regular':
        default: return 'mds-font';
    }
};

const getInline = (type) => {
    if (type === true) {
        return ' u-inline-text';
    }
    return '';
};

const makeElement = (elType, props, children) => {
    const htmlProps = filterHtmlProps(props, (key, val) => [
      'className',
      'style',
      'data-testid',
      'onClick',
      'onBlur',
      'onFocus',
      'onTarget',
      'onHover']
      .includes(key));
    let el = null;
    const custom = props.el ? true : false;

    switch (elType) {
        case 'hero':
        case 'intro-1':
        case 'intro-2':
        case 'body':
            el = React.createElement(custom ? props.el : 'div', htmlProps, children);
            break;
        case 'category-title':
            el = React.createElement(custom ? props.el : 'span', htmlProps, children);
            break;
        case 'legal':
        case 'paragraph':
        case 'light-italic':
        case 'bold-italic':
        case 'regular-italic':
        case 'regular':
        default:
            el = React.createElement(custom ? props.el : 'p', htmlProps, children);
    }

    return el;
};

const mdsText = (props) => {
    const className = `${props.className ? props.className : ''} ${getClass(props.mdsType)} ${getInline(props.isInline)}`;
    const newProps = {...props, className};
    const el = makeElement(props.mdsElement ? props.mdsElement : null, newProps, props.children);

    return el;
};

mdsText.propTypes = {
    mdsType: PropTypes.string,
    isInline: PropTypes.bool,
    el: PropTypes.string,
    className: PropTypes.any,
};

export default mdsText;
