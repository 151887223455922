/* eslint-disable max-lines */
import { multiplyBD, addBD } from '../../../components/common/utlities';
import * as PurchaseStates from './purchaseStates';

export function createOperatorPromotions(promoCodes, newPromoCode) {
    // cart.operatorPromotions = {
    //     appliedOperatorPromotions: state.cart.operatorPromotions
    // }
    const promoCodeList = [];
    if (promoCodes != null && promoCodes.appliedOperatorPromotions != null && promoCodes.appliedOperatorPromotions != undefined
        && promoCodes.appliedOperatorPromotions.length > 0) {
        promoCodeList.push({
            ...(promoCodes.appliedOperatorPromotions[0]),
            updated: true,
            active: true,
            code: newPromoCode,
            deducedAmount: 0
        })
    } else {
        promoCodeList.push({
            code: newPromoCode,
            updated: true,
        })
    }
    promoCodes = {
        appliedOperatorPromotions: promoCodeList,
        totalDeducedAmount: 0
    };
    return promoCodes;
}
export function updateError(error) {

    error = error.map(err => {

        return {
            code: err.code,
            type: err.type,
            message: err.message,
            offerId: err?.offerId,
            oldPrice: err?.oldPrice
        }
    })
    return error;
}

export function updateActive(promoCodes, active) {
    const promoCodeList = [];
    if (promoCodes != null && promoCodes.appliedOperatorPromotions != null && promoCodes.appliedOperatorPromotions != undefined
        && promoCodes.appliedOperatorPromotions.length > 0) {
        promoCodeList.push({
            ...(promoCodes.appliedOperatorPromotions[0]),
            code: '',
            active: active,
            deducedAmount: 0,
            updated: false
        })
    }
    promoCodes = {
        appliedOperatorPromotions: promoCodeList,
        totalDeducedAmount: 0
    };
    return promoCodes;
}
export function updateLineItems(lineItems, distributorName, newShippingType, price) {
    const updatedLineItems = [...lineItems];

    updatedLineItems.forEach((element) => {
        if (element.offer.distributor.name === distributorName) {
            element.shippingType = newShippingType;
            element.shippingCost = price;
            if(!!element.estimateShippingType){
                element.estimateShippingType = newShippingType;
                element.estimateShippingCost = price;
            }
        }
    });

    return updatedLineItems;
}

export function updateLineItemQuantity(lineItems, lineItemId, newQuantity) {
    const updatedLineItems = [...lineItems];

    updatedLineItems.forEach((element) => {
        if (element.id === lineItemId) {
            element.quantity = newQuantity;
            element.lineitemPrice = multiplyBD(newQuantity, element.offer.price)
        }
    });

    return updatedLineItems;
}

export function mergeBasket(stateCart, actionCart, section, cartOffers) {
    const updatedLineItems = actionCart.lineitem;
    if (!stateCart.lineitem || !updatedLineItems) {
        return [];
    }

    stateCart.lineitem.forEach((stateElement) => {
        let found = false;
        updatedLineItems.forEach((actionElement) => {
            if (stateElement.product.id === actionElement.product.id
                && stateElement.offer.id === actionElement.offer.id) {
                found = true;
                cartOffers.forEach((off) => {
                    if (off.id === actionElement.offer.id) {
                        actionElement.offer.promotions = off.promotions;
                        actionElement.offer.promotionDetails = off.promotionDetails;
                    }
                });

            }
        })

        if (!found) {
            updatedLineItems.push(stateElement);
        }

    });

    if (section == PurchaseStates.CART) {
        updatedLineItems.forEach(li => {
            li.shippingCost = null
            li.tax = null
            li.shippingType = null
        })

    }
    return updatedLineItems;
}

export function addLineItemQuantity(lineItems, quantity, product, offer) {
    const updatedLineItems = [...lineItems];

    updatedLineItems.push(
        {
            quantity: quantity,
            product: product,
            offer: offer,
            lineitemPrice: multiplyBD(offer.price, quantity)
        });

    return updatedLineItems;
}

export function updateLineItemOffers(lineItems, offer, error) {
    lineItems = [...lineItems];
    const lineItemOpt = lineItems.filter(item => item.offer.id === offer.id);
    if (lineItemOpt && lineItemOpt[0]) {
        const lineItem = lineItemOpt[0];
        lineItem.offer = offer;
        lineItem.error = error;
    }
    return lineItems;
}

export function updateDistributorShippingPrice(
    distributors,
    distributorName,
    price
) {
    const newDistributors = [...distributors];

    newDistributors.forEach((element) => {
        if (element.distributor.name === distributorName) {
            element.shippingPrice = price;
        }
    });

    return newDistributors;
}

function generateDistributorList(arr) {
    return arr.reduce((accumulator, currentValue) => {
        const distributor = currentValue.offer.distributor;
        const result = accumulator.find(
            (x) => x.distributor.name === distributor.name
        );
        if (result) {
            result.totalPrice = addBD(result.totalPrice, currentValue.lineitemPrice);
        } else {
            accumulator.push({
                distributor: distributor,
                totalPrice: addBD(currentValue.lineitemPrice, 0),
            });
        }
        return accumulator;
    }, []);
}

export function mergeDistributors(
    distributors,
    actionCart,
    section
) {

    return updateDistributorShippingPriceOnLoad(generateDistributorList(actionCart.lineitem), actionCart, section)
}


export function updateDistributorShippingPriceOnLoad(
    distributors,
    actionCart,
    section
) {
    if (!actionCart || !actionCart.lineitem) {
        return distributors
    }

    /*
    
    if(distributors.length<=0 && actionCart.lineitem.length>0) {
        return generateDistributorList(actionCart.lineitem);
    }
    */


    const newDistributors = [...distributors];

    newDistributors.forEach(x => {
        const matchedLineItem = actionCart.lineitem.filter(y =>
            x.distributor.name === y.offer.distributor.name)

        let shippingCost = 0;
        if (section != PurchaseStates.CART && section != PurchaseStates.CHECKOUT_EDIT_SHIPPING) {
            matchedLineItem.forEach((item) => {            
                if (!item.shippingCost) {
                    shippingCost += item.offer.deliveryOptions ? item.offer.deliveryOptions[0].price : 0
                } else {
                    const idx = item.shippingType ? item.offer.deliveryOptions.findIndex(obj => obj.code === item.shippingType) : 0
                    // null or empty handling
                    shippingCost += item.offer.deliveryOptions[idx >= 0 ? idx:0].price;
                }
            });
            if (!x.shippingPrice || x.shippingPrice === "0") {
                x.shippingPrice = shippingCost
            }
        } else if (section == PurchaseStates.CART) {
            let shippingCostFound  = false;
            matchedLineItem.forEach((item) => {
                 if (!!item.estimateShippingCost) {
                    shippingCostFound =  true;
                    shippingCost += item.estimateShippingCost;
                } 
           });
           if (shippingCostFound && (!x.shippingPrice || x.shippingPrice === "0")) {
            x.shippingPrice = shippingCost
           }
        } else if(section == PurchaseStates.CHECKOUT_EDIT_SHIPPING){
            let shippingCostFound  = false;
            matchedLineItem.forEach((item) => {
                if(!!item.shippingCost){
                    shippingCostFound =  true;
                    const idx = item.shippingType ? item.offer.deliveryOptions.findIndex(obj => obj.code === item.shippingType) : 0
                    // null or empty handling
                    shippingCost += item.offer.deliveryOptions[idx >= 0 ? idx:0].price;
                } else if (!!item.estimateShippingCost) {
                    shippingCostFound =  true;
                    shippingCost += item.estimateShippingCost;
                } 
           });
           if (shippingCostFound && (!x.shippingPrice || x.shippingPrice === "0")) {
            x.shippingPrice = shippingCost
           }
        }  
    })
    return newDistributors
}

export function updateDistributorPrice(distributors, distributorName, price) {
    const newDistributors = [...distributors];
    let removeDistributorIndex = -1;
    newDistributors.forEach((element, index) => {
        if (element.distributor.name === distributorName) {
            element.totalPrice = element.totalPrice + price;
            if (element.totalPrice <= 0)
                removeDistributorIndex = index;
        }
    });

    if (removeDistributorIndex !== -1) {
        newDistributors.splice(removeDistributorIndex, 1);
    }
    return newDistributors;
}

export function loadCartOfferSuccess(stateOffers, offer) {
    const index = stateOffers.findIndex((stateOff) => stateOff.id === offer.id);
    if (index < 0) {
        stateOffers.push(offer);
    }
    return stateOffers;
}