/* eslint-disable max-lines*/
import React from 'react'; import MdsHeader1 from '../../../atomics/headers/mds-header-1';

import styles from './TermsAndConditions.module.scss';

const TermsAndConditions = (props) => {
   
    return ( <div className={styles.terms_and_cond}><MdsHeader1>3M Marketplace Buyer Terms and
    Conditions</MdsHeader1> <p>By using the 3M Marketplace, you agree to the applicable 3M terms and conditions,
    including the following. If you do not agree to these terms and conditions, then do not use the 3M Marketplace.</p>

           <h3>Products, Pricing, Orders, and Fulfillment</h3>

           <p>3M products (“Products”) available for purchase on the 3M Marketplace are offered for sale by
           participating 3M authorized distributors (“Distributors”), not by 3M.  Distributors set the prices (as well
           as promotional discounts and shipping terms) for the Products they offer.  Distributors are responsible for
           the fulfillment and shipment of Products identified in orders that the Distributors accept.</p>

			<p>Acknowledgement of an order means that your order request has been received; it does not mean that your order has
			been accepted.  Acceptance of an order does not constitute acceptance of any other terms and conditions.</p>

			<p>3M will share order information (e.g., name, address, Products, etc.) with the applicable Distributors to help
			facilitate order fulfillment.  Distributors will receive and use this information for the sole purpose of fulfilling
			orders and completing transactions, and for no other purpose.  While 3M will seek to help facilitate Distributors&apos;
			fulfillment, 3M is not liable for Distributors&apos; failure to ship, or your failure to receive, Products by a certain
			date or in a certain quantity or condition. 3M may also use order information to contact you regarding order processing
			errors and assist with error resolution and fulfillment.</p>

			<p>Title and risk of loss for the ordered Products transfers from Distributor upon delivery of the Products to
			you.</p>

			<p>3M and Distributors seek to ensure accurate pricing on the 3M Marketplace, but errors may occur.  If this happens,
			3M and Distributors will work to correct pricing errors as soon as possible.  3M and Distributors each reserve the
			right to refuse or cancel orders containing pricing errors, with no further obligations other than refund of the
			associated payment.  </p>

			<p>3M and Distributors each reserve the right to refuse or cancel orders placed for Products where the sale or use of
			such Products is restricted or prohibited.</p>

			<p>All orders are closed after 90 days, after which incidents concerning your order can not be opened (for example, 
				order not received).  Please promptly report any incidents.</p>

			<h3>Payment and Taxes</h3>

			<p>Payment information and processing for Products ordered via the 3M Marketplace is handled by a third-party payment
			service provider, not 3M.  Payment processing is subject to that provider&apos;s terms and conditions. 
			If a credit card or other payment authorization expires prior to order fulfillment or cancelation (due, for example, 
			to an item being on backorder), then that payment authorization will be renewed until the order is either fulfilled 
			or canceled.</p>

			<p>3M is not a party to any agreement you may have with the issuer of your credit card, debit card, or other form of
			payment, and you are solely responsible for all payment obligations owed to such issuer.  </p>

			<p>Payment funds are processed and the net amount released to Distributors in payment for Products purchased.</p>

			<p>3M calculates tax on the Distributors&apos; behalf per applicable taxing requirements.  The tax amount shown at the
			time of order placement is an estimate and final tax amounts may differ, including as a result of the ultimate
			shipment and delivery locations, with any differences resolved using the payment method provided.  Applicable tax
			exemption certificates must be submitted via the My Account portion of the 3M Marketplace.</p>

			<h3>Account Creation and Usage</h3>

			<p>You may order Products via the 3M Marketplace as a guest or by creating an account.  Some features require an
			account.  You are responsible for maintaining the security of your account, including your username and password, and
			for all activity logged and orders placed using your account.  3M and Distributors are not responsible any losses
			arising from or relating to intended, unintended, or unauthorized use of your account.  3M reserves the right to
			suspend, restrict, or terminate user accounts, and to block or restrict access to the 3M Marketplace.  3M also
			reserves the right to: modify or discontinue any part of the 3M Marketplace; charge, modify, or waive fees required
			to use the 3M Marketplace; and offer additional or different opportunities to use the 3M Marketplace to some or all
			users.</p>

			<h3>Questions and Concerns</h3>

			<p>You can submit questions and concerns to 3M or Distributors via the 3M Marketplace.  Inquiries directed to
			Distributors will be sent to those Distributors for review and response.  3M may monitor such communications.</p>

			<a name="cancellation"></a>
			<h3>Order Cancellation</h3>

			<p>You may cancel an order until it is in the shipping process. To cancel your order:</p>

			<ul>
				<li>Create or log into your 3M Marketplace account.</li>
				<li>If you do not have a 3M Marketplace account, click here and enter your order number and email address.</li>
				<li>Select Order History and then View Order Details.</li>
				<li>Click on Cancel order button.</li>
				<li>After submitting the cancellation you&apos;ll receive a confirmation email.</li>
			</ul>

			<p>If the Cancel order button is not available, you will need to contact the Distributor directly by clicking on 
			the Message Distributor button.  If the order is in the shipping process, Distributors are unable to stop shipment. 
			You will need to wait until you receive the order and then process a return.</p>

			<a name="returns"></a>
			<h3>Incidents/Returns</h3>

			<p>Incidents and Returns can be raised for one of the following reasons: damaged item, item not received, item not as
			 ordered, delivery took longer than expected, general issue, or I want to return the product.</p>

			<p>Products purchased via the 3M Marketplace can be returned only to the fulfilling Distributor, and only through the
			3M Marketplace.  Returns cannot be done in-store. Please work with the Distributor on resolution before going to 
			your bank or credit card to dispute the charge. </p>

			<p>Products can be returned within 30 days from the date you receive it, subject to the fulfilling Distributor&apos;s
			 acceptance of your return request.  All returned items must: be in original, clean, and resalable condition;
			 contain the original 3M packaging and intact UPC codes, if applicable; and be in a complete unit of measure
			 (for example, if the initial transaction was for a full case, then a partial case is not eligible for return).</p>

			<p>The following are not eligible for returns: items that have been used, altered, or otherwise made non-resalable
			 (such as disposable respirators, which cannot be returned once opened); custom-made items; items whose packaging 
			 has been altered or custom labeled; and any item that has been obsoleted, has an expired shelf life, or is not a 
			 current product available from Distributors.</p>

			<p>Distributor will provide a pre-paid shipping label via the original carrier used in the shipment if a return is
			due to the fault of the Distributor. If you change your mind and want to return a Product, then you will pay the
			shipping cost to send the item back. In the event of a permitted partial return, where an order previously qualified
			for a free or reduced shipping promotion, the shipping costs for the original order will not be recalculated.</p>

			<p>To process an incident or return:</p>

			<ul>
				<li>Create or log-in to your 3M Marketplace account.</li>
				<li>If you do not have a 3M Marketplace account, <a href="https://ui.mkpl.3m.com/guestCheckOrder">click here</a>
				&nbsp;and enter your order number and email address.</li>
				<li>Select Order History and then View Order Details.</li>
				<li>Click Initiate Incident/Return next the item you wish to return.</li>
				<li>Click Return Product within the Select Incident Reason field.</li>
				<li>Monitor your account to determine if the Distributor has accepted or denied your return request.</li>
				<ul> <li>If the return is accepted, the Distributor will send you a return
			shipping label.  Use this label to ship the Product to the Distributor.  Once the Distributor receives the returned
			Product, the refund will be processed (typically in the next three business days).</li> </ul> 
			</ul>

			<a name="refunds"></a>
			<h3>Refunds</h3>

			<p>Refunds are processed using the payment method specified in the original purchase.  You can check the status of
			pending refunds via your 3M Marketplace account.  Price matching and other similar adjustments are not available via
			the 3M Marketplace.</p>

			<h3>Exchanges</h3>

			<p>Exchanges cannot be processed via the 3M Marketplace.  If a different Product is desired (for
			example, different size or color), then you will need to consider whether to return a previously purchased Product
			and order a different Product.</p>

			<h3>User Reviews, Comments, and Submissions</h3>

			<p>You may post reviews, comments, photos, videos, and other content so long as the content is not illegal, obscene,
			threatening, defamatory, invasive of privacy, infringing of intellectual property rights (including publicity
			rights), or otherwise injurious to third parties or objectionable, and does not consist of or contain software
			viruses, political campaigning, commercial solicitation, or any form of &quote;spam&quote; or unsolicited commercial electronic
			messages.  You may not use a false e-mail address, impersonate any person or entity, or otherwise mislead as to the
			origin of the content.  3M reserves the right to reject, remove, or revise nonconforming content.</p>

			<p>If you do post content or submit material, then you grant 3M a nonexclusive, royalty-free, perpetual, irrevocable,
			and fully sublicensable right to use, reproduce, syndicate, modify, adapt, publish, perform, translate, create
			derivative works from, distribute, and display such content throughout the world in any media.  You grant 3M the
			right to use the name that you submitted in connection with such content.  You represent and warrant that you own or
			otherwise control all the rights to the content that you post; that the content is accurate; and that use of the
			content you supply does not violate applicable 3M terms and conditions and will not injure any person or entity.  3M
			has the right but not the obligation to monitor and reject, remove, or revise any activity or content on the 3M
			Marketplace. 3M takes no responsibility and assumes no liability for any content posted by you or any third
			party.</p>

			<h3>Prohibited Conduct</h3>

			<p>In connection with the 3M Marketplace, you will not:</p>

			<ul> <li>Violate any legal requirements or terms and conditions applicable to the 3M Marketplace.</li> <li>Post any
			content that violates applicable guidelines and requirements.</li> <li>Engage in any fraudulent, tortious, or
			otherwise unlawful or injurious actions.</li> <li>Restrict or inhibit any other person from using the 3M
			Marketplace.</li> <li>Use or upload any virus, spyware, or other computer code, file, or program that is or is
			potentially harmful, invasive, or intended to damage or interfere with the 3M Marketplace.</li> <li>Harvest or
			collect information from or about the 3M Marketplace or its users.</li> <li>Reproduce, modify, adapt, translate,
			create derivative works of, or otherwise exploit any portion of (or any use of) the 3M Marketplace.</li> <li>Reverse
			engineer, decompile, or disassemble any portion of the 3M Marketplace, except where such restriction is expressly
			prohibited by applicable law.</li> <li>Frame or mirror any portion of the 3M Marketplace, or otherwise incorporate
			any portion of the 3M Marketplace into any product or service.</li> <li>Use any robot, spider, site search/retrieval
			application, or other manual or automatic device to retrieve, index, “scrape,” “data mine,” or otherwise gather
			information or reproduce or circumvent the navigational structure or presentation of the 3M Marketplace.</li> </ul>

			<h3>Compliance</h3>

			<p>You must comply with all federal, state, and local laws applicable to the use of the 3M Marketplace and to the
			handling, transportation, storage, use, processing, disposal, distribution, sale, and resale of Products.  You must
			not offer or make any payment or give anything of value to another person or entity where such payment or action
			would violate an applicable law or regulation, including, but not limited to, any applicable anti-bribery,
			anti-corruption, or anti-kickback law.</p>

			<h3>Product Information, Selection, and Use</h3>

			<p>3M may provide Product information, including technical information, specifications, recommendations, literature,
			and other materials (collectively “Product Information”) for your convenience in your selection of Products. The
			accuracy or completeness of Product Information is not guaranteed and is subject to change without notice. No license
			under any 3M or third-party intellectual property rights is granted or implied with this Product Information. You are
			solely responsible for evaluating and selecting Products and determining whether each Product is fit for a particular
			purpose and suitable for your use and method of application. Products are not manufactured to any of your specific
			requirements or specifications unless expressly agreed to in writing by 3M. Products that are sold for your use or
			consumption may not be repackaged, resold, or redistributed in any manner without 3M&apos;s prior written consent.</p>

			<h3>Industrial/Occupational Products</h3>

			<p>3M intends, labels, and packages its industrial and occupational products for sale to trained industrial and
			occupational customers for workplace use.  Unless specifically stated otherwise on the applicable product packaging
			or literature, these products are not intended, labeled, or packaged for sale to or use by consumers (e.g., for home,
			personal, primary or secondary school, recreational/sporting, or other uses not described in the applicable product
			packaging or literature), and must be selected and used in compliance with applicable health and safety regulations
			and standards (e.g., U.S. OSHA, ANSI), as well as all Product Information, user instructions, warnings, and other
			limitations, and the user must take any action required under any recall, field action, or other product use notice.
			To the extent you promote or sell such products outside of industrial and occupational channels or to consumers; or
			relabel the Product in any manner; or subdivide, repack, or sell products in units smaller than as packaged by 3M;
			you bear sole responsibility for meeting all applicable requirements (including packaging and labeling requirements)
			and for any resulting claims, losses, and damages.</p>

			<h3>Product Warranty, Disclaimer, and Limited Remedy</h3>

			<p>Unless a different warranty is expressly specified on the applicable Product Information or Product packaging (in
			which case such warranty governs), 3M warrants that each Product meets 3M&apos;s applicable Product specifications at the
			time 3M ships each Product.  3M MAKES NO OTHER WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING, BUT NOT
			LIMITED TO, ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND ARISING
			OUT OF A COURSE OF DEALING, PERFORMANCE, CUSTOM, OR USAGE OF TRADE.  If a Product does not conform to this warranty,
			your sole and exclusive remedy is, at 3M&apos;s option, repair or replacement of the nonconforming Product or refund of
			its purchase price.  3M has no obligation under this warranty with respect to Products that are modified or damaged
			by you, including through misapplication, misuse, abuse, accident, neglect, or mishandling.  You must notify 3M
			within 45 days after receipt that you believe a Product is non-conforming and, thereafter, cooperate with 3M&apos;s
			investigation of such claim, otherwise you waive any right or claim with respect to such non-conformance.  The
			foregoing warranty and remedy shall not apply to experimental or developmental Products, which are sold “as is” and
			without any warranty.</p>

			<h3>3M Marketplace Disclaimer</h3>

			<p>3M cannot and does not represent or warrant that the 3M Marketplace, its servers, or its associated communications
			will be error-free, uninterrupted, free from unauthorized access (including third-party hackers or denial of service
			attacks), or otherwise meet your requirements.  THE 3M MARKETPLACE AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS,
			SERVICES, AND USER CONTENT INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SITE (COLLECTIVELY, THE
			&quote;SITE CONTENTS&quote;) ARE PROVIDED ON AN &quote;AS IS&quote; AND &quote;AS AVAILABLE&quote; BASIS, WITHOUT
			REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF ANY KIND.  3M MAKES NO REPRESENTATIONS, WARRANTIES, OR CONDITIONS OF
			ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
			FITNESS FOR A PARTICULAR PURPOSE, AND ARISING OUT OF A COURSE OF DEALING, PERFORMANCE, CUSTOM, OR USAGE OF TRADE, AS
			TO THE OPERATION OF THE 3M MARKETPLACE, THE ACCURACY OR COMPLETENESS OF THE SITE CONTENTS, OR THAT COMMUNICATIONS
			SENT VIA THE 3M MARKETPLACE ARE FREE OF MALWARE OR OTHER HARMFUL COMPONENTS.</p>

			<h3>Limitation of Liability</h3>

			<p>Except for the limited remedy above, and except to the extent prohibited by law, 3M is not liable for direct,
			indirect, incidental, special, punitive, or consequential damages (including, but not limited to, lost profits,
			revenue, business, opportunity, or goodwill) that directly or indirectly arise from or relate to Products or the 3M
			Marketplace, regardless of the legal or equitable theory asserted, including, but not limited to, warranty, contract,
			negligence, fraud, or strict liability.</p>

			<h3>Exports</h3>

			<p>Products are sold for use or consumption within the United States only.</p>

			<h3>Governing Law and Dispute Resolution</h3>

			<p>Minnesota law, without regard to conflicts of laws principles, shall exclusively govern your use of the 3M
			Marketplace and all claims and disputes arising from or relating to such use. The United Nations Convention on
			Contracts for the International Sales of Goods does not apply. Except where 3M files a claim or action to collect
			unpaid amounts due from you, all claims and disputes arising from or relating to these terms and conditions, any
			order, or the Products must be resolved exclusively through the following dispute resolution processes: (a) good
			faith negotiations between duly authorized representatives of each party conducted within 90 days of a request for
			negotiations; (b) if necessary, non-binding mediation before a neutral mediator jointly selected and paid for by the
			parties within 120 days of a request for mediation at a location acceptable to both parties; and (c) as a last
			resort, litigation. The state or federal court located within Ramsey County, Minnesota shall be the sole and
			exclusive jurisdiction and venue for any litigation between you and 3M. You agree to submit to the jurisdiction of
			such court in the event of any litigation.</p>

			<h3>Additional Provisions</h3>

			<p>These terms and conditions, including those additional terms and conditions, policies, and information linked from
			or incorporated herein or otherwise found on the 3M Marketplace, constitute the entire agreement between you and 3M
			with respect to the 3M Marketplace and supersede all prior or contemporaneous communications, agreements, and
			proposals with respect to the 3M Marketplace. No provision of these such terms and conditions can be waived except
			pursuant to a writing signed by the party against whom the waiver is sought. No failure to exercise, partial exercise
			of, or delay in exercising any right or remedy under such terms and conditions will operate as a waiver or estoppel
			of any right, remedy, or requirement. </p>

			<h3>U.S. Government Agency Contracts</h3>

			<p>3M primarily sells commercial items as defined in FAR 2.101. For orders which reference a U.S. Government agency 
			prime contract number, or are otherwise identified as a U.S. Government subcontract, the following clauses as in 
			effect on the date of the order are hereby incorporated by reference into these terms only to the extent that they 
			are applicable and required to be flowed down by FAR or DFARS prescription: (a) the clauses required to be flowed 
			down to subcontracts in FAR 52.212-5(e) when the buyer&apos;s government contract is for commercial items/services or 
			those required to be flowed down to subcontracts in FAR 52.244-6 when the buyer&apos;s government contract is for 
			noncommercial items/services, and (b) for contracts with the Department of Defense, the clauses required to be 
			flowed down to subcontracts for commercial items per DFARS 252.244-7000(a), except for DFARS 252.225-7009 - 
			Restriction of Acquisition of Certain Articles Containing Specialty Metals and DFARS 252.227-7013 - Rights in 
			Technical Data--Noncommercial Items, which must be separately negotiated if applicable.  All other U.S. 
			Government agency contract provisions are rejected.</p>

			<h3>Changes</h3>

			<p>3M reserves the right to change these terms and conditions at any time without notice, and such changes will be
			effective immediately upon being posted on the 3M Marketplace.  Each time you use the 3M Marketplace, you should
			review the current terms and conditions.</p>

			<p>Last revised June 24, 2022.</p>


        </div> ); };

export default TermsAndConditions;
