import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {MdsText} from '../../../../mds';

import styles from './thread-tile.module.scss';

const ThreadTile = (props) => {
    const threadLink = props.routeTo === 'undefined' ? true : props.routeTo;
    const { dataTestId = '' } = props;
    return (
        <div className={`${styles.thread_tile}${props.active ? ' ' + styles['thread_tile--active'] : ''}`}>
            {threadLink ? (
                <Link
                    key={props.key}
                    className={styles.thread_tile__container}
                    to={`/myaccount/message-center/${props.subOrder}/${propTypes.thread}`}
                    data-testid={dataTestId}
                >
                    {props.children}
                </Link>
            ) : (
                <div
                    className={styles.thread_tile__container}
                    data-testid={dataTestId}
                >
                    {props.children}
                </div>
            )}
        </div>
    );
};

ThreadTile.propTypes = {
    key: PropTypes.string,
    children: PropTypes.any,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    routeTo: PropTypes.bool,
    thread: PropTypes.string,
    subOrder: PropTypes.string,
    dataTestId: PropTypes.string,
};

const ThreadTileData = (props) => {
    return (
        <div className={styles.thread_tile__content}>
            <div className={styles.thread_tile__content__row}>
                <MdsText mdsType="body-bold">{props.label}</MdsText>
                <MdsText mdsType="body">{props.lastUpdate || ''}</MdsText>
            </div>
            <MdsText mdsType="body" className={!props.active ? styles.thread_tile__content_text_msg : ''}>
                {props.description}
            </MdsText>
            <span className={`${styles.thread_tile__content_status} ${styles['thread_tile__content_status--' + props.status]}`}>
                <MdsText mdsType="body"></MdsText>
            </span>
        </div>
    );
};

ThreadTileData.propTypes = {
    label: PropTypes.string.required,
    description: PropTypes.string,
    lastUpdate: PropTypes.string,
    status: PropTypes.string,
    active: PropTypes.active,
};

export {ThreadTileData};
export default ThreadTile;
