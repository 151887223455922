import React from 'react';
import ViewHeader from '../common/view/header';
import styles from './networkerror.module.scss';
import {getBasketId, getBasketIdShort} from '../../connectivity/localStorage';
import {Alert, Link} from '@3mcom/mds-library/dist/index';

const NetworkErrorView = () => {
    return (
        <main
            className={styles.error_view_container}
            id="NetworkErrorPage"
            data-error-type="generic"
            data-error-code-short={
                getBasketIdShort() !== null ? getBasketIdShort() : ''
            }
            data-error-code-full={getBasketId() !== null ? getBasketId() : ''}
        >
            <ViewHeader title="We're sorry, a network error has occurred." style={{fontSize: '20px'}}>
                <Alert.Alert variant='error'>
                    <p>
                        Our sincere apologies for the inconvenience. Please try{' '}
                        <Link href={window.location.href} className="mds-link_primary">
                            refresh
                        </Link>{' '}
                        this page, if you continue to get this error please
                        reach out to our customer service team at
                        <span style={{fontWeight: '800'}}>
                            {' '}
                            1-800-538-5500.
                        </span>{' '}
                        Please reference this code
                        <span style={{fontWeight: '800'}}>
                            {getBasketIdShort() !== null
                                ? ` "` + getBasketIdShort() + `"`
                                : ` "000"`}
                        </span>{' '}
                        when connected with a representative. Thanks.
                        </p>
                </Alert.Alert>
            </ViewHeader>
        </main>
    );
};

export default NetworkErrorView;
