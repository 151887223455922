import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ProductBar from '../../../3M-mini-apps/shared/components/product-bar'
import getUserDetails from '../gsn/getUserDetails'
import './carouselModal.scss';
import axios from 'axios';
import PropTypes from 'prop-types';
import {addProtocol} from '../mds/helpers.util';
import {getNormalizedProductURL} from '../common/utlities.js';

const RecommendationCarouselModal = (props) => {
  const { t } = useTranslation();
  const baseTranslationKey = 'translation.cart.carousel';
  const [productData, setProductData] = useState([]);
  const { authenticated } = getUserDetails()

  // Function to update the URLs in the data to be authenticated URLs
  const updateAuthenticatedLinks = () => {
    if (authenticated) {
      const authenticatedData = productData
      authenticatedData.map((prod) => {
        if (prod?.product_url) {
          prod.product_url = prod.product_url.replace('/3M/', '/my3M/');
        }
      })
      setProductData(authenticatedData)
    }
    return null
  }

  // Call API and collect product data on initial load
  useEffect(() => {
    if (!!props.upsellSkusList) {
      axios
        .get(
          process.env.REACT_APP_API_BASE_PRODUCT_URL +
          '/products' +
          `?productIds=${props.upsellSkusList}&offers=false&channelCodes=US`,
          {
            headers: {
              Auth: 1212,
            },
          }
        )
        .then(
          (result) => {   
            const jsonProductList = []; 
            result.data.products.forEach((prod)=>{
              const jsonProduct = {};
              jsonProduct.sku = prod.id;
              jsonProduct.locale ="en_US";
              jsonProduct.product_name = prod.description;
              jsonProduct.image_url = addProtocol(prod.imgURL);
              jsonProduct.product_url = getNormalizedProductURL(prod.productURL);
              jsonProduct.bazaarvoice_id =`${prod.id}|3m-country-catalog|FUZEexperience|en_US`;
              jsonProductList.push(jsonProduct);

            });
            setProductData(jsonProductList);
            updateAuthenticatedLinks();
          },
          (error) => {
            console.log("error getting upsell product data");
          }
        )
        .catch((error) => {          
          console.log("error getting upsell product data");
        })
    } else {
      fetch(process.env.REACT_APP_API_RECOMMENDED_PRODUCTS)
        .then(res => res.json())
        .then(
          (result) => {
            setProductData(result["cross-sell-products"])
            updateAuthenticatedLinks()
          },
          (error) => {
            console.log(error);
          }
        )
        .catch((error) => {          
          console.log("error getting  product data");
        })
    }
  }, [])

  // Create listener for authenticated incase it updates
  useEffect(() => {
    updateAuthenticatedLinks()
  }, [authenticated])

  const productBarData = {
    data: productData
  }

  return (
    <div className="mkpl-carousel-modal">
      {productData.length > 0 &&
        <>
          <h4 className="mds-font_header--5" style={{ textAlign: 'center' }}>{t(`${baseTranslationKey}.recommendedProducts`)}</h4>

          <div className="mkpl-productbar">
            <ProductBar json={productBarData} carousel={true} modal={true} addUpsellCallback = {props.addUpsellCallback} />
          </div>
        </>
      }
    </div>
  );
}

RecommendationCarouselModal.propTypes = {
  upsellSkusList: PropTypes.string,
  addUpsellCallback: PropTypes.func  
}
export default RecommendationCarouselModal;