/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import enUS from './gsnLanguage';
import Cart from './cart'

const Profile = (props) => {
  const {toggleDropdown, isMobile, dropdownHidden, userDetails} = props
  const {authenticated, firstName, lastName} = userDetails; 
  
  const profileIcon = (
      authenticated ?
        <React.Fragment><div className="m-navbar_profIcn m-navbar_profIcn--letter">{firstName[0]}</div>{firstName} {lastName[0]}{lastName !== ' ' ? '.' : ''}</React.Fragment> :
        <React.Fragment><i className="MMM--icn MMM--icn_userHollow large-icn"></i>{enUS.account}</React.Fragment>
  )

  const profileButtonMobile = (
    <div className="m-header-madbar h-notOnDesktop">
      <div className="MAD-Bar">
          <div className="m-account mad-section" style={{minWidth: '300px'}}>
            <Cart />
            <a tabIndex={0} role="button" className="m-btn m-btn--free font--size m-navbar_loginBtn m-navbar_profileBtn link--dark is-header_madSI is-signInToggle is-open" onClick={toggleDropdown}>
              {profileIcon}
              <i className={`MMM--icn mad-barSI-arrow ${dropdownHidden ? 'MMM--icn_down_arrow' : 'MMM--icn_up_arrow'}`}></i>
            </a>
          </div>
      </div>
    </div>
  );

  const profileButtonDesktopUnauth = (
    <a role="button" tabIndex={0} className="m-btn m-btn--free font--size m-navbar_loginBtn m-navbar_signInBtn link--dark is-signInToggle is-header_madDesktopSI" target="_self" onClick={toggleDropdown}>
        {profileIcon}
        <i className={`MMM--icn mad-barSI-arrow ${dropdownHidden ? 'MMM--icn_down_arrow' : 'MMM--icn_up_arrow'}`}
         style={{paddingLeft: '10px'}, {fontSize: '11px'}}></i>
    </a>
  )

  const profileButtonDesktopAuth = (
    <a role="button" tabIndex={0} className="m-btn m-btn--free font--size m-navbar_loginBtn m-navbar_profileBtn link--dark is-profileToggle is-header_madDesktop" target="_self" onClick={toggleDropdown}>
      <span id="iL" className="initialLetter mad-bar-initialLetter">{firstName[0]}</span>
      <i className="fieldZone1" style={{width: 'auto'}}>{firstName}</i> <i className="fieldZone2" alt={lastName}>
        {lastName[0]}{lastName !== ' ' ? '.' : ''}</i>
      <i className={`MMM--icn mad-bar-arrow ${dropdownHidden ? 'MMM--icn_down_arrow' : 'MMM--icn_up_arrow'}`}></i>
    </a>
  )

  if (isMobile) {
    return profileButtonMobile
  } else {
    return (authenticated ? profileButtonDesktopAuth : profileButtonDesktopUnauth)
  }
}

Profile.propTypes = {
  toggleDropdown: PropTypes.func,
  isMobile: PropTypes.bool,
  dropdownHidden: PropTypes.bool,
  userDetails: PropTypes.object,
};

Profile.defaultProps = {
  toggleDropdown: () => {return null},
  isMobile: false,
  dropdownHidden: true
};

export default Profile
