/* eslint-disable */

import React, {useState, useEffect, useRef} from 'react';
import {Alert, Modal, Button, Link} from '@3mcom/mds-library/dist/node/index';
import 'react-responsive-modal/styles.css';
import PropTypes from 'prop-types';

import RadioButtonWrapper from '../atomics/formElements/wrappers/radioButton';
import styles from './popup.module.scss';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import * as cartActions from './../../redux/actions/cart/cartActions';

const PopUp = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const baseTranslationKey =
        'translation.forms.shippingAddressVerificationPopup';
    const {Shipping} = props;
    const modalRef = useRef();
    useEffect(() => {
        modalRef.current.toggle();
        if (props.allowEnteredAddress)
            setSelectedAddress({
                fieldName: 'Entered Address:',
                value: 'enteredAddress',
                defaultSelected: true,
            });
        else
            setSelectedAddress({
                fieldName: 'Recommended Address:',
                value: 'recommendedAddress',
                defaultSelected: true,
            });
    }, [props.Shipping, props.allowEnteredAddress]);

    const recommendedAddressProps = {
        uniqueID: 'recommendedAddress',
        fields: {
            name: 'primaryAddress',
            type: 'primary',
            size: undefined,
            options: [
                {
                    fieldName: 'Recommended Address:',
                    value: 'recommendedAddress',
                    defaultSelected: true,
                },
            ],
        },
    };

    const enteredAddressProps = {
        uniqueID: 'enteredAddress',
        fields: {
            name: 'primaryAddress',
            type: 'primary',
            size: undefined,
            options: [
                {
                    fieldName: 'Entered Address:',
                    value: 'enteredAddress',
                    defaultSelected: props.allowEnteredAddress,
                },
            ],
        },
    };

    const [selectedAddress, setSelectedAddress] = useState({
        fieldName: 'Recommended Address:',
        value: 'recommendedAddress',
        defaultSelected: true,
    });

    const onChange = (e, option) => {
        setSelectedAddress(option);
    };

    const handleContinue = () => {
        if (props.onCloseAfterSelection)
            props.onCloseAfterSelection(selectedAddress, 'continue');
        modalRef.current.toggle();
        dispatch(cartActions.addressValidation({}));
    };

    const handleEditAddress = () => {
        if (props.onCloseAfterSelection)
            props.onCloseAfterSelection(selectedAddress, 'edit');
        modalRef.current.toggle();
        dispatch(cartActions.addressValidation({}));
    };

    return (
        <div>
            <Modal.Container
                size="small"
                closeLabel={t(`${baseTranslationKey}.close`)}
                ref={modalRef}
            >
                <Modal.Heading
                    title={
                        selectedAddress.value === 'recommendedAddress'
                            ? t(`${baseTranslationKey}.titleA`)
                            : t(`${baseTranslationKey}.titleB`)
                    }
                    tag="h3"
                    style={{
                        marginBlockStart: '1.33em',
                        marginBlockEnd: '1.33em',
                    }}
                />
                <Modal.Content>
                    <div>
                        {selectedAddress.value === 'recommendedAddress' ? (
                            t(`${baseTranslationKey}.alertA`)
                        ) : (
                            <Alert.Alert variant="error">
                                <p>
                                    {t(`${baseTranslationKey}.alertB`)}{' '}
                                    {t(`${baseTranslationKey}.alertC`)}
                                </p>
                            </Alert.Alert>
                        )}
                    </div>

                    {!props.allowEnteredAddress && (
                        <div style={{paddingLeft: '10px'}}>
                            <RadioButtonWrapper
                                {...recommendedAddressProps}
                                onClick={(e, option) => onChange(e, option)}
                            />
                            <div className={styles.address_padding}>
                                {!!Shipping.street1
                                    ? Shipping.street1 + ','
                                    : ''}{' '}
                                {!!Shipping.city ? Shipping.city + ',' : ''}{' '}
                                {!!Shipping.state ? Shipping.state + ',' : ''}{' '}
                                {!!Shipping.zipCode.trim()
                                    ? Shipping.zipCode.trim()
                                    : ''}
                                .
                            </div>
                        </div>
                    )}

                    <div style={{paddingLeft: '10px'}}>
                        <RadioButtonWrapper
                            {...enteredAddressProps}
                            onClick={(e, option) => onChange(e, option)}
                        />
                        <div className={styles.address_padding}>
                            {!!props.street1 ? props.street1 + ',' : ''}{' '}
                            {!!props.street2 ? props.street2 + ',' : ''}{' '}
                            {!!props.city ? props.city + ',' : ''}{' '}
                            {!!props.state ? props.state + ',' : ''}{' '}
                            {!!props.zipCode.trim()
                                ? props.zipCode.trim()
                                : ''}
                            .
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Footer>
                    <Button buttonType="primary" onClick={() => handleContinue()}>
                        {t(`${baseTranslationKey}.continue`)}
                    </Button>
                    {selectedAddress.value === 'enteredAddress' && (
                        <Link
                            className="mds-link_secondary"
                            onClick={() => handleEditAddress()}
                        >
                            {props.isNewAddressSelected
                                ? t(`${baseTranslationKey}.editAddress`)
                                : t(`${baseTranslationKey}.changeAddress`)}
                        </Link>
                    )}
                </Modal.Footer>
            </Modal.Container>
        </div>
    );
};

PopUp.propTypes = {
    Shipping: PropTypes.any,
    street1: PropTypes.any,
    street2: PropTypes.any,
    city: PropTypes.any,
    state: PropTypes.any,
    zipCode: PropTypes.any,
    onCloseAfterSelection: PropTypes.func,
    allowEnteredAddress: PropTypes.bool,
    isNewAddressSelected: PropTypes.bool,
};

export default PopUp;
