/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { removeSignedUserCookiesIfAny } from '../../connectivity/cookie';
import { removeGuestLocalStorageItemsIfAny } from '../../connectivity/localStorage';
import headerData from './headerData';

const ProfileDropdown = (props) => {
  const {dropdownHidden, isMobile, userDetails} = props
  const {authenticated, firstName, lastName, email} = userDetails 
  const unauthenticatedLinkData = headerData.profilePopUp.unauthenticated
  const authenticatedLinkData = headerData.profilePopUp.authenticated
  const [showAccountLinks, setShowAccountLinks] = useState(false)
  const [showMyLinks, setShowMyLinks] = useState(false)

  useEffect(() => {
    if (isMobile) {
      setShowAccountLinks(false)
      setShowMyLinks(false)
    } else {
      setShowAccountLinks(true)
      setShowMyLinks(true)
    }
  }, [isMobile])
  
  const dropdownAuthenticated = (
    <div className="m-navbar m-navbar-profile">
          <div className={`is-profilePopUp ${dropdownHidden ? 'h-hidden' : ''}`}>
           <div className="m-navbar_profilePopup font--standard mad-menu-container" aria-modal="true"
            style={isMobile ? {top: '-3px'} : {}} >
            
            <div id="mad-myAccount-id" className={`font--standard m-navbar_popupOptions
              m-navbar_profileOptions mad-myAccount ${showAccountLinks ? 'myTitleBox' : ''}`}
              style={{display: `${isMobile && showMyLinks ? 'none' : 'block'}`}}>                          
              <div className={`myAccountl1 mobile-linkBox ${showAccountLinks && isMobile ? 'myLinkTitlel2': ''}`}
                onClick={() => {setShowAccountLinks(!showAccountLinks)}}>                
                <span className="m-section-title">
                  <i className="MMM--icn MMM--icn_userHollow mad-nav-icn font--size m-navbar_profIcn" 
                    style={{display: `${showAccountLinks && isMobile? 'none' : 'inline-block'}`}}></i>
                  My Account
                  <i className={`MMM--icn mad-nav-arrow font--size m-navbar_profIcn h-notOnDesktop 
                    ${showAccountLinks ? 'MMM--icn_lt2' : 'MMM--icn_gt2'}`}></i>
                </span>                
              </div>
              <div className="myAccountl2 mobile-l2" style={{display: `${showAccountLinks ? 'block' : 'none'}`}}>
                <span id="iL" className="initialLetter h-notOnMobile h-notOnTablet">{firstName[0]}</span> 
                <span id="uN" className="h-notOnMobile h-notOnTablet userName">
                  <i id="fieldZoneDropDown" style={{width: 'auto'}}>{firstName + ' '} </i> 
                  <i className="fieldZone2" alt={lastName} style={{marginLeft: '4px'}}> {lastName[0]}{lastName !== ' ' ? '.' : ''}</i>
                </span>
                <span id="uE" className="userEmail h-notOnMobile h-notOnTablet">{email}</span>
                {authenticatedLinkData.myAccount.map((link, index) => {
                  return (
                    <a href={link.href} key={index}
                      className="link--dark m-navbar_popupOption_noBG" target="_self">{link.text}</a>
                  )
                })}
              </div>
              <div className="MyLogOut h-notOnTablet h-notOnMobile h-onDesktop-block">
                <a className="m-btn font--size m-signOutBtn_lg" 
                   onClick={() => { removeSignedUserCookiesIfAny(); removeGuestLocalStorageItemsIfAny();}}
                   href={process.env.REACT_APP_MMM_LOGOUT_URL} target="_self">Sign out</a>
              </div>
            </div>

            
            <div id="mad-myLinks-id" className={`font--standard m-navbar_popupOptions m-navbar_profileOptions mad-myLinks 
              ${showMyLinks ? 'myTitleBox' : ''}`}
              style={{display: `${isMobile && showAccountLinks ? 'none' : 'block'}`}}>
            <div className={`myLinkl1 mobile-linkBox ${showMyLinks && isMobile ? 'myLinkTitlel2': ''}`}
              onClick={() => {setShowMyLinks(!showMyLinks)}}>
              <span className="m-section-title">
                <i className="MMM--icn MMM--icn_myLinksDropdown mad-nav-icn font--size m-navbar_profIcn"
                  style={{display: `${showMyLinks && isMobile ? 'none' : 'inline-block'}`}}></i>
                My Links
                <i className={`MMM--icn font--size m-navbar_profIcn mad-nav-arrow h-notOnDesktop
                  ${showMyLinks ? 'MMM--icn_lt2' : 'MMM--icn_gt2'}`}></i>
              </span>
            </div>
            <div className="myLinkl2 mobile-l2" style={{display: `${showMyLinks ? 'block' : 'none'}`}} >
              {authenticatedLinkData.myLinks.map((link, index) => {
                  return (
                    <a href={link.href} key={index}
                      className="m-navbar_popupOption_noBG" target="_self">{link.text}</a>
                  )
              })}
            </div>
          </div>
	            
          <div id="mad-LogOut-id" className="MyLogOutl1 MyLogOut h-notOnDesktop-block"
            style={{display: `${(showMyLinks || showAccountLinks) ? 'none' : 'block'}`}}>
            <a className="m-btn font--size m-signOutBtn_lg" href={process.env.REACT_APP_MMM_LOGOUT_URL}
              onClick={() => { removeSignedUserCookiesIfAny(); removeGuestLocalStorageItemsIfAny();}} target="_self">Sign out</a>
          </div>            
        </div>
        <div className="m-navbar_profileOverlay m-overlay is-profileToggle is-close h-hidden"></div>
      </div>           
    </div>
  )

  const signInLink = (
    window.location.href.indexOf('/buyingOptions/') > -1 || window.location.href.indexOf('/myaccount/') > -1
      ? process.env.REACT_APP_ENL_LOGIN
        + '?regcode=MKPL&target=' + encodeURIComponent(process.env.REACT_APP_MY_ACCT_URL)
      : process.env.REACT_APP_ENL_LOGIN
        + '?regcode=MKPL&target=' + encodeURIComponent(window.location.href)
  )

  const dropdownUnauthenticated = (
    <div className="m-navbar m-navbar-noStyle">
      <div className={`is-signInPopUp ${dropdownHidden ? 'h-hidden' : ''}`}>
        <div className="m-navbar_signInPopup font--standard mad-menu-container" aria-modal="true">                           
          <div className="MyLogOut MySignIn">
            <a target="_self" href={signInLink}
              className="m-btn--red font--size m-signOutBtn_lg">{unauthenticatedLinkData[0].text}</a>
          </div>
          <div className="signIn-linkBox">
            <a href={unauthenticatedLinkData[1].href} target="_self">{unauthenticatedLinkData[1].text}</a>
          </div>
        </div> 
      </div>
    </div>
  );
  
  return (authenticated ? dropdownAuthenticated : dropdownUnauthenticated)
}

ProfileDropdown.propTypes = {
  dropdownHidden: PropTypes.bool,
  isMobile: PropTypes.bool,
  simplifiedHeader: PropTypes.bool
};

ProfileDropdown.defaultProps = {
  dropdownHidden: true,
  isMobile: false,
  simplifiedHeader: false
};

export default ProfileDropdown
