import * as PurchaseStates from '../../redux/reducers/reducer-helpers/purchaseStates';

const BreadcrumbData = [
    {
        state: PurchaseStates.CHECKOUT_EDIT_SHIPPING,
        label: 'Shipping',
        step: '1',
        order: 0,
    },
    {
        state: PurchaseStates.CHECKOUT_EDIT_DELIVERY,
        label: 'Delivery',
        step: '2',
        order: 1,
    },
    {
        state: PurchaseStates.CHECKOUT_EDIT_PAYMENT,
        label: 'Payment',
        step: '3',
        order: 2,
    },
    {
        state: PurchaseStates.CHECKOUT_REVIEW,
        label: 'Review',
        step: '4',
        order: 3,
    },
];

export default BreadcrumbData;
