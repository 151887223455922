import React from 'react';
import styles from './addAddressButton.module.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const addAddressButton = (props) => {
    const { t } = useTranslation();
    const baseTranslationKey = 'translation.editShipping.addAdressButton'
    const newClassname =
        styles.IconVerticalLineContainer + ' ' + styles.IconStackTopContainer;

    return (
        <div
            className={`${styles.EditShippingButton} ${styles.edit_shipping__button}`}
            data-testid="clickable"
            onClick={() => props.handleAddAddressButtonClick(props.index)}
        >
            <div className={styles.AddNewAddressMessage}>
                <div className={styles.IconContainer}>
                    <div className={styles.IconVerticalLineContainer}>
                        <div className={styles.IconVerticalLine}></div>
                    </div>
                    <div className={newClassname}>
                        <div className={styles.IconHorizontalLne}></div>
                    </div>
                </div>
                <div className={styles.AddAddressText}>{t(`${baseTranslationKey}.buttonText`)}</div>
            </div>
        </div>
    );
};

addAddressButton.propTypes = {
    handleAddAddressButtonClick: PropTypes.func,
    index: PropTypes.number,
};

export default addAddressButton;
