import * as types from '../actions/actionTypes';
import { myAddressesInitialState as defaultState } from './initialState';

const myAddressesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case types.LOAD_MY_ADDRESSES_USER_SUCCESS:
            return {
                ...state,
                user: action.user,
            };
        case types.ADD_ADDRESS_TO_MY_ADDRESSES_SUCCESS:
            return {
                ...state,
                user: {
                    address: addAddressToMyAddresses(state.user.address, action.address),
                },
            };
        case types.EDIT_ADDRESS_IN_MY_ADDRESSES_SUCCESS:
            return {
                ...state,
                user: {
                    address: editAddressInMyAddresses(state.user.address, action.address),
                },
            };
        case types.REMOVE_ADDRESS_FROM_MY_ADDRESSES_SUCCESS:
            return {
                ...state,
                user: {
                    address: removeAddressFromMyAddresses(state.user.address, action.address),
                }
            }
        default:
            return {
                ...state,
            };
    }
};

const addAddressToMyAddresses = (addressList, address) => {
    const newAddressList = [...addressList];
    newAddressList.push(address);
    return newAddressList;
};

const editAddressInMyAddresses = (addressList, address) => {
    const newAddressList = [...addressList];
    newAddressList.splice(
        addressList.findIndex(x => x.id === address.id),
        1,
        address
    );
    return newAddressList;
};

const removeAddressFromMyAddresses = (addressList, address) => {
    const newAddressList = [...addressList];
    newAddressList.splice(
        addressList.findIndex(x => x.id === address.id),
        1
    );
    return newAddressList;
};

export default myAddressesReducer;