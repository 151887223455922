/* eslint-disable max-lines */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdsButton, Select, Textarea, MdsHeader1 } from '../mds';
import styles from './newIncidentForm.module.scss';
import FileDropzone from '../common/file-upload/file-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import { initiateIncidentFileUpload } from '../../redux/actions/fileUploadActions';
import Loader from '../loader/loader';
import { useTranslation } from 'react-i18next';

const NewIncidentForm = (props) => {
    const {
        products = [{name: ''}],
        formData = {
            product: '',
            reason: '',
            description: '',
            files: [],
        },
        onChange = () => {},
        onSubmit = () => {},
    } = props;

    const {t} = useTranslation();
    const baseTranslationKey = 'translation.forms.newIncidentForm';
    
    const reasons = [
        {name: t(`${baseTranslationKey}.reasons.defectiveItem`)},
        {name: t(`${baseTranslationKey}.reasons.itemNotAsOrdered`)},
        {name: t(`${baseTranslationKey}.reasons.itemNotRecieved`)}
    ];

    const [data, setData] = useState({...formData})
    const [buttonsDisabled, areButtonsDisabled] = useState(true);
    const [attachingFiles, isAttachingFiles] = useState(false);

    const incident = useSelector(state => state.fileUpload.incident)
    const { pendingFiles, files, isLoading } = incident;

    const dispatch = useDispatch();

    useEffect(() => {
        updateFileList();
    },[files]);

    const onChangeHandler = (fieldName, e) => {
        const newData = {...data};
        newData[fieldName] = e.target.value;
        setData({...newData});
        onChange(fieldName, e.target.value);
        requiredFieldsAreEmpty(newData);
    }

    const onSubmitHandler = (e) => {
        e.preventDefault();
        onSubmit(e, data);
    }

    const requiredFieldsAreEmpty = (formData) => {
        areButtonsDisabled(
            formData.product === '' ||
            formData.reason === ''
        );
    }

    const attachFilesClickHandler = (e) => {
        e.preventDefault();
        isAttachingFiles(!attachingFiles);
    }

    const fileSetterHandler = (binaryStr, filePath) => {
        dispatch(initiateIncidentFileUpload(binaryStr, filePath));
    }

    const updateFileList = () => {
        const newData = {...data}
        const newFiles = [...files]
        newData.files = newFiles;
        setData({...newData})
    }

    const buttons = {
        ATTACH_FILES: t(`${baseTranslationKey}.buttons.attachFiles`),
        CANCEL_ATTACH_FILES: t(`${baseTranslationKey}.buttons.cancelAttachFiles`),
        SUBMIT: t(`${baseTranslationKey}.buttons.submit`),
    }

    const fieldNames = {
        PRODUCT: t(`${baseTranslationKey}.fieldNames.product`),
        REASON: t(`${baseTranslationKey}.fieldNames.reason`),
        DESCRIPTION: t(`${baseTranslationKey}.fieldNames.description`),
    }

    const defaultOptions = {
        SELECT_PRODUCT: t(`${baseTranslationKey}.defaultOptions.selectProduct`),
        SELECT_REASON: t(`${baseTranslationKey}.defaultOptions.selectReason`),
    }
    const { ATTACH_FILES, CANCEL_ATTACH_FILES, SUBMIT } = buttons;
    const { PRODUCT, REASON, DESCRIPTION } = fieldNames;
    const { SELECT_PRODUCT, SELECT_REASON } = defaultOptions;

    return(
        <>
        <MdsHeader1>{t(`${baseTranslationKey}.title`)}</MdsHeader1>
        <form onSubmit={(e) => onSubmitHandler(e)}> 
            <div className={styles.newIncidentForm__dropdown}>
                <Select
                    uniqueId='products'
                    dataTestId='newIncidentForm_products'
                    defaultOption={SELECT_PRODUCT}
                    fields={{
                        fieldName: PRODUCT,
                        name: 'productDesignation',
                        disabled: false,
                        options: products,
                        selected: formData.product || SELECT_PRODUCT
                    }}
                    onChange={(e) => onChangeHandler('product', e)}
                />
            </div>

            <div className={styles.newIncidentForm__dropdown}>
                <Select
                    uniqueId='reasons'
                    dataTestId='newIncidentForm_reasons'
                    defaultOption={SELECT_REASON}
                    fields={{
                        fieldName: REASON,
                        name: 'reasonDesignation',
                        disabled: false,
                        options: reasons,
                        selected: formData.reason || SELECT_REASON
                    }}
                    onChange={(e) => onChangeHandler('reason', e)}
                    
                />
            </div>

            <div className={styles.newIncidentForm__textarea}>
                <Textarea
                    uniqueID='description'
                    dataTestId='newIncidentForm_description'
                    name='description'
                    required={false}
                    type='text'
                    fields={{fieldName: DESCRIPTION}}
                    value={formData.description}
                    onChange={(e) => onChangeHandler('description', e)}
                />
            </div>
        </form>
        {attachingFiles && 
        <div 
            className={styles.newIncidentForm__dropzone}
            data-testid='newIncidentForm__dropzone'
        >
            
            <FileDropzone 
                fileSetter={(binaryStr, filePath) => fileSetterHandler(binaryStr, filePath)}
                allowedFileTypes={['gif', 'png', 'jpg', 'jpeg', 'tiff','pdf', 'doc', 'docx','word', 'xlx', 'xlsx', 'zip']}
            />
            
            {(files.length > 0 && !isLoading) && files.map((file, index) => {
                return (
                <div key={index}>
                    {isLoading && pendingFiles.length > files.length ? 
                    <Loader isLoading={isLoading} /> :
                    <img src={file} width='200px' height='200px'/>}
                </div>
                )
            })}
        </div>}
        <div className={styles.newIncidentForm__button_container}>
            <MdsButton 
                mdsType='tertiary'
                className={styles.newIncidentForm__button}
                onClick={(e) => attachFilesClickHandler(e)}
                disabled={buttonsDisabled}
            >
                {!attachingFiles ? ATTACH_FILES: CANCEL_ATTACH_FILES}
            </MdsButton>
            <MdsButton 
                mdsType='primary' 
                className={styles.newIncidentForm__button}
                dataTestId='newIncidentForm_submitButton'
                onClick={(e) => onSubmitHandler(e)}
                disabled={buttonsDisabled}
            >
                {SUBMIT}
            </MdsButton>
        </div>
        </>
    )
}

NewIncidentForm.propTypes = {
    products: PropTypes.arrayOf(PropTypes.exact({
        name: PropTypes.string.isRequired,
    })),
    formData: PropTypes.exact({
        product: PropTypes.string.isRequired,
        reason: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        files: PropTypes.array,
    }),
    onChange: PropTypes.func,
    onSubmit: PropTypes.func
};

export default NewIncidentForm;
