const Big = require('big.js');



export const areShippingAddressEqual = (lhs, rhs) => {
    if (rhs == null && lhs == null) {
        return true;
    }

    if (rhs == null || lhs == null) {
        return false;
    }

    if ((rhs.name !== lhs.name) && (rhs.firstName + ' ' + rhs.lastName !== lhs.name)) {
        return false;
    }

    if (rhs.street1 !== lhs.street1) {
        return false;
    }

    if (rhs.street2 !== lhs.street2) {
        return false;
    }

    if (rhs.city !== lhs.city) {
        return false;
    }

    if (rhs.state !== lhs.state) {
        return false;
    }

    if (rhs.country !== lhs.country) {
        return false;
    }

    if (rhs.zipCode !== lhs.zipCode) {
        return false;
    }

    if (rhs.phoneNumber !== lhs.phoneNumber) {
        return false;
    }

    if (rhs.ext !== lhs.ext) {
        return false;
    }

    return true;
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

export const multiplyBD = (a,b) => {
    const result = new Big(a?a:0).times(new Big(b?b:0)).round(2).toFixed(2);
    return parseFloat(result);
}

export const addBD = (a,b) => {
    const result = new Big(a?a:0).add(new Big(b?b:0)).round(2).toFixed(2);
    return parseFloat(result);
}

export const getBackOrderQty = (offer) => {
    const backOrderOpt = offer?.offerAdditionalFields ? offer.offerAdditionalFields.filter(field=>field.code === 'backorder')
            .map(field=>field.value) : [];
    const backOrderQty= backOrderOpt.length > 0 ? backOrderOpt[0] : 0;
    return backOrderQty;
}
export const getHighUOM = (product) => {
    const highUOM = product.productAdditionalFields ? product.productAdditionalFields.filter(field=>field.code === 'high_uom')
            .map(field=>field.value) : [];
    const getHighUOM= highUOM.length > 0 ? highUOM[0] : 0;
    return getHighUOM;
}
export const getLowUOM = (product) => {
    const lowUOM = product.productAdditionalFields ? product.productAdditionalFields.filter(field=>field.code === 'low_uom')
            .map(field=>field.value) : [];
    const getLowUOM= lowUOM.length > 0 ? lowUOM[0] : 0;
    return getLowUOM;
}
export const getNumOfEaches = (product) => {
    const numOfEaches = product.productAdditionalFields ? product.productAdditionalFields.filter(field=>field.code === 'low_uom_num_of_eaches')
            .map(field=>field.value) : [];
    const getNumOfEaches= numOfEaches.length > 0 ? numOfEaches[0] : 0;
    return getNumOfEaches;
}
export const getUOMInHighUom = (product) => {
    const uOMInHighUom = product.productAdditionalFields ? product.productAdditionalFields.filter(field=>field.code === 'num_low_uom_in_high_uom')
            .map(field=>field.value) : [];
    const getUOMInHighUom= uOMInHighUom.length > 0 ? uOMInHighUom[0] : 0;
    return getUOMInHighUom;
}
export function isValidPhoneNumber(phoneNumber) {
    if (!phoneNumber) return false;
    const phoneRegEx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const digits = phoneNumber.replace(/-|(|)| /g, "");
    return phoneRegEx.test(digits);
}

export function isPOBoxAddress(streetAddress1) {
    if(!streetAddress1) return false;
    const poAddressRegEx = /\bP(ost|ostal)?(([ \.]*(O|0)(ffice)?)|([ \.]*(b|B)(ox)?))+([ \.]*(b|B)(ox)?)?\b/ig;
    return poAddressRegEx.test(streetAddress1);
}

export function getNormalizedProductURL(productURL,variantId) {
    if (!productURL) {
        if (variantId != null && variantId != '') {
    		productURL = process.env.REACT_APP_MMM_DOTCOM_URL + '/3M/en_US/p/d/' + variantId.toLowerCase() + '/';
    	} else {
    		productURL = null;
    	}
    } else {
        productURL = productURL.replace('www.3m.com', process.env.REACT_APP_MMM_DOTCOM_URL);
        if (productURL.indexOf('https://') != 0 && productURL.indexOf('http://') != 0) {
            productURL = 'https://' + productURL;
        }
    }
    return productURL;
}

export function checkIsWeekEnd(){   
    let isWeekEnd = false;     
    const currentCSTTime = new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' });
    const currentDateTime = new Date(currentCSTTime);
    if(currentDateTime.getDay() == 0 || currentDateTime.getDay() == 6 || (currentDateTime.getDay() == 5 && currentDateTime.getHours() >=13 ))
     isWeekEnd = true;
    return isWeekEnd;
} 