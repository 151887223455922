import React from 'react';

import {MdsHeader2b} from '../mds';
import ViewHeader from '../common/view/header';

import styles from './error.module.scss';

const ErrorView = () => {
  return (
    <main className={styles.error_view_container}>
      <ViewHeader title="We're sorry">
        <MdsHeader2b>The page you are trying to access has either been moved or is temporarily unavailable.</MdsHeader2b>
      </ViewHeader>
    </main>
  );
};

export default ErrorView;