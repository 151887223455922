import * as PurchaseStates from '../../redux/reducers/reducer-helpers/purchaseStates.js';
import { checkIsWeekEnd, getBackOrderQty } from '../common/utlities.js';
import { generateDistributorList } from '../../redux/actions/cart/cartActions.js';
export const filterHtmlProps = (props = {}, filterMethod) => {
  if (!filterMethod) filterMethod = (key, item) => key.toLowerCase() == key;
  const validProperties = {};
  let key;

  for (key in props) {
    if (props.hasOwnProperty(key) && filterMethod(key, props[key])) {
      validProperties[key] = props[key];
    }
  }
  return validProperties;
}

export const addProtocol = (strUrl) => {
  if (strUrl && !strUrl.startsWith('https://') && !strUrl.startsWith('/')) {
    return 'https://' + strUrl
  }
  return strUrl;
}

export const prepareCartRequest = (cart) => {
  if(!cart.action) {
    cart.action = 'CHECKOUT'
  }
  if (cart.lineitem && cart.lineitem.length > 0) {
    cart.lineitem.forEach(li => {
      if (li.offer && li.offer.distributor) {
        li.offer.distributor.contactInfo = {}
        li.offer.distributor.distributorAdditionalFields = []
      }
      if (li.product && li.product.offers) {
        li.product.offers = []
      }      

    })
  }

}


export const manageCartRequest = (cart,section) => {
  if(section == PurchaseStates.CART) {
    cart.lineitem.forEach(li => {
      li.shippingCost = null
      li.tax =null
      li.shippingType = null
      li.offer.deliveryOptions = null
    })
    cart.shippingAddress = {}
    cart.poNumber = ''    
  }

  if(section == PurchaseStates.CHECKOUT_EDIT_SHIPPING) {
    const isWeekEnd = checkIsWeekEnd();
    cart.lineitem.forEach(li => {
      li.shippingCost = null
      li.tax =null
      li.shippingType = null
      li.offer.deliveryOptions = null
      if(isWeekEnd && !!li.estimateShippingType)
      li.estimateShippingType = 'STD';
    });
    cart.lineitem = updateShippingTypeForBackOrderOffers(cart.lineitem);
  }
}

export function updateShippingTypeForBackOrderOffers(lineItems){
const distributors = generateDistributorList(lineItems);
    const backOrderLineItemIds = [];
    distributors.forEach(x => {
        const matchedLineItems = lineItems.filter(y =>
            x.distributor.name === y.offer.distributor.name);
        let backOrderLineItemFound = false;
        matchedLineItems.forEach(li => {
            const backOrderQty = getBackOrderQty(li.offer);
            if(li.offer.availableQuantity - backOrderQty - li.quantity < 0)
            backOrderLineItemFound = true;
            return;
        });
        if(backOrderLineItemFound){                
            backOrderLineItemIds.push(...matchedLineItems.map(li => li.id));              
        }
    });   
    if(backOrderLineItemIds.length > 0 ){
      lineItems.forEach(li => {
            if(backOrderLineItemIds.includes(li.id)){
                if(!!li.estimateShippingType)
                li.estimateShippingType='STD';
            }
        });
    }
    return lineItems;
  }
