/* eslint-disable */
import axios from 'axios';
import * as types from '../actionTypes';

const headers = {
    headers: { Auth: 1212 },
};

const useStaticTestData = false;

const sortCards = (cardList) => {
    const returnList = cardList.slice();
    returnList.sort((a, b) => {
        return (
            a.cardType < b.cardType ? -1 : a.cardType > b.cardType ? 1 : 0
        );
    })
    return returnList;
}

export function loadPaymentMethods(userId) {
        return loadPaymentMethodsAPI(userId,false);
}

export function loadAllPaymentMethods(userId) {
    return loadPaymentMethodsAPI(userId,true);
}


export function loadPaymentMethodsAPI(userId,allCards) {

    return function (dispatch, getState) {

        if(!userId) {
            return
        }
        return axios
            .get(
                process.env.REACT_APP_API_BASE_PAYMENT_URL +
                '/user/' +
                userId +
                '/paymentCards'+(allCards ? '?all=true': ''),{
                    withCredentials: true,
                    headers: {
                        Auth: '1212',
                    },
                }
            )
            .then((result) => {
                const cards = result.data.cards.map(card=>card.id).join(',') ;
                    return axios.get(
                        process.env.REACT_APP_API_BASE_ORDER_URL + 
                        '/orders/cardUsage?customer_id=' + 
                        userId + "&card_ids=" + 
                        cards,
                        {
                            withCredentials: true,
                            headers: {
                                Auth: '1212',
                            },
                        }
                    ).then((usageResult) => {
                        if(usageResult.error) {
                            dispatch(loadPaymentMethodsSuccess(sortCards(result.data.cards)));
                        } else {
                            
                            const cardsCopy = [];
                            result.data.cards.map( card => {
                                const match = usageResult.data.cards.filter(usage=> usage.cardUUID === card.id)[0]
                                cardsCopy.push({...card, inUse : !!match? match.inUse : false,  orderIds : !!match? match.orderIds: []});
                            })
                            dispatch(loadPaymentMethodsSuccess(sortCards(cardsCopy)));
                        }

                    }).catch((e)=>{
                        dispatch(loadPaymentMethodsSuccess(sortCards(result.data.cards)));
                    })
                
            })
            .catch((e) => {
                console.log(e);
                
            });
    };
}


export function removePaymentMethod(userId, cardUUID, index) {
    if (useStaticTestData === true) {
        return removePaymentMethodStatic(userId, cardUUID, index);
    } else {
        return removePaymentMethodAPI(userId, cardUUID, index);
    }
}

export function removePaymentMethodStatic(userId, cardUUID, index) {
    return function (dispatch, getState) {
        dispatch(removePaymentMethodSuccess(index));
    }
}

export function removePaymentMethodAPI(userId, cardUUID, index) {

    return function (dispatch, getState) {

        return axios
            .delete(
                process.env.REACT_APP_API_BASE_PAYMENT_URL +
                '/user/' +
                userId +
                '/paymentCards/' +
                cardUUID,
                {
                    withCredentials: true,
                    headers: {
                        Auth: '1212',
                    },
                }
            )
            .then((result) => {
                dispatch(removePaymentMethodSuccess(index));
            })
            .catch((e) => {
                dispatch(removePaymentMethodFailure());
            });
    };
}

export function loadPaymentMethodsSuccess(paymentList) {
    return { type: types.LOAD_PAYMENT_METHODS_SUCCESS, paymentList: [...paymentList] };
}

export function removePaymentMethodSuccess(index) {
    return { type: types.REMOVE_PAYMENT_METHOD_SUCCESS, index : index };
}

export function removePaymentMethodFailure() {
    return { type: types.REMOVE_PAYMENT_METHOD_FAILURE };
}

export const cardExpiry = (payment) => {
    return payment.expiryMonth + '/' + payment.expiryYear;
}

export function titleCase(str) {
    const firstLetter = str.substring(0, 1).toUpperCase();
    const rest = str.substring(1, str.length).toLowerCase();
    return firstLetter + rest;
} 

export function createSetupIntent(callBack) {

    return function (dispatch, getState) {
        const state = getState();
        const userId = state.signIn.id;
        return axios
            .post(
                process.env.REACT_APP_API_BASE_PAYMENT_URL +
                '/user/' +
                userId +
                '/paymentCards', {},
                {
                    withCredentials: true,
                    headers: {
                        Auth: '1212',
                    },
                }
            )
            .then((result) => {
                if (result.errors) {
                    callBack(null, result.errors);
                    dispatch(getCardUUIDFailure(result.errors));
                } else { 
                    callBack(result.data,null);
                    dispatch(getCardUUIDSuccess(result.data));
                }
            })
            .catch((e) => {
                callBack(null, {error : e.message});
                dispatch(getCardUUIDFailure([{error : e.message}]));
            });
    };
}

export function getCardUUIDSuccess(input) {
    return { type: types.CREATE_SETUP_INTENT_SUCCESS, data : {...input} };
}

export function getCardUUIDFailure(errors) {
    return { type: types.CREATE_SETUP_INTENT_FAILURE, errors: {...errors} };
}

export function getCardDetailsFromState() {
    return function (dispatch, getState) {
        const state  = getState();
        const payMethod = state.paymentList.cardUUIDData;
        return payMethod;
    }
}

export function setPaymentCardSuccess(pm) {
    return { type : types.PAYMENT_METHOD_SETUP_SUCCESS, paymentMethod : pm};
}

export function resetPaymentCard() {
    return {type : types.PAYMENT_METHOD_SETUP_RESET};
}

export function setCardComplete(isComplete) {
    return { type : types.PAYMENT_CARD_FORM_COMPLETE, isComplete: isComplete};
}

export function setCardIsProcessing(isProcessing) {
    return { type : types.PAYMENT_CARD_PROCESSING, isProcessing : isProcessing};
}