import React from 'react';
import PropTypes from 'prop-types';

const generateInvalidLabel = (props) => {
  let {invalid, fieldName} = props.fields;
  const {uniqueID, invalidLabel} = props;
  fieldName = fieldName !== undefined ? fieldName.toLowerCase() : undefined;
  if (invalid && invalidLabel !== undefined && invalidLabel !== '') {
    return (
      <label
        htmlFor={uniqueID}
        className="mds-form_elementLabel--invalid"
        id={`${uniqueID}-invalidLabel`}
        aria-hidden="true"
      >{`${invalidLabel} ${fieldName}`}</label>
    );
  }
  return null;
};

generateInvalidLabel.propTypes = {
  uniqueID: PropTypes.string,
  invalidLabel: PropTypes.string,
  fields: PropTypes.object,
};

export default generateInvalidLabel;
