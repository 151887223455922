import React from 'react';
import PropTypes from 'prop-types';

import BoxContentHeader from '../atomics/text/boxContentHeader';

import styles from '../../assets/scss/containers.module.scss';

const ContentBox = (props) => {
  const cnSelector = props.className
    ? props.className
    : '';

  const selectedSelector = props.selected
    ? styles['content-box--selected']
    : '';

  return (
    <div
      className={`${cnSelector} ${styles['content-box']} ${selectedSelector}`}
      style={props.style}
    >
      {props.title && <BoxContentHeader>{props.title}</BoxContentHeader>}
      {props.children}
    </div>
  )
};

ContentBox.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  selected: PropTypes.bool,
};

export default ContentBox;
