import React from 'react';
import styles from './ReturnPolicy.module.scss';

const ReturnPolicy = (props) => {
    return (
        <div className={styles.return_policy}>
            <p>
                Incidents and Returns can be raised for one of the following
                reasons: damaged item, item not received, item not as ordered,
                delivery took longer than expected, general issue, or I want to
                return the product.
            </p>
            <p>
                Products purchased via the 3M Marketplace can be returned only
                to the fulfilling Distributor, and only through the 3M
                Marketplace. Returns cannot be done in-store. Please work with
                the Distributor on resolution before going to your bank or
                credit card to dispute the charge.
            </p>
            <p>
                Products can be returned within 30 days from the date you
                receive it, subject to the fulfilling Distributor&apos;s
                acceptance of your return request. All returned items must: be
                in original, clean, and resalable condition; contain the
                original 3M packaging and intact UPC codes, if applicable; and
                be in a complete unit of measure (for example, if the initial
                transaction was for a full case, then a partial case is not
                eligible for return).
            </p>
            <p>
                The following are not eligible for returns: items that have been
                used, altered, or otherwise made non-resalable{' '}
                <span>
                    (such as disposable respirators which cannot be returned
                    once opened)
                </span>
                ; custom-made items; items whose packaging has been altered or
                custom labeled; and any item that has been obsoleted, has an
                expired shelf life, or is not a current product available from
                Distributors.
            </p>
            <p>
                Distributor will provide a pre-paid shipping label via the
                original carrier used in the shipment if a return is due to the
                fault of the Distributor. If you change your mind and want to
                return a Product, then you will pay the shipping cost to send
                the item back. In the event of a permitted return, regardless of
                reason, the shipping costs for the original order will not be
                recalculated or refunded.
            </p>
            <p>To process a return:</p>
            <ul>
                <li> Create or log-in to your 3M Marketplace account.</li>
                <li>
                    If you do not have a 3M Marketplace account, click on the
                    following link and enter your order number and email address{' '}
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://ui.mkpl.3m.com/guestCheckOrder"
                    >
                        Click here
                    </a>
                    .
                </li>
                <li>Select Order History and then View Order Details.</li>
                <li>
                    Click Initiate Incident/Return next the item you wish to
                    return.
                </li>
                <li>
                    Click Return Product within the Select IncidentReason field.
                </li>
                <li>
                    Monitor your account to determine if the Distributor has
                    accepted or denied your return request.
                </li>
                <ul>
                    <li>
                        If the return is accepted, the Distributor will send you
                        a return shipping label. Use this label to ship the
                        Product to the Distributor. Once the Distributor
                        receives the returned Product, the refund will be
                        processed (typically in the next three business days).
                    </li>
                </ul>
            </ul>
            <h3>Refunds</h3>
            <p>
                Refunds are processed using the payment method specified in the
                original purchase. You can check the status of pending refunds
                via your 3M Marketplace account. Price matching and other
                similar adjustments are not available via the 3M Marketplace.
                Accepted returns are not subject to any restocking or handling
                fees.
            </p>
            <h3>Exchanges</h3>
            <p>
                Exchanges cannot be processed via the 3M Marketplace. If a
                different Product is desired (for example, different size or
                color), then you will need to consider whether to return a
                previously purchased Product and order a different Product.
            </p>
        </div>
    );
};

export default ReturnPolicy;
