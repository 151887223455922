/* eslint-disable max-lines*/

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Password from './password';
import NumberFormat from 'react-number-format';
{
    /* Component Generators*/
}
import GenerateSearchButton from './generators/searchButton';
import GenerateInvalidLabel from './generators/invalidLabel';
import GenerateRequiredLabel from './generators/requiredLabel';
import GenerateHelpText from './generators/helpText';

const textEmpty = (text, debug = '') => {
    if (!text) return true;
    return false;
};

const InputWrapper = (props) => {
    const placeholder = props.placeholder ? props.placeholder : '';
    // const [textState, setTextState] = useState(props.value ? props.value : '');
    const [isFocused, setIsFocused] = useState(false);
    const [hasValue, setHasValue] = useState(
        textEmpty(props.value, 'initial hook set') ? false : true
    );
    if(hasValue==false&&props.value){
        setHasValue(textEmpty(props.value, 'initial hook set') ? false : true);
    }
    const [showPassword, setShowPassword] = useState(true);

    const textValue = textEmpty(props.value, 'set from initial variable check')
        ? ''
        : props.value;
    const fields = props.fields;

    const {
        name,
        fieldName,
        required,
        disabled,
        className,
        maxLength,
        ...otherProps
    } = fields;
    const {type} = props;
    {
        /* Event Handlers*/
    }
    const onChangeEventHandler = (e) => {
        const newVal = e.target.value;
        const valNotEmpty = e.target.value !== '';
        const same = newVal !== textValue;

        if (same) {
            setHasValue(valNotEmpty);
        }

        if (props.onChange) props.onChange(e, newVal);
    };

    const onBlurEventHandler = (e) => {
        setIsFocused(false);
        if (props.onBlur) props.onBlur(e);
    };

    const onFocusEventHandler = (e) => {
        setIsFocused(true);
        if (props.onFocus) props.onFocus(e);
    };

    const onClickEventHandler = (e) => {
        if (props.onClick) props.onClick(e);
    };

    const onPasswordEventHandler = () => {
        const a = !showPassword;
        setShowPassword(a);
    };

    const fieldTypeChecker = (type = 'text') => {
        if (type === 'password') {
            return showPassword ? 'password' : 'text';
        }
        return type;
    };

    return (
        <div
            className={`mds-form_element ${
                className !== undefined ? className : ''
            }`}
        >
            <label
                htmlFor={props.uniqueID}
                className={
                    isFocused || hasValue
                        ? 'mds-form_elementLabel--floating'
                        : 'mds-form_elementLabel'
                }
                id={`${props.uniqueID}-Label`}
            >
                {`${fieldName}${required ? '*' : ''}`}
            </label>
            {props.format ? (
                <NumberFormat
                    format={props.format}
                    onChange={onChangeEventHandler}
                    onFocus={onFocusEventHandler}
                    onBlur={onBlurEventHandler}
                    value={textValue}
                    disabled={disabled}
                    data-testid={props.dataTestId}
                    aria-labelledby={`${props.uniqueID}-Label ${props.uniqueID}-invalidLabel ${props.uniqueID}-requiredLabel`}
                    required={required}
                    id={props.uniqueID}
                    type={fieldTypeChecker(type)}
                    name={name}
                />
            ) : (
                <input
                    type={fieldTypeChecker(type)}
                    name={name}
                    placeholder=" "
                    data-testid={props.dataTestId}
                    id={props.uniqueID}
                    required={required}
                    aria-labelledby={`${props.uniqueID}-Label ${props.uniqueID}-invalidLabel ${props.uniqueID}-requiredLabel`}
                    onBlur={onBlurEventHandler}
                    onFocus={onFocusEventHandler}
                    onChange={onChangeEventHandler}
                    onClick={onClickEventHandler}
                    disabled={disabled}
                    value={textValue}
                    maxLength ={maxLength}
                />
            )}
            <GenerateSearchButton {...otherProps} />
            {/* <Password value={textValue} placeholder={placeholder} /> */}
            <Password onClick={onPasswordEventHandler} type={type} />
            <GenerateInvalidLabel
                value={textValue}
                placeholder={placeholder}
                {...props}
                {...otherProps}
            />
            <GenerateRequiredLabel
                value={textValue}
                placeholder={placeholder}
                {...props}
                {...otherProps}
            />
            <GenerateHelpText
                value={textValue}
                placeholder={placeholder}
                {...props}
                {...otherProps}
            />
        </div>
    );
};

InputWrapper.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    fields: PropTypes.object,
    uniqueID: PropTypes.string,
    dataTestId: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    isPhone: PropTypes.bool,
    format: PropTypes.string,
};

export default InputWrapper;
