import React, {useState} from 'react';
import PropTypes from 'prop-types';
import GenerateHelpText from './generators/helpText';
import GenerateSearchButton from './generators/searchButton';
import GenerateInvalidLabel from './generators/invalidLabel';
import GenerateRequiredLabel from './generators/requiredLabel';

const textEmpty = (text, debug = '') => {
    if (!text) return true;
    return false;
};

const TextAreaWrapper = (props) => {
    const {
        placeholder = '',
        uniqueID = '',
        dataTestId = '',
        fields = {},
    } = props;
    const {
        name = 'textarea',
        fieldName = '',
        required = false,
        disabled = false,
        className = '',
        value = '', // defaultValue
        ...otherProps
    } = fields;

    const [isFocused, setIsFocused] = useState(false);
    const [hasValue, setHasValue] = useState(
        textEmpty(value, 'initial hook set') ? false : true
    );

    const textValue = textEmpty(props.value, 'set from initial variable check')
        ? ''
        : props.value;

    const onBlurEventHandler = (e) => {
        setIsFocused(false);
        if (props.onBlur) props.onBlur(e);
    };

    const onFocusEventHandler = (e) => {
        setIsFocused(true);
        if (props.onFocus) props.onFocus(e);
    };

    const onChangeEventHandler = (e) => {
        const newVal = e.target.value;
        const valNotEmpty = e.target.value !== '';
        const same = newVal !== textValue;

        if (same) {
            setHasValue(valNotEmpty);
        }

        if (props.onChange) props.onChange(e, newVal);
    };

    const onClickEventHandler = (e) => {
        if (props.onClick) props.onClick(e);
    };

    return(
        <div className ={`mds-form_element ${className}`}>
            <label
                htmlFor={uniqueID}
                className={
                    isFocused || hasValue
                        ? 'mds-form_elementLabel--floating'
                        : 'mds-form_elementLabel'
                }
                id={`${uniqueID}-Label`}
            >
                {`${fieldName}${required ? '*' : ''}`}
            </label>
            <textarea
                id={uniqueID}
                name={name}
                placeholder={placeholder}
                dirName={`${name}.dir`}
                value={textValue}
                data-testid={dataTestId}
                required={required}
                disabled={disabled}
                aria-labelledby={`${uniqueID}-Label ${uniqueID}-invalidLabel ${uniqueID}-requiredLabel `}
                onBlur={onBlurEventHandler}
                onFocus={onFocusEventHandler}
                onChange={onChangeEventHandler}
                onClick={onClickEventHandler}
            >

            </textarea>
            <GenerateSearchButton {...otherProps} />
            <GenerateInvalidLabel
                value={textValue}
                placeholder={placeholder}
                {...props}
                {...otherProps}
            />
            <GenerateRequiredLabel
                value={textValue}
                placeholder={placeholder}
                {...props}
                {...otherProps}
            />
            <GenerateHelpText
                value={textValue}
                placeholder={placeholder}
                {...props}
                {...otherProps}
            />
        </div>
                    
   )

};

TextAreaWrapper.propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    uniqueID: PropTypes.string,
    dataTestId: PropTypes.string,
    value: PropTypes.string,
    fields: PropTypes.object,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
}

export default TextAreaWrapper;