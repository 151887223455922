/* eslint-disable max-lines*/
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Flex, Box } from 'rebass';
import { MdsHeader5, MdsHeader6, MdsText, MdsButton } from '../../mds';
import { Link } from 'react-router-dom';
import { Button } from '@3mcom/mds-library/dist/index';
import ImageContainer from '../../common/images/image-container';
import { tealiumLink } from '../../../redux/actions/trackingActions.js';
import { currencyFormatter, getNormalizedProductURL } from '../../common/utlities';
import { continueCheckout } from '../../../redux/actions/cart/cartActions.js';
import { signInGuest,signInUser,checkIfUserIsValid,createNewUser } from '../../../redux/actions/signInActions.js';
import { getGuestLoginSelected, removeGuestLocalStorageItemsIfAny } from '../../../connectivity/localStorage.js';
import { getAuthLoggedId } from '../../../connectivity/cookie.js';
import * as PurchaseStates from '../../../redux/reducers/reducer-helpers/purchaseStates.js';
import * as UserDefinitions from '../../common/userSettings/userDefinitions.js';
import * as APIStates from '../../../redux/reducers/reducer-helpers/apiStates';

import styles from './ItemSummary.module.scss';

const ItemSummary = ({ product, offer, quantity }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const baseTranslationKey = 'translation.cart.addToCart.itemSummary';
    const purchaseState = useSelector((state) => state.cart.purchaseState);
    const checkoutError = useSelector((state) => state.cart.error);      
    const loadCartRequest = useSelector((state) => state.cart.loadCartRequest);
    const signInType = useSelector((state) => state.signIn.signInType);  
    const noOfItems = useSelector((state) => state.cart.cart.lineitem.reduce((r, li) => r + li.quantity, 0));
    
    const isAPIInProgress = useSelector(
        (state) => state.cart.continueButtonState === APIStates.IN_PROGRESS
    );

    let lineItemHasError = false;
    const cartLineItems = useSelector((state) => state.cart.cart.lineitem);
    console.log("cartitems",cartLineItems );
    if (!!cartLineItems && cartLineItems.length > 0) {
        const erroredLines = cartLineItems.filter((li) => !!li.error);
        lineItemHasError = erroredLines != null && erroredLines.length > 0;
    }

    const keyDetailsLinkHandler = (e, url) => {
        if (url) {
            dispatch(tealiumLink({
                event_name: 'Back to Product',
                funnel_stage: 'Cart Page',
            }))
            window.location.href = getNormalizedProductURL(url)
        }
        e.preventDefault();
    }

    const imageUrl = product.imgURL
        ? product.imgURL
        : 'https://www.3m.com/3m_theme_assets/themes/3MTheme/assets/images/unicorn/NoImage.jpg';

    let unitOfMeasure = '';
    if (offer && offer.displayUnit) {
        const uom = offer.displayUnit;
        unitOfMeasure = uom.charAt(0).toUpperCase() + uom.toLowerCase().slice(1);
    }

    const goToCheckout = () => {
        dispatch(continueCheckout(purchaseState, history));
        if (purchaseState === PurchaseStates.CART && getGuestLoginSelected() === 'true') {
            (signInType !== UserDefinitions.REGISTERED_USER) ? dispatch(signInGuest()) : removeGuestLocalStorageItemsIfAny();
        }
        dispatch(checkIfUserIsValid())
        dispatch(createNewUser(getAuthLoggedId()))
        dispatch(signInUser(getAuthLoggedId()))
    }

    return (
        <>
            <Flex className={styles.CartItem} key={offer.id}>
                <ImageContainer
                    className={styles.CartItem__ItemImage}
                    src={imageUrl}
                    alt={product.description}
                />
                <Box className={styles.ProductDetail}>
                    <div className={styles.CartItem__Distributor}>
                        <MdsHeader6>
                            {t(`${baseTranslationKey}.distributedBy`)}
                            {offer.distributor.name}
                        </MdsHeader6>
                    </div>
                    <div className={styles.CartItem__ProductDescription}>
                        <a
                            className={`${offer.id}-description`}
                            onClick={(e) => keyDetailsLinkHandler(e, product.productURL)}
                        >
                            {(product && product.description) ||
                                'PLACEHOLDER'}
                        </a>
                    </div>

                    <div className={styles.CartItem__KeyDetails}>

                        <div className={styles.ItemQuantity}>
                            {t(`${baseTranslationKey}.quantity`)}
                            : {quantity}
                        </div>
                        <div className={styles.ItemQuantity}>
                            {t(
                                `${baseTranslationKey}.unitOfMeasurement`)}
                            : {unitOfMeasure}
                        </div>

                    </div>
                    <div className={styles.CartItem__KeyDetails__Price}>
                        <MdsHeader5>
                            {currencyFormatter.format(offer.price)}/
                            {unitOfMeasure.toLowerCase()}
                        </MdsHeader5>
                    </div>
                </Box>
            </Flex>
            <div className={styles.buttonsContainer}>
                <div>
                    <MdsButton
                        mdsSize="medium"
                        mdsType="primary"
                        className={styles.buttonsCustomCheckout}
                        onClick={() => { goToCheckout() }}
                        icon={ loadCartRequest != 'COMPLETE'}
                        id="checkout"
                        dataTestId="checkout-testId"
                        disabled={(!!checkoutError && checkoutError.length > 0) 
                                     || loadCartRequest != 'COMPLETE'
                                     || isAPIInProgress 
                                     || lineItemHasError}
                    >
                        {t(`${baseTranslationKey}.checkout`)} {`( ${noOfItems} ) ${noOfItems > 1 ? ' items': ' item'}`}
                    </MdsButton>
                </div>
                <div>
                    <Link to={{ pathname: '/checkout' }}>
                        <Button
                            buttonType="secondary"
                            size="medium"
                            tealiumTracking=""
                            className={styles.buttonsCustom}
                        >
                            {t(`${baseTranslationKey}.goToCart`)}
                        </Button>
                    </Link>
                </div>
            </div>
            {!!checkoutError && checkoutError.length > 0 ?
                <MdsText msdType="body" isInline={false} className={styles.error}>
                    {t(`${baseTranslationKey}.checkoutError`)}
                </MdsText>
                : null
            }
            <hr className={styles.divider}></hr>
        </>
    );
};

ItemSummary.propTypes = {
    product: PropTypes.object.isRequired,
    offer: PropTypes.object.isRequired,
    quantity: PropTypes.number.isRequired,
};

export default ItemSummary;
