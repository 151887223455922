import Cookies from 'js-cookie';

const scope = {path: "/", domain: ".3m.com"};

export function getAuthLoggedId() {
    const userId = Cookies.get('USER_NAME');
    if (userId) {
        !!window.decibelInsight && window.decibelInsight('sendCustomDimension', 'loggedIn', true);
    }
    return userId;
}

export function removeSignedUserCookiesIfAny() {
    Cookies.remove("SMSESSION", scope);
    Cookies.remove("USER_NAME", scope);
    return;
}

export function getTestVersion() {
    return Cookies.get('TestVersion');
}

export function getAnalyticsCookies() {
    const analyticsCookies = Cookies.get('s_ecid');
    return !!analyticsCookies ? analyticsCookies : '';
}

export function getCampaignCookies() {
    const campaignCookies = Cookies.get('campaign_tracking_code');
    return !!campaignCookies ? campaignCookies : '';
}

// export function saveSignedUserToCookie(sess, userName) {
//   Cookies.set('SMSESSION', sess, scope);
//   Cookies.set('USER_NAME', userName, scope);
//   return;
// }
