const enUS = {
    logoTitle: '3M in the United States',
    logoAltText: '3M Logo',
    search: 'Search',
    account: 'Account',
    openNavigation: 'Open navigation',
    close: 'Close',
    localePopUpTitle: '3M in the United States',
    save: 'Save',
    products: 'Products',
};
export default enUS;
