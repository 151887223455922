import React from 'react';
import PropTypes from 'prop-types';

import styles from './image-container.module.scss';
import {addProtocol} from '../../mds/helpers.util'

const ImageContainer = (props) => {
  const {
    src = '',
    alt = '',
    direction = 'wide',
    className = '',
    hideDefault = false,
  } = props;
  const customClass = className;

  const changedSrc = addProtocol(src);


  const errorEventHandler = (e) => {
    if (!hideDefault) e.target.src = 'https://www.3m.com/3m_theme_assets/themes/3MTheme/assets/images/unicorn/NoImage.jpg';
  };

  return (
    <div className={`${styles.image_container} ${customClass}`}
         onClick={props.onClick
            ? props.onClick
            :() => {}
      }>
      <img src={changedSrc} alt={alt} className={styles[direction]} onError={errorEventHandler} />
    </div>
  );
};

ImageContainer.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  direction: PropTypes.oneOf(['tall', 'wide']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  hideDefault: PropTypes.bool,
};

export default ImageContainer;
