/* eslint-disable max-len */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import styles from './password.module.scss';

const PasswordSelector = (props) => {
  // track the state of the input on clicks
  const [isHidden, setIsHidden] = useState(true);
  // take in the type of the element
  const type = props.type;
  // labels to show for toggle
  const { t } = useTranslation();
  const labels = {
    show: t('translation.atomics.formElements.wrappers.password.show'),
    hide: t('translation.atomics.formElements.wrappers.password.hide')
  };

  // event triggered on click
  const togglePassword = () => {
    // set state to next value
    setIsHidden(!isHidden);
    // If the onClick event handler was passed down, trigger it
    if (props.onClick) props.onClick();
  };

  // If the input is a password, show this, otherwise don't
  if (type === 'password') {
    return (
      <a className={`mds-form_element--link ${styles.password__link_helper}`} tabIndex='0' onClick={togglePassword}>
        {/* Render the label based on the hidden state */ isHidden ? labels.show : labels.hide}
      </a>
    );
  }
  return null;
};

PasswordSelector.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['text', 'password']),
};

export default PasswordSelector;
