// import CheckboxElement from '../../../assets/mdsComponents/Forms/checkbox';
// import FormElement from '../../../assets/mdsComponents/Forms/form';
// import InputElement from '../../../assets/mdsComponents/Forms/input';
// import PasswordElement from '../../../assets/mdsComponents/Forms/password';
// import RadioButtonElement from '../../../assets/mdsComponents/Forms/radiobutton';
// import SelectElement from '../../../assets/mdsComponents/Forms/select';
// import TextareaElement from '../../../assets/mdsComponents/Forms/textarea';

import CheckboxElement from './wrappers/checkbox';
import FormElement from './wrappers/form';
import InputElement from './wrappers/input';
import PasswordElement from './wrappers/password';
import RadioButtonElement from './wrappers/radioButton';
import SelectElement from './wrappers/select';
import TextareaElement from './wrappers/textarea';
import TypeAheadElement from './type-ahead';

export const Checkbox = CheckboxElement;
export const Form = FormElement;
export const Input = InputElement;
export const Password = PasswordElement;
export const RadioButton = RadioButtonElement;
export const Select = SelectElement;
export const Textarea = TextareaElement;
export const TypeAhead = TypeAheadElement;
