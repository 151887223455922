import statesUSA from './statesUSA.json';
// import orderHistSelect from './orderHistSelect.json';

export {
  statesUSA
}

// export {
//   orderHistSelect
// }
