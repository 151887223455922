/* eslint-disable max-lines */
const NEWUSER = "NewUser"
const LOGGED_USER = "LoggedInUser"
const ORDER = "Order"
const USERID = "UserId"
const GUESTLOGINSELECTED = "GuestLoginSelected"
const SHIPPINGADDRESS = "ShippingAddress"
const COUNTRYDESIGNATIONS = "CountryDesignations"
const SHAREDBASKETID = "sharedBasketId"
const SHARER = "sharer"
const ADDEDUPSELLPRODUCTS = "addedUpsellProducts"

export function saveBasketId(basketId) {
    localStorage.setItem('basketId', basketId);
}

export function saveSharedBasketId(sharedBasketId) {
    localStorage.setItem(SHAREDBASKETID, sharedBasketId);
}

export function saveSharer(sharer) {
    localStorage.setItem(SHARER, sharer);
}

export function setNewUser() {
    localStorage.setItem(NEWUSER, 'Y');
}

export function setLoginEmail(email) {
    localStorage.setItem(LOGGED_USER, email);
}
export function deleteLoginEmail() {
    localStorage.removeItem(LOGGED_USER);
}
export function getLoginEmail() {
    return localStorage.getItem(LOGGED_USER);
}

export function isNewUser() {
    return localStorage.getItem(NEWUSER);
}

export function deleteNewUser() {
    return localStorage.removeItem(NEWUSER);
}

export function getBasketId() {
    return localStorage.getItem('basketId');
}

export function getSharedBasketId() {
    return localStorage.getItem(SHAREDBASKETID);
}

export function deleteSharedBasketIdInStorage() {
    localStorage.removeItem(SHAREDBASKETID);
}

export function getSharer() {
    return localStorage.getItem(SHARER);
}

export function deleteSharerInStorage() {
    localStorage.removeItem(SHARER);
}

export function getBasketIdShort() {
    if ('basketId' in localStorage) {
        return localStorage.getItem('basketId').split('-')[0];
    }
    return null;
}

export function getLanguage() {
    return localStorage.getItem('i18nextLng');
}

export function setOrderInStorage(order) {
    localStorage.setItem(ORDER, JSON.stringify(order));
}

export function deleteOrderInStorage() {
    localStorage.removeItem(ORDER);
}

export function getOrderFromStorage() {
    return JSON.parse(localStorage.getItem(ORDER));
}


export function setUserIdInStorage(userId) {
    localStorage.setItem(USERID, JSON.stringify(userId));
}

export function deleteUserIdInStorage() {
    localStorage.removeItem(USERID);
}

export function getUserIdFromStorage() {
    return JSON.parse(localStorage.getItem(USERID));
}

export function setShippingAddress(shippingAddress) {
    localStorage.setItem(SHIPPINGADDRESS, JSON.stringify(shippingAddress));
}

export function getShippingAddress() {
    return localStorage.getItem(SHIPPINGADDRESS);
}

export function deleteShippingAddress() {
    localStorage.removeItem(SHIPPINGADDRESS);
}

export function setGuestLoginSelected() {
    localStorage.setItem(GUESTLOGINSELECTED, 'true');
}

export function getGuestLoginSelected() {
    return localStorage.getItem(GUESTLOGINSELECTED);
}

export function deleteGuestLoginSelected() {
    localStorage.removeItem(GUESTLOGINSELECTED);
}

export function removeGuestLocalStorageItemsIfAny() {
    localStorage.removeItem(GUESTLOGINSELECTED);
    localStorage.removeItem(SHIPPINGADDRESS);
    return;
}

export function setCountryDesignations(country) {
    localStorage.setItem(COUNTRYDESIGNATIONS, JSON.stringify(country));
}

export function getCountryDesignations() {
    return JSON.parse(localStorage.getItem(COUNTRYDESIGNATIONS));
}

export function setAddedUpsellProducts(upsellProduct) {
    let upsellProductsInStorage = getAddedUpsellProducts();
    upsellProductsInStorage = !!upsellProductsInStorage ? upsellProductsInStorage : [];
    upsellProductsInStorage.push(upsellProduct);
    return localStorage.setItem(ADDEDUPSELLPRODUCTS, JSON.stringify(upsellProductsInStorage));
    
}

export function getAddedUpsellProducts() {
    return JSON.parse(localStorage.getItem(ADDEDUPSELLPRODUCTS));
}

export function deleteAddedUpsellProducts() {
    return localStorage.removeItem(ADDEDUPSELLPRODUCTS);
}