import { getAuthLoggedId } from '../../connectivity/cookie'

export async function getUserData() {
    const vsrmID = getAuthLoggedId();
    const userDetails = {
        authenticated: false,
        firstName: 'Account1',
        lastName: '',
        email: ''
    }
    // Determine if user is authenticated
    if (vsrmID !== undefined && vsrmID !== null) {

    await fetch(process.env.REACT_APP_API_BASE_USER_URL + "/users?vsrmId="
            + vsrmID, {
            credentials: "include",
            headers: new Headers({ 'Auth': 1212 })
        })
        .then(res => res.json())
        .then(
            (result) => {
                userDetails.authenticated = true;

                if (result?.user?.address.length > 0 && result?.user?.address[0].firstName !== undefined
                    && result?.user?.address[0].lastName !== undefined) {
                    userDetails.firstName = result.user.address[0].firstName;
                    userDetails.lastName = result.user.address[0].lastName;
                }
                userDetails.email = result.user.email !== undefined ? result.user.email : '';
            }
        ).catch((error) => { console.log(error) })
    }

    return userDetails;
}
