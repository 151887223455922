/* eslint-disable */
import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import PropTypes from 'prop-types';

import ImageContainer from '../../common/images/image-container';

import uploadIcon from '../../../assets/icons/upload/upload.svg';

import styles from './file-dropzone.module.scss';

const FileDropzone = (props) => {
  const {
    fileSetter = () => {},
    allowedFileTypes = ['']
  } = props;


  const onDrop = useCallback(acceptedFiles => {

    acceptedFiles &&
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
        const binaryStr = reader.result
        try {
          if (isWhiteListed(file.path)) {
            fileSetter(binaryStr, file.path);
          } else {
            throw new Error("File must be one of the following types: " +
              allowedFileTypes.join(", ")
            );
          }
        }
        catch(e) {
          console.error(e);
        }
      }
      reader.readAsArrayBuffer(file);

    })

  }, []);

  const {getRootProps, getInputProps, isDragActive, acceptedFiles} = useDropzone({onDrop})

  const uploadFilesHandler = (e) => {
    e.preventDefault();
    onDrop(e.target.value);
  }

  const isWhiteListed = (filePath) => {
    const fileParts = filePath.split('.');
    const fileType = fileParts[fileParts.length - 1];
    return allowedFileTypes.includes(fileType);
  }

  return (
    <section className={`${styles.file_dropzone__container} ${props.className ? props.className : ''}`}>
      <div {...getRootProps({className: styles.file_dropzone__zone})}>
        <ImageContainer src={uploadIcon} className={styles.file_dropzone__icon} />
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Drop the files here</p> :
            <p>Drop the files here or <a onClick={(e) => uploadFilesHandler(e)}>browse files</a></p>
        }
      </div>
    </section>
  )
}

FileDropzone.propTypes = {
  fileSetter: PropTypes.func,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
}

export default FileDropzone;
