import React from 'react';
import PropTypes from 'prop-types';

const generateSearchButton = (props) => {
    const {search} = props;
    if (search) {
        return (
            <button
                className="mds-form_element--search"
                type="submit"
                value="Search"
            >
                <i
                    className="MMM--icn MMM--icn_search mix-MMM--icn_red mds-font_bold"
                    aria-label="Search"
                ></i>
            </button>
        );
    }
    return null;
};

generateSearchButton.propTypes = {
    search: PropTypes.bool,
};

export default generateSearchButton;
