/* eslint-disable max-len */
/* eslint-disable max-lines*/
/* eslint-disable react/prop-types */
import React from 'react';
import enUS from './gsnLanguage';
import Searchbar from './searchbar';
import Localization from './localization';
import Profile from './profile'
import ProfileDropdown from './profileDropdown'
import Navbar from './navbar';
import NavbarLinks from './navbarLinks';
import NavbarMobile from './navbarMobile';
import Cart from './cart';
import Logo from './logo';
import './header.scss';
import { getUserData }  from './getUserDetailsUtil';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            locale: 'US - EN',
            searchbarFocus: false,
            menuHidden: true,
            navHidden: true,
            navbarLinksIndex: -1,
            mobileScreen: false,
            itemsInCart: 1,
            profileDropdownHidden: true,
            userDetails:{
                authenticated: false,
                firstName: 'Account',
                lastName: '',
                email: ''
            }
        };
        this.header = React.createRef();
        this.nav = React.createRef();
        this.navbarLinks = React.createRef();
        this.linkList = React.createRef();
        this.searchbarFocus = this.searchbarFocus.bind(this);
        this.searchbarBlur = this.searchbarBlur.bind(this);
        this.mobileMenu = this.mobileMenu.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleNav = this.toggleNav.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.toggleProfileDropdown = this.toggleProfileDropdown.bind(this);  
        this.setUserDetails = this.setUserDetails.bind(this);  
    }
    
    async setUserDetails() {
        const userData = await getUserData();
        this.setState({ userDetails: userData });
    }

    searchbarBlur() {
        this.setState({searchbarFocus: false});
    }
    searchbarFocus() {
        this.setState({searchbarFocus: true});
    }

    mobileMenu() {
        return (
            <div style={{display: 'inline-block'}}>
                <div className="m-header_menu l-centerVertically h-notOnDesktop">
                    <a
                        role="button" tabIndex="0"
                        className={`h-linkNoUnderline link--dark is-header_menuLink m-header_menuLink ${
                            this.state.menuHidden ? 'is-collapsed' : 'is-open'
                        }`}
                        aria-haspopup="true"
                        aria-controls="navOptions"
                        aria-label={
                            this.state.menuHidden
                                ? enUS.openNavigation
                                : enUS.close
                        }
                        aria-expanded={!this.state.menuHidden}
                        onClick={this.toggleMenu}
                    >
                        <i
                            className={`MMM--icn is-header_menuIcon m-header_menuIcn ${
                                this.state.menuHidden
                                    ? 'MMM--icn_hamburgerMenu'
                                    : 'MMM--icn_close'
                            }`}
                        ></i>
                    </a>
                    <i className="MMM--icn MMM--icn_close link--dark m-header_hiddenIcn m-header_menuIcn"></i>
                </div>
            </div>
        );
    }

    toggleMenu() {
        this.setState({menuHidden: !this.state.menuHidden});
    }

    toggleNav(value) {
        if (!this.state.profileDropdownHidden) {
            this.setState({profileDropdownHidden: true})
        }
        if (value !== this.state.navbarLinksIndex && this.state.navbarLinksIndex !== -1 && value !== -1) {
            this.setState({navbarLinksIndex: value})
        } else {
            this.setState({
                navHidden: !this.state.navHidden,
                navbarLinksIndex: value,
            });
        }
    }

    handleResize() {
        if (window.innerWidth < 960) {
            this.setState({mobileScreen: true});
        } else {
            this.setState({mobileScreen: false});
        }
    }

    toggleProfileDropdown() {
        if (!this.state.navHidden) {
            this.setState({
                navHidden: true,
                navbarLinksIndex: -1,
            });
        }
        this.setState({profileDropdownHidden: !this.state.profileDropdownHidden})
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        this.setUserDetails();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    render() {
        return (
            <React.Fragment>
                <div className="m-dropdown-list_overlay"
                    style={this.state.navHidden ? {display: 'none'} : {display: 'block'}}
                    onClick={() => {this.toggleNav(-1)}}>
                </div>
                <nav className="m-nav">
                    <div className={`is-header_container h-fitWidth m-header_container ${
                        this.state.menuHidden ? '' : 'h-fixed'}`}>
                        <div className={`m-header ${this.state.searchbarFocus ? 'is-searching' : ''} 
                            ${this.state.menuHidden ? '' : 'is-navigating'}`} ref={this.header} >
                            <Logo />
                            <Searchbar onFocus={this.searchbarFocus} onBlur={this.searchbarBlur} />
                            <this.mobileMenu />
                        </div>
                        <Profile 
                            toggleDropdown={this.toggleProfileDropdown} 
                            isMobile={true}
                            dropdownHidden={this.state.profileDropdownHidden}
                            userDetails={this.state.userDetails} />
                        <div className="m-header_overlay"></div>
                        {/* My account dropdown */}
                            <ProfileDropdown 
                                dropdownHidden={this.state.profileDropdownHidden} 
                                isMobile={this.state.mobileScreen}
                                simplifiedHeader={false}
                                userDetails={this.state.userDetails}  />
                        {/* End of My Account dropdown */}
                        <div
                            className={`is-dropdown m-navbar_container ${
                                this.state.menuHidden ? 'h-hidden' : ''
                            }`}
                        >
                            <div
                                className={`m-navbar_overlay h-fitHeight h-notOnMobile is-overlay h-notOnDesktop ${
                                    this.state.menuHidden ? 'h-hidden' : ''
                                }`}
                                onClick={this.toggleMenu}
                            ></div>
                            <div className="m-navbar">
                                <div className="m-navbar_level-1 js-navbar_level-1"
                                    style={
                                        !this.state.navHidden &&
                                        this.state.mobileScreen
                                            ? {display: 'none'}
                                            : {display: 'block'}
                                    }
                                >
                                    {/* <Localization locale={this.state.locale} /> */}
                                    <div className="m-navbar_login m-navbar_loginProfile h-notOnMobile h-notOnTablet h-onDesktop">
                                        <div className="mad-section" style={{minWidth: '300px'}}>
                                            <Cart items={this.state.itemsInCart} />
                                            <Profile 
                                                toggleDropdown={this.toggleProfileDropdown} 
                                                dropdownHidden={this.state.profileDropdownHidden} 
                                                userDetails={this.state.userDetails} />
                                        </div>
                                    </div>
                                    <div id="js-gsnMenu"
                                        className={`m-navbar_nav ${this.state.menuHidden && this.state.mobileScreen
                                            ? 'h-hidden'
                                            : ''
                                        }`}
                                    >
                                        <Localization locale={this.state.locale} />
                                        <Navbar
                                            nav={this.state.nav}
                                            onClick={this.toggleNav}
                                            navHidden={this.state.navHidden}
                                            navIndex={
                                                this.state.navbarLinksIndex
                                            }
                                        />
                                    </div>
                                </div>
                                <div
                                    className="m-dropdown-list js-navbar_level-2"
                                    style={
                                        this.state.navHidden
                                            ? {display: 'none'}
                                            : {display: 'block'}
                                    }
                                    ref={this.navbarLinks}
                                >
                                    <NavbarMobile
                                        navbarLinksIndex={
                                            this.state.navbarLinksIndex
                                        }
                                        onClick={this.toggleNav}
                                    />
                                    <NavbarLinks
                                        navbarLinksIndex={
                                            this.state.navbarLinksIndex
                                        }
                                        navHidden={this.state.navHidden}
                                        linkList={this.navbarLinks}
                                        userDetails={this.state.userDetails}
                                    />
                                </div>
                            </div> 
                        </div>
                    </div>
                </nav>
                <div className={`m-navbar_profileOverlay is-profileToggle is-close no-wpthemeHeaderOverlay 
                    ${this.state.profileDropdownHidden || !this.state.isMobile ? 'h-hidden': ''}`} 
                    onClick={this.toggleProfileDropdown}></div>
                <div className={`m-navbar_signInOverlay m-overlay is-signInToggle is-close ${this.state.profileDropdownHidden || this.state.isMobile ? 'h-hidden': ''}`}
                    onClick={this.toggleProfileDropdown}></div>
            </React.Fragment>
        );
    }
}
