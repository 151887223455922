import React from 'react';
import PropTypes from 'prop-types';

import {MdsButton, MdsHeader4, MdsLink} from '../../mds';

import styles from './generic-modal.module.scss';

const Modal = (props) => {

    const {show} = props;

    const confirmEventHandler = (e) => {
      if (props.actions.confirmEvent) props.actions.confirmEvent(e);
    };

    const cancelEventHandler = (e) => {
      if (props.actions.cancelEvent) props.actions.cancelEvent(e);
    };

    const dismissEventHandler = (e) => {
      if (props.actions.dismissEvent) props.actions.dismissEvent(e);
    };

  const bodyClickEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  return (
    <>
    {show && (
      <div
        className={styles.mds_modal__background}
        onClick={(e) => dismissEventHandler(e)}
        data-testid='modal-test-container'
      >
        <div className={styles.mds_modal__layout_container}>
          <div className={styles.mds_modal__container} onClick={(e) => bodyClickEventHandler(e)}>
            <header className={styles.mds_modal__header}>
              <MdsHeader4>{props.title ? props.title : 'Alert'}</MdsHeader4>
            </header>
            {props.children && (<main className={styles.mds_modal__content}>{props.children}</main>)}
            <footer className={styles.mds_modal__actions}>
              <MdsButton
                className={styles.mds_modal__actions__confirm_button}
                onClick={(e) => confirmEventHandler(e)}
              >
                {props.actions.confirmLabel
                  ? props.actions.confirmLabel
                  : 'Confirm'}
              </MdsButton>
              {props.actions.cancelLabel && (
                <MdsLink
                  className={styles.mds_modal__actions__cancel_button}
                  onClick={(e) => cancelEventHandler(e)}
                >
                  {props.actions.cancelLabel}
                </MdsLink>
              )}
            </footer>
          </div>
        </div>
      </div>
    )}
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  actions: PropTypes.shape({
    confirmEvent: PropTypes.func,
    confirmIcon: PropTypes.string,
    confirmLabel: PropTypes.string,
    cancelEvent: PropTypes.func,
    cancelIcon: PropTypes.string,
    cancelLabel: PropTypes.string,
    dismissEvent: PropTypes.func,
  }),
  show: PropTypes.bool.isRequired,
};

export default Modal;
