/* eslint-disable max-lines*/
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { MdsHeader5, MdsText } from '../../mds';
import ImageContainer from '../images/image-container';
import { useTranslation } from 'react-i18next';
import styles from './upsellItemSummary.module.scss';
import { tealiumLink, checkoutEvent} from '../../../redux/actions/trackingActions.js';
import { addToCart, selectOffer, removeAddedUpsellOffer } from '../../../redux/actions/offerActions.js';
import { getNormalizedProductURL, currencyFormatter, multiplyBD, getBackOrderQty } from '../utlities';
import { Link } from 'react-router-dom';
import { Button } from '@3mcom/mds-library/dist/index';
import { getRefinements } from '../../distributorPageCards/Service.js';
import { setAddedUpsellProducts } from '../../../connectivity/localStorage';
import QuantitySelector from './QuantitySelector';

const upsellItemSummary = ({ product, offer, setLoading }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [quantity, setQuantity] = useState(1);    
    const BUY_NOW_EVENT = 'Buy Now';
    const refinements = getRefinements();
    const backOrderQty = parseInt(getBackOrderQty(offer));

    const keyDetailsLinkHandler = (e, url) => {
        if (url) {
            dispatch(tealiumLink({
                event_name: 'Back to Product'
            }));
            window.location.href = getNormalizedProductURL(url);
        }
        e.preventDefault();
    }
    let unitOfMeasure = '';
    if (offer && offer.displayUnit) {
        const lc = offer.displayUnit;
        unitOfMeasure = lc.charAt(0).toUpperCase() + lc.toLowerCase().slice(1);
    }

    const handleQtyChange = (value) => {
        setQuantity(value);
    };

    const imageUrl = product.imgURL
        ? product.imgURL
        : 'https://www.3m.com/3m_theme_assets/themes/3MTheme/assets/images/unicorn/NoImage.jpg';

    const checkoutFunction = (event) => {
        setLoading(true);
        dispatch(selectOffer(offer, offer.price))
        dispatch(addToCart( quantity? quantity:1, product, {
            ...offer,
            offerAdditionalFields: offer.offerAdditionalFields
                ? [...offer.offerAdditionalFields, {...refinements}]
                : [{...refinements}],
        },setLoading))
        dispatch(removeAddedUpsellOffer(product, offer));
        dispatch(
            tealiumLink({
                event_name: event
            })
        );

        // For analytics
        setAddedUpsellProducts(offer.id);
        const subTotalAmount = quantity
        ? multiplyBD(quantity, offer.price)
        : 0;

        dispatch(checkoutEvent('cart_add', product.id, subTotalAmount, null, quantity));

    };

    return (
        <Flex className={styles.upsellItem} key={offer.id}>
            <ImageContainer
                className={styles.upsellItem__ItemImage}
                src={imageUrl}
                alt={product.description}
            />
            <Box className={styles.ProductDetail}>
                <div className={styles.upsellItem__KeyDetails}>
                    <a
                        className={`${product.id}-description`}
                        onClick={(e) => keyDetailsLinkHandler(e, product.productURL)}
                    >
                        {(product && product.description) ||
                            'PLACEHOLDER'}
                    </a>
                    <div className={styles.upsellItem__KeyDetails__Price}>
                        <MdsHeader5>
                            {currencyFormatter.format(offer.price)}/
                            {unitOfMeasure.toLowerCase()}
                        </MdsHeader5>
                    </div>
                </div>
                <div className={styles.addToCartAndUom}>
                    <div>
                        <div  className={styles.unitOfMeasure}>
                        {t(
                            'translation.common.items.upsellItemSummary.unitOfMeasurement'
                        )}
                        : {unitOfMeasure}
                        </div>
                        <div className={styles.ItemQuantity}>
                            {t('translation.common.items.upsellItemSummary.quantity')}:
                            <div
                                className="mds-form_element mds-form_select"
                                    style={{
                                    width: '10%',
                                    marginLeft: 10,
                                    minWidth: '4.5rem',
                                }}
                            >
                                {
                                        <QuantitySelector
                                            quantity={quantity}
                                            handleQtyChange={handleQtyChange}
                                            availableQty={offer.availableQuantity}
                                            backOrderQty = {backOrderQty}
                                            error={!!product?.error}
                                        />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.addToCartButton}>
                        <Link
                            to={{
                                pathname: '/checkout',
                                state: { addedItem: product },
                            }}
                        >
                            <Button
                                buttonType="primary"
                                size="medium"
                                className="upsellAddToCartBtn"
                                onClick={() => {
                                    checkoutFunction(BUY_NOW_EVENT);
                                }}
                            >
                                {t(
                                    'translation.common.items.upsellItemSummary.addToCart'
                                )}
                            </Button>
                        </Link>
                    </div>
                 </div>
                <MdsText msdType="body" isInline={true} className={styles.error}>
                    {product.error ? t(`translation.cart.lineError.${product.error}`) : null}
                </MdsText>
            </Box>
        </Flex>
    );
};
upsellItemSummary.proptypes = {
    product: PropTypes.object.isRequired,
    offer: PropTypes.array.isRequired,
    setLoading: PropTypes.func,
}
export default upsellItemSummary;
