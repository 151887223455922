import * as types from '../actions/actionTypes';

const defaultState = {
    orderId: 'default',
    order: {
        orders: [{
            lineitems: []
        }]
    }
};

export default function orderDetailsReducer(state = defaultState, action) {
    if (action.type === types.LOAD_ORDER_DETAILS_SUCCESS) {
        return {
            ...state, 
            orderId: action.orderId, 
            order: action.order
        };
    }
    return state;
}