import React from 'react';
import PropTypes from 'prop-types';

const mdsHeader2b = (props) => (
    <h2 className="mds-font_header--2b">{props.children}</h2>
);

mdsHeader2b.propTypes = {
    children: PropTypes.any,
};

export default mdsHeader2b;
