/* eslint-disable max-lines */
/* eslint react/prop-types: 0 */
/* eslint max-lines: ["error", 250] */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link, Route} from 'react-router-dom';

import * as PurchaseStates from '../../redux/reducers/reducer-helpers/purchaseStates.js';

import styles from './styleguide.module.scss';

import {
    MdsText,
    MdsConfirmationModal,
    MdsButton,
    MdsHeader1,
    MdsHeader2a,
    MdsHeader2b,
    MdsHeader3,
    MdsHeader4,
    MdsHeader5,
    MdsHeader6,
    Select
} from '../mds';

import Address from '../common/address';

import ContentBox from '../containers/contentBox';
import ImageContainer from '../common/images/image-container';
import {Input, Checkbox, RadioButton, TypeAhead} from '../atomics/formElements';
import Breadcrumb from '../breadcrumb';
import SingleItemSummary from '../common/items/singleItemSummary';
import CardDetailsForm from '../forms/stripe/cardDetailsForm.js';
import ThreadsList from '../myaccount/message-center/threads-list';

import dummyData from './styleguideDummyData.json';
import statesData from '../../data/statesUSA.json';

import '../../styles/global.scss';
import NewIncidentForm from '../forms/newIncidentForm.js';
import MessageDescription from '../myaccount/message-center/message-description/index.js';
import mockProduct from '../../../public/images/mockProduct.jfif';
import statuses from '../myaccount/message-center/incident-status/statuses.js';

const DataOutput = (props) => {
    const DataPoint = ({name, value}) => (
        <span className={styles.dataPoint}>
            <strong>Key: {name}</strong>
            <code>Value: {value}</code>
        </span>
    );
    return (
        <div className={styles.dataOutput}>
            {Object.entries(props.data).map(([name, value, i]) => {
                return (
                    <DataPoint
                        key={`${name}--${i}`}
                        name={name}
                        value={value.toString()}
                    />
                );
            })}
        </div>
    );
};

const StyleguideView = (props) => {
    const [formData, updateFormData] = useState({
      userName: '',
      email: '',
      password: '',
      state: '',
      checked1: false,
    });

    const [confModal, setConfModal] = useState(false);

    const showConfModal = () => {
        setConfModal(true);
    };

    const handleConfModalEventHandler = (e) => {
        setConfModal(false);
    };

    const handleCheckboxClickEventHandler = (e) => {
      console.log(e);
      updateFormData({...formData, checked1: e.target.checked})
    }

    const handleCheckboxChangeEventHandler = (e) => {
      console.log(e.target);
    }

    const handleSelectChangeEventHandler = (e) => {
      console.log('Select Change Event: ', e);
      updateFormData({ ...formData, state: e.target.value })
    }

    const newIncidentFormData = {
      product: '',
      reason: '',
      description: '',
      files: []
    };

    const [incident, setIncident] = useState({...newIncidentFormData});

    const onNewIncidentFormChange = (fieldName, value) => {
      const newIncident = {...incident}
      newIncident[fieldName] = value;
      setIncident(newIncident);
      console.log({incident});
    }

    const handleNewIncidentFormSubmit = (e, data) => {
      console.log({e});
      console.log({incidentAfterSubmit: data});
    }

    const product = {
      iconUrl: mockProduct,
      title: '3M™ Maxim™™ Elite 1000 Series, MXE1008AF-CLR, Clear Frame, Anti-Fog Coating, Blue Mirror AF-AS Lens, 20 ea/Case',
      description: 'Category Eye Protection Brand Maxim™'
    }

    const {
      OPEN,
      CLOSED,
      DISPUTED,
      UNDISPUTED
    } =
    statuses;

    const [status, setStatus] = useState(OPEN);

    const closeIncident = (e) => {
      e.preventDefault();
      setStatus(CLOSED);
      // TODO: ADD API CALL
      console.log('Incident Closed Function Called in Parent');
    }

    const disputeIncident = (e) => {
      e.preventDefault();
      setStatus(DISPUTED);
      // TODO: ADD API CALL
      console.log('Incident Disputed Function Called in Parent');
    }

    const closeDispute = (e) => {
      e.preventDefault();
      setStatus(UNDISPUTED);
      // TODO: ADD API CALL
      console.log('Dispute Closed in Parent');
    }


    return (
    <div className="mds-wrapper">
      <MdsConfirmationModal
        title="Confirmation Modal"
        show={confModal}
        actions={{
          confirmEvent: (e)=>handleConfModalEventHandler(e),
          dismissEvent: (e)=>handleConfModalEventHandler(e),
          cancelEvent: (e)=>handleConfModalEventHandler(e),
          confirmLabel: 'Close',
          cancelLabel: 'Dismiss'
        }}
      >
        This is a modal with body!
      </MdsConfirmationModal>
      <nav>
        <Link to={`${props.match.path}`}>Show All</Link>
        <Link to={`${props.match.path}/headers`}>Headers</Link>
        <Link to={`${props.match.path}/buttons`}>Buttons</Link>
        <Link to={`${props.match.path}/forms`}>Forms</Link>
      </nav>
      <Route path={[`${props.match.path}/headers`]} exact={false}>
        <ContentBox>
            <MdsHeader1>Headers</MdsHeader1>
            <hr />
            <MdsHeader1>Header 1</MdsHeader1>
            <MdsHeader2a>Header 2a</MdsHeader2a>
            <MdsHeader2b>Header 2b</MdsHeader2b>
            <MdsHeader3>Header 3</MdsHeader3>
            <MdsHeader4>Header 4</MdsHeader4>
            <MdsHeader5>Header 5</MdsHeader5>
            <MdsHeader6>Header 6</MdsHeader6>
        </ContentBox>
      </Route>
      <Route path={[`${props.match.path}/buttons`]} exact={false}>
        <ContentBox>
            <MdsHeader1>Buttons</MdsHeader1>
            <hr />
            <MdsHeader4>Button</MdsHeader4>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <MdsButton asLink={false}>Button</MdsButton>
                <MdsButton disabled asLink={false}>Disabled</MdsButton>
                <MdsButton mdsType="primary" asLink={false}>Primary Button</MdsButton>
                <MdsButton mdsType="secondary" asLink={false}>Secondary Button</MdsButton>
                <MdsButton mdsType="tertiary" asLink={false}>Tertiary Button</MdsButton>
            </div>
            <MdsHeader4>Anchor</MdsHeader4>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <MdsButton>Button</MdsButton>
                <MdsButton disabled>Disabled</MdsButton>
                <MdsButton mdsType="primary">Primary Button</MdsButton>
                <MdsButton mdsType="secondary">Secondary Button</MdsButton>
                <MdsButton mdsType="tertiary">Tertiary Button</MdsButton>
            </div>
        </ContentBox>
      </Route>
      <Route path={[`${props.match.path}/forms`]} exact={false}>
        <ContentBox>
          <DataOutput data={formData} />
          <div>Email</div>
          <Input
            type='email'
            fields={{name:'email', fieldName: 'Email'}}
            value={formData.email}
            onChange={(e) => updateFormData({...formData, email: e.target.value})} />
          <div>Text</div>
          <Input
            type='text'
            fields={{name:'userName', fieldName: 'Username'}}
            value={formData.userName}
            onChange={(e) => updateFormData({...formData, userName: e.target.value})} />
          <div>Password</div>
          <Input
            type='password'
            fields={{name:'password', fieldName: 'Password'}}
            value={formData.password}
            onChange={(e) => updateFormData({...formData, password: e.target.value})} />
          <Select
            uniqueID="bananas-checkbox"
            fields={{
              name:'select-papaya',
              fieldName: 'Select Papaya',
              options: statesData
            }}
            onChange={(e) =>handleSelectChangeEventHandler(e)}
          />
        <div>
          <TypeAhead
            fields={{
              name:'select-papaya',
              fieldName: 'Select Papaya',
              options: statesData
            }}
            onChange={(e) => updateFormData({ ...formData, state: e.target.value })}
            onSelect={(e) => updateFormData({ ...formData, state: e.target.value })}
          />
        </div>
          <div>
            <Checkbox
              uniqueID="bananas-checkbox"
              fields={{
                name: 'demoCheckbox',
                fieldName: 'Demo Checkbox',
                required: false,
                value: 'demo-checkbox-checked',
                disabled: false,
                fields: {
                  checked: formData.checked1
                }
              }}
              onChange={(e) => handleCheckboxChangeEventHandler(e)}
              onClick={(e) => handleCheckboxClickEventHandler(e)}
            />
          </div>
          <div>
            <RadioButton
              uniqueID='checkbox-potatos'
              fields={{
                name: 'potato-checkboxes',
                options: [
                  {
                    fieldName: 'First Potato',
                    value: 'One Potatos'
                  },
                  {
                    fieldName: 'Five Potatoes',
                    value: 'potato-5'
                  },
                  {
                    fieldName: 'Ten Potatoes',
                    value: 'potato-10'
                  }
                ]
              }}
            />
          </div>
        </ContentBox>
      </Route>
        <ContentBox>
          <MdsHeader2a>Mds Confirmation Modal</MdsHeader2a>
          <MdsButton onClick={showConfModal}>Show Confirmation Modal</MdsButton>
        </ContentBox>

        <ContentBox>
            <MdsHeader1>Button Widths</MdsHeader1>
            <hr />
              <div style={{display: 'flex', flexDirection: 'column', boxSizing: 'border-box'}}>
                  <MdsButton mdsWidth='0' asLink={false}>Button Sample</MdsButton>
                  <MdsButton mdsWidth='1' asLink={false}>Button Sample</MdsButton>
                  <MdsButton mdsWidth='3/4' asLink={false}>Button Sample</MdsButton>
                  <MdsButton mdsWidth='2/3' asLink={false}>Button Sample</MdsButton>
                  <MdsButton mdsWidth='1/2' asLink={false}>Button Sample</MdsButton>
                  <MdsButton mdsWidth='1/3' asLink={false}>Button Sample</MdsButton>
                  <MdsButton mdsWidth='1/4' asLink={false}>Button Sample</MdsButton>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', boxSizing: 'border-box'}}>
                  <MdsButton icon={true} asLink={false}>Button Sample</MdsButton>
              </div>
        </ContentBox>
        <ContentBox>
            <MdsHeader1>Text</MdsHeader1>
            <hr />
            <div>
                <MdsText mdsType='hero'>Hero Text</MdsText>
                <MdsText mdsType='intro-1'>Intro 1</MdsText>
                <MdsText mdsType='intro-2'>Intro 2</MdsText>
                <MdsText mdsType='body'>Body Text</MdsText>
                <MdsText mdsType='category-title'>Category Title</MdsText>
                <MdsText mdsType='legal'>Legal</MdsText>
                <MdsText mdsType='paragraph'>Paragraph Text</MdsText>
                <MdsText mdsType='light-italic'>Light Italic Text</MdsText>
                <MdsText mdsType='bold-italic'>Bold Italic Text</MdsText>
                <MdsText mdsType='regular-italic'>Regular Italic Text</MdsText>
                <MdsText mdsType='regular'>Regular Text</MdsText>
            </div>
        </ContentBox>
        <ContentBox style={{backgroundColor: '#fafafa'}}>
            <SingleItemSummary item={dummyData.cartItem} updateCartQuantity={()=>{}} removeCartLineItem={()=>{}} />
        </ContentBox>
        <ContentBox>
          <MdsHeader1>Flex Layouts</MdsHeader1>
          <div className={styles['example-layout']}>
            <div className={`${styles.highlight} ${styles['ew-1x2']}`}>1x2</div>
            <div className={`${styles.highlight} ${styles['ew-1x2']}`}>1x2</div>
          </div>
          <div className={styles['example-layout']}>
            <div className={`${styles.highlight} ${styles['ew-3x12']}`}>3x12</div>
            <div className={`${styles.highlight} ${styles['ew-9x12']}`}>9x12</div>
          </div>
          <div className={styles['example-layout']}>
            <div className={`${styles.highlight} ${styles['ew-2x8']}`}>2x8</div>
            <div className={`${styles.highlight} ${styles['ew-2x8']}`}>2x8</div>
            <div className={`${styles.highlight} ${styles['ew-4x8']}`}>4x8</div>
          </div>
        </ContentBox>
        <ContentBox className={styles.breadcrumb_container}>
          <Breadcrumb
          purchaseState={PurchaseStates.CHECKOUT_REVIEW}
          handleClick={(e) => {}}
          />
        </ContentBox>
        <ContentBox>
          <CardDetailsForm />
        </ContentBox>
        <ContentBox>
          <NewIncidentForm
            products={[
              {name: 'Example Product 1'},
              {name: 'Example Product 2'},
              {name: 'Example Product 3'},
              {name: 'Example Product 4'},
              {name: 'Example Product 5'},
            ]}
            formData={incident}
            onChange={(fieldName, value) => onNewIncidentFormChange(fieldName, value)}
            onSubmit={(e, data) => handleNewIncidentFormSubmit(e, data)}
          />
        </ContentBox>
        <ContentBox title="Message Center Threads">
          <ThreadsList threads={[
            {
              label: 'Item Name',
              description: 'This is a item description of the product',
              status: 'REFUND',
              active: true,
            },
            {
              label: 'Item Name',
              description: 'This is a item description of the product',
              status: 'REFUND',
              active: false,
            },
            {
              label: 'Item Name',
              description: 'This is a item description of the product',
              status: 'REFUND',
              active: false,
            },
          ]}
          routeBase={props.match.path} />
        </ContentBox>
        <ContentBox>
          <MessageDescription
            product={product}
            reason='Defective Item'
            created={Date.now()}
            status={status}
            closeIncident={(e) => closeIncident(e)}
            disputeIncident={(e) => disputeIncident(e)}
            closeDispute={(e) => closeDispute(e)}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse tempor dolor non velit
            maximus eleifend sit amet at eros. Vivamus vel mauris quam. Integer ultricies erat quis felis
            feugiat pharetra. Mauris tristique porttitor ultricies. Sed tellus dui, sodales sed porttitor
            nec, placerat mattis urna. Integer fringilla sagittis hendrerit. Morbi a risus vel lorem
            pharetra tempor ac nec ex. Mauris augue velit, posuere id lorem id, sagittis interdum diam.
            Curabitur maximus feugiat diam, at cursus lacus feugiat id.
          </MessageDescription>
        </ContentBox>
        <ContentBox>
            <MdsHeader1>Image Container</MdsHeader1>
            <ImageContainer src='failed.jpg' />
        </ContentBox>
        <ContentBox>
            <MdsHeader1>Address Format</MdsHeader1>
            <Address data={{
                    addr1: '123 Mulberry Lane',
                    city: 'Barnet',
                    state: 'California',
                    zip: '12345',
                    tele: '9037211232'
                }} />
        </ContentBox>
    </div>
  );
};

StyleguideView.propType = {
  match: PropTypes.any,
}

export default StyleguideView;
