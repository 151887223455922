
/* eslint-disable max-lines */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { continueCheckout, loadCart } from '../../redux/actions/cart/cartApiActions';
import styles from './cart.module.scss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button } from '@3mcom/mds-library/dist/index';
import PropTypes from 'prop-types';
import { MdsText } from '../mds';
import { useTranslation } from 'react-i18next';
import * as PurchaseStates from '../../redux/reducers/reducer-helpers/purchaseStates.js';
import * as UserDefinitions from '../common/userSettings/userDefinitions.js';
import { getGuestLoginSelected, removeGuestLocalStorageItemsIfAny } from '../../connectivity/localStorage';
import { signInGuest } from '../../redux/actions/signInActions';
import NumberFormat from 'react-number-format';
import { MdsHeader6 } from '../mds';
import PersistentCartDistributorList from '../cart/cartList/distributorList/PersistentCartDistributorList';
import { Box } from 'rebass';

const Cart = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadCart());
    }, []);
    const purchaseState = useSelector((state) => state.cart.purchaseState);
    const checkoutError = useSelector((state) => state.cart.error);
    const signInType = useSelector((state) => state.signIn.signInType);
    const distributorList = useSelector(
        (state) => state.cart.distributorOrderedLineItems
    );

    const appliedPromotion = useSelector((state) => state.cart.cart.promotions);
    const big = require('big.js');
    const sort = (list) => {
        if (!list)
            return [];
        const returnList = list.slice();
        returnList.sort((a, b) => {
            const s1 = a.shippingPrice ? a.shippingPrice : 0;
            const s2 = b.shippingPrice ? b.shippingPrice : 0;
            return (
                s1 < s2 ? 1 : s1 > s2 ? -1 : 0
            );
        })
        return returnList;
    }

    const distributorListSorted = sort(distributorList);
    const { t } = useTranslation();
    const baseTranslationKey = 'translation.cart.addedToCart';
    const baseTranslationKey1 = 'translation.common.order-summary.orderSummaryBreakdown';
    const baseTranslationKey2 = 'translation.cart';
    const history = useHistory();
    const items = useSelector((state) => {
        let tempItems = 0;
        if (state.cart.cart.lineitem !== undefined) {
            state.cart.cart.lineitem.map((item, index) => {
                tempItems += item.quantity;
            })
        }
        return tempItems;
    });
    const freeItemValue = appliedPromotion ? (appliedPromotion.appliedPromotions || []).reduce((total, currentItem) => {
        return total.add(currentItem.configuration.type === "FREE_ITEMS" ? big(currentItem.deducedAmount) : big(0));
    }, big(0)) : big(0);
    const total = (distributorListSorted || []).reduce((total, currentItem) => {
        return total.add(big(currentItem.totalPrice));
    }, big(0));
    const lineItem = useSelector((state) => state.cart.cart.lineitem);
    const totalTax = (lineItem || []).reduce((total, currentItem) => {
        return total.add(currentItem.tax ? currentItem.tax : 0);
    }, big(0));
    let totalShippingCost = big(0);
    const subTotal = total.add(freeItemValue);
    const totalOperatorDiscount = 0;
    const goToCheckout = () => {
        dispatch(continueCheckout(purchaseState, history));
        if (purchaseState === PurchaseStates.CART && getGuestLoginSelected() === 'true') {
            (signInType !== UserDefinitions.REGISTERED_USER) ? dispatch(signInGuest()) : removeGuestLocalStorageItemsIfAny();
        }
    }
    const shipping = purchaseState === PurchaseStates.CHECKOUT_EDIT_SHIPPING;
    const placeOrder = purchaseState === PurchaseStates.CHECKOUT_ORDER_SUCCESS;
    const payment = purchaseState === PurchaseStates.CHECKOUT_EDIT_PAYMENT;
    const delivery = purchaseState === PurchaseStates.CHECKOUT_EDIT_DELIVERY;
    const review = purchaseState === PurchaseStates.CHECKOUT_REVIEW;
    const lengthMore = distributorList.length > 1
    const position = (location.pathname === '/cart') ? styles.tooltip_cart : (
        (shipping || placeOrder || payment || delivery || review)) ? styles.hide : styles.tooltip

    const cardClassName = (location.pathname === '/cart' && lengthMore)
        ? styles.content1 : (location.pathname !== '/cart') ? styles.content1 : styles.content

    {
        (distributorListSorted || []).map((x) => {
            let val = '';
            if (x.shippingPrice !== undefined) {
                val = `$${big(x.shippingPrice ? x.shippingPrice : 0).toFixed(2)}`;
                totalShippingCost = totalShippingCost.add(big(x.shippingPrice ? x.shippingPrice : 0));
            } else {
                val = '-';
            }
            return (
                <LineItem
                    title={x.distributor.name}
                    key={x.distributor.name}
                    value={val}
                    type={'distributor'}
                />
            );
        })
    }
    const eCart = distributorList.length <= 0 &&
        <div>
            <MdsHeader6 mdsType={'body'} className={`${styles.title} ${styles.emptyCart}`}>
                <div className={styles.thick}>{t(`${baseTranslationKey2}.emptyCart4`)}</div>
                <div className={styles.empty} >
                    {t(`${baseTranslationKey2}.emptyCart1`)}
                    <a href={process.env.REACT_APP_MMM_DOTCOM_URL + process.env.REACT_APP_BACKTO_SHOPPING_URL}>
                        {t(`${baseTranslationKey2}.emptyCart2`)}</a>
                    {t(`${baseTranslationKey2}.emptyCart3`)}
                </div>
            </MdsHeader6>
        </div>

    const component = () => {
        // window.scrollTo(0, 0);
        switch (purchaseState) {
            case PurchaseStates.LOADING:
            case PurchaseStates.CART:
                return distributorList.length > 0 ? tooltipContent() : eCart
            case PurchaseStates.CHECKOUT_EDIT_SHIPPING:
                return <ReactTooltip
                    uuid={'cartTip'}
                    id="cartTip"
                    border={false}
                    backgroundColor={'white'}
                ></ReactTooltip>;
            case PurchaseStates.CHECKOUT_EDIT_DELIVERY:
                return <ReactTooltip
                    uuid={'cartTip'}
                    id="cartTip"
                    border={false}
                    backgroundColor={'white'}
                ></ReactTooltip>;
            case PurchaseStates.CHECKOUT_EDIT_PAYMENT:
                return <ReactTooltip
                    uuid={'cartTip'}
                    id="cartTip"
                    border={false}
                    backgroundColor={'white'}
                ></ReactTooltip>;
            case PurchaseStates.CHECKOUT_REVIEW:
                return <ReactTooltip
                    uuid={'cartTip'}
                    id="cartTip"
                    border={false}
                    backgroundColor={'white'}
                ></ReactTooltip>;
            case PurchaseStates.CHECKOUT_ORDER_SUCCESS:
                return <ReactTooltip
                    uuid={'cartTip'}
                    id="cartTip"
                    border={false}
                    backgroundColor={'white'}
                ></ReactTooltip>;

        }
    };

    const tooltipContent = () => {
        return (
            <>
                <div className={`${cardClassName}`}>
                    <div className={styles.listContainer}>
                        {distributorList.length > 0 &&
                            <Box className={`${styles.listContainer__cart} `}>
                                {t(`${baseTranslationKey2}.shoppingCart`)}
                            </Box>
                        }
                        {distributorList.map((distributor) => {
                            const distName = distributor.distributor.name
                                ? distributor.distributor.name
                                : 'n/a';
                            return (
                                <PersistentCartDistributorList distributor={distributor} key={distName} />
                            );
                        })}
                        {eCart}
                    </div>
                    {
                        distributorList.length > 0 &&
                        <div className={styles.border}>
                            <LineItem
                                title={t(`${baseTranslationKey1}.estimatedTaxes.estimatedTotalContainer`)}
                                value={`$${subTotal.add(totalShippingCost.add(totalTax)).minus(
                                    big(appliedPromotion ? appliedPromotion.totalDeducedAmount : 0)).minus(totalOperatorDiscount).toFixed(2)}`}
                                titleClassName={styles.estimatedTotal__title}
                                textClassName={styles.estimatedTotal__text}
                            />

                        </div>
                    }
                    {distributorList.length > 0 &&
                        <Box className={styles.bottomText}>
                            {t(
                                `${baseTranslationKey1}.estimatedTaxes.taxesWillBeCalculatedDuringCheckout`
                            )}
                        </Box>
                    }
                    {distributorList.length > 0 &&
                        <div className={styles.buttonsContainer}>
                            <div>
                                <Button
                                    buttonType="primary"
                                    size="medium"
                                    className={styles.buttonsCustom}
                                    onClick={() => { goToCheckout() }}
                                    disabled={!!checkoutError && checkoutError.length > 0}
                                >
                                    {t(`${baseTranslationKey}.checkout`)}
                                </Button>
                            </div>
                            <div>
                                <Link to={{ pathname: '/checkout' }}>
                                    <Button
                                        buttonType="secondary"
                                        size="medium"
                                        className={styles.buttonsCustom}
                                    >
                                        {t(`${baseTranslationKey}.viewCart`)}
                                    </Button>
                                </Link>
                            </div>
                            {!!checkoutError && checkoutError.length > 0 ?
                                <MdsText msdType="body" isInline={false} className={styles.error}>
                                    {t(`${baseTranslationKey}.checkoutError`)}
                                </MdsText>
                                : null
                            }
                        </div>
                    }
                </div>
            </>
        );
    };


    return (
        <div className={`${"m-navbar_login"} `} style={{ marginRight: '0px', float: 'right' }}>
            <div className={styles.mobile}>
                <a
                    href="/checkout"
                    className={`${"m-btn font--size m-navbar_loginBtn m-navbar_cart"} ${styles.hover_fx}`}
                    style={{ paddingRight: '0px' }}
                >
                    <i className={`${"MMM--icn MMM--icn_cart "}`} ></i>
                    <div
                        className={`m-navbar_cartBadge ${items > 0 ? '' : 'm-navbar_cartBadge--hidden'
                            }`}
                    >
                        {items.toString()}
                    </div>
                </a>
            </div>
            <div className={styles.laptop}>
                <a

                    href="/checkout"
                    data-tip
                    data-for="cartTip"
                    className={`${"m-btn font--size m-navbar_loginBtn m-navbar_cart"} ${styles.hover_fx}`}
                    style={{ paddingRight: '0px' }}
                >
                    <i className={`${"MMM--icn MMM--icn_cart "}`} ></i>
                    <div
                        className={`m-navbar_cartBadge ${items > 0 ? '' : 'm-navbar_cartBadge--hidden'
                            }`}
                    >
                        {items.toString()}
                    </div>
                </a>
            </div>
            <div>
                <ReactTooltip
                    uuid={'cartTip'}
                    id="cartTip"
                    border={true}
                    delayHide={400}
                    borderColor={'black'}
                    arrowColor={'#4b4b4b'}
                    clickable={true}
                    className={position}
                    place='bottom'
                    offset={{ top: 5, left: 190 }}
                    effect={'solid'}
                    interactive={true}
                >{component()}</ReactTooltip>
            </div>
        </div>
    );
}

Cart.propTypes =
{
    removeCartLineItem: PropTypes.func
};

const LineItem = (props) => {
    let title = 'n/a';
    if (typeof props.title === 'string' || props.title instanceof String) {
        title = props.title;
    } else if (props.type === 'promocode' && typeof props.title === 'object') {
        title = props.title;
    } else {
        title = props.title ? props.title.name : 'n/a';
    }
    return (
        <Box className={`${styles.lineItem} ${props.className}`}>
            <Box
                style={props.type === 'distributor' || props.type === 'promotion' ? { textIndent: '1rem' } : {}}
                className={props.titleClassName}>
                {title}
            </Box>
            <NumberFormat
                prefix='$'
                displayType='text'
                thousandSeparator={true}
                value={props.value}
                renderText={value => (
                    <Box className={`${styles.lineItemNumber}  ${props.dataTestId} ${props.textClassName}`}>{value}</Box>
                )}
            />
        </Box>
    );
};

LineItem.propTypes = {
    value: PropTypes.string,
    title: PropTypes.any.isRequired,
    type: PropTypes.string,
    dataTestId: PropTypes.string,
    className: PropTypes.string,
    textClassName: PropTypes.string,
    titleClassName: PropTypes.string,
};

export default Cart;