import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '../public/css/index.css';
import App from './components/App';
import * as serviceWorker from './assets/utils/serviceWorker';
import './assets/utils/i18n';
import {BrowserRouter as Router} from 'react-router-dom';
import i18n from  '../src/assets/utils/i18n';
import './styles/global.scss';
import { I18nextProvider } from 'react-i18next';
import Root from './Root';


ReactDOM.render(
  <Root>
    <Router>
      <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <Suspense fallback={(<div>Loading....</div>)}>
                <App />
            </Suspense>
         </I18nextProvider>
      </React.StrictMode>
    </Router>
  </Root>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
