export const initialShippingAddress = {
    email: 'NirmalDharsha@buyburners.com',
    id: '',
    name: '',
    firstName: 'Nirmal',
    lastName: 'Dharsha',
    street1: '7162 Ferrell Street',
    street2: '',
    city: 'Bemidji',
    state: 'MN',
    country: '',
    zipCode: '54341',
    phoneNumber: '381-320-9551',
    ext: '',
};

export const createBlankShippingAddress = () => {
    return {
        email: '',
        id: '',
        name: '',
        firstName: '',
        lastName: '',
        companyName: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zipCode: '',
        phoneNumber: '',
        ext: '',
    };
};
