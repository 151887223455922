import React, {useState} from 'react';
import styles from './PackageTypeToggle.module.scss';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

const PackageTypeToggle = ({children = []}) => {
    const [chosenPackageType, setChosenPackageType] = useState(0);
    const {t} = useTranslation();

    const packageTypeOptions = (
        <div className={styles.packageType_options}>
            {children.map((option, index) => {
                const {packageType, priceRange} = option.props;
                return (
                    <button
                        key={index}
                        className={
                            chosenPackageType === index
                                ? styles.packageType_button_active
                                : styles.packageType_button
                        }
                        onClick={() => {
                            setChosenPackageType(index);
                        }}
                    >
                        <p
                            className={`${styles.packageType_label} mds-font_header--6`}
                        >
                            {packageType}
                        </p>
                        <p
                            className={`${styles.packageType_price} mds-font_paragraph`}
                        >
                            {priceRange}
                        </p>
                    </button>
                );
            })}
        </div>
    );

    return (
        <>
            <div className={`${styles.packageType}`}>
                <h2 className="mds-font_header--5">
                    {t(
                        'translation.distributorPage.packageType.selectPackageTypeAndQuantity'
                    )}
                </h2>
                {packageTypeOptions}
            </div>
            {children[chosenPackageType]}
        </>
    );
};

PackageTypeToggle.propTypes = {
    children: PropTypes.array.isRequired
};

export default PackageTypeToggle;
