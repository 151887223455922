import React from 'react';
import {Provider} from 'react-redux';
import configureStore from './redux/configureStore';
import PropTypes from 'prop-types';



const store = configureStore();

 function Root (props) {

    return(
        <Provider store={store}>
            {props.children}
        </Provider>
    );  
};

Root.propTypes = {
    children: PropTypes.any.isRequired
};

export default Root;