import React from 'react';
import enUS from './gsnLanguage';

export default class Searchbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  render() {
    return (
      <div className="m-header_search l-smallMarginRight">
        <form className="h-fitHeight" id="typeahead-root" action={process.env.REACT_APP_MMM_DOTCOM_URL + "/3M/en_US/company-us/search/"}>
          <input id="js-searchBar" className="m-header_searchbar h-fitWidth h-fitHeight l-centerVertically is-searchbar" name="Ntt"
           type="search" placeholder={enUS.search} aria-label={enUS.search} onFocus={this.state.onFocus} onBlur={this.state.onBlur} />
          <button className="m-header_searchbutton" type="submit" value="Search">
            <i className="MMM--icn MMM--icn_search color--white" aria-label={enUS.search}></i>
          </button>
        </form>
      </div>
    );
  }
}
