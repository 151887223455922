/* eslint-disable max-lines*/
import * as types from '../actions/actionTypes.js';
import { miscellaneousInitialState } from './initialState.js';

export default function miscellaneousReducer(state = miscellaneousInitialState, action) {
    switch (action.type) {        
        case types.UPDATE_PO_NUMBER:
            return {
                ...state,
                poNumber :  action.poNumber
            }  
        default:
            return state;
    }
}
