import React from 'react';
import footerData from './footerData';
import getUserDetails from '../getUserDetails'
import './footer-simplified.scss';

const Legal = () => {
  const {authenticated} = getUserDetails()

  const legalLinks = footerData.legal.links.map((link, index) => {
    return (
      <React.Fragment key={index}>
        <a href={authenticated ? link.href.replace('/3M/', '/my3M/') : link.href}
          className={`m-footer_legalLink ${link.extraClasses}`} title={link.text}
          target={link.target ? link.target : '_blank'}>{link.text}</a>
        {index !== footerData.legal.links.length - 1 &&
          <div className="m-footer_legalSpan">|</div>
        }
      </React.Fragment>
    );
  });

  return (
    <div className="m-footer_legal">
      <div className="m-footer_legal--logo">
        <a className="h-linkNoUnderline" href="https://www.3M.com/" target="_self">
          <img src="https://www.3m.com/3m_theme_assets/themes/3MTheme/assets/images/unicorn/Logo_mobile.png" alt="3M Logo" />
        </a>
      </div>
      <div>
        <div className="m-footer_legalLinks">
          {legalLinks}
        </div>
        <div className="m-footer_copyRight">{footerData.legal.copyright}</div>
      </div>
    </div>
  );
};

class SimplifiedFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  render() {
    return (
      <div className="m-footer m-footer-simplified">
        <Legal />
        <div className="m-footer_socialContainer">
        </div>
      </div>
    );
  }
}

export default SimplifiedFooter;
