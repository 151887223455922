import React, {useEffect, useState} from 'react';
import footerData from './footerData';
import PropTypes from 'prop-types';

const CategoryLinks = (props) => {
    const [expandedCategory, setExpandedCategory] = useState([])
    const [open, setOpen] = useState(false)
    const [mobileScreen, setMobileScreen] = useState (false)
    const {authenticated} = props.authenticated;

    const toggleCategory = (value) => {
        setOpen(!open)
        if (expandedCategory.includes(value)) {
            const newList = expandedCategory.filter((index) => index !== value)
            setExpandedCategory(newList)
        } else {
            expandedCategory.push(value)
        }
        return null
    }

    const handleResize = () => {
        setMobileScreen(window.innerWidth < 768)
    }

    useEffect(() => {
        handleResize()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const categories = footerData.categoryLinks.map((category, index) => {
        const links = category.links.map((link, index) => {
            return (
                <li key={index} className="m-footer-link">
                    <a
                        href={authenticated ? link.href.replace('/3M/', '/my3M/') : link.href}
                        title={link.text}
                        className="link--dark"
                        target="_self"
                    >
                        {link.text}
                    </a>
                </li>
            );
        });
        const expanded = expandedCategory.includes(index);
        return (
            <li key={index} className="m-footer_category">
                <a
                    id={`categoryLink-${index}`}
                    role="none"
                    className={`m-footer_expHead link--dark is-expandable ${
                        expanded ? 'is-open' : ''
                    }`}
                    tabIndex={mobileScreen ? '0' : '-1'}
                    target="_self"
                    aria-expanded={expanded && mobileScreen}
                    onClick={() => toggleCategory(index)}
                >
                    {category.header}
                </a>
                <ul
                    id="help--links"
                    className="m-footer-expLinks"
                    aria-labelledby={`categoryLink-${index}`}
                >
                    {links}
                </ul>
            </li>
        );
    });

    return <ul className="m-footer_categories">{categories}</ul>;
}

CategoryLinks.propTypes = {
    authenticated: PropTypes.bool
};
  
CategoryLinks.defaultProps = {
    authenticated: false
}

export default CategoryLinks
