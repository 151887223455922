import React from 'react';
import PropTypes from 'prop-types';

const mdsHeader1 = (props) => {
  const extClass = props.className
    ? props.className
    : '';
  return (
    <h1 className={`mds-font_header--1 ${extClass}`}>{props.children}</h1>
)};

mdsHeader1.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
};

export default mdsHeader1;
