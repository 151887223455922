import {useState, useEffect} from 'react'
import {getAuthLoggedId} from '../../connectivity/cookie'

const getUserDetails = () => {
    const vsrmID = getAuthLoggedId()
    const [authenticated, setAuthenticated] = useState(false)
    const [firstName, setFirstName] = useState('Account')
    const [lastName, setLastName] = useState(' ')
    const [email, setEmail] = useState('')

    const userDetails = {
        authenticated: authenticated,
        firstName: firstName,
        lastName: lastName,
        email: email
    }

    useEffect(() => {
        // Determine if user is authenticated
        if (vsrmID !== undefined && vsrmID !== null) {
            fetch(process.env.REACT_APP_API_BASE_USER_URL+"/users?vsrmId="
            + vsrmID, {
                credentials: "include",
                headers: new Headers({'Auth': 1212})
            })
            .then(res => res.json())
            .then(
                (result) => {
                    setAuthenticated(true);
                
                    if (result?.user?.address.length > 0 && result?.user?.address[0].firstName !== undefined
                            && result?.user?.address[0].lastName !== undefined) {
                        setFirstName(result.user.address[0].firstName)
                        setLastName(result.user.address[0].lastName)
                    }
                    setEmail(result.user.email !== undefined ? result.user.email : '') 
            }
            ).catch((error) => {console.log(error)})
        }
    }, [])

    return userDetails;
}

export default getUserDetails