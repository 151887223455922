import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import {Input} from '@3mcom/mds-library/dist/index';
import {useTranslation} from 'react-i18next';

import {zipCodeSelected, clearShippingData} from '../../../../redux/actions/offerActions.js';
import {useDispatch, useSelector} from 'react-redux';
import styles from './ShippingInput.module.scss';

import { tealiumLink } from '../../../../redux/actions/trackingActions.js';

const ShippingSelector = (props) => {
    const {t} = useTranslation();

    const dispatch = useDispatch();
    const selectedZipCode = useSelector((state) =>
       state.offer.selectedZipCode
    );
    const zipCodeStatus = useSelector((state) =>
       state.offer.zipCodeStatus
    );

    const errors = useSelector((state) =>
       state.offer.errors
    );
       
    let alertMessage = ""

    if(zipCodeStatus == "success")
    alertMessage=(t('translation.distributorPage.quantitySelector.shippingInfo.geoZipSuccessTooltip'));
    else if(zipCodeStatus == "failed")
    alertMessage = (t('translation.distributorPage.quantitySelector.shippingInfo.geoZipFailedTooltip')); 
    else if(zipCodeStatus == "" && !selectedZipCode)      
    alertMessage = (t('translation.distributorPage.quantitySelector.shippingInfo.zipCodeEmpty'));             
    else     
    alertMessage = "";  
    
    let redAlert = "";
    if(errors.length > 0){
      const zipCodeErrors = errors.filter(e => e.code === 422);
      if(zipCodeErrors.length> 0){ 
        redAlert = styles.shipping_info__alert__red ;        
        alertMessage = (t('translation.distributorPage.quantitySelector.shippingInfo.zipCodeInvalid'));
      }
    }
    

    const zipCodeChange = (value) => {                  
        dispatch(zipCodeSelected( value, "")); 
        if(value.length == 0)
        dispatch(clearShippingData()); 
        dispatch(tealiumLink({
            event_name: 'Zipcode change',
            funnel_stage: 'Distributor Selection',
            zipCode: value
        }));   
    };

     useEffect(() => {          
            props.zipCodeEnter(selectedZipCode);         
    },[]);

    return (
        <div>
            <div className={styles.shipping_input}>
                <label className="mds-font_body">
                    {t(
                        'translation.distributorPage.quantitySelector.shippingInfo.shippingTo'
                    )}
                </label>
                <Input
                    id="zipCode"
                    fieldName={t(
                        'translation.distributorPage.quantitySelector.shippingInfo.zipCode'
                    )}
                    value={!!selectedZipCode ? selectedZipCode : ""}
                    onChange={(e) => {                          
                        zipCodeChange(e.target.value); 
                        if(e.target.value.length == 5)
                        props.zipCodeEnter(selectedZipCode);

                    }}
                    // onKeyDown={(e) => {
                    //    if(e.keyCode === 13)
                    //    props.onKeyDown();                         
                    // }}
                />
                {!!alertMessage ? 
                <span className={`${styles.shipping_info__alert} ${redAlert}`}>
                    <i className="MMM--icn MMM--icn_information"></i>
                    {alertMessage}.
                </span>:null
                }
            </div>
        </div>
    );
};

ShippingSelector.propTypes = {
    zipCodeEnter: PropTypes.func.isRequired
};

export default ShippingSelector;
