/* eslint-disable max-lines*/
import * as types from '../actions/actionTypes.js';
import { cartInitialState } from './initialState.js';
import * as helper from './reducer-helpers/cartReducer-helper.js';

export default function cartReducer(state = cartInitialState, action) {
    switch (action.type) {
        case types.CHANGE_DISTRIBUTOR_DELIVERY_METHOD:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    lineitem: helper.updateLineItems(
                        state.cart.lineitem,
                        action.distributorName,
                        action.shippingType,
                        action.price
                    ),
                },
                distributorOrderedLineItems: helper.updateDistributorShippingPrice(
                    state.distributorOrderedLineItems,
                    action.distributorName,
                    action.price
                ),
            };
        case types.LOAD_CART_SUCCESS:
            return {
                ...state,
                loadCartRequest: 'COMPLETE',
                distributorOrderedLineItems: helper.mergeDistributors(
                    state.distributorOrderedLineItems,
                    action.cart,
                    state.purchaseState
                ),
                cart: {
                    ...state.cart,
                    ...action.cart,
                    lineitem: helper.mergeBasket(
                        state.cart,
                        action.cart,
                        state.purchaseState,
                        state.cartOffers
                    ),
                },
                error:[]
            };
        case types.LOAD_CART_REQUEST:
            return {
                ...state,
                loadCartRequest: 'INPROGRESS'
            };
        case types.UPDATE_DELIVERY_OPTIONS: {
            return {
                ...state,
                cart: {
                    ...state.cart,
                },
            };
        }
        case types.UPDATE_CART_QUANTITY:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    lineitem: helper.updateLineItemQuantity(
                        state.cart.lineitem,
                        action.value.lineItemId,
                        action.value.newValue
                    ),
                },
            };
        case types.UPDATE_CART:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    lineitem: helper.addLineItemQuantity(
                        state.cart.lineitem,
                        action.value.quantity,
                        action.value.product,
                        action.value.offer
                    ),
                },
            };
        case types.UPDATE_LINEITEM_OFFER:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    lineitem: helper.updateLineItemOffers(
                        state.cart.lineitem,
                        action.value.offer,
                        action.value.error
                    )
                }
            }
        case types.REMOVE_CART_LINE_ITEM:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    lineitem: state.cart.lineitem.filter(
                        (x) => x.id !== action.id
                    ),
                },
            };
        case types.REMOVE_ALL_CART_LINE_ITEM:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    lineitem: []
                },
            };

        case types.SAVE_SORT_ORDER:
            return {
                ...state,
                initialCalculation: action.sortAgain,
                distributorOrderedLineItems: helper.updateDistributorShippingPriceOnLoad(
                    action.distributorOrderedLineItems,
                    action.cart
                )

            };
        case types.UPDATE_DISTRIBUTOR_AMOUNT:
            return {
                ...state,
                distributorOrderedLineItems: helper.updateDistributorPrice(
                    state.distributorOrderedLineItems,
                    action.distributorName,
                    action.priceChangeAmount
                ),
            };
        case types.REMOVE_EMPTY_DISTRIBUTOR:
            return {
                ...state,
                distributorOrderedLineItems: state.distributorOrderedLineItems.filter(
                    (x) => x.distributorName !== action.distributorName
                ),
            };
        case types.UPDATE_SHIPPING_TYPE:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    lineitem: action.newLineItems,
                },
            };
        case types.ADDRESS_VALIDATION:
            return {
                ...state,
                addressValidation: {
                    status: action.result.errors && action.result.errors.length > 0 ? false : true,
                    errors: action.result.errors,
                    newAddress: {
                        ...action.result.address
                    }
                }
            };
        case types.UPDATE_SHIPPING_ADDRESS:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    cart: {
                        ...state.cart.cart,
                    }
                },
            };
        case types.UPDATE_SHIPPING_PRICES:
            return {
                ...state,
                distributorOrderedLineItems:
                    action.newDistributorOrderedLineItems,
            };
        case types.UPDATE_BASKETID:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    basketId: action.basketId,
                },
            };
        case types.UPDATE_BASKET_ACTION:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    action: action.basketAction,
                },
            };
        case types.UPDATE_ORDER_RESULTS:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    orderCommercialId: action.orderCommercialId,
                    orderData: action.basket
                },
            };
        case types.ORDER_CONTINUE:
            return {
                ...state,
                purchaseState: action.nextState,
            };
        case types.ORDER_API_STATE:
            return {
                ...state,
                continueButtonState: action.continueButtonState,
            };
        case types.UPDATE_EMAILOPTIN:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    emailOptIn: action.value,
                },
            };
        case types.UPDATE_CERTIFYSAFETY:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    certifySafety: action.value,
                },
            };
        case types.ADD_PROMO_CODE:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    operatorPromotions: helper.createOperatorPromotions(state.cart.operatorPromotions, action.promoCode)
                },

            }
        case types.UPDATE_PROMOCODE_ERROR:
            return {
                ...state,
                cart: {
                    ...state.cart,
                    operatorPromotions: helper.updateActive(state.cart.operatorPromotions,false)
                }
            }
        case types.UPDATE_CART_ERROR:
            return {
                ...state,
                error: helper.updateError(action.err)
            }
        case types.LOAD_CART_OFFERS_SUCCESS:
            return {
                ...state,
                cartOffers: helper.loadCartOfferSuccess(state.cartOffers, action.offer)
            }
        default:
            return state;
    }
}
