import * as types from '../actions/actionTypes.js';

const defaultState = {
    userId: null,
    ordersList: [],
    isLoading: true
};

export default function ordersHistoryReducer(state = defaultState, action) {
    if (action.type === types.LOAD_USER_ORDERS_SUCCESS) {
        return {
            ...state,
            userId: action.userId,
            ordersList: action.orders,
            isLoading: false
        };
    }
    return state;
}