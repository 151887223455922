import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import ProductBar from '../../../3M-mini-apps/shared/components/product-bar'
import getUserDetails from '../gsn/getUserDetails'
import './carousel.scss';

const RecommendationCarousel = () => {
  const { t } = useTranslation();
  const baseTranslationKey = 'translation.cart.carousel';
  const [productData, setProductData] = useState([]);
  const {authenticated} = getUserDetails()

  // Function to update the URLs in the data to be authenticated URLs
  const updateAuthenticatedLinks = () => {
    if (authenticated) {
      const authenticatedData = productData
      authenticatedData.map((prod) => {
        if (prod?.product_url) {
          prod.product_url = prod.product_url.replace('/3M/', '/my3M/');
        }
      })
      setProductData(authenticatedData)
    }
    return null
  }

  // Call API and collect product data on initial load
  useEffect(() => {
    fetch(process.env.REACT_APP_API_RECOMMENDED_PRODUCTS)
    .then(res => res.json())
    .then(
      (result) => {
        setProductData(result["cross-sell-products"])
        updateAuthenticatedLinks()
      },
      (error) => {
        console.log(error);
      }
    )
  }, [])

  // Create listener for authenticated incase it updates
  useEffect(() => {
    updateAuthenticatedLinks()
  }, [authenticated])

  const productBarData = {
      data: productData
  }

  return (
    <div className="mkpl-carousel">
      {productData.length > 0 &&
        <>
          <h4 className="mds-font_header--4">{t(`${baseTranslationKey}.recommendedProducts`)}</h4>
          
          <div className="mkpl-productbar">
            <ProductBar json={productBarData} carousel={true} />
          </div>
        </>
      }
    </div>
  );
}
          

export default RecommendationCarousel;