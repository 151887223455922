import React from 'react';
import enUS from './gsnLanguage';

const Logo = () => {
    return (
        <a
            href="https://www.3m.com/"
            className="is-3MLogo m-header_logo l-smallMarginRight h-linkNoUnderline l-centerVertically"
            title={enUS.logoTitle}
        >
            <img
                className="h-onDesktop m-header_logoImg"
                src="https://www.3m.com/3m_theme_assets/themes/3MTheme/assets/images/unicorn/Logo.svg"
                alt={enUS.logoAltText}
            />
            <img
                className="h-notOnDesktop l-img"
                src="https://www.3m.com/3m_theme_assets/themes/3MTheme/assets/images/unicorn/Logo_mobile.png"
                alt={enUS.logoAltText}
            />
        </a>
    );
};

export default Logo;