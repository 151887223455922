/* eslint-disable max-len */
/* eslint-disable max-lines */
import axios from 'axios';
import * as cartActions from './cartActions';
import { getBasketId, getSharedBasketId, setShippingAddress, deleteGuestLoginSelected, 
    deleteShippingAddress, deleteSharedBasketIdInStorage, getCountryDesignations, 
    saveBasketId, getSharer, deleteSharerInStorage, getAddedUpsellProducts, deleteAddedUpsellProducts } from '../../../connectivity/localStorage.js';
import * as PurchaseStates from '../../reducers/reducer-helpers/purchaseStates';
import * as APIStates from '../../../redux/reducers/reducer-helpers/apiStates.js';
import * as errorLogActions from '../errorLogActions';
import { addGuestRequest, addGuestSuccess, addUserShippingAddressSuccess } from '../signInActions';
import pako from 'pako';
import { addBD, getBackOrderQty } from '../../../components/common/utlities';
import { prepareCartRequest, manageCartRequest } from '../../../components/mds/helpers.util';
import axiosRetry from 'axios-retry';
import { updateAddressResidentialIndicator, updateZipCode } from '../selectedAddressActions';
import * as UserDefinitions from '../../../components/common/userSettings/userDefinitions.js';
import { getAnalyticsCookies, getCampaignCookies } from '../../../connectivity/cookie';
import uuid from 'react-uuid';

const headers = {
    headers: { Auth: 1212 },
    withCredentials: true
};

const orderApi = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_ORDER_URL,
    withCredentials: true,
    headers: {
        Auth: 1212,
    }
    ,
    transformRequest: [
        function (data, headers) {
            // compress strings if over 1KB
            const jsonStr = JSON.stringify(data)
            if (typeof jsonStr === 'string' && jsonStr.length > 1024) {
                headers['Content-Encoding'] = 'gzip';
                headers['Content-Type'] = 'application/json';
                return pako.gzip(jsonStr);
            } else {
                // delete is slow apparently, faster to set to undefined
                // headers['Content-Encoding'] = undefined;
                headers['Content-Type'] = 'application/json';
                return jsonStr;
            }
        }
    ]

});

const placeOrderApi = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_ORDER_URL,
    withCredentials: true,
    headers: {
        Auth: 1212,
    }
    ,
    transformRequest: [
        function (data, headers) {
            // compress strings if over 1KB
            const jsonStr = JSON.stringify(data)
            if (typeof jsonStr === 'string' && jsonStr.length > 1024) {
                headers['Content-Encoding'] = 'gzip';
                headers['Content-Type'] = 'application/json';
                return pako.gzip(jsonStr);
            } else {
                // delete is slow apparently, faster to set to undefined
                // headers['Content-Encoding'] = undefined;
                headers['Content-Type'] = 'application/json';
                return jsonStr;
            }
        }
    ]

});


export function loadCart() {
    return function (dispatch, getState) {
        let basketId = getBasketId();
        const sharedBasketId = getSharedBasketId();
        if (!basketId) {
            const state = getState();
            basketId = state.signIn.basketId;
        }
        if(!!sharedBasketId && sharedBasketId.includes('-')){
            basketId = sharedBasketId;
        }
        const basketAction = getState().cart.cart.action;
        const basketLoadRequest = getState().cart.loadCartRequest;
        if (basketAction === 'COMPLETE') {
            return
        }
        if (basketLoadRequest === 'INPROGRESS') {
            return
        }

        if (!basketId) {
            return
        }

        dispatch(cartActions.loadCartRequest());

        axiosRetry(axios, {
            retries: 2,
            retryDelay: (retryCount) => {
                console.log(`retry attempt: ${retryCount}`);
                return retryCount * 1000;
            }
        });
        return axios
            .get(
                process.env.REACT_APP_API_BASE_ORDER_URL +
                '/baskets/' +
                basketId,
                headers
            )
            .then((result) => {

                if (!!sharedBasketId && sharedBasketId.includes('-')) {

                    const newBasketId = uuid();
                    saveBasketId(newBasketId);
                    const newBasket = result.data.basket;
                    newBasket.id = newBasketId;

                    axiosRetry(axios, { 
                        retries: 3,
                        retryDelay: (retryCount) => {
                            return retryCount * 1000;
                        }
                    });
                    
                    axios
                        .post(
                            process.env.REACT_APP_API_BASE_ORDER_URL + '/baskets',
                            newBasket,
                            {
                                headers: {
                                    Auth: 1212,
                                },
                                withCredentials: true
                            }
                        )
                        .then((result) => {
                            result.data.basket.lineitem.sort((a, b) => {
                                return (
                                    b.lineitemPrice -
                                    a.lineitemPrice
                                );
                            });
                            dispatch(cartActions.loadCartSuccess(result.data.basket));
                            deleteSharedBasketIdInStorage();
                        })
                        .catch((e) => {

                            !!window.decibelInsight && window.decibelInsight(
                                'sendApplicationError',
                                'Cart: loadCart() error with shared basket - ' + e?.message ? e?.message : 'An error occurred'
                            );

                        });

                } else {

                    // Normal carts (not shared baskets)

                    result.data.basket.lineitem.sort((a, b) => {
                        return (
                            b.lineitemPrice -
                            a.lineitemPrice
                        );
                    });

                    dispatch(cartActions.loadCartSuccess(result.data.basket));

                }

                // dispatch(
                //     cartActions.saveDistributorSortOrder(
                //         generateDistributorList(result.data.basket.lineitem)
                //     )
                // );
                // dispatch(saveCartProgress('CHECKOUT'));

            })
            .catch((e) => {

                !!window.decibelInsight && window.decibelInsight(
                    'sendApplicationError',
                    'Cart: Load error - ' + e?.message ? e?.message : 'An error occurred'
                );

            });
    };
}

export function syncCartOffers() {
    return function (dispatch, getState) {
        let sortedLineItems = [];
        const cartOffers = getState().cart.cartOffers;
        const lineItems = getState().cart.cart.lineitem;
        lineItems.map((lineItem) => {
            cartOffers.forEach((cartOffer) => {
                if (cartOffer.id != lineItem.offer.id) {
                    sortedLineItems.push(lineItem);
                }
            });
            if (cartOffers.length <= 0) {
                sortedLineItems.push(lineItem);
            }
        });
        sortedLineItems.map((item) => {
            cartOffers.forEach((off) => {
                if (off.id === item.offer.id) {
                    sortedLineItems = sortedLineItems.filter(items => items.id != item.id);
                }
            });
        });
        sortedLineItems.map(lineitem => dispatch(syncLineItemOffer(lineitem)));
    }
}

export function syncLineItemOffer(lineItem) {
    return function (dispatch) {
        const errorMsgNotAvailable = "offerNotAvailable";
        const errorMsgInvalid = "invalidOffer";
        return axios
            .get(
                process.env.REACT_APP_API_BASE_PRODUCT_URL +
                `/product/offers?productIds=${lineItem.product.id}${'&quantity=' + lineItem.quantity
                }&channelCodes=US&isCart=${true}`,
                {
                    withCredentials: true,
                    headers: {
                        Auth: 1212,
                    },
                    timeout: (process.env.REACT_APP_API_TIMEOUT + 500)
                }
            ).then((result) => {
                const offers = result.data.products ? result.data.products[0].offers : null;
                if (!offers || offers.length === 0) {
                    dispatch(cartActions.updateLineItemOffer(lineItem.offer, errorMsgNotAvailable));
                    return;
                }

                const matchOpt = offers.filter(offer => offer.id === lineItem.offer.id);
                const newOffer = matchOpt[0];
                if (matchOpt) {

                    const newOnHandQty = newOffer.availableQuantity - getBackOrderQty(newOffer);
                    const oldOnHandQty = lineItem.offer.availableQuantity - getBackOrderQty(lineItem.offer);
                    if (lineItem.quantity <= oldOnHandQty) {
                        // i.e this was an In stock offer
                        if (lineItem.quantity > newOnHandQty) {
                            // now the order needs to be supplied via backOrder or can't be fulfilled
                            // either ways ask user to change buying option.
                            dispatch(cartActions.updateLineItemOffer(lineItem.offer, errorMsgInvalid));
                            return;
                        }
                    }

                } else {

                    // The selected offer is no longer available
                    dispatch(cartActions.updateLineItemOffer(lineItem.offer, errorMsgNotAvailable));
                    return;
                }
                lineItem.offer = newOffer;
                dispatch(cartActions.loadCartOfferSuccess(JSON.parse(JSON.stringify(newOffer))));
                dispatch(cartActions.updateLineItemOffer(newOffer, null));

            }).catch((_error) => {
                dispatch(cartActions.updateLineItemOffer(lineItem.offer, errorMsgNotAvailable));
                return;
            });
    };
}

export function preCheckoutChecks() {
    return function (dispatch, getState) {
        dispatch(cartActions.apiCallState(APIStates.IN_PROGRESS));
        const state = getState();
        const cart = state.cart.cart;
        if (cart.emailOptIn) {
            const eloquaForm = {
                firstName: cart.shippingAddress.firstName,
                lastName: cart.shippingAddress.lastName,
                companyName: cart.shippingAddress.companyName,
                street1: cart.shippingAddress.street1,
                street2: cart.shippingAddress.street2,
                city: cart.shippingAddress.city,
                state: cart.shippingAddress.state,
                zipCode: cart.shippingAddress.zipCode,
                country: cart.shippingAddress.country,
                email: cart.shippingAddress.email,
                phone: cart.shippingAddress.phoneNumber
            };

            dispatch(optinToEloqua(eloquaForm));
        }

    };
}



export function optinToEloqua(form) {
    return function (dispatch, getState) {
        const requestUrl = process.env.REACT_APP_ELOQUA_MARKETING_URL;
        const country = getCountryDesignations();
        const body = {
            elqFormName: 'CORP-202007-none_ALL-ECM-MKPLconsent',
            elqSiteId: '837031577',
            language1: 'English',
            division1: 'PSD - Personal Safety Division',
            leadSourceMostRecent1: 'Web Order',
            leadSourceDetailMostRecent1: 'CORP-202007-none_ALL-ECM-MKPLconsent',
            emailAddress: form.email,
            firstName: form.firstName,
            lastName: form.lastName,
            busPhone: form.phone,
            company: form.companyName, // @todo this needs to filled
            address1: form.street1,
            address2: form.street2,
            city: form.city,
            stateProv: form.state,
            zipPostal: form.zipCode,
            country: country[0].name, // @todo eloqua wants this to be long form
            optin: 'on',
            dataPrivacy: 'on',
            marketingConsent: 'on',
            profilingConsent: 'on',
            endDevices: 'on'
        };
        const formData = new FormData();
        Object.keys(body).forEach((key) => {
            formData.append(key, body[key])
        })

        return axios
            .post(requestUrl, formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            .then((_result) => {
                // console.log("......TODO result ==-",result);
            })
            .catch((_error) => {
                // console.log(".........error ==-",error);
            });
    };
}

export const deepEqual = (object1, object2) => {
    if (object1 === null || object2 === null)
        return false;
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

export const deepContains = (addressList, address) => {
    if (!addressList || addressList.length == 0 || !address)
        return false;
    const match = addressList.filter(a => deepEqual(a, address));
    return !!match[0];
}

export const updateUser = (user, addressesToAdd) => {
    return (dispatch, getState) => {
        const requestUrl =
            process.env.REACT_APP_API_BASE_USER_URL + '/users/' + user.id;
        return axios
            .put(requestUrl, user, {
                headers: {
                    Auth: '1212',
                },
            })
            .then((result) => {
                addressesToAdd.map(a => dispatch(addUserShippingAddressSuccess(a)));
            })
            .catch((e) => {

                !!window.decibelInsight && window.decibelInsight(
                    'sendApplicationError',
                    'User: update error - ' + (e?.message ? e?.message : 'An error occurred')
                );

                dispatch(errorLogActions.appendError(e.toString()));
            });
    }
}

export const addAddress = (addressesToAdd) => {
    return (dispatch, getState) => {
        const state = getState();
        const userId = state.signIn.id;

        return axios
            .get(
                process.env.REACT_APP_API_BASE_USER_URL + '/users/' + userId,
                {
                    headers: {
                        Auth: 1212,
                    },
                }
            )
            .then((result) => {
                const user = result.data.user;
                user.address = [...user.address, ...addressesToAdd]
                dispatch(updateUser(user, addressesToAdd));
            });
    };
};

export const addOrderAddresses = () => {
    return (dispatch, getState) => {
        const state = getState();
        const newShippingAddress = state.selectedAddress.newUserAddressSelected;
        const newBillingAddress = state.selectedAddress.newBillingAddressSelected;
        const existingAddresses = state.signIn.address;
        const addressesToAdd = [];
        if (newShippingAddress && !deepContains(existingAddresses,
            state.selectedAddress.selectedShippingAddress)) {
            addressesToAdd.push(state.selectedAddress.selectedShippingAddress)
        }
        if (newBillingAddress && !deepContains([...existingAddresses, { ...state.selectedAddress.selectedShippingAddress }],
            state.selectedAddress.selectedBillingAddress)) {
            addressesToAdd.push(state.selectedAddress.selectedBillingAddress);
        }
        if (addressesToAdd.length > 0)
            dispatch(addAddress(addressesToAdd));
    };
}

export function checkoutComplete(history) {
    return function (dispatch, getState) {
        dispatch(cartActions.updateBasketAction('COMPLETE'));
        const state = getState();
        const cart = { ...state.cart.cart };

        cart.action = 'COMPLETE';
        const userId = state.signIn.id;
        const emailId = state.signIn.emailAddress;

        cart.shippingAddress = state.selectedAddress.selectedShippingAddress;
        cart.shippingAddress.userId = userId;
        cart.shippingAddress.country = 'USA';

        if (!cart.shippingAddress.email || cart.shippingAddress.email.length === 0) {
            cart.shippingAddress.email = emailId
        }

        cart.billingAddress = state.selectedAddress.selectedBillingAddress;
        cart.billingAddress.userId = userId;
        cart.billingAddress.country = 'USA';
        if (state.signIn.signInType === "GUEST") {
            cart.guest = true;
        } else {
            cart.guest = false;
        }
        cart.externalUserId = state.signIn.vsrmId;

        cart.paymentToken = state.paymentList.cardUUIDData.data.id;

        cart.poNumber = state.miscellaneous.poNumber.poNumber;

        cart.analyticsCookies = getAnalyticsCookies();
        cart.campaignCookies = getCampaignCookies();

        cart.sharer = getSharer();

        cart.upsellData = getAddedUpsellProducts();

        return placeOrderApi
            .put(
                process.env.REACT_APP_API_BASE_ORDER_URL +
                '/baskets/' +
                getBasketId(),
                cart,
                headers
            )
            .then((result) => {
                if (state.signIn.signInType !== "GUEST") {
                    dispatch(addOrderAddresses());
                }
                dispatch(
                    cartActions.updateOrderResults(
                        result.data.basket.orderCommercialId,
                        result.data.basket
                    )
                );

                dispatch(cartActions.apiCallState(APIStates.SUCCESS));
                dispatch(
                    cartActions.goToPurchaseState(
                        PurchaseStates.CHECKOUT_ORDER_SUCCESS
                    )
                );

                dispatch(cartActions.updateBasketAction('CHECKOUT'));
                deleteGuestLoginSelected();
                deleteShippingAddress();
                deleteSharerInStorage();
                deleteAddedUpsellProducts();
                // last thing to happen so analytics can pick up on these
                dispatch(cartActions.removeAllCartLineItem());

            }).catch((_error) => {

                dispatch(cartActions.apiCallState(APIStates.SUCCESS));

                let promoCodeError = false;
                const cartError = [];
                let canUpdateData = false;
                // let promoCodeErrorMsg='';

                if (_error?.response?.status === 400) {
                    if (!!_error?.response?.data && !!_error?.response?.data?.errors && _error?.response?.data?.errors?.length > 0) {
                        _error?.response?.data?.errors?.forEach(err => {
                            if (err.code === 410 && err.message.includes('Please enter a valid promo code')) {
                                promoCodeError = true;
                                // promoCodeErrorMsg=err.message;
                                !!window.decibelInsight && window.decibelInsight(
                                    'sendApplicationError',
                                    'Checkout: ' + (err?.message ? err?.message : 'An error occurred')
                                );

                            }
                            if (err.code === 501 && err.message.includes("Couldn't calculate Tax.")) {

                                cartError.push({
                                    code: err.code,
                                    message: err.message,
                                    type: 'taxError'
                                })
                                canUpdateData = true;

                                !!window.decibelInsight && window.decibelInsight(
                                    'sendApplicationError',
                                    'Checkout: ' + (err?.message ? err?.message : 'An error occurred')
                                );

                            }
                            if (err.code === 409 && err.message.includes("Price has been changed")) {
                                cartError.push({
                                    code: err.code,
                                    message: err.message,
                                    type: 'priceMisMatch',
                                    offerId: err.message.substring(err.message.indexOf('{') + 1, err.message.indexOf('}')),
                                    oldPrice: parseFloat(err.message.substring(err.message.indexOf('$') + 1).trim()),
                                })
                                canUpdateData = true;

                                !!window.decibelInsight && window.decibelInsight(
                                    'sendApplicationError',
                                    'Checkout: ' + (err?.message ? err?.message : 'An error occurred')
                                );

                            }
                            if (err.code === 145 && err.message.includes("Quantity not available")) {

                                cartError.push({
                                    code: err.code,
                                    message: err.message,
                                    type: 'outOfStock',
                                    offerId: err.message.substring(err.message.indexOf('(') + 1, err.message.indexOf(')'))
                                })
                                canUpdateData = true;

                                !!window.decibelInsight && window.decibelInsight(
                                    'sendApplicationError',
                                    'Checkout: ' + (err?.message ? err?.message : 'An error occurred')
                                );

                            }
                        });
                    }
                } else if (_error?.response?.status === 408 || _error?.response?.status === 504) {

                    cartError.push({
                        code: _error?.response?.status,
                        message: "TimeOut",
                        type: 'timeOut'
                    });

                    !!window.decibelInsight && window.decibelInsight(
                        'sendApplicationError',
                        'Checkout: Time out during checkout' + (_error?.message ? _error?.message : 'An error occurred')
                    );

                }

                if (cartError.length > 0) {

                    if (canUpdateData) {
                        dispatch(cartActions.loadCartSuccess(_error.response.data.basket));
                    }

                    dispatch(cartActions.updateError(cartError));

                } else if (promoCodeError) {

                    dispatch(cartActions.updatePromoCodeError(true));

                } else if (history) {

                    history.push('/error');

                    !!window.decibelInsight && window.decibelInsight(
                        'sendApplicationError',
                        'Checkout: Error - ' + (_error?.message ? _error?.message : 'An error occurred')
                    );

                }

            });;
    };
}

export function saveCartProgress(action, callBack = null, setLoading = null) {
    return function (dispatch, getState) {
        const state = getState();
        const cart = {
            ...state.cart.cart,
            action: action,
            shippingAddress: { ...state?.selectedAddress?.selectedShippingAddress }
        };
        prepareCartRequest(cart)
        return orderApi
            .put(
                process.env.REACT_APP_API_BASE_ORDER_URL +
                '/baskets/' +
                getBasketId(),
                cart,
                headers
            )
            .then((result) => {
                !!setLoading ? setLoading(false) : null;
                dispatch(cartActions.loadCartSuccess(result.data.basket));
                if (callBack) {
                    callBack();
                }
            }).catch((_error) => {
                !!setLoading ? setLoading(false) : null;
                let promoCodeError = false;
                // let promoCodeErrorMsg='';
                if (_error?.response?.status === 400) {
                    if (!!_error?.response?.data && !!_error?.response?.data?.errors && _error?.response?.data?.errors?.length > 0) {
                        _error?.response?.data?.errors?.forEach(err => {
                            if (err.code === 410 && err.message.includes('Please enter a valid promo code')) {
                                promoCodeError = true;
                                // promoCodeErrorMsg=err.message;
                            }
                        });
                    }
                }
                if (promoCodeError) {
                    dispatch(cartActions.updatePromoCodeError(true));
                    dispatch(cartActions.loadCartSuccess(_error.response.data.basket));

                }
            });

    };
}

function getTaxErrorPage(result, dispatch) {
    dispatch(cartActions.loadCartSuccess(result.data.basket));
    const cartError = []
    cartError.push({
        code: 501,
        message: "Couldn't calculate Tax.",
        type: 'taxError'
    })
    dispatch(cartActions.apiCallState(APIStates.SUCCESS));
    dispatch(cartActions.updateError(cartError));

}

function continueCheckOutInternal(currentPurchaseState, result, previousPurchaseState, history, dispatch) {
    dispatch(cartActions.apiCallState(APIStates.SUCCESS));
    dispatch(cartActions.orderContinue(currentPurchaseState));
    dispatch(cartActions.loadCartSuccess(result.data.basket));
    if (previousPurchaseState === PurchaseStates.CART) {
        history.push('/checkout');
    }
}

export function continueCheckout(currentPurchaseState, history) {
    const previousPurchaseState = currentPurchaseState;
    return function (dispatch, getState) {
        dispatch(cartActions.apiCallState(APIStates.IN_PROGRESS));
        const state = getState();
        // console.log('state in cartapiaction', state)
        const userId = state.signIn.id;
        const userType = state.signIn.signInType;
        const cart = {
            ...state.cart.cart
        };
        if (state.selectedAddress.selectedBillingAddress) {
            cart.billingAddress = state.selectedAddress.selectedBillingAddress;
        }
        if (state.selectedAddress.selectedShippingAddress) {
            cart.shippingAddress = state.selectedAddress.selectedShippingAddress;
            if(process.env.REACT_APP_CERTCAPTURE_TAX_EXEMPTION_ENABLED == 'true' || userType === UserDefinitions.REGISTERED_USER){
                cart.shippingAddress.userId = userId;
            }
            if (userType === UserDefinitions.REGISTERED_USER) {
                cart.guest = false;
            } else if (userType === UserDefinitions.GUEST)
                cart.guest = true;
            if ((userType === UserDefinitions.REGISTERED_USER && state.selectedAddress.newUserAddressSelected === true)
                || userType === UserDefinitions.GUEST)
                setShippingAddress(state.selectedAddress.selectedShippingAddress);
            dispatch(cartActions.updateShippingAddress(state.selectedAddress.selectedShippingAddress));
        }
        if (!!state.miscellaneous.poNumber.poNumber) {
            cart.poNumber = state.miscellaneous.poNumber.poNumber;
        }
        prepareCartRequest(cart)
        manageCartRequest(cart, state.cart.purchaseState)
        axiosRetry(orderApi, {
            retries: 2,
            retryDelay: (retryCount) => {
                console.log(`retry attempt: ${retryCount}`);
                return retryCount * 1000;
            }
        });
        return orderApi
            .put(
                process.env.REACT_APP_API_BASE_ORDER_URL +
                '/baskets/' +
                getBasketId(),
                cart,
                headers
            )
            .then((result) => {

                let taxError = false;

                if (currentPurchaseState === PurchaseStates.CART || !!result.data.basket.oneTimeTaxFreeTransaction ||
                    !!result.data.basket.taxExemptUser) {
                    
                    continueCheckOutInternal(
                        currentPurchaseState, result, previousPurchaseState, history, dispatch
                    );

                } else {

                    const lineItem = result.data.basket.lineitem;

                    if (!!lineItem && lineItem.length > 0) {

                        const taxCalculation = lineItem.reduce((total, currentValue) => total += (currentValue.tax ? currentValue.tax : 0), 0);
                        
                        if (!taxCalculation) {

                            !!window.decibelInsight && window.decibelInsight(
                                'sendApplicationError',
                                'Checkout: Tax error - no calculated tax'
                            );

                            getTaxErrorPage(result, dispatch);
                            taxError = true;

                        }

                    }
                }

                if (!taxError) {
                    continueCheckOutInternal(currentPurchaseState, result, previousPurchaseState, history, dispatch);
                }

            }).catch((_error) => {

                let isShippingZoneCodeError = false;
                let shippingZoneCodeErrorMsg = "";
                let promoCodeError = false;
                const cartError = [];
                let canUpdateData = false;

                // let promoCodeErrorMsg='';
                if (_error?.response?.status === 400) {
                    if (!!_error?.response?.data && !!_error?.response?.data?.errors && _error?.response?.data?.errors?.length > 0) {
                        _error?.response?.data?.errors?.forEach(err => {
                            if ((err.message).includes("Alaska") || (err.message).includes("Hawaii")) {
                                isShippingZoneCodeError = true;
                                shippingZoneCodeErrorMsg = err.message;

                                !!window.decibelInsight && window.decibelInsight(
                                    'sendApplicationError',
                                    'Checkout: ' + (err?.message ? err?.message : 'An error occurred')
                                );

                            }
                            if (err.code === 410 && err.message.includes('Please enter a valid promo code')) {
                                promoCodeError = true;
                                // promoCodeErrorMsg=err.message;

                                !!window.decibelInsight && window.decibelInsight(
                                    'sendApplicationError',
                                    'Checkout: ' + (err?.message ? err?.message : 'An error occurred')
                                );

                            }
                            if (err.code === 501 && err.message.includes("Couldn't calculate Tax.")) {
                                cartError.push({
                                    code: err.code,
                                    message: err.message,
                                    type: 'taxError'
                                })

                                !!window.decibelInsight && window.decibelInsight(
                                    'sendApplicationError',
                                    'Checkout: ' + (err?.message ? err?.message : 'An error occurred')
                                );

                            }
                            if (err.code === 409 && err.message.includes("Price has been changed")) {
                                cartError.push({
                                    code: err.code,
                                    message: err.message,
                                    type: 'priceMisMatch',
                                    offerId: err.message.substring(err.message.indexOf('{') + 1, err.message.indexOf('}')),
                                    oldPrice: parseFloat(err.message.substring(err.message.indexOf('$') + 1).trim()),
                                })
                                canUpdateData = true;

                                !!window.decibelInsight && window.decibelInsight(
                                    'sendApplicationError',
                                    'Checkout: ' + (err?.message ? err?.message : 'An error occurred')
                                );

                            }
                            if (err.code === 145 && err.message.includes("Quantity not available")) {

                                cartError.push({
                                    code: err.code,
                                    message: err.message,
                                    type: 'outOfStock',
                                    offerId: err.message.substring(err.message.indexOf('(') + 1, err.message.indexOf(')'))
                                })
                                canUpdateData = true;

                                !!window.decibelInsight && window.decibelInsight(
                                    'sendApplicationError',
                                    'Checkout: ' + (err?.message ? err?.message : 'An error occurred')
                                );

                            }
                        });
                    }
                }
                else if (_error?.response?.status === 408 || _error?.response?.status === 504) {

                    cartError.push({
                        code: _error?.response?.status,
                        message: "TimeOut",
                        type: 'timeOut'
                    });

                    !!window.decibelInsight && window.decibelInsight(
                        'sendApplicationError',
                        'Checkout: Time out during checkout'
                    );

                }

                if (cartError.length > 0) {
                    if (canUpdateData) {
                        dispatch(cartActions.loadCartSuccess(_error.response.data.basket));
                    }
                    dispatch(cartActions.updateError(cartError));
                    dispatch(cartActions.apiCallState(APIStates.SUCCESS));
                }
                else if (promoCodeError) {
                    dispatch(cartActions.updatePromoCodeError(true))
                    dispatch(cartActions.apiCallState(APIStates.SUCCESS));
                }
                else if (isShippingZoneCodeError) {

                    history.push({
                        pathname: '/error',
                        shippingZoneCodeError: true,
                        shippingZoneCodeErrorMsg: shippingZoneCodeErrorMsg
                    });

                } else if (history) {

                    history.push('/error');

                    !!window.decibelInsight && window.decibelInsight(
                        'sendApplicationError',
                        'Checkout: Error - ' + (_error?.message ? _error?.message : 'An error occurred')
                    );

                }

            });

    };
}

function shippingEngineAPICall(dispatch, getState, mode, purchaseState, history) {

    const headers = {
        headers: { Auth: 1212 },
    };
    let data = {};
    let selectedShippingAddress = null;
    if (mode === 'shipping' || mode === 'allowAutomaticDelivery') {
        selectedShippingAddress = getState().selectedAddress.selectedShippingAddress;
        data =
        {
            "street1": selectedShippingAddress.street1,
            "street2": selectedShippingAddress.street2,
            "city": selectedShippingAddress.city,
            "state": selectedShippingAddress.state,
            "zipCode": selectedShippingAddress.zipCode
        }
    } else {
        const selectedBillingAddress = getState().selectedAddress.selectedBillingAddress;
        data =
        {
            "street1": selectedBillingAddress.street1,
            "street2": selectedBillingAddress.street2,
            "city": selectedBillingAddress.city,
            "state": selectedBillingAddress.state,
            "zipCode": selectedBillingAddress.zipCode.split('-')[0]
        }
    }
    if (process.env.REACT_APP_SHIPENGINE_ADDRESS_VALIDATION === 'true' && mode !== 'allowAutomaticDelivery' &&
        purchaseState !== PurchaseStates.CHECKOUT_EDIT_PAYMENT) {
        axios
            .post(process.env.REACT_APP_API_BASE_ORDER_URL + '/shipping', data, headers
            )
            .then((res) => {
                dispatch(cartActions.addressValidation({}));
                dispatch(updateAddressResidentialIndicator(false, res?.data?.addressResidentialIndicator));
                if (selectedShippingAddress?.zipCode.split('-').length <= 1
                    && !!res?.data?.zipCode)
                    dispatch(updateZipCode(false, res?.data?.zipCode));
                dispatch(continueCheckout(purchaseState, history));
            }).catch((e) => {
                dispatch(cartActions.addressValidation(e?.response?.data));
                dispatch(updateAddressResidentialIndicator(false, e?.response?.data?.addressResidentialIndicator));
                dispatch(cartActions.apiCallState(APIStates.SUCCESS));
            });
    } else {
        dispatch(cartActions.addressValidation({}));
        dispatch(continueCheckout(purchaseState, history));
    }
}

export function validateAddressApi(mode, purchaseState, history) {
    return function (dispatch, getState) {
        dispatch(cartActions.apiCallState(APIStates.IN_PROGRESS));
        const state = getState();
        if (purchaseState === PurchaseStates.CHECKOUT_EDIT_SHIPPING && state.signIn.signInType === 'GUEST') {
            const emailAddress = state.selectedAddress.selectedShippingAddress.email;
            dispatch(addGuestRequest());
            const requestUrl = process.env.REACT_APP_API_BASE_USER_URL + '/users';
            const body = {
                email: emailAddress,
            };
            return axios
                .post(requestUrl, body, {
                    withCredentials: true,
                    headers: {
                        Auth: '1212',
                    },
                })
                .then((result) => {
                    dispatch(addGuestSuccess(emailAddress, result.data.user.id));
                    shippingEngineAPICall(dispatch, getState, mode, purchaseState, history);
                })
                .catch((error) => {

                    dispatch(errorLogActions.appendError(error.toString()));

                    !!window.decibelInsight && window.decibelInsight(
                        'sendApplicationError',
                        'User: Create user failed - ' + (error?.message ? error?.message : 'An error occurred')
                    );

                    if (history) {
                        history.push('/error');
                    }

                });
        } else {
            shippingEngineAPICall(dispatch, getState, mode, purchaseState, history);
        }
    };
}

export function generateDistributorList(arr) {
    return arr.reduce((accumulator, currentValue) => {
        const distributor = currentValue.offer.distributor;
        const result = accumulator.find(
            (x) => x.distributor.name === distributor.name
        );
        if (result) {
            result.totalPrice = addBD(result.totalPrice, currentValue.lineitemPrice);
        } else {
            accumulator.push({
                distributor: distributor,
                totalPrice: addBD(currentValue.lineitemPrice, 0),
            });
        }
        return accumulator;
    }, []);
}
