/* eslint-disable max-lines */
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import SubTotalFooter from './subTotalFooter/SubTotalFooter.js';
import {Link, Container, Details} from './productInfo/ProductInfo.js';
import PackageTypeToggle from './quantitySelector/PackageTypeToggle.js';
import QuantitySelector from './quantitySelector/QuantitySelector.js';
import {useHistory} from 'react-router-dom';
import {loadOffer, addToCart} from '../../redux/actions/offerActions.js';
import { tealiumView } from '../../redux/actions/trackingActions.js';
import {addProtocol} from '../mds/helpers.util';

import styles from './DistributorOfferPage.module.scss';
import {getNormalizedProductURL} from '../common/utlities.js';

import { invokeGeoLocationApi } from '../common/geoLocationApi';
import { zipCodeSelected } from '../../redux/actions/offerActions.js';
import {useTranslation} from 'react-i18next';
import NetworkErrorView from '../views/networkerror.view.js';
import Carousel from '../carousel';

export const DistributorOfferPage = ({location}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const products = useSelector((state) => state.offer.products);
    const offerAggregateTabs = useSelector(
        (state) => state.offer.offerAggregateTabs
    );
    const addItemToCart = (quantity, product, offer) => {
        dispatch(addToCart(quantity, product, offer));
    };
    const {sku} = useParams();

    const networkErrorStatus = useSelector((state)=> state.offer.networkErrorStatus);
    const isOffersAvailable = useSelector((state) => state.offer.isOffersAvailable);
    const INITIAL_LOAD_FAILED = 'INITIAL_LOAD_FAILED';
    const [isMobile, setMobile] = useState(window.innerWidth < 768);  

    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };
    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });
    useEffect(() => {
        dispatch(loadOffer(sku, 0, history, true));
        // set metatag for adobe analytics
        if (document.querySelectorAll('meta[name="WT.pn_sku"]').length > 0) {
            if (
                document
                    .querySelectorAll('meta[name="WT.pn_sku"]')[0]
                    .hasAttribute('content')
            ) {
                document.querySelectorAll(
                    'meta[name="WT.pn_sku"]'
                )[0].content = sku;
            }
        } else {
            const skuMeta = document.createElement('meta');
            skuMeta.setAttribute('name', 'WT.pn_sku');
            skuMeta.setAttribute('content', sku);
            document.getElementsByTagName('head')[0].appendChild(skuMeta);
        }
    }, []);

    useEffect(() => {

        dispatch(tealiumView({
            'view_content': '1',
            'funnel_stage': 'Distributor Selection',
            'content_ids': [sku],
            'content_type': 'product',
            'currency': 'USD'
        }));
        
    }, []);

    useEffect(() =>{
        invokeGeoLocationApi(function(results){
            let selectedZipCode = results?.data?.postalCode;
            let zipCodeStatus = "failed";
            if(!!selectedZipCode){                           
                zipCodeStatus = "success";
            } else {                    
                selectedZipCode = "";
            }
            dispatch(zipCodeSelected(selectedZipCode,zipCodeStatus));
        });
    },[]);

    const highUom = products[0].highUom;
    const lowUom = products[0].lowUom;
    const numLowUomInHighUom = products[0].numLowUomInHighUom;
    const lowUomNumOfEaches = products[0].lowUomNumOfEaches;
    const productUrl = getNormalizedProductURL(products[0].productURL);

    return (
        <div className={styles.distributor_offer_page}>
            {productUrl ? (
                <Container>
                    <Link productUrl={productUrl} />
                    <Details
                        imageUrl={addProtocol(products[0].imgURL)}
                        productInfo={products[0].description}
                        headerPromo={products[0].promoDistributorSelectionHeader}
                        headerPromoLinkText={products[0].promoDistributorSelectionHeaderLinkText}
                        headerPromoLinkURL={products[0].promoDistributorSelectionHeaderLinkURL}
                        headerPromoDisplayType={products[0].promoDistributorSelectionHeaderDisplayType}
                        seoText={products[0].dspSeoText}
                    />
                </Container>
            ) : null}

            {networkErrorStatus ? (
                <NetworkErrorView />
            ) : ((isOffersAvailable === INITIAL_LOAD_FAILED) ?
                (
                    <>
                        <div className={styles.distributor_offer_page__noOffersAvailableSection}>
                            <span className={styles.distributor_offer_page__outOfStock}>
                                {t('translation.distributorPage.quantitySelector.distributorInfo.outOfStock'
                                )}
                            </span>
                            <span className={styles.distributor_offer_page__noOffersAvailable_content}>
                                {t('translation.distributorPage.quantitySelector.distributorInfo.noOffersAvailable'
                                )}
                            </span>
                        </div>
                        <Carousel />
                    </>
                ) :
                (<PackageTypeToggle>
                    {offerAggregateTabs?.map((distributorOffer) => {

                        const offersForUnit = products[0].offers.filter((x) => {
                            return x.displayUnit === distributorOffer.unit;
                        });
                        const unit = `${distributorOffer.unit}`;

                        const labelUnit =
                            offerAggregateTabs.length >= 1
                                ? (unit === highUom && ` ${distributorOffer.unitMap}` == ' ' && numLowUomInHighUom != null && lowUom != null)
                                    ? "(" + numLowUomInHighUom + " " + lowUom + ")" :
                                    (unit === lowUom && ` ${distributorOffer.unitMap}` == ' ' &&lowUomNumOfEaches!=null)
                                        ? lowUomNumOfEaches : ` ${distributorOffer.unitMap}`
                                : '';

                        const tabLabel = `${distributorOffer.unit
                            }${labelUnit}\\n $${distributorOffer.lowPrice
                                ? distributorOffer.lowPrice.toFixed(2)
                                : 0.0
                            }${
                        distributorOffer.lowPrice === distributorOffer.highPrice
                            ? ''
                            : `-${
                                  distributorOffer.lowPrice
                                      ? distributorOffer.highPrice.toFixed(2)
                                      : 0.0
                              }`
                    }`;
                    const packageType = distributorOffer.unit + labelUnit;
                    const priceRange =
                        '$' +
                        (distributorOffer.lowPrice
                            ? distributorOffer.lowPrice.toFixed(2)
                            : 0.0) +
                        (distributorOffer.lowPrice ===
                        distributorOffer.highPrice
                            ? ''
                            : `-${
                                  distributorOffer.lowPrice
                                      ? distributorOffer.highPrice.toFixed(2)
                                      : 0.0
                              }`);


                    return (
                        <div
                            label={tabLabel}
                            key={distributorOffer.unit}
                            packageType={packageType}
                            priceRange={priceRange}
                        >
                            <QuantitySelector
                                dropdownMax={500}
                                offers={offersForUnit}
                                unit={distributorOffer.unit}
                                sku={sku}
                                highestInventory={distributorOffer.maxQty}
                            />
                        </div>
                    );
                })}
            </PackageTypeToggle>)
            )}
            {!networkErrorStatus && isMobile && process.env.REACT_APP_DSP_BUTTON_MOBILE != 'true'?
                  (<SubTotalFooter addToCart={addItemToCart} sku={sku} />):null}
        </div>
    );
};

DistributorOfferPage.propTypes = {
    location: PropTypes.shape({search: PropTypes.string}),
};

export default DistributorOfferPage;
