import * as types from './actionTypes.js';

export function updateShippingAddress(
    isForBilling,
    address,
    isValidAddress,
    newUserAddressSelected,
    selectedUserAddressIndex
) {
    return {
        type: types.UPDATE_ADDRESS,
        isForBilling,
        address,
        isValidAddress,
        selectedUserAddressIndex,
        newUserAddressSelected,
    };
}
export function updateBillingAddressSame(isForBilling) {
    return { type: types.UPDATE_BILLING_ADDRESS_SAME, isForBilling };
}

export function clearBillingAddressGuest(isForBilling) {
    return { type: types.CLEAR_BILLING_ADDRESS_GUEST, isForBilling };
}

export function clearBillingAddressRegisteredUser(isForBilling) {
    return { type: types.CLEAR_BILLING_ADDRESS_REGISTERED_USER, isForBilling };
}

export function updateIsValidAddres(isForBilling, isValidAddress) {
    return { type: types.UPDATE_IS_VALID_ADDRESS, isForBilling, isValidAddress };
}

export function validateAddress(isForBilling, validate) {
    return { type: types.VALIDATE_ADDRESS, isForBilling, validate };
}

export function updateAddressResidentialIndicator(isForBilling, addressResidentialIndicator) {
    return { type: types.UPDATE_ADDRESS_RESIDENTIAL_INDICATOR, isForBilling, addressResidentialIndicator };
}

export function updateZipCode(isForBilling, zipCode) {
    return { type: types.UPDATE_ZIP_CODE, isForBilling, zipCode };
}

export function setNewAddressSelected(isForBilling) {
    return { type: types.SET_NEW_ADDRESS_SELECTED, isForBilling };
}

export function setNewEmptyAddressSelected(isForBilling) {
    return { type: types.SET_NEW_EMPTY_ADDRESS_SELECTED, isForBilling };
}

export const updateSameBillingCheckbox = (status) => {
    return {
        type: types.UPDATE_SAME_BILLING_CHECKBOX,
        isSameBillingChecked: status
    }
}



