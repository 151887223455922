import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import loadingImage from '../../../../public/images/lazy-preloader-clear.gif';

import styles from './mds-button.module.scss';


const MDSButton = (props) => {
    const asLink = props.hasOwnProperty('asLink') ? props.asLink : true;
    const asRouter = props.hasOwnProperty('asRouter') ? props.asRouter : false;
    let el;

    const styleObj = props.style ? props.style : { cursor: 'pointer' };
    const disabled = props.disabled ? true : false;
    const fullWidth = props.fullWidth ? styles['mds_button--full_width'] : '';
    const mdsSize = ['small', 'regular', 'large', 'medium'].includes(
        props.mdsSize
    )
        ? props.mdsSize
        : 'medium';

    const mdsType = ['primary', 'secondary', 'tertiary'].includes(props.mdsType)
        ? props.mdsType
        : 'primary';

    const mdsWidth = ['0', '1', '3/4', '1/2', '1/4', '2/3', '1/3'].includes(
        props.mdsWidth
    )
        ? props.mdsWidth
        : '';

    const mdsDisabled = disabled ? styles[`mds_button_${mdsType}--disabled`] : '';

    const width = !fullWidth ? `mds_button--${mdsWidth.replace('/', '')}` : '';

    const btnType = styles['mds_button_' + mdsType];

    const classesList =
        `${styles['mds_button']} mds-button_${mdsType} ${mdsDisabled} ${btnType} ` +
        `mds-button_${mdsType}--${mdsSize ? mdsSize : 'regular'} ${styles[width]} ${fullWidth}` +
        `${props.blockEl ? ' mds_button--block_el' : ''}`;

    const loadingIcon = (<img src={loadingImage} className={styles.mds_button__icon} />);

    const clickEventHandler = (e) => {
        if (props.onClick && (!props.disabled || props.id === 'validateField')) props.onClick(e);
    };

    const blurEventHandler = (e) => {
        if (props.onBlur) props.onBlur(e);
    };

    const focusEventHandler = (e) => {
        if (props.onFocus) props.onFocus(e);
    };

    let elType = 'button';
    if (asLink) elType = 'a';
    if (asRouter) elType = 'Link';

    switch (elType) {
        case 'Link':
            el = (
                <Link
                    className={`${classesList} ${props.className}`}
                    disabled={disabled}
                    data-testid={`${props.dataTestId}`}
                    key={props.key}
                    onClick={e => clickEventHandler(e)}
                    onBlur={e => blurEventHandler(e)}
                    onFocus={e => focusEventHandler(e)}
                    style={styleObj}
                    to={props.to ? props.to : undefined}
                >
                    {props.icon && loadingIcon}
                    {props.children}
                </Link>
            );
            break;
        case 'button':
            el = (
                <button
                    className={`${classesList} ${props.className}`}
                    disabled={disabled}
                    data-testid={`${props.dataTestId}`}
                    key={props.key}
                    onClick={clickEventHandler}
                    onBlur={blurEventHandler}
                    onFocus={focusEventHandler}
                    style={styleObj}
                    type={props.type ? props.type : 'button'}
                >
                    {props.icon && loadingIcon}
                    {props.children}

                </button>
            );
            break;
        case 'a':
        default:
            el = (
                <a
                    id={props.id !== undefined ? props.id : ''}
                    className={`${classesList} ${props.className}`}
                    disabled={disabled}
                    data-testid={`${props.dataTestId}`}
                    key={props.key}
                    onClick={clickEventHandler}
                    onBlur={blurEventHandler}
                    onFocus={focusEventHandler}
                    style={styleObj}
                    href={props.to ? props.to : undefined}
                >
                    {props.icon && loadingIcon}
                    {props.children}
                </a>
            );
            break;
    }

    return el;
};

MDSButton.propTypes = {
    id: PropTypes.string,
    mdsSize: PropTypes.string,
    mdsType: PropTypes.string,
    mdsWidth: PropTypes.string,
    mdsHeight: PropTypes.string,
    asLink: PropTypes.bool,
    asRouter: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    icon: PropTypes.any,
    width: PropTypes.string,
    height: PropTypes.string,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    blockEl: PropTypes.bool,
    style: PropTypes.any,
    to: PropTypes.string,
    type: PropTypes.string
};

export default MDSButton;
