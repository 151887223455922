/* eslint-disable */
import React from 'react';
import {useTranslation} from 'react-i18next';
import Profile from './profile';
import ProfileDropdown from './profileDropdown';
import Cart from './cart';
import Searchbar from './searchbar';
import { getUserData }  from './getUserDetailsUtil';
import './header.scss';
import './header-simplified.scss';

const Logo = () => {
    const {t} = useTranslation();
    return (
        <a
            href="https://www.3m.com/"
            className="is-3MLogo m-header_logo l-smallMarginRight h-linkNoUnderline l-centerVertically"
            title={t('translation.logoTitle')}
        >
            <img
                className="h-onDesktop m-header_logoImg"
                src="https://www.3m.com/3m_theme_assets/themes/3MTheme/assets/images/unicorn/Logo.svg"
                alt={t('translation.logoAltText')}
            />
            <img
                className="h-notOnDesktop l-img"
                src="https://www.3m.com/3m_theme_assets/themes/3MTheme/assets/images/unicorn/Logo_mobile.png"
                alt={t('translation.logoAltText')}
            />
        </a>
    );
};

export default class SimplifiedHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props,
            profileDropdownHidden: true,
            mobileScreen: false,
            searchbarFocus: false,
            userDetails:{
                authenticated: false,
                firstName: 'Account',
                lastName: '',
                email: ''
            }
        };
        this.header = React.createRef();
        this.toggleProfileDropdown = this.toggleProfileDropdown.bind(this)
        this.handleResize = this.handleResize.bind(this)
        this.setUserDetails = this.setUserDetails.bind(this); 
        this.searchbarFocus = this.searchbarFocus.bind(this);
        this.searchbarBlur = this.searchbarBlur.bind(this);
    }
    
    async setUserDetails() {
        const userData = await getUserData();
        this.setState({ userDetails: userData });
    }

    handleResize() {
        if (window.innerWidth < 960) {
            this.setState({mobileScreen: true});
        } else {
            this.setState({mobileScreen: false});
        }
    }

    toggleProfileDropdown() {
        this.setState({profileDropdownHidden: !this.state.profileDropdownHidden})
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        this.setUserDetails();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    searchbarBlur() {
        this.setState({searchbarFocus: false});
    }
    searchbarFocus() {
        this.setState({searchbarFocus: true});
    }

    render() {
        return (
            <>
            <nav className="m-nav m-nav-simplified">
                <div className="is-header_container h-fitWidth m-header_container">
                    <div className={`m-header ${this.state.searchbarFocus ? 'is-searching' : ''}`} ref={this.header}>
                        <Logo />
                        { window.location.pathname == '/cart' && <Searchbar onFocus={this.searchbarFocus} onBlur={this.searchbarBlur} /> }                         
                        <Cart />
                        <Profile 
                            toggleDropdown={this.toggleProfileDropdown}
                            dropdownHidden={this.state.profileDropdownHidden} 
                            userDetails={this.state.userDetails}/>
                        <ProfileDropdown 
                            isMobile={this.state.mobileScreen}
                            dropdownHidden={this.state.profileDropdownHidden}
                            simplifiedHeader={true} 
                            userDetails={this.state.userDetails}/>
                    </div>
                    <div className="m-header_overlay"></div>
                </div>
            </nav>
            <div className={`m-navbar_profileOverlay is-profileToggle is-close no-wpthemeHeaderOverlay 
                    ${this.state.profileDropdownHidden || !this.state.isMobile ? 'h-hidden': ''}`} 
                    onClick={this.toggleProfileDropdown}></div>
                <div className={`m-navbar_signInOverlay m-navbar_signInOverlay--simplified m-overlay is-signInToggle is-close ${this.state.profileDropdownHidden || this.state.isMobile ? 'h-hidden': ''}`}
                    onClick={this.toggleProfileDropdown}></div>
            </>
        );
    }
}
