/* eslint-disable */
import axios from 'axios';
import * as types from './actionTypes';
import {appendError} from './errorLogActions';

export const initiateIncidentFileUpload = (file, filePath) => {
    return (dispatch, getState) => {
        const state = getState();
        state.fileUpload.incident.isLoading = true;
        const fileParts = filePath.split('.');
        const fileType = fileParts[fileParts.length - 1];
        return axios
            .post(`${process.env.REACT_APP_API_BASE_ORDER_URL}/orders/incident/attachment/`, {},
                {
                    headers: {
                        Auth: 1212,
                    },
                }
            )
            .then((result) => {
                const {puturl, geturl, geturlExpiry, id} = result.data;
                dispatch(loadFileInitiated(puturl, geturl, file, fileType, filePath, geturlExpiry, id));
                dispatch(putIncidentFileInAwsBucket(puturl, geturl, file, fileType, filePath, geturlExpiry, id));
            })
            .catch(err => {
              dispatch(appendError(err.toString()));
            });
    }
}

export const putIncidentFileInAwsBucket = (putUrl, getUrl, file, fileType, filePath, geturlExpiry, id) => {
    return (dispatch) => {
        return axios
            .put(putUrl, file, {
                headers: {
                    'Content-Type': fileType,
                }
            })
            .then(result => {
                dispatch(putIncidentFileInAwsBucketSuccess(getUrl, filePath, geturlExpiry, id));
            })
            .catch(err => {
              dispatch(appendError(err.toString()));
            });
    }
}

export const initiateMessageFileUpload = (file, filePath) => {
  return (dispatch, getState) => {
    const state = getState();
    state.fileUpload.messages.isLoading = true;
    const fileParts = filePath.split('.');
    const fileType = fileParts[fileParts.length - 1];
    // dispatch(messageFileInitiated(file, fileType, filePath));
    return axios
      .post(`${process.env.REACT_APP_API_BASE_ORDER_URL}/orders/incident/attachment/`, {},
        {
          headers: {
            Auth: 1212,
          },
        }
      )
      .then((result) => {
        const {puturl, geturl, geturlExpiry, id} = result.data;
        // dispatch(putExemptionFileInAwsBucket(puturl, geturl, file, fileType));
        dispatch(messageFileInitiated(puturl, geturl, file, fileType, filePath, geturlExpiry, id));
        dispatch(putMessageFileInBucket(puturl, geturl, file, fileType, filePath, geturlExpiry, id));
      })
      .catch(err => {
        dispatch(appendError(err.toString()));
        dispatch(taxExemptError(true));
      });
  }
}

export const putMessageFileInBucket = (putUrl, getUrl, file, fileType, filePath, geturlExpiry, id) => {
  return (dispatch) => {
    return axios
      .put(putUrl, file, {
        headers: {
          'Content-Type': fileType,
          'x-amz-server-side-encryption': 'aws:kms',
        }
      })
      .then(result => {
        dispatch(putMessageFileInBucketSuccess(getUrl, filePath, geturlExpiry, id));
      })
      .catch(err => {
        dispatch(appendError(err.toString()));
        dispatch(taxExemptError(true));
      });
  }
}

export const loadUserExemptionsThunk = () => {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.signIn.id;
    const url = process.env.REACT_APP_API_BASE_USER_URL;
    const userExemptUrl = process.env.REACT_APP_CERTCAPTURE_TAX_EXEMPTION_ENABLED == 'true' ? `${url}/users/certificates/${userId}` : `${url}/users/${userId}/exemption`;
    return axios.get(userExemptUrl, {
      headers: {
        Auth: 1212,
      },
    })
    .then(result => {
      const data = result.data;
      if (!data.exemption) data.exemption = [

      ];
      dispatch(loadExemptionsForUser(data.exemption));
    })
    .catch(err => {
      dispatch(appendError(err.toString()));
    })
  }
}

export const initiateExemptionFileUpload = (file, filePath) => {
  return (dispatch, getState) => {
    const state = getState();
    state.fileUpload.incident.isLoading = true;
    const fileParts = filePath.split('.');
    const fileType = fileParts[fileParts.length - 1];
    dispatch(taxExemptionInitiated(file, fileType, filePath));
    return axios
      .post(
        process.env.REACT_APP_API_BASE_USER_URL + `/users/${state.signIn.id}/exemption`, {},
        {
          headers: {
            Auth: 1212,
          },
        }
      )
      .then((result) => {
        const data = result.data.exemption[0];
        // dispatch(putExemptionFileInAwsBucket(puturl, geturl, file, fileType));
        dispatch(putExemptionFileInAwsBucket(data.url, file, fileType, filePath));
      })
      .catch(err => {
        dispatch(appendError(err.toString()));
        dispatch(taxExemptError(true));
      });
  }
};

export const putExemptionFileInAwsBucket = (putUrl, file, fileType, filePath) => {

  return (dispatch) => {
    return axios
      .put(putUrl, file, {
        headers: {
          'x-amz-server-side-encryption': 'aws:kms'
        }
      })
      .then(result => {
        dispatch(putTaxExemptionAwsBucketSuccess(result.config.url, filePath, fileType));
      })
      .catch(err => {
        dispatch(appendError(err.toString()));
        dispatch(taxExemptError(true));
      });
  }
};

export const messageFileInitiated = (puturl, geturl, file, fileType, filePath, geturlExpiry, id) => {
  return {type: types.MESSAGE_FILE_INITIATED, data: {url: geturl, file, fileType, name: filePath}}
}

export const putMessageFileInBucketSuccess = (getUrl, filePath, expire, id) => {
  return {type: types.MESSAGE_FILE_AWS_SUCCESS, data: { getUrl, id, name: filePath, expire}}
}

export const loadFileInitiated = (url) => {
    return {type: types.INCIDENT_LOAD_FILE_INITIATED, url}
}

export const putIncidentFileInAwsBucketSuccess = (getUrl, filePath, expire, id) => {
    return {type: types.PUT_INCIDENT_FILE_IN_AWS_BUCKET_SUCCESS, data: { getUrl, name: filePath, id, expire }}
}

export const loadExemptionsForUser = (exemptionsArr) => {
  return { type: types.LOAD_TAX_EXEMPTS, data: { arr: exemptionsArr }}
}

export const taxExemptionInitiated = (file, fileType, filePath, url = '') => {
  return {type: types.TAX_EXEMPT_DOC_INITIATED, data: {url, file, fileType, filePath}}
}

export const putTaxExemptionAwsBucketSuccess = (url, filePath, fileType) => {
  return {type: types.PUT_TAX_EXEMPT_AWS_SUCCESS, data: { url, fileType, filePath}}
}

export const taxExemptError = (status = false, loading = null) => {
  return {type: types.SET_TAX_EXEMPT_ERR, data: {status, loading}}
}

export const processingExemption = () => {
  return {type: types.SET_EXEMPT_FOR_PROCESSING, data:{}}
}

export const clearPendingExemptions = () => {
  return {type: types.CLEAR_PENDING_EXEMPT}
}
