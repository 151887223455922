/* eslint-disable max-lines*/
/* eslint-disable max-len */
import * as types from './actionTypes.js';
import * as cartActions from './cart/cartActions.js';
import * as signInActions from './signInActions.js';
import {getBasketId, saveBasketId} from '../../connectivity/localStorage.js';
import axios from 'axios';
import uuid from 'react-uuid';
import pako from 'pako';
import {multiplyBD} from '../../components/common/utlities';
import axiosRetry from 'axios-retry';
import {prepareCartRequest} from '../../components/mds/helpers.util';

const orderApi = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_ORDER_URL,
    withCredentials: true,
    headers: {
        Auth: 1212,
    },
    transformRequest: [
      function (data, headers) {
        // compress strings if over 1KB
        const jsonStr= JSON.stringify(data)
        if (typeof jsonStr === 'string' && jsonStr.length > 1024) {
          headers['Content-Encoding'] = 'gzip';
          headers['Content-Type'] = 'application/json';
          return pako.gzip(jsonStr);
        } else {
          // delete is slow apparently, faster to set to undefined
          // headers['Content-Encoding'] = undefined;
          headers['Content-Type'] = 'application/json';
          return jsonStr;
        }
      }
    ]
});

// orderApi.interceptors.response.use(
//     response => {
//         return response;
//     },
//     error => {
//         if (error.response) {
//             let data = error.response.data;
//             if (typeof data !== 'string') {
//                 data = JSON.stringify(data);
//             }
//             // console.error('Response error:', error.response.status, data);
//             !!window.decibelInsight && window.decibelInsight('sendApplicationError', 'Cart: creation response error - ' + data);
          
//         } else if (error.request) {
//             !!window.decibelInsight && window.decibelInsight('sendApplicationError', 'Cart: request error - ' + error.request);
//         } else {
//             !!window.decibelInsight && window.decibelInsight('sendApplicationError', 'Cart: creation error - ' + error.message);
//         }

//         return Promise.reject(error);
//     }
// );


export function loadOfferSuccess(offer) {
    return {type: types.LOAD_OFFER_SUCCESS, offer};
}

export function loadOfferAggregates(offer) {
    return {type: types.LOAD_OFFER_AGGREGATES, offer};
}

export function updateOfferAggregates(offer) {
    return {type: types.UPDATE_OFFER_AGGREGATES, offer};
}

export function loadOfferRequest() {
    return {type: types.LOAD_OFFER_REQUEST};
}

export function selectQuantity(quantity, unit, productId,setLoading) {
    return function (dispatch) {
        dispatch(loadOffer(productId, quantity,null,null,unit,setLoading));
        dispatch(quantitySelected(quantity, unit));
    };
}

export function quantitySelected(quantity, unit) {
    return {type: types.SELECT_QUANTITY, quantity, unit};
}
export function zipCodeSelected(zipCode, zipCodeStatus) {
    return {type: types.SELECT_ZIPCODE, zipCode, zipCodeStatus};
}

export function clearShippingData() {
    return {type: types.CLEAR_SHIPPING_DATA};
}

export function selectEstimatedDeliveryOption(offerId, deliveryOption) {
    return {type: types.SELECT_ESTIMATED_DELIVERY_OPTION, offerId, deliveryOption};
}

export function selectOffer(offer, offerPrice) {
    return {type: types.SELECT_OFFER, offer, offerPrice};
}

export function deselectOffer() {
    return {type: types.DESELECT_OFFER};
}

export function updateNetworkErrorStatus(networkErrorStatus){
    return {type: types.UPDATE_NETWORK_ERROR_STATUS, networkErrorStatus};
}

export function removeAllOffer(){
    return {type: types.REMOVE_ALL_OFFER};
}

export function loadUpsellOfferSuccess(offer) {
    return {type: types.LOAD_UPSELL_OFFER_SUCCESS, offer}
}

export function removeAddedUpsellOffer (product, offer) {
    return {type: types.REMOVE_ADDED_UPSELL_OFFER, product, offer}
}

export function updateIsOffersAvailable (isOffersAvailable) {
    return {type: types.UPDATE_IS_OFFERS_AVAILABLE, isOffersAvailable}
}

export function addToCart(quantity, product, offer, setLoading = null) {
    return function (dispatch, getState) {
        let basketId = getBasketId();  
        const state = getState(); 
        if (!basketId) {            
            basketId = state.signIn.basketId;
        }     
        const localProduct = JSON.parse(JSON.stringify(product));

        !!window.decibelInsight && window.decibelInsight('sendTrackedEvent', 'Add to Cart');

        if (basketId) {

            dispatch(cartActions.loadCartRequest());

            axiosRetry(axios, { 
                retries: 3,
                retryDelay: (retryCount) => {
                    return retryCount * 1000;
                }
            });

            axios
                .get(
                    process.env.REACT_APP_API_BASE_ORDER_URL +
                        `/baskets/${basketId}`,
                    {
                        headers: {
                            Auth: 1212,
                        },
                    }
                )
                .then((result) => {
                    const resultBody = result.data.basket;
                    resultBody.lineitem = resultBody.lineitem
                        ? resultBody.lineitem
                        : [];
                    resultBody.action = 'CHECKOUT';
                    offer.deliveryOptions = null;
                    resultBody.lineitem.unshift({
                        quantity: quantity,
                        product: localProduct,
                        offer: offer,
                        estimateShippingType: offer.selectedDeliveryOption ? offer.selectedDeliveryOption.shippingType:null,
                        estimateShippingCost: offer.selectedDeliveryOption ? offer.selectedDeliveryOption.price:null,
                        lineitemPrice: multiplyBD(offer.price , quantity), 
                        id : uuid()
                    });
                    resultBody.lineitem.forEach((li) => {
                        if(li.offer.distributor.id == offer.distributor.id)
                        li.estimateShippingType = offer.selectedDeliveryOption ? offer.selectedDeliveryOption.shippingType:null;
                    });
                    resultBody.shippingAddress = !!state.offer.selectedZipCode ?{ zipCode :state.offer.selectedZipCode } : {};
                    resultBody.billingAddress = {};
                    prepareCartRequest(resultBody)

                    axiosRetry(orderApi, { 
                        retries: 3,
                        retryDelay: (retryCount) => {
                            return retryCount * 1000;
                        }
                    });

                    orderApi
                        .put(
                            process.env.REACT_APP_API_BASE_ORDER_URL +
                                '/baskets/' +
                                basketId,
                            resultBody,
                            {
                                headers: {
                                    Auth: 1212,
                                }
                            },
                        )
                        .then((result) => {
                            !!setLoading ? setLoading(false) : null;
                            dispatch(
                                cartActions.loadCartSuccess(result.data.basket)
                            );
                        })
                        .catch((_error) => {
                            !!setLoading ? setLoading(false) : null;
                            if (_error?.response?.status === 400) {
                                handleErrors(_error, dispatch);                                 
                            }                            
                        });
                });
        } else {
            const newBasketId = uuid();
            saveBasketId(newBasketId);
            localProduct.offers = []
            dispatch(signInActions.updateBasketIdSuccess(newBasketId));
            offer.deliveryOptions = null;
            const newBasket = {
                id: newBasketId,
                action: 'CHECKOUT',
                lineitem: [
                    {
                        id: uuid(),
                        lineitemPrice: multiplyBD(offer.price, quantity), 
                        estimateShippingType: offer.selectedDeliveryOption ? offer.selectedDeliveryOption.shippingType:null,                        
                        estimateShippingCost: offer.selectedDeliveryOption ? offer.selectedDeliveryOption.price:null,
                        quantity: quantity,
                        product: localProduct,
                        offer: offer,
                    },
                ],
                shippingAddress: {},
                billingAddress: {},
            };

            axiosRetry(axios, { 
                retries: 3,
                retryDelay: (retryCount) => {
                    return retryCount * 1000;
                }
            });
            
            axios
                .post(
                    process.env.REACT_APP_API_BASE_ORDER_URL + '/baskets',
                    newBasket,
                    {
                        headers: {
                            Auth: 1212,
                        },
                        withCredentials: true
                    }
                )
                .then((result) => {
                    dispatch(cartActions.loadCartSuccess(result.data.basket));
                });
        }
    };
}

function handleErrors(_error, dispatch) {
    const cartError = [];
    let canUpdateData = false;
    if (!!_error ?.response ?.data && !!_error ?.response ?.data ?.errors && _error ?.response ?.data ?.errors ?.length > 0) {
        _error ?.response ?.data ?.errors ?.forEach(err => {
            if (err.code === 409 && err.message.includes("Price has been changed")) {
                cartError.push({
                    code: err.code,
                    message: err.message,
                    type: 'priceMisMatch',
                    offerId: err.message.substring(err.message.indexOf('{') + 1, err.message.indexOf('}')),
                    oldPrice: parseFloat(err.message.substring(err.message.indexOf('$') + 1).trim()),
                })
                canUpdateData = true;

                !!window.decibelInsight && window.decibelInsight(
                    'sendApplicationError',
                    'Checkout: ' + (err?.message ? err?.message : 'An error occurred')
                );

            }
            if (err.code === 145 && err.message.includes("Quantity not available")) {

                cartError.push({
                    code: err.code,
                    message: err.message,
                    type: 'outOfStock',
                    offerId: err.message.substring(err.message.indexOf('(') + 1, err.message.indexOf(')'))
                })
                canUpdateData = true;

                !!window.decibelInsight && window.decibelInsight(
                    'sendApplicationError',
                    'Checkout: ' + (err?.message ? err?.message : 'An error occurred')
                );

            }
            if (cartError.length > 0) {

                if (canUpdateData) {
                    dispatch(cartActions.loadCartSuccess(_error.response.data.basket));
                }

                dispatch(cartActions.updateError(cartError));

            }

        });
    }
}

export function loadOffer(productId, quantity, history, initialLoad = false, unit,setLoading=null) {
    return function (dispatch, getState) {
        
        const state = getState();
        const zipCode = state.offer? state.offer.selectedZipCode : null;
        const INITIAL_LOAD_FAILED = 'INITIAL_LOAD_FAILED';
        dispatch(loadOfferRequest());
        dispatch(updateIsOffersAvailable(''));
        axiosRetry(axios, {retries: 2});

        if (!quantity || quantity == 0) {
            quantity = 1;
        }

        return axios
            .get(
                process.env.REACT_APP_API_BASE_PRODUCT_URL +
                    `/product/offers?productIds=${productId}${
                        quantity && quantity > 0 ? '&quantity=' + quantity : ''
                    }&channelCodes=US${unit ? '&unit='+unit : ''}${zipCode ? '&zipCode='+zipCode : ''}`,
                {
                    headers: {
                        Auth: 1212,
                    },
                    timeout: (process.env.REACT_APP_API_TIMEOUT + 2000)
                }
            ).then((result) => {

                try {

                    if (result?.data?.products[0] !== undefined) {

                        if (result?.data?.products[0]?.description !== undefined) {
                            document.title = 'Buy Online: ' + result?.data?.products[0]?.description;
                            if (document.querySelector('meta[name="description"]')) {
                                document.querySelector('meta[name="description"]').setAttribute('content', 'Fast and easy purchasing from the brand you know and trust. Shop the 3M Marketplace today for ' + result?.data?.products[0]?.description);
                            }

                            // add canoncial tag
                            const canonical = !!document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
                            canonical.setAttribute('rel', 'canonical');
                            canonical.setAttribute('href', location.protocol + '//' + location.host + location.pathname);
                            document.head.appendChild(canonical);

                            if (result?.data?.products[0].offerAggregates !== undefined) {
                                const schemaData = {
                                    "@context": "https://schema.org",
                                    "@type": "Product",
                                    "name": result?.data?.products[0]?.description,
                                    "sku": result?.data?.products[0].id,
                                    "brand": {
                                        "@type": "Brand",
                                        "name": "3M"
                                    },
                                    "offers": {
                                        "@type": "AggregateOffer",
                                        "url": "https://ui.mkpl.3m.com/buyingoptions/" + result?.data?.products[0].id,
                                        "offerCount": parseInt(result?.data?.products[0].offers?.length),
                                        "lowPrice": result?.data?.products[0].offerAggregates[0].lowPrice,
                                        "highPrice": result?.data?.products[0].offerAggregates[0].highPrice,
                                        "priceCurrency": "USD"
                                    },
                                    "image": [
                                      "https://" + result?.data?.products[0].imgURL
                                    ]
                                };

                                const script = document.createElement('script');
                                script.type = "application/ld+json";
                                script.innerHTML = JSON.stringify(schemaData);
                                document.getElementsByTagName('head')[0].appendChild(script);

                            }

                        }

                    }

                } catch (e) {

                    !!window.decibelInsight && window.decibelInsight(
                        'sendApplicationError',
                        'DSP: Schema injection issue - ' + (e?.message ? e?.message : 'An error occurred')
                    );

                }

                if (initialLoad) {
                    const resultData = JSON.parse(JSON.stringify(result.data));                     
                    if(result.data.products[0].offers.length == 0){                        
                      dispatch(updateIsOffersAvailable(INITIAL_LOAD_FAILED));
                    } else {

                      const offers = result.data.products[0].offers.filter(offer => offer.onHandQuantity >= quantity);
                      result.data.products[0].offers = offers.length > 0 ?  offers : [];

                        if (offers.length > 0) {
                            // Sort offers based on their displayUnit
                            result.data.products[0].offers.sort(function(a, b) {
                              if (a.displayUnit === result.data.products[0].lowUom) {
                                return -1;
                              }
                              if (a.displayUnit === result.data.products[0].highUom) {
                                if (b.displayUnit !== result.data.products[0].lowUom) {
                                  return -1;
                                } else {
                                  return 1;
                                }
                              }
                              return 1;
                            });
                        }


                      dispatch(loadOfferAggregates(resultData)); 
                    }

                } else {                    
                    if(result.data.products[0].offers.length == 0){
                        dispatch(updateOfferAvailabilityMessage(state, quantity, unit));  
                    } else {
                        dispatch(updateOfferAggregates(result.data));
                    }              
                }
               
                dispatch(loadOfferSuccess(result.data));
                dispatch(updateNetworkErrorStatus(false));    
                !!setLoading?setLoading(false):null;

            }).catch((_error) => {   

                console.log("Error occured during loadOfferAPI call "+_error);   

                !!setLoading?setLoading(false):null;
                dispatch(removeAllOffer());

                if( !!_error.response && _error.response.status == 404 && !!unit) {

                    dispatch(updateOfferAvailabilityMessage(state, quantity, unit));                

                    !!window.decibelInsight && window.decibelInsight(
                      'sendApplicationError',
                      'DSP: Offer not available - ' + (_error?.message ? _error?.message : 'An error occurred')
                    );

                } else {

                    !!window.decibelInsight && window.decibelInsight(
                      'sendApplicationError',
                      'DSP: Offer load issue - ' + (_error?.message ? _error?.message : 'An error occurred')
                    );

                    if (_error.message === "Network Error") {
                        dispatch(updateNetworkErrorStatus(true));
                    } else if(history){
                        history.push("/error");
                    }

                } 

            });
    };
}

function updateOfferAvailabilityMessage(state, quantity, unit) {
    return function (dispatch ) {
    const FAILED_WITH_UNIT = 'FAILED_WITH_UNIT';
    const FAILED_WITH_UNIT_AND_QUANTITY_GREATER_THAN_ONE = 'FAILED_WITH_UNIT_AND_QUANTITY_GREATER_THAN_ONE';
    const offerAggregateTabs = state.offer ? state.offer.offerAggregateTabs : [];
    let maxQty = 0;
    offerAggregateTabs.forEach((offerAgg) => {
        if (offerAgg.unit === unit) {
            maxQty = offerAgg.maxQty;
            return;
        }
    })
    if (quantity == 1) {
        dispatch(updateIsOffersAvailable(FAILED_WITH_UNIT));
    } else {
        if ( !maxQty || maxQty <= 0) {
            dispatch(updateIsOffersAvailable(FAILED_WITH_UNIT));
        } else {
            dispatch(updateIsOffersAvailable(FAILED_WITH_UNIT_AND_QUANTITY_GREATER_THAN_ONE));
        }
    } 
  }
}

export function syncUpsellProducts () {
    return function (dispatch, getState) {
        const state = getState();
        let upsellSkus = [];
        const cartLineItems = getState().cart.cart.lineitem;
        const upsellProducts = state.offer ? state.offer.upsellProducts : [];

        // keep track of the distributors we show'd up sells for
        const addedUpsellForDistributor = [];

        cartLineItems.forEach((lineItem) => {
            if (lineItem.product.upsellSkus) {
                upsellSkus = lineItem.product.upsellSkus.split(",");

                // only show one upsell per distributor
                // eventually we may want to group these together under the same header
                if(!addedUpsellForDistributor.includes(lineItem.offer.distributor.name)) {

                    // store the name of the distributor we show a set of upsells for
                    addedUpsellForDistributor.push(lineItem.offer.distributor.name);

                    upsellProducts.forEach((product) => {
                        const index = upsellSkus.findIndex((sku) => sku === product.id);
                        if (index >= 0) {
                            upsellSkus.splice(index,1);
                        }
                    });

                    upsellSkus.forEach((productId) => {
                        dispatch(loadUpsellOffers(productId, cartLineItems));
                    });

                }
            }
        });
    }
}

export function loadUpsellOffers (productId, cartLineItems) {
    return function (dispatch) {
        return axios
            .get(
                process.env.REACT_APP_API_BASE_PRODUCT_URL +
                `/product/offers?productIds=${productId}&quantity=1&channelCodes=US`,
                {
                    withCredentials: true,
                    headers: {
                        Auth: 1212,
                    },
                    timeout: (process.env.REACT_APP_API_TIMEOUT + 500)
                }
            ).then((result) => {
                if (result?.data?.products[0] !== undefined) {
                    if (result.data.products[0].id === productId) {
                        const offers = result.data.products[0].offers;
                        if (offers.length > 0) {                          
                            cartLineItems.forEach((cartLineItem) => {
                                const index = offers.findIndex((offer) => offer.id === cartLineItem.offer.id);
                                index != -1 ? offers.splice(index, 1) : null;
                            });  
                                                  
                            if (offers.length > 0) {
                                result.data.products[0].offers = offers;
                                dispatch(loadUpsellOfferSuccess(result.data));
                            }
                       }
                    }
                }                
            }).catch((error) => {
                if( !!error.response && error.response.status == 404){                    

                    !!window.decibelInsight && window.decibelInsight(
                      'sendApplicationError',
                      'DSP: Offer for upsell products not available - ' + (_error?.message ? _error?.message : 'An error occurred')
                    );

                  } else {

                    !!window.decibelInsight && window.decibelInsight(
                      'sendApplicationError',
                      'DSP: Offer load issue for upsell products - ' + (_error?.message ? _error?.message : 'An error occurred')
                    );

                  }
            });
    };
}