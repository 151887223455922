import React from 'react';
import PropTypes from 'prop-types';

import {MdsHeader1} from '../../mds';

import styles from './header.module.scss';

const ViewHeader = (props) => {
  return (
    <header className={styles.view_header}>
      <section className={styles.view_header__primary}>
        <MdsHeader1 className={styles.view_header__title}>{props.title}</MdsHeader1>
        <div className={styles.view_header__primary_content}>{props.children}</div>
      </section>
      <section className={styles.view_header__actions}>
        {props.ctaContent}
      </section>
    </header>

  );
};

ViewHeader.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
  ctaContent: PropTypes.any,
}

export default ViewHeader;
