/* eslint-disable max-lines */
import React,{useEffect, useState, useRef} from 'react';
import styles from './DistributorCard.module.scss';
import { Link } from 'react-router-dom';
import { Button } from '@3mcom/mds-library/dist/index';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, selectOffer, selectEstimatedDeliveryOption } from '../../../../redux/actions/offerActions.js';
import { useTranslation } from 'react-i18next';
import { checkoutEvent } from '../../../../redux/actions/trackingActions.js';
import {currencyFormatter, getBackOrderQty } from '../../../common/utlities';
import {getRefinements} from '../../Service';

import ImageContainer from '../../../common/images/image-container';
import ReactTooltip from 'react-tooltip';
import {excludePromoInternalDesc} from '../../../../data/ExcludePromotions';
import Select from 'react-select';
import ContentLoader from '../../../../../public/images/moving-train-loader.gif';
import AddedToCartModal from '../../../cart/addToCart/AddedToCartModal';

const DistributorCard = ({
    offer,
    selectedOffer,
    bestCustomerScore,
    adtlDistributor,
    sku,
    zipCodeSelected,
    loading,
    isWeekEnd
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    let tooltipReference = null;
    const promotionsDescription = [];
    const [isMobile, setMobile] = useState(window.innerWidth < 768);    
    const [showSellerDesc, setShowSellerDesc] = useState(false);
    const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);
    const childRef = useRef(null);

    const updateMedia = () => {
        setMobile(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });

    // Buy now button variables
    // const selectedPrice = useSelector((state) => state.offer.selectedPrice);
    const selectedQuantity = useSelector((state) =>
        state.offer.selectedQuantity.find(
            (x) => x.unit === offer.displayUnit
        )
    );   

    const product = useSelector((state) => state.offer.products[0]);
    product.unit = selectedOffer.displayUnit;

    // const subTotalAmount = selectedQuantity
    //     ? multiplyBD(selectedQuantity.quantity, selectedPrice)
    //     : 0;

    const refinements = getRefinements();

    // allow the first offer to be selected if there is only one offer available
    if (selectedOffer) {
        dispatch(selectOffer(offer, offer.price));
    }
    if (offer?.promotions) {
        offer.promotions = (offer.promotions || []).filter(
            (promotion) =>
                promotion?.internalDescription != excludePromoInternalDesc
        );
    }
    if (offer.promotions) {
        if(offer.promotions.length > 0) {
            for (let i = 0; i < offer.promotions.length; i++) {
                const allDescription = offer.promotions[i].publicDescriptions;
                let description;
                if (allDescription) {
                    description = allDescription.find(
                        (data) => data.locale === 'en_US'
                    ).value;
                }
                promotionsDescription.push(description);
            }
        }
    }
    const tooltipContent = () => {
        return (
            <div>
                <span>Promotion</span>
                <p
                    onClick={() => {
                        tooltipReference.tooltipRef = null;
                        ReactTooltip.hide();
                    }}
                >
                    X
                </p>
                    <ul id="promotionList">
                    {promotionsDescription.map((promotion) => {
                            return (
                                <li key={promotion}>
                                        {promotion}
                                </li>
                            );
                        })}
                    </ul>
            </div>
        );
    };

    // shipping cost - dropdown

    const customSelectStyles = {
        dropdownIndicator: base => ({
          ...base,
          color: "red", 
          padding: "2px",
        }),
        option: (base, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...base,
              paddingLeft:5,
              paddingRight:5,
            };
          },  
        valueContainer: base => ({
            ...base,
            padding: "2px 3px",
          }),      
        menu: base => ({
            ...base,
            marginTop: 1.5,
          }),
        menuList: (provided, state) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom:0,
         }),
    };

    const formatOptionLabel = ({ priceLabel, methodLabel, dateLabel }, { context }) => {
        if (context === "value") {
          return <div style = {{display:'flex',flexWrap: 'wrap',justifyContent: !loading ? 'flex-start':'space-between',fontSize: '14px'}} >
          {!!priceLabel.trim() && !loading ? <div style = {{fontWeight:'bold',paddingRight: '10px'}}>{priceLabel}</div> : null }
          <div style = {{paddingRight:'10px'}}>{methodLabel}</div>
          <div>{ !!priceLabel ? 
              !loading ? 
              <span style = {{fontWeight:'bold'}}>{dateLabel}</span>                            
              :                                          
              <img src={ContentLoader} alt="loading price..."></img>
              : null
              }
          </div>
      </div>
        } else if (context === "menu") {
          return (
            <div style = {{display:'flex',flexWrap: 'wrap',justifyContent: 'space-between',fontSize: '14px'}} >
                {!!priceLabel.trim() && !loading ? <div style = {{fontWeight:'bold'}}>{priceLabel}</div> : null }
                <div>{methodLabel}</div>
                <div>{ !!priceLabel ? 
                    !loading ? 
                    <span style = {{fontWeight:'bold'}}>{dateLabel}</span>                            
                    :                                          
                    <img src={ContentLoader} alt="loading price..."></img>
                    : null
                    }
                </div>
            </div>
          );
        }
      };

    const distributorName = offer.distributor.name; 
    let defaultShippingOptions = 
      [{
            distributor: distributorName,
            methodLabel: 'Ground' + ' Delivery'   ,  
            priceLabel: ' ' ,
            value: 'STD',
            price: 0.00
        },
        {
            distributor: distributorName,
            methodLabel:'2 day' + ' Delivery' ,
            priceLabel:' ' ,           
            value: 'EXP',
            price: 0.00
        },
        {
            distributor: distributorName,
            methodLabel: 'Overnight' + ' Delivery' ,            
            priceLabel:  ' ' ,
            value: 'overnight',
            price: 0.00
        }]  
    if(isWeekEnd || offer.availableQuantity - getBackOrderQty(offer) - selectedQuantity?.quantity < 0) {        
        defaultShippingOptions.splice(1,2);
    }
    if( !!offer.allowedShippingTypes && offer.allowedShippingTypes.length > 0) {        
        defaultShippingOptions = defaultShippingOptions.filter( ( opt ) => offer.allowedShippingTypes.includes( opt.value) );
    }

    const deliveryOptions = (!offer.deliveryOptions ||  offer.deliveryOptions.length === 0)? defaultShippingOptions:
    offer.deliveryOptions.map((x, index) => {                
    const options = { month: 'short', day: 'numeric' };
    const formattedDeliveryDate =  x.estimatedDate == null || x.estimatedDate == ""?
                                      x.estimatedDate : new Intl.DateTimeFormat('en-US', options).format(new Date(x.estimatedDate));
   // const isDefault = selectedDeliveryOption !== null ? selectedDeliveryOption?.shippingType === x.code : index === 0;
   return {
            distributor: distributorName,
            methodLabel: t(
                'translation.distributorPage.quantitySelector.distributorInfo.distributorLine.' +
                 x.code ) + ' Delivery' ,
            priceLabel:  '$' + x.price.toFixed(2),
            dateLabel:  ' Est. Delivery : ' + formattedDeliveryDate,
            value: x.code,
            price: x.price
        };
    });

    const onDeliveryMethodChange = (v) => {
        const deliveryOption = {
            distributorName: v.distributor,
            price: v.price,
            shippingType: v.value,
        };
        dispatch(selectEstimatedDeliveryOption(offer.id,deliveryOption));
        offer.selectedDeliveryOption = deliveryOption;      
    };

    const checkoutFunction = (event) => {  

        dispatch(selectOffer(offer, offer.price));

        dispatch(addToCart(selectedQuantity?.quantity, product, {
            ...offer,
            offerAdditionalFields: offer.offerAdditionalFields
                ? [...offer.offerAdditionalFields, {...refinements}]
                : [{...refinements}],
        }));

        if (event == 'add_to_cart') {
            dispatch(checkoutEvent('cart_add', sku, offer.price.toFixed(2), null, selectedQuantity?.quantity));
        }

    };

    const openAddedToCartModal = () => {
        if(showAddedToCartModal){
            childRef.current.popupToggle();
        }
        setShowAddedToCartModal(true);
    }

    const sellerDescriptionObject =  offer.distributor.distributorAdditionalFields && offer.distributor.distributorAdditionalFields.length >0 ?
                                         offer.distributor.distributorAdditionalFields.filter(addField =>
                                               addField.code === 'seller-description')
                                               :
                                               null;
    const sellerDescription = sellerDescriptionObject && sellerDescriptionObject.length > 0 ? sellerDescriptionObject[0].value: null;


    let cardClassName = bestCustomerScore
        ? styles.distributorCard_best
        : adtlDistributor
        ? styles.distributorCard_adtl
        : styles.distributorCard;

    if (selectedOffer && isMobile && process.env.REACT_APP_DSP_BUTTON_MOBILE != 'true') cardClassName += ` ${styles.distributorCard_selected}`;

    let availabilityClassName = '';
    switch (offer.availableQuantityDescription) {
        case 'InStock':
            availabilityClassName = styles.distributor_availability_available;
            break;
        case 'LessThan5':
            availabilityClassName = styles.distributor_availability_available;
            break;
        case 'Replenishing':
            availabilityClassName = styles.distributor_availability_replenishing;
            break;
        case 'ReplenishingMidRange':
            availabilityClassName = styles.distributor_availability_midrange;
            break;
        case 'ReplenishingMidRange2':
            availabilityClassName = styles.distributor_availability_midrange;
            break;
        case 'ReplenishingMidRange3':
            availabilityClassName = styles.distributor_availability_midrange;
            break;
        case 'ReplenishingMidRange4':
            availabilityClassName = styles.distributor_availability_midrange;
            break;
    }

    // error display 
    const errors = useSelector((state) =>
       state.offer.errors
    );
    let zipCodeTooltip = null;
    if(errors.length > 0){
        const zipCodeErrors = errors.filter(e => e.code === 500);
        if(zipCodeErrors.length> 0){        
          zipCodeTooltip = (t('translation.distributorPage.quantitySelector.distributorInfo.distributorLine.zipCodeToolTipError'));
        } else {
          zipCodeTooltip = null;
        }
    }
    if(offer.errors && offer.errors.length > 0){
        zipCodeTooltip = (t('translation.distributorPage.quantitySelector.distributorInfo.distributorLine.zipCodeToolTipError'));
    }

    if(!zipCodeSelected)
       zipCodeTooltip = (t('translation.distributorPage.quantitySelector.distributorInfo.distributorLine.zipCodeToolTipEmpty'));
        
    // Should be placed at the end once radioOptions updated 
    const selectedDeliveryOption = useSelector((state) =>{
        const offerShown = !!state.offer.products[0].offers?state.offer.products[0].offers.find(
                    (x) => x?.id === offer?.id
                ):null; 
         return !!offerShown ? offerShown?.selectedDeliveryOption: null; 
        }     
    );  
    if(!selectedDeliveryOption){
        onDeliveryMethodChange(deliveryOptions[0]);    
    }
    let selectedDeliveryOptionDropdown = deliveryOptions.find(x => x.value === selectedDeliveryOption?.shippingType);
    
    if(!selectedDeliveryOptionDropdown) {
        onDeliveryMethodChange(deliveryOptions[0]);
        selectedDeliveryOptionDropdown = deliveryOptions[0];
    }

    let imgId = null;
    if (product?.imgURL) {
        imgId = product?.imgURL.match(/\/([A-Za-z0-9]+)[A-Z]\?/)[1];
    }

    const tealiumAddToCartObj = {
        event_name: 'mds-button Click: Add to cart',
        event_type: "Engagement Event",
        product_sku: sku,
        product_name: product?.description,
        mkpl_product_img_id: imgId,
        mkpl_product_qty: selectedQuantity?.quantity,
        mkpl_product_variant_sku: product?.variantId
    };

    const tealiumBuyNowObj = {
        event_name: 'mds-button Click: Buy now',
        event_type: "Engagement Event",
        product_sku: sku,
        product_name: product?.description,
        mkpl_product_img_id: imgId,
        mkpl_product_qty: selectedQuantity?.quantity,
        mkpl_product_variant_sku: product?.variantId
    };

    return (
        <div
            className={cardClassName}
        >
            {showAddedToCartModal && 
              <AddedToCartModal product = {product} offer = {offer} quantity = {selectedQuantity.quantity} ref = {childRef} />
            }
            {bestCustomerScore && (
                <div
                    className={`mds-font_body ${styles.distributorCard_best_flag}`}
                    aria-hidden={true}
                >
                   <span style={{lineHeight:'26px'}}> Best Customer Score </span>
                </div>
            )}
            <div style={{display:showSellerDesc ? '':'none'}} id={'popupDiv'+ offer.id}>
                <ImageContainer
                    className={styles.distributor_sellerImage}
                    src={offer.distributor.imgURL}
                />
                <hr className={styles.distributor__line}/>
                <div className={styles.distributor_sellerDesc}>
                    {sellerDescription}
                </div>
                <hr className={styles.distributor__line}/>
                <div className={styles.distributor_button}>
                    <Button
                        onClick={() => {
                           setShowSellerDesc(false);
                        }}
                        id="popupClose"
                        buttonType="tertiary"
                        size="medium"
                    >
                        {t(
                        'translation.distributorPage.quantitySelector.distributorInfo.distributorLine.closeBtn'
                        )}
                    </Button>
                </div>
            </div> 
            <div id ={'cardDiv'+ offer.id} style={{display:showSellerDesc ? 'none':''}}>
                <div className={styles.distributor_availability_container}>
                 <div className={availabilityClassName}>
                    {t(
                        'translation.distributorPage.quantitySelector.distributorInfo.distributorLine.' +
                            offer.availableQuantityDescription
                    )}
                  </div>
                </div>
                <div className={styles.distributor_details} >
                    <div datatestid="distributor" className={styles.distributor}>
                        <ImageContainer
                            className={styles.distributor_image}
                            src={offer.distributor.imgURL}
                        />
                        {!!sellerDescription ? (
                            <div className={styles.distributor_name_link}>
                                <a
                                    id={'link'+ offer.id}                                   
                                    onClick={() => {
                                        setShowSellerDesc(true);
                                    }}
                                >
                                {offer.distributor.name}
                                </a>
                            </div>
                            ) : (
                            <div className={styles.distributor_name}>
                                {offer.distributor.name}
                            </div>)
                        }   
                        <div className={styles.distributor_additionalInfo}>
                           {offer.priceAdditionalInfo}
                        </div>
                    </div>
                    <div className={styles.distributor_offer}>
                        
                        <div className={styles.distributor_offer_price}>
                            <div className={`mds-font_header--5`}>
                                {currencyFormatter.format(
                                    offer.price ? offer.price : 0
                                )}
                                /{offer.displayUnit.toLowerCase()}
                            </div>
                        </div>
                        <div className={styles.distributor_offer_discounts}>
                            {offer.volumePriceOriginal && (
                                <div className={styles.distributor_offer_discounts_wrapper}>
                                    <span className={styles.distributor_offer_discounts_price}>{currencyFormatter.format(
                                            offer.volumePriceOriginal
                                        )} 
                                    </span> 
                                    <span className={styles.distributor_offer_discounts_percentage}>
                                    {offer.volumePricePercentage} 
                                    </span>                             
                                </div>
                            )}
                            {offer.applicableVolumePricing && offer.applicableVolumePricing.length > 0 && (
                                <div className={styles.distributor_offer_discounts_list}>
                                  {offer.applicableVolumePricing.slice(0,3).map((volumeOptions,index) => {
                                    return (
                                       <p key={index}>
                                           <span className={styles.distributor_offer_discounts_options}>{volumeOptions}</span>
                                        </p>
                                    );
                                  })}                              
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.distributor_promo}>
                    <div className={styles.distributor_additionalInfo_mobile}>
                           {offer.priceAdditionalInfo}
                    </div>
                </div>
                    { promotionsDescription.length > 0 ? (
                        <div className={styles.distributor_promotion}>
                            <ReactTooltip
                                uuid={'promotionTip'}
                                id="promotionTip"
                                border={true}
                                globalEventOff={'click'}
                                borderColor={'#4b4b4b'}
                                backgroundColor={'#4b4b4b'}
                                arrowColor={'#4b4b4b'}
                                clickable={true}
                                className={styles.tooltip}
                                ref={ref => tooltipReference = ref}
                            >{tooltipContent()}</ReactTooltip>
                            <a
                                className={styles.distributor_promo_link}
                                data-tip
                                data-event={'click'}
                                data-for="promotionTip"
                            >
                                {t(
                                    `translation.distributorPage.promotion.promotionAvailable`
                                )}
                            </a>
                        </div>
                    ) : null}
                <hr className={styles.distributor__line}/>
                {!zipCodeTooltip ?null: 
                    <div className={styles.distributor__alert}>
                        <i className="MMM--icn MMM--icn_information"></i>
                       { zipCodeTooltip }
                    </div>
                }
                <div className={styles.distributor_selectContainer}>
                 <Select
                    className={styles.distributor_reactSelect}
                    classNamePrefix="distributor_reactSelect" 
                    placeholder="Shipping Methods"
                    value={selectedDeliveryOptionDropdown}
                    components={{IndicatorSeparator:() => null }}
                    styles={customSelectStyles}
                    options={deliveryOptions}
                    onChange={(value) => {
                        onDeliveryMethodChange(value);
                    }}
                    isSearchable={false}
                    formatOptionLabel={formatOptionLabel}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary:'black'
                        },
                    })}
                  />                
                </div> 
                
                {!isMobile || (isMobile && process.env.REACT_APP_DSP_BUTTON_MOBILE == 'true') ? <div className={styles.distributor_buttons}>
                    
                    <div>
                        {/* <Link
                            to={{
                                pathname: '/addedToCart',
                                state: { addedItem: product },
                            }}
                        > */}
                            <Button
                                onClick={() => {
                                    checkoutFunction('add_to_cart');
                                    openAddedToCartModal();
                                }}
                                buttonType="secondary"
                                size="medium"
                                tealiumObj={tealiumAddToCartObj}
                                className = {styles.distributor_buttons_custom}
                            >
                                {t(
                                    `translation.distributorPage.subTotalFooter.addToCart`
                                )}
                            </Button>
                        {/* </Link> */}
                    </div>
                    <div>
                        <Link to={{pathname: '/checkout'}}>
                            <Button
                                buttonType="primary"
                                size="medium"
                                tealiumObj={tealiumBuyNowObj}
                                onClick={() => {
                                    checkoutFunction('buy_now');
                                }}
                                className = {styles.distributor_buttons_custom}
                            >
                                {t(`translation.distributorPage.subTotalFooter.buyNow`)}
                            </Button>
                        </Link>
                    </div>
                </div> : null } 
            </div>    
        </div>
    );
};

DistributorCard.propTypes = {
    offer: PropTypes.object.isRequired,
    selectedOffer: PropTypes.bool.isRequired,
    bestCustomerScore: PropTypes.bool,
    adtlDistributor: PropTypes.bool,
    addToCart: PropTypes.func,
    sku: PropTypes.string,
    zipCodeSelected: PropTypes.string,
    loading: PropTypes.bool,
    isWeekEnd:PropTypes.bool,
};

export default DistributorCard;
