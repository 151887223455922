import React from 'react';
import PropTypes from 'prop-types';

const generateHelpText = (props) => {
    const {helpText} = props.fields;
    const {uniqueID} = props;
    if (helpText && helpText !== '') {
        return (
            <label
                htmlFor={uniqueID}
                className="mds-form_elementLabel--helpText"
                id={`${uniqueID}-helpText`}
            >
                {helpText}
            </label>
        );
    }
    return null;
};

generateHelpText.propTypes = {
    uniqueID: PropTypes.string,
    fields: PropTypes.object,
};

export default generateHelpText;
