import React, {useState, useEffect,Suspense} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {signInUser} from '../../redux/actions/signInActions';
import PropTypes from 'prop-types';
import * as UserDefinitions from '../common/userSettings/userDefinitions.js';
import {getAuthLoggedId} from '../../connectivity/cookie';
import Loader from '../loader/loader';


const RequireUser = (props) => {
  const [access, setAccess] = useState(false);
  const [check, setCheck] = useState(false);
  const user = useSelector((state) => state.signIn);

  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if(!!props && !!props?.isLoading) {
      setLoading(true);
    }

    if (!user) {
      setAccess(false)
      return
    }

    if (user.vsrmId) { setAccess(true) }

    if (user.signInType === UserDefinitions.GUEST) {
      try {
        if (props.children.props.location.pathname === '/orderDetails') {
          setCheck(true);
          setAccess(true)
        }
      } catch (err) { }
    } else {

      if (!user.vsrmId && !check) {
        setCheck(true);
        dispatch(signInUser(getAuthLoggedId()))
      }
      if (!user.vsrmId && check) setAccess(false);
    }
  }, [user]);

  return (
    <>
    {isLoading? <Loader isLoading={isLoading} />: null}
    <Suspense fallback={<div>Loading...</div>}>
    <>
      {access ? props.children : ''}
    </>
    </Suspense>
    </>
  )
}

RequireUser.propTypes = {
  children: PropTypes.any,
  isLoading: PropTypes.bool,
}

export default RequireUser;
