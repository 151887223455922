/* eslint-disable max-len */
/* eslint-disable max-lines */
import React, { Component } from 'react';
import ContactInformation from '../common/contactInformation';
import * as UserDefinitions from '../common/userSettings/userDefinitions.js';
import { connect } from 'react-redux';
import {
    updateShippingAddress,
    setNewAddressSelected,
    setNewEmptyAddressSelected
} from '../../redux/actions/selectedAddressActions.js';
import { addNewGuest } from '../../redux/actions/signInActions.js';
import { tealiumLink, tealiumView } from '../../redux/actions/trackingActions.js';
import PropTypes from 'prop-types';

import ShippingAddress from '../forms/shippingAddressForm';
import ShippingAddressButton from '../common/shippingAddressButton';
import AddAddressButton from '../common/addAddressButton';
import * as shippingParameters from './shippingParameters';
import EmailValidator from 'email-validator';
import { MdsHeader4 } from '../mds';
import ContentBox from '../containers/contentBox';
import styles from './editShipping.module.scss';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import * as shippingAddressValidator from '../../redux/actions/shipping/shippingAddressValidator';
import * as PurchaseStates from '../../redux/reducers/reducer-helpers/purchaseStates';
import { isPOBoxAddress } from '../common/utlities';
import { getShippingAddress } from '../../connectivity/localStorage';

export class EditShipping extends Component {
    constructor(props) {
        super(props);
        this.showAddressForm = false;
        const userData = {
            userAddressList: this.props.userShippingAddress,
            userEmail: this.props.userEmail,
        };

        let address = shippingParameters.createBlankShippingAddress();
        address = {
            ...address,
            email: this.props.userEmail
        }

        if (this.props.isForBilling && this.props.isSameBillingChecked === true) {
            address = { ...this.props.shippingAddress };
        } else if (this.props.isForBilling && this.props.billingAddress != null) {
            address = { ...this.props.billingAddress };
        } else if (!this.props.isForBilling && this.props.shippingAddress !== null) {
            address = { ...this.props.shippingAddress };
        }

        const _newAddressSelected = this.newAddressSelected();
        if (_newAddressSelected)
            this.showAddressForm = true;
        if (!!getShippingAddress() && this.props.shippingAddress === null && this.props.isForBilling !== true) {
            try {
                if (this.props.userType === UserDefinitions.GUEST) {
                    address = { ...JSON.parse(getShippingAddress()) };
                } else if (this.props.userType === UserDefinitions.REGISTERED_USER) {
                    address = {
                        ...JSON.parse(getShippingAddress()),
                        email: userData.userEmail,
                    };
                    this.showAddressForm = true;
                    const streetAddress1 = address.street1;
                    const validAddress = shippingAddressValidator.validate(address);
                    const isPOAddress = !this.poAddressValidationPropUpdate(streetAddress1);
                    this.props.updateShippingAddress(
                        this.props.isForBilling,
                        address,
                        validAddress && isPOAddress,
                        true,
                        -1
                    );
                }
            }
            catch (err) {
                console.log(err);
            }
        }
        if (
            this.props.userType === UserDefinitions.REGISTERED_USER &&

            userData.userAddressList && userData.userAddressList.length > 0
        ) {
            if ((!getShippingAddress() && this.props.isForBilling != true) || this.props.isForBilling === true) {
                let selectedUserAddress;
                let newAddressSelected = this.newAddressSelected();
                let selectedAddressIndex = this.selectedAddressIndex();
                let update = false;
                if (this.props.isForBilling === true && this.props.isSameBillingChecked === true
                    && this.props.selectedUserAddressIndex === -1) {
                    update = true;
                    newAddressSelected = true;
                    selectedAddressIndex = -1
                    selectedUserAddress = this.props.shippingAddress;
                    address = {
                        ...this.props.shippingAddress,
                        email: userData.userEmail,
                    };
                }
                else if (!_newAddressSelected) {
                    update = true;
                    selectedUserAddress =
                        userData.userAddressList[this.selectedAddressIndex()];
                    address = {
                        ...selectedUserAddress,
                        email: userData.userEmail,
                        id: String(selectedUserAddress.id),
                    };
                }
                if (update) {
                    const streetAddress1 = selectedUserAddress.street1;
                    const isPOAddress = !this.poAddressValidationPropUpdate(streetAddress1);
                    this.props.updateShippingAddress(
                        this.props.isForBilling,
                        address,
                        isPOAddress,
                        newAddressSelected,
                        selectedAddressIndex
                    );
                }

            }

        }

        if (this.props.userType === UserDefinitions.GUEST) {
            const validAddress = shippingAddressValidator.validate(
                address
            );
            const _newAddressSelected = this.newAddressSelected();
            const _selectedAddressIndex = this.selectedAddressIndex();
            this.props.updateShippingAddress(
                this.props.isForBilling,
                address,
                validAddress,
                _newAddressSelected,
                _selectedAddressIndex
            );
            const email = address.email;
            if (EmailValidator.validate(email) && !this.props.isForBilling) {
                this.props.addNewGuest(email);
            }
        }

        this.state = {
            userData: userData,
            userAddress: address,
            formHasError: true,
            baseTranslationKey: this.props.isForBilling ? 'translation.editBilling' : 'translation.editShipping',
            emailAlreadyExists: this.props.isForBilling ? true : false,
        };

        if (this.props.userType === UserDefinitions.REGISTERED_USER &&
            !(userData.userAddressList && userData.userAddressList.length > 0)) {
            if ((!getShippingAddress() && this.props.isForBilling != true) || this.props.isForBilling === true) {
                this.handleAddAddressButtonClick();
            }
        }

    }

    selectedAddressIndex() {
        return this.props.isForBilling ?
            this.props.selectedBillingAddressIndex
            : this.props.selectedUserAddressIndex;
    }

    newAddressSelected() {
        return this.props.isForBilling ?
            this.props.newBillingAddressSelected
            : this.props.newUserAddressSelected;
    }

    componentDidMount() {
        
        const { purchaseState } = this.props;

        if (purchaseState !== PurchaseStates.CART && this.props.userType === UserDefinitions.UNDEFINED) {

            // if we are a first time user that is checking out we get redireted to the login page
            // so we don't need to track this otherwise we end up with two events
            // the login page will track it

        } else {

            if (purchaseState === PurchaseStates.CHECKOUT_EDIT_SHIPPING) {
                this.props.tealiumView({
                    event_name: 'View Checkout ' + this.props.isForBilling ? 'Billing' : 'Shipping',
                    funnel_stage: purchaseState
                })
            }

        }

    }

    evaluateError(hasError, email) {
        if (this.props.userType === UserDefinitions.REGISTERED_USER) {
            return hasError;
        }
        const validEmail = EmailValidator.validate(email);
        return hasError || (!this.props.isForBilling && !validEmail); // don't check email for billing address
    }

    formChangeEvent(field, value, hasError) {
        if (Object.keys(this.state.userAddress).includes(field)) {
            const newAddress = this.state.userAddress;
            newAddress[field] = value;
            const shippingHasError = this.evaluateError(
                hasError,
                this.state.userAddress.email
            );
            const _newAddressSelected = this.newAddressSelected();
            const _selectedAddressIndex = this.selectedAddressIndex();
            this.props.updateShippingAddress(
                this.props.isForBilling,
                newAddress,
                !shippingHasError,
                _newAddressSelected,
                _selectedAddressIndex
            );
            this.setState({
                userAddress: newAddress,
                formHasError: hasError,
            });
        }
    }

    contactInfoChangedEvent(value) {
        const newAddress = { ...this.state.userAddress };
        newAddress['email'] = value;
        const hasError = this.evaluateError(
            this.state.formHasError,
            value
        );

        this.props.updateShippingAddress(
            this.props.isForBilling,
            newAddress,
            !hasError,
            this.newAddressSelected(),
            this.selectedAddressIndex()
        );
        this.setState({
            userAddress: newAddress,
        });
    }

    poAddressValidationPropUpdate(streetAddress1) {
        if (!this.props.isForBilling && isPOBoxAddress(streetAddress1.trim())) {
            return true;
        } else {
            return false;
        }
    }

    handleAddressButtonClick(index) {
        const { tealiumLink, updateShippingAddress, purchaseState } = this.props;
        const { userData } = this.state;

        const eventName = this.props.isForBilling ? 'Billing Address Selection' : 'Shipping Address Selection';
        tealiumLink({ event_name: eventName, funnel_stage: purchaseState });
        this.showAddressForm = false;
        const selectedUserAddress = this.selectedAddressIndex();
        if (index === selectedUserAddress) {
            return;
        }

        const newAddress = {
            ...userData.userAddressList[index],
            firstName: userData.userAddressList[index].firstName,
            lastName: userData.userAddressList[index].lastName,
            email: userData.userEmail,
            id: String(userData.userAddressList[index].id),
        };
        const streetAddress1 = newAddress.street1;
        const isPOAddress = !this.poAddressValidationPropUpdate(streetAddress1);
        updateShippingAddress(
            this.props.isForBilling,
            newAddress,
            isPOAddress,
            false,
            index
        );
        if (!this.props.isForBilling && this.props.isSameBillingChecked) {
            updateShippingAddress(
                !this.props.isForBilling,
                newAddress,
                isPOAddress,
                false,
                index
            );
        }
        this.setState({
            userAddress: newAddress,
        });
    }

    handleAddAddressButtonClick() {
        const { tealiumLink, purchaseState, setNewAddressSelected, setNewEmptyAddressSelected } = this.props;
        tealiumLink({
            event_name: 'Add New ' + (this.props.isForBilling ? 'Billing' : 'Shipping')
                + ' Address', funnel_stage: purchaseState
        });
        this.showAddressForm = !this.showAddressForm;

        let address = null;
        if (!this.props.isForBilling) {
            setNewAddressSelected(this.props.isForBilling);
            address = {
                ...shippingParameters.createBlankShippingAddress(),
                email: this.state.userData.userEmail,
            };
        } else {
            const userAddressList = this.state?.userData?.userAddressList ? this.state?.userData?.userAddressList : [];
            if (!!userAddressList && userAddressList.length > 0) {
                address = {
                    ...shippingParameters.createBlankShippingAddress(),
                    email: this.state.userData.userEmail,
                };
                setNewEmptyAddressSelected(this.props.isForBilling);
            } else if (this.props.isSameBillingChecked) {
                setNewAddressSelected(this.props.isForBilling);

                address = {
                    ...this.props.shippingAddress,
                    email: this.state.userData.userEmail,
                };
            }
            this.showAddressForm = true
        }
        this.setState({
            userAddress: address,
        });
    }

    onAddressVerification(selectedAddress) {
        const _newAddressSelected = this.newAddressSelected();
        const _selectedAddressIndex = this.selectedAddressIndex();
        const newAddress = {
            ...this.props.shippingAddress,
            street1: selectedAddress.street1,
            street2: selectedAddress.street2,
            city: selectedAddress.city,
            state: selectedAddress.state,
            zipCode: selectedAddress.zipCode,
        };
        this.props.updateShippingAddress(
            this.props.isForBilling,
            newAddress,
            true,
            _newAddressSelected,
            _selectedAddressIndex
        );
        this.setState({
            userAddress: newAddress,
        });
        if (this.props.onAddressVerification) this.props.onAddressVerification();
    }

    buildGuestControls() {
        const editAddressControls = [];
        const { baseTranslationKey } = this.state;
        const address = this.state.userAddress;
        if (!this.props.isForBilling) {
            editAddressControls.push(

                <ContactInformation
                    key={0}
                    handleContactInfo={this.contactInfoChangedEvent.bind(this)}
                    contactEmail={address['email']}
                    emailDisabled={this.props.hasUserId}
                />
            );
        }
        editAddressControls.push(
            <ContentBox
                key={1}
                title={i18n.t(`${baseTranslationKey}.title`)}
                className={styles.edit_shipping__shipping_address}
            >
                <ShippingAddress
                    key={1}
                    formData={address}
                    onChange={this.formChangeEvent.bind(this)}
                    onAddressVerification={this.onAddressVerification.bind(this)}
                    isNewAddressSelected={true}
                    isForBilling={this.props.isForBilling}
                />
            </ContentBox>
        );
        return editAddressControls;
    }

    buildRegisteredUserControls() {
        const editAddressControls = [];
        const addressFormControls = [];
        const { baseTranslationKey } = this.state;
        const userAddressList = this.state.userData.userAddressList ? this.state.userData.userAddressList : [];
        userAddressList.map((userAddress, index) => {
            const _selectedAddressIndex = this.selectedAddressIndex();
            const selected = index === _selectedAddressIndex;
            let isPOAddress = false;
            if (selected) {
                const streetAddress1 = userAddress.street1;
                isPOAddress = this.poAddressValidationPropUpdate(streetAddress1);
            }
            return editAddressControls.push(
                <ShippingAddressButton
                    key={userAddress.id}
                    address={userAddress}
                    selected={selected}
                    index={index}
                    handleAddressButtonClick={this.handleAddressButtonClick.bind(
                        this
                    )}
                    isPOAddress={isPOAddress}
                />
            );
        });
        const _newAddressSelected = this.newAddressSelected();
        if (!_newAddressSelected) {
            editAddressControls.push(
                <AddAddressButton
                    key={userAddressList.length}
                    handleAddAddressButtonClick={this.handleAddAddressButtonClick.bind(
                        this
                    )}
                />
            );
        }
        addressFormControls.push(
            <ContentBox
                key={userAddressList.length}
                title={i18n.t(`${baseTranslationKey}.title`)}
                selected
            >
                <ShippingAddress
                    key={userAddressList.length}
                    formData={this.state.userAddress}
                    onChange={this.formChangeEvent.bind(this)}
                    onAddressVerification={this.onAddressVerification.bind(this)}
                    isNewAddressSelected={_newAddressSelected}
                    isForBilling={this.props.isForBilling}
                />
            </ContentBox>
        );

        return this.props.isForBilling ? (
            <div>
                {editAddressControls}
                {this.showAddressForm ? addressFormControls :
                    <div className={styles.hideAddressForm}>{addressFormControls}</div>}
            </div>
        ) : (
            <ContentBox title={i18n.t(`${baseTranslationKey}.title`)}>
                {editAddressControls}
                {this.showAddressForm ? addressFormControls :
                    <div className={styles.hideAddressForm}>{addressFormControls}</div>}
            </ContentBox>
        );
    }

    render() {
        let editAddressControls;
        const { baseTranslationKey } = this.state;
        if (this.props.userType === UserDefinitions.GUEST) {
            editAddressControls = this.buildGuestControls();
        } else {
            editAddressControls = this.buildRegisteredUserControls();
        }

        return (
            <div className={styles.edit_shipping}>
                <MdsHeader4 className={styles.edit_shipping__header}>
                    {this.props.isForBilling ? (<Trans
                        i18nKey={`${baseTranslationKey}.shippingInfo.shipping`}
                    >
                        Billing
                    </Trans>) : (
                        <Trans
                            i18nKey={`${baseTranslationKey}.shippingInfo.shipping`}
                        >
                            Shipping
                        </Trans>
                    )}
                </MdsHeader4>
                {editAddressControls}
            </div>
        );
    }
}

EditShipping.propTypes = {
    updateShippingAddress: PropTypes.func,
    setNewAddressSelected: PropTypes.func,
    setNewEmptyAddressSelected: PropTypes.func,
    addNewGuest: PropTypes.func,
    tealiumLink: PropTypes.func,
    tealiumView: PropTypes.func,
    userType: PropTypes.string,
    shippingAddress: PropTypes.shape({
        email: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
        zipCode: PropTypes.string,
        phoneNumber: PropTypes.string,
        ext: PropTypes.string,
    }),
    billingAddress: PropTypes.shape({
        email: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
        zipCode: PropTypes.string,
        phoneNumber: PropTypes.string,
        ext: PropTypes.string,
    }),
    userShippingAddress: PropTypes.array,
    userEmail: PropTypes.string,
    newUserAddressSelected: PropTypes.bool,
    newBillingAddressSelected: PropTypes.bool,
    selectedUserAddressIndex: PropTypes.number,
    selectedBillingAddressIndex: PropTypes.number,
    hasUserId: PropTypes.bool,
    purchaseState: PropTypes.string,
    isForBilling: PropTypes.bool,
    isSameBillingChecked: PropTypes.bool,
    onAddressVerification: PropTypes.func
};

function mapStateToProps(state) {
    return {
        billingAddress: state.selectedAddress.selectedBillingAddress,
        shippingAddress: state.selectedAddress.selectedShippingAddress,
        isSameBillingChecked: state.selectedAddress.isSameBillingChecked,
        newBillingAddressSelected: state.selectedAddress.newBillingAddressSelected,
        selectedBillingAddressIndex:
            state.selectedAddress.selectedBillingAddressIndex,
        newUserAddressSelected: state.selectedAddress.newUserAddressSelected,
        selectedUserAddressIndex:
            state.selectedAddress.selectedUserAddressIndex,
        userShippingAddress: state.signIn.address,
        userEmail: state.signIn.emailAddress,
        hasUserId: state.signIn.id !== '',
        purchaseState: state.cart.purchaseState
    };
}

const mapDispatchToProps = {
    updateShippingAddress,
    setNewAddressSelected,
    setNewEmptyAddressSelected,
    addNewGuest,
    tealiumLink,
    tealiumView
};

export default connect(mapStateToProps, mapDispatchToProps)(EditShipping);