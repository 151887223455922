/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
import {track} from '../../assets/utils/tracker.js';

export function tealiumLink(trackConstants) {

    return (dispatch, getState) => {
        const state = getState();
        return track('link', trackConstants, state)
            .then((result) => {
                // console.log('success');
            })
            .catch((err) => {
                console.log('error: ' + err);
            });
    };
}

export function tealiumView(trackConstants) {
    return (dispatch, getState) => {
        const state = getState();
        return track('view', trackConstants, state)
            .then((result) => {
                // console.log('success');
            })
            .catch((err) => {
                console.log('error: ' + err);
            });
    };
}

export function checkoutEvent(eventType, sku, unitValue, orderNumber, unitQty) {
    return (dispatch, getState) => {

        try {

            const state = getState();

            let summaryTotal = 0;
            const summaryQuantity = [];
            const summaryPrice = [];

            const products = [];
            const productTotals = [];
            const productQty = [];

            if (typeof state.cart != 'undefined') {
                if (typeof state.cart.cart != 'undefined') {
                    if (typeof state.cart.cart.orderData != 'undefined') {
                        for (let i = 0; i < state.cart.cart.orderData.lineitem.length; i++) {

                            const sku = state.cart.cart.orderData.lineitem[i].product.id;
                            const price = state.cart.cart.orderData.lineitem[i].lineitemPrice;
                            const qty = state.cart.cart.orderData.lineitem[i].quantity;

                            if (productTotals[sku]) {
                                productTotals[sku] += parseFloat(price.toFixed(2));
                            } else {
                                productTotals[sku] = parseFloat(price.toFixed(2));
                            }

                            if (productQty[sku]) {
                                productQty[sku] += qty;
                            } else {
                                productQty[sku] = qty;
                            }

                            // orderTotal += state.cart.cart.orderData.lineitem[i].shippingCost;
                            // orderTotal += state.cart.cart.orderData.lineitem[i].tax;

                            summaryTotal += state.cart.cart.orderData.lineitem[i].lineitemPrice;

                            if (products.indexOf(state.cart.cart.orderData.lineitem[i].product.id) === -1) {
                                products.push(state.cart.cart.orderData.lineitem[i].product.id);
                            }

                        }

                        summaryTotal = parseFloat(summaryTotal.toFixed(2));

                        for (const [sku, qty] of Object.entries(productQty)) {
                            summaryQuantity.push(qty.toString());
                        }

                        for (const [sku, price] of Object.entries(productTotals)) {
                            summaryPrice.push(price.toString());
                        }

                    }
                }
            }

            if (eventType == 'conversion') {

                if (window.utag) {

                    window.utag.view({
                        'event_name': 'Checkout Success',
                        'funnel_stage': 'Checkout Success',
                        'mkpl_order_id': orderNumber,
                        'purchase_event': '1',
                        'content_ids': products,
                        'content_type': 'product',
                        'order_qty': summaryQuantity,
                        'value': summaryPrice,
                        'currency': 'USD'
                    });

                }

                !!window.decibelInsight && window.decibelInsight("sendTrackedEvent", "Checkout Success", summaryTotal);
                !!window.decibelInsight && window.decibelInsight("sendCustomDimension", "Marketplace Order Number", orderNumber);

            } else if (eventType == 'cart_add') {

                if (window.utag) {

                    window.utag.view({
                        'add_to_cart': '1',
                        'event_name': 'Add to Cart',
                        'content_ids': [sku],
                        'content_type': 'product',
                        'order_qty': [unitQty.toString()],
                        'value': [unitValue.toString()],
                        'currency': 'USD'
                    });

                }

                !!window.decibelInsight && window.decibelInsight("sendTrackedEvent", "Add to Cart", unitValue);

            } else if (eventType == 'view') {

                if (window.utag) {

                    window.utag.view({
                        'view_content': '1',
                        'content_ids': [sku],
                        'content_type': 'product',
                        'currency': 'USD'
                    });

                }

                !!window.decibelInsight && window.decibelInsight("sendTrackedEvent", "Product View");

            }

        } catch (e) {

            !!window.decibelInsight && window.decibelInsight(
                'sendApplicationError',
                'Checkout: Event Tracking Issue' + (e?.message ? e?.message : 'An error occurred')
            );

        }

    };
}

// adobeTriggerView function allows for tracking different views across a SPA
export function adobeTriggerView (location = '') {

    try {

        if (location.startsWith('#') || location.startsWith('/')) {
            location = location.substr(1);
        }
        // Validate if the Target Libraries are available on your website
        if (typeof adobe != 'undefined' && adobe.target && typeof adobe.target.triggerView === 'function') {
            adobe.target.triggerView(location);
        }

    } catch (e) {
        // !!window.QuantumMetricAPI && QuantumMetricAPI.sendEvent(14, 0, "Adobe Target Trigger View Issue || " + e);
    }

    return null
}

export function adobeTargetEvent(eventType, sku, skuValue) {

    return (dispatch, getState) => {

        try {

            const state = getState();

            let orderTotal = 0;
            // let orderQty = 0;

            const products = [];
            const variants = [];
            const productsArr = [];
            const variantsArr = [];

            if (typeof state?.cart?.cart?.orderData != 'undefined') {
                for (let i = 0; i < state.cart.cart.orderData.lineitem.length; i++) {

                    orderTotal += state.cart.cart.orderData.lineitem[i].lineitemPrice;
                    // orderQty += state.cart.cart.orderData.lineitem[i].quantity;

                    if (products.indexOf(state.cart.cart.orderData.lineitem[i].product.id) === -1) {
                        products.push({
                            'id': state.cart.cart.orderData.lineitem[i].product.id
                        });
                        productsArr.push(state.cart.cart.orderData.lineitem[i].product.id);
                    }

                    if (variants.indexOf(state.cart.cart.orderData.lineitem[i].product.variantId) === -1) {
                        variants.push({
                            'id': state.cart.cart.orderData.lineitem[i].product.variantId
                        });
                        variantsArr.push(state.cart.cart.orderData.lineitem[i].product.variantId);
                    }

                }

                orderTotal = parseFloat(orderTotal.toFixed(2));
            }

            if (typeof adobe.target != 'undefined' && eventType == 'conversion') {
                adobe.target.trackEvent({ 
                    "mbox": "orderConfirmPage", 
                    "params":{  
                        "orderId": state?.cart?.cart?.orderCommercialId,  
                        "orderTotal": orderTotal,  
                        "productPurchasedId": variantsArr.join(',')  
                    } 
                });
            }

        } catch (e) {
            // !!window.QuantumMetricAPI && QuantumMetricAPI.sendEvent(14, 0, "Adobe Target Tracking Issue || " + e);
        }

    };
}