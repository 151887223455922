/* eslint-disable max-lines */
/* eslint-disable max-len */

import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Flex } from 'rebass';
import { Input, Checkbox } from '../atomics/formElements';
import { MdsText, MdsLink, MdsHeader4, MdsHeader6, MdsButton } from '../mds';
import { signInGuest, signInUser } from '../../redux/actions/signInActions.js';
import * as UserDefinitions from '../common/userSettings/userDefinitions.js';
import { useTranslation } from 'react-i18next';
import { tealiumView, tealiumLink } from '../../redux/actions/trackingActions.js';
import queryString from 'query-string';
import { getLanguage, deleteLoginEmail, setLoginEmail, getLoginEmail, setGuestLoginSelected, setNewUser } from '../../connectivity/localStorage'
import { removeSignedUserCookiesIfAny } from '../../connectivity/cookie'

import styles from './checkoutLogin.module.scss';

export const checkoutLogin = ({ signInGuest, signInUser, signInType }) => {

    const { t } = useTranslation();
    const baseTranslationKey = 'translation.checkoutLogin';
    const [formData, updateFormData] = useState({
        userName: '',
        password: '',
    });
    const dispatch = useDispatch();
    const funnelStage = 'Login / Registration';

    useEffect(() => {
        
        dispatch(tealiumView({
            funnel_stage: funnelStage
        }));

        if (getLoginEmail()) {
            updateFormData({
                userEmail: getLoginEmail()
            })
        }

        document.title = '3M Marketplace';
        if (document.querySelector('meta[name="description"]')) {
            document.querySelector('meta[name="description"]').setAttribute('content', '3M Marketplace');
        }

    }, []);

    const rememberEmail = (e) => {
        if (e.target.checked) {
            setLoginEmail(formData.userEmail)
        } else {
            deleteLoginEmail()
        }
        e.preventDefault();
    }

    const guestSignIn = (e) => {
        removeSignedUserCookiesIfAny();
        dispatch(tealiumLink({
            event_name: 'Checkout as guest',
            funnel_stage: funnelStage
        }))
        signInGuest();
        setGuestLoginSelected();
    }

    const createAccountLinkHandler = (e) => {
        dispatch(tealiumLink({
            event_name: 'Create an account link',
            funnel_stage: funnelStage
        }));

        setNewUser()

        // const createLink = process.env.REACT_APP_ENL_CREATE_ACCT_URL+getLanguage();
        // const overrideUrl = location.protocol+'//'+location.hostname+(location.port ? ':'+location.port: '')+'/registration-confirmation';
        // window.location.href=createLink+'&overrideURL='+encodeURI(overrideUrl);

        window.location.href = process.env.REACT_APP_ENL_CREATE_ACCT_URL + getLanguage();

    }

    let hideGuest = false;
    /* istanbul ignore next */
    if (queryString.parse(location.search).noGuest) {
        hideGuest = queryString.parse(location.search).noGuest;
    }



    const guestBlockStyle = hideGuest
        ? styles.checkout_login__guest_block_hide
        : styles.checkout_login__guest_block;

    let signInRedirect = null;
    if (signInType !== UserDefinitions.UNDEFINED) {
        signInRedirect = <Redirect to={'/checkout'} />;
    }

    let isSsoError = false;
    if (queryString.parse(location.search).mmmSsoError) {
        isSsoError = true;
    }

    const newActivationLoginNoticeBlockStyle = isSsoError
        ? styles.checkout_login__newActivationLoginNotice
        : styles.checkout_login__newActivationLoginNotice_hide;

    /* istanbul ignore next */
    const cleanUpPasswordError = () => {        
        if (isSsoError) {
            const indx = queryString.parse(location.search).mmmSsoError.indexOf('|')
            return queryString.parse(location.search).mmmSsoError.substring(indx + 1).replace(/%20/g, " ");
        }
        return '';
    }

    const newActivationLoginNotice = () => {
        if (isSsoError) {
            return 'Please note if you are attempting the first login after account activation it may take up to 30-60 seconds for your account to fully synchronize. If you get an error, please try again after 10 seconds. We apologize for any inconveniences.';
        }
        return '';
    }

    return (
        <div className={styles.checkout_login}>
            {signInRedirect}

            <Flex className={styles.MainFlexContainer}>
                <div className={styles.login_modal + " checkoutLogin-loginModal"}>
                    <Flex className={styles.FlexItems}>
                        <div className={styles.ImageBox + " checkoutLogin-logoBlock"}>
                            <img src="https://www.3m.com/3m_theme_assets/themes/3MTheme/assets/images/unicorn/Logo_mobile.png" />
                        </div>
                        <Box className={styles.HeaderBox + " checkoutLogin-headerBlock"}>
                            <MdsHeader4 className="checkoutLogin-header">
                                {t(`${baseTranslationKey}.signIn.signIn3MAccount`)}
                            </MdsHeader4>
                        </Box>
                        <form name="loginform" className="checkoutLogin-loginForm" action={process.env.REACT_APP_ENL_URL} method="post">
                            <input type="hidden" name="js_valid" value="false"></input>
                            <input type="hidden" name="enscheck" value="y"></input>
                            <input type="hidden" name="ck_required" value="y"></input>
                            <input type="hidden" name="target" value={window.location.origin + "/checkout"}></input>
                            <input type="hidden" name="ORIGIN_URL" value={window.location.origin + "/checkoutLogin"}></input>
                            <Input
                                name="userName"
                                type="text"
                                uniqueID={'email'}
                                fields={{ name: 'userName', fieldName: t(`${baseTranslationKey}.emailAddress`), className: styles.InputItemElement }}
                                value={formData.userEmail}
                                onChange={(e) =>
                                    updateFormData({
                                        ...formData,
                                        userEmail: e.target.value,
                                    })}
                            />
                            <Input
                                name="passwd"
                                type="password"
                                uniqueID={'password'}
                                fields={{
                                    name: 'passwd',
                                    fieldName: t(`${baseTranslationKey}.password`),
                                    className: styles.InputItemElement
                                }}
                                value={formData.password}
                                onChange={(e) =>
                                    updateFormData({
                                        ...formData,
                                        password: e.target.value,
                                    })
                                }
                            />

                            <MdsText mdsType="body" className={styles.checkout_login__red} style={{marginTop: '5px', marginBottom: '5px', marginLeft: '-5px'}}>
                                {cleanUpPasswordError()}
                            </MdsText>

                            <div className={newActivationLoginNoticeBlockStyle}>
                                {newActivationLoginNotice()}
                            </div>

                            <Flex className={styles.FlexCheckout}>
                                <Checkbox
                                    name="checkPassword"
                                    type="checkbox"
                                    uniqueID={'checkbox'}
                                    fields={{
                                        fieldName: t(`${baseTranslationKey}.rememberMyEmail`),
                                        className: styles.Checkbox,
                                        checked :  getLoginEmail()
                                    }}
                                    required
                                    value={''}
                                    onChange={(e) =>
                                        rememberEmail(e)
                                    }
                                />
                                <Box className={styles.ForgotPassword}>
                                    <MdsLink
                                        className={styles.link}
                                        to={process.env.REACT_APP_ENL_FORGOT_PWD_URL + getLanguage() + '&overrideURL=' + encodeURI(window.location.href)}>
                                        {t(`${baseTranslationKey}.forgotPassword`)}
                                    </MdsLink>
                                </Box>
                            </Flex>
                            <Box className={styles.LogInButtonBox}>
                                <MdsButton
                                    className={styles.LogInButton + " checkoutLogin-loginBtn"}
                                    asLink={false}
                                    dataTestId="checkoutAsSignUser"
                                    type='submit'
                                >
                                    {t(`${baseTranslationKey}.signIn.signIn`)}
                                </MdsButton>

                            </Box>
                        </form>
                        <div className={guestBlockStyle + " checkoutLogin-guestBlock"}>
                            <hr className={styles.flex_separator + " checkoutLogin-hr"} />
                            <Box className={styles.GuestButtonBox}>
                                <MdsButton
                                    id={styles.LogInButton}
                                    mdsType="secondary"
                                    className={styles.LogInButton + " checkoutLogin-guestBtn"}
                                    fullWidth={true}
                                    dataTestId="checkoutAsGuest"
                                    asLink={false}
                                    onClick={() => {
                                        guestSignIn();
                                    }}
                                >
                                    {t(`${baseTranslationKey}.payAsGuest`)}
                                </MdsButton>
                            </Box>
                        </div>
                        <Flex className={styles.FlexAccountCreation + " checkoutLogin-acctCreationBlock"}>
                            <MdsHeader6 className={styles.FlexAccountCreation__message + " checkoutLogin-header2"}>
                                {t(`${baseTranslationKey}.createAccount.preferToCreateAccount`)}
                            </MdsHeader6>
                            <MdsButton
                                id={styles.LogInButton}
                                mdsType="tertiary"
                                className={styles.LogInButton + " checkoutLogin-createAcctBtn"}
                                fullWidth={true}
                                dataTestId="createAccount"
                                asLink={false}
                                onClick={(e) => createAccountLinkHandler(e)}
                            >
                                {t(`${baseTranslationKey}.createAccount.createAccount`)}
                            </MdsButton>
                        </Flex>
                    </Flex>
                </div>
            </Flex>
        </div>
    );
};

checkoutLogin.propTypes = {
    signInGuest: PropTypes.func.isRequired,
    signInUser: PropTypes.func.isRequired,
    signInType: PropTypes.string.isRequired,
    isSignedIn: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
    signInGuest,
    signInUser,
};

const mapStateToProps = (state) => {
    return {
        isSignedIn: state.signIn.token !== '',
        signInType: state.signIn.signInType
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(checkoutLogin);