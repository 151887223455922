import * as types from '../actionTypes.js';
import * as PurchaseStates from '../../reducers/reducer-helpers/purchaseStates';
export * from './cartApiActions.js';

export function changeDistributorDeliveryMethod(newDistributorDeliveryMethod) {
    return {
        type: types.CHANGE_DISTRIBUTOR_DELIVERY_METHOD,
        ...newDistributorDeliveryMethod,
    };
}
export function updateCertifySafety(value) {
    return {type: types.UPDATE_CERTIFYSAFETY, value};
}
export function updatEmailOptIn(value) {
    return {type: types.UPDATE_EMAILOPTIN, value};
}
export function updateOrderResults(orderCommercialId, basket) {
    return {type: types.UPDATE_ORDER_RESULTS, orderCommercialId, basket};
}
export function loadCartSuccess(cart) {
    if (cart?.lineitem) {
        window.mkpl_cart_item_cnt = cart?.lineitem.length;
    }
    return {type: types.LOAD_CART_SUCCESS, cart};
}

export function updateLineItemOffer(offer, error) {
    return {type: types.UPDATE_LINEITEM_OFFER, value : {offer, error}};
}

export function updateDeliveryOptions(deliveryOptions) {
    return {type: types.UPDATE_DELIVERY_OPTIONS, deliveryOptions};
}

export function updateBasketID(id) {
    return {type: types.UPDATE_BASKETID, basketId: id};
}

export function updateBasketAction(action) {
    return {type: types.UPDATE_BASKET_ACTION, basketAction: action};
}

export function loadCartRequest() {
    return {type: types.LOAD_CART_REQUEST};
}

export function updateCartQuantity(lineItemId, newValue) {
    return {type: types.UPDATE_CART_QUANTITY, value: {lineItemId, newValue}};
}

export function updateCart(quantity, product, offer) {
    return {type: types.UPDATE_CART, value: {quantity, product, offer}};
}

export function removeCartLineItem(id) {
    return {type: types.REMOVE_CART_LINE_ITEM, id};
}

export function removeAllCartLineItem() {
    return {type: types.REMOVE_ALL_CART_LINE_ITEM};
}

export function saveDistributorSortOrder(distributorOrderedLineItems) {
    return {
        type: types.SAVE_SORT_ORDER,
        sortAgain: false,
        distributorOrderedLineItems,
    };
}

export function updateDistributorAmount(distributorName, priceChangeAmount) {
    return {
        type: types.UPDATE_DISTRIBUTOR_AMOUNT,
        distributorName,
        priceChangeAmount,
    };
}

export function removeEmptyDistributor(distributorName) {
    return {type: types.REMOVE_EMPTY_DISTRIBUTOR, distributorName};
}

export function updateShippingType(newLineItems) {
    return {type: types.UPDATE_SHIPPING_TYPE, newLineItems};
}

export function updateShippingAddress(address) {
    return {type: types.UPDATE_SHIPPING_ADDRESS, address};
}

export function updateShippingPrices(newDistributorOrderedLineItems) {
    return {type: types.UPDATE_SHIPPING_PRICES, newDistributorOrderedLineItems};
}

export function addressValidation(result) {
    return {type: types.ADDRESS_VALIDATION, result};
}

export function goToPurchaseState(purchaseState) {
    return {type: types.ORDER_CONTINUE, nextState: purchaseState};
}

export function apiCallState(continueButtonState) {
    return {type: types.ORDER_API_STATE,  continueButtonState};
}

export function addPromoCode(promoCode){
    return {type: types.ADD_PROMO_CODE, promoCode}
}

export function updatePromoCodeError(invalid){
    return {type: types.UPDATE_PROMOCODE_ERROR,invalid}
}

export function orderContinue(currentPurchaseState) {
    let nextState = '';
    switch (currentPurchaseState) {
        case PurchaseStates.CART:
            nextState = PurchaseStates.CHECKOUT_EDIT_SHIPPING;
            break;
        case PurchaseStates.CHECKOUT_EDIT_SHIPPING:
            nextState = PurchaseStates.CHECKOUT_EDIT_DELIVERY;
            break;
        case PurchaseStates.CHECKOUT_EDIT_DELIVERY:
            nextState = PurchaseStates.CHECKOUT_EDIT_PAYMENT;
            break;
        case PurchaseStates.CHECKOUT_EDIT_PAYMENT:
            nextState = PurchaseStates.CHECKOUT_REVIEW;
            break;
    }
    return {type: types.ORDER_CONTINUE, nextState};
}
// cart Error as array
export function updateError(err){
    return {type: types.UPDATE_CART_ERROR,err}

}

export function loadCartOfferSuccess(offer){
    return {type: types.LOAD_CART_OFFERS_SUCCESS, offer}
}
