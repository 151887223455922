import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {Flex, Box} from 'rebass';
import styles from './addedToCart.module.scss';
import MDSButton from '../../atomics/buttons/mds-button';
import {useHistory} from 'react-router-dom';
import {MdsText} from '../../mds';
import {useTranslation} from 'react-i18next';
import Carousel from '../../carousel';
import { useDispatch } from 'react-redux';
import { tealiumLink, tealiumView } from '../../../redux/actions/trackingActions.js';
import ImageContainer from '../../common/images/image-container';
import { getNormalizedProductURL } from '../../common/utlities';

const addedToCart = ({item}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const baseTranslationKey = 'translation.cart.addedToCart';
    const dispatch = useDispatch();

    window.scrollTo(0,0);
    
    const renderImage = () => {
        return <ImageContainer
            className={styles.image} src={item.imgURL}
            alt={item.description} />;
    };

    useEffect(() => {
        dispatch(tealiumView({
            event_name: 'View Add to Cart Page',
            funnel_stage: 'Added to Cart',
        }));

        document.title = '3M Marketplace';
        if (document.querySelector('meta[name="description"]')) {
            document.querySelector('meta[name="description"]').setAttribute('content', '3M Marketplace');
        }

    },[])


    const onCheckout = () => {
        dispatch(tealiumLink({
            event_name: 'Checkout Clicked',
            funnel_stage: 'Added to Cart',
        }));
        history.push('/checkout');
    };

    const productDetailLinkHandler = (e,url) => {
        
        if (url) {
            dispatch(tealiumLink({
                event_name: 'Return to Product Clicked',
                funnel_stage: 'Added to Cart',
            }));
            window.location.href= getNormalizedProductURL(url);
        }
        e.preventDefault();

    }

    return (
      <React.Fragment>
        <Flex className={styles.AddedToCart} key={item.id}>
            <Box className={styles.AddedToCart__Itemimage}>{renderImage()}</Box>
            <Box className={styles.ProductDetail}>
                <Box className={styles.ProductText}>
                    <a
                        onClick={(e) => productDetailLinkHandler(e, item.productURL)}
                    >
                        {(item && item.description) || 'PLACEHOLDER'}
                    </a>
                    </Box>
                    <MdsText dataTestId="addedToCart" className={styles.AddMessage}>
                    {t(`${baseTranslationKey}.addMessage`)}
                    </MdsText>
                    <Box className={styles.AddedToCart__button_row}>
                        <MDSButton
                            mdsType="primary"
                            asLink={false}
                            dataTestId="checkoutCart"
                            onClick={onCheckout}
                            classList={styles.AddedToCart__action}
                        >
                            {t(`${baseTranslationKey}.checkout`)}
                        </MDSButton>
                    </Box>
                </Box>
                <div
                    id="post-add-to-cart"
                    className={styles.add_to_cart__custom_module}
                ></div>
            </Flex>
            <Carousel />
        </React.Fragment>
    );
};

addedToCart.propTypes = {
    item: PropTypes.object.isRequired,
};

export default addedToCart;
