/* eslint-disable max-lines */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    removeCartLineItem,
    saveCartProgress,
    updateDistributorAmount
} from '../../redux/actions/cart/cartActions';
import { Flex, Box } from 'rebass';
import { MdsText } from '../mds';
import Modal from '../common/modals/generic';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { tealiumLink } from '../../redux/actions/trackingActions.js';
import styles from './remove.module.scss';
import trashIcon from '../../assets/icons/trash/trash_icon.svg';
import ImageContainer from '../common/images/image-container';

const Remove = ({ item }) => {
    const dispatch = useDispatch();
    const [displayModal, setDisplayModal] = useState(false);
    const [modalType, setModalType] = useState('remove');
    const { t } = useTranslation();
    const baseTranslationKey = 'translation.common.items.singleItemSummary';
    const removeItemClickEventHandler = (e) => {
        e.preventDefault();
        setModalType('remove');
        setDisplayModal(true);
    }
    const name = item?.offer?.distributor?.name
    const removeItemHandler = () => {
        dispatch(tealiumLink({
            event_name: 'Remove Product',
            funnel_stage: 'Cart Page',
        }));
        removeItemAndCall(null);
    }

    const removeItemAndCall = (callBack) => {
        const priceChange = (0 - item.lineitemPrice);
        dispatch(removeCartLineItem(item.id));
        dispatch(
            updateDistributorAmount(
                name,
                priceChange
            )
        );
        setDisplayModal(false);
        dispatch(saveCartProgress(null, callBack));

    }

    const confirmChange = (addlFields) => {
        dispatch(tealiumLink({
            event_name: 'Change Buying Option',
            funnel_stage: 'Cart Page',
        }));
        setDisplayModal(false);
        const refinements = addlFields.filter(f => f.code === process.env.REACT_APP_ORDER_DETAILS_REFINEMENT_PREFIX)
            .map(f => f.value);
        if (refinements[0]) {
            refinements[0] = '#' + Object.entries(JSON.parse(refinements[0]))
                .map(e => e[0] + '=' + e[1])
                .join('|')
        } else {
            refinements.push('');
        }

        removeItemAndCall(() => { history.push('/buyingOptions/' + item?.product?.id + refinements[0]); });
    }


    const cancelChange = () => {
        setDisplayModal(false);
    }


    return (
        <>
            <Flex>
                {displayModal &&
                    <Modal
                        title={t(`${baseTranslationKey}.${modalType === 'remove'
                            ? 'removeDialog'
                            : 'changeDialog'
                            }.title`)}
                        actions={{
                            confirmEvent: () => modalType === 'remove'
                                ? removeItemHandler()
                                : confirmChange(item?.offer?.offerAdditionalFields),
                            confirmLabel: t(`${baseTranslationKey}.${modalType === 'remove'
                                ? 'removeDialog'
                                : 'changeDialog'
                                }.submitButton`),
                            cancelLabel: t(`${baseTranslationKey}.${modalType === 'remove'
                                ? 'removeDialog'
                                : 'changeDialog'
                                }.cancel`),
                            cancelEvent: cancelChange,
                            dismissEvent: cancelChange
                        }}
                        show={displayModal}
                    >
                        <MdsText mdsType="body-bold" isInline={true}>
                            {t(`${baseTranslationKey}.${modalType === 'remove'
                                ? 'removeDialog'
                                : 'changeDialog'
                                }.message`)}
                        </MdsText>
                    </Modal>
                }
                <Box>
                    <span
                        onClick={(e) => removeItemClickEventHandler(e)}
                    >
                        <ImageContainer
                            src={trashIcon}
                            className={styles.trash_icon_action} />
                    </span>
                </Box>
            </Flex>
        </>
    );
};

Remove.propTypes = {
    item: PropTypes.object.isRequired,
    updateCartQuantity: PropTypes.func,
    removeCartLineItem: PropTypes.func,
};

export default Remove;

