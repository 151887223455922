import React from 'react';
import PropTypes from 'prop-types';
const mdsHeader5 = (props) => {
  const classes = props.className ? props.className : '';
  return (
    <h5 className={`mds-font_header--5 ${classes}`}>{props.children}</h5>
  )
};

mdsHeader5.propTypes = {
    children: PropTypes.any,
    className: PropTypes.any,
};
export default mdsHeader5;
