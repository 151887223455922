import React, {useState} from 'react';
import Input from '../../atomics/formElements/wrappers/input';
import PropTypes from 'prop-types';
import ContentBox from '../../containers/contentBox.js';
import * as commonMessages from '../commonMessages';
import EmailValidator from 'email-validator';
import {useTranslation} from 'react-i18next';

import styles from './contactInformation.module.scss';
import { useSelector } from 'react-redux';

const contactInformation = (props) => {
    const {t} = useTranslation();
    const baseTranslationKey = 'translation.common.contactInformation';
    const [selected, setSelected] = useState(true);

    const deselectOnBlur = () => {
        setSelected(false);
    };
    const validateAddress=useSelector(state=>{
        return state?.selectedAddress?.validateAddress});
    
    if(validateAddress&&selected)
    setSelected(false);

    const invalidMessage = t(commonMessages.invalidEmailAddress);
    const invalidEmail =
        !selected && !EmailValidator.validate(props.contactEmail);

    const contactInformationDesign = {
        title: 'Contact Inpformation',
        type: 'input',
        className: 'mkpl_sample-section--2up',
        props: {
            uniqueID: 'inputUniqueID',
            fields: {
                name: 'Email',
                fieldName: t(`${baseTranslationKey}.fields.email`),
                type: 'email',
                invalid: invalidEmail,
                helpText: !invalidEmail
                    ? t(commonMessages.sendOrderEmailUpdates)
                    : '',
                required: true
            },
        },
    };

    return (
        <ContentBox title={t(`${baseTranslationKey}.title`)}>
            <div className={styles.contactInformation__inputContainer}>
                <Input
                    name="formName"
                    type="email"
                    uniqueID={'12'}
                    dataTestId="shippingEmail"
                    fields={{...contactInformationDesign.props.fields}}
                    value={props.contactEmail}
                    invalidLabel={invalidMessage}
                    onChange={(e) => props.handleContactInfo(e.target.value)}
                    onBlur={(e) => {deselectOnBlur()}}
                />
            </div>
        </ContentBox>
    );
};

contactInformation.propTypes = {
    handleContactInfo: PropTypes.func.isRequired,
    contactEmail: PropTypes.string.isRequired,
};

export default contactInformation;
