import React from 'react';
import PropTypes from 'prop-types';
import headerData from './headerData';

const NavbarMobile = (props) => {
  const navbarHeaders = headerData.navbar.map((header, index) => {
    return (
      <label key={index} id="dropdown-list_products-title" style={props.navbarLinksIndex === index ? {display: 'block'} : {display: 'none'}}>
        {header.title}
      </label>
    );
  });

  return (
    <div className="m-dropdown-list_header">
      <button className="m-dropdown-list_backBtn js-dropdown-list_backBtn" onClick={() => {props.onClick(-1)}}>
        <i className="MMM--icn MMM--icn_lt2"></i>
      </button>
      <div className="m-dropdown-list_title">
        {navbarHeaders}
      </div>
    </div>
  );
};

NavbarMobile.propTypes = {
  navbarLinksIndex: PropTypes.number,
  onClick: PropTypes.func,
};

NavbarMobile.defaultProps = {
  navbarLinksIndex: -1,
  onClick: () => {},
};

export default NavbarMobile;
