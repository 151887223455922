
const getRefinements = () => {
    // console.log(window.location.href)
    const refinementList =
        window.location.href.indexOf('#') > 0
            ? window.location.href.substr(window.location.href.indexOf('#') + 1)
            : null;

    // console.log(refinementList)
    const refinementsMap = new Map([]);
    refinementList && refinementList.trim().length > 0
        ? refinementList
              .trim()
              .split('|')
              .map((refinement) => {
                  const field = refinement.split('=');
                  refinementsMap.set(field[0], field[1]);
              })
        : [];

    const refinements = {
        code: 'refinements',
        type: 'STRING',
        value: JSON.stringify(Object.fromEntries(refinementsMap)),
    };

    return refinements
}

export {getRefinements}