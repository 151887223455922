import React from 'react';
import PropTypes from 'prop-types';
import {Select} from '@3mcom/mds-library/dist/index';
import {useTranslation} from 'react-i18next';
import styles from './QuantityDropdown.module.scss';

const QuantityDropdown = (props) => {
    const {t} = useTranslation();
    const myArr = [];
    for (let index = 1; index < props.dropdownMax + 1; index++) {
        myArr.push(index);
    }

    const translatedBase =
        'translation.distributorPage.quantitySelector.quantityDropdown.selectQuantity';
    const stockAvailableAtLabel =
        t(translatedBase + '.stockAvailableAlertA') +
        ' ' +
        props.highestInventory +
        ' ' +
        t(translatedBase + '.stockAvailableAlertB');

    return (
        <div
            className={`mds-form_element mds-form_select ${styles.quantity_dropdown}`}
        >
            <div className={styles.quantity_dropdown__selector_container}>
                <Select
                    id="Quantity"
                    fieldName="Quantity"
                    onChange={(e) => {
                        props.onChange(e.target.value);
                    }}
                    value={props.defaultValue}
                >
                    <option
                        key="default"
                        value="0"
                        style={{padding: 30}}
                        disabled
                    >
                        {t(translatedBase + '.pleaseSelectQuantity')}
                    </option>
                    {myArr.map((x) => {
                        return (
                            <option key={x} value={x} style={{padding: 30}}>
                                {x}
                            </option>
                        );
                    })}
                </Select>               
            </div>
            {props.highestInventory && props.highestInventory < 10 && props.highestInventory > 0 ? (
                    <div className={styles.quantity_dropdown__alert}>
                      <i className="MMM--icn MMM--icn_information"></i>
                      {stockAvailableAtLabel}
                    </div>
            ): null }
        </div>
    );
};

QuantityDropdown.propTypes = {
    dropdownMax: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.number.isRequired,
    highestInventory: PropTypes.number,
};

export default QuantityDropdown;
