import React from 'react';
import PropTypes from 'prop-types';
import {MdsText} from '../../mds';
import NumberFormat from 'react-number-format';

import styles from './address.module.scss';

const Address = (props) => {
  const {classNames = {}} = props;

  const {
    nickname = '',
    name = '',
    addr1 = '',
    addr2 = '',
    tele = '',
  } = props.data;

  let {
    city = '',
    state = '',
    zip = '',
  } = props.data;

  if (!city) city = '';
  if (!state) state = '';
  if (!zip) zip = '';

  const munici = [];
  if (city.length !== 0) munici.push(city);
  if (state.length !== 0) munici.push(state);
  if (zip.length !== 0) munici.push(zip);

  return (
    <div className={`${styles.address_container} ${classNames.container ? classNames.container : ''}`}>
      {nickname && (<MdsText el='div' mdsType='body' className={classNames.nickname ? classNames.nickname : ''}>{nickname}</MdsText>)}
      {name && (<MdsText el='div' mdsType='body' className={classNames.name ? classNames.name : ''}>{name}</MdsText>)}
      {addr1 && (<MdsText el='div' mdsType='body' className={classNames.addr1 ? classNames.addr1 : ''}>{addr1}</MdsText>)}
      {addr2 && (<MdsText el='div' mdsType='body' className={classNames.addr2 ? classNames.addr2 : ''}>{addr2}</MdsText>)}
      {munici && (<MdsText el='div' mdsType='body' className={classNames.munici ? classNames.munici : ''}>
        {munici.join(', ')}
      </MdsText>)}
      {tele && (
        <NumberFormat
            format="(###)-###-####"
            displayType={'text'}
            value={tele}
        />
      )}
    </div>
  );
};

Address.propTypes = {
  children: PropTypes.any,
  data: PropTypes.shape({
    nickname: PropTypes.string,
    name: PropTypes.string,
    addr1: PropTypes.string,
    addr2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    tele: PropTypes.phone,
  }),
  classNames: PropTypes.shape({
    container: PropTypes.string,
    nickname: PropTypes.string,
    address: PropTypes.string,
    name: PropTypes.string,
    addr1: PropTypes.string,
    addr2: PropTypes.string,
    munici: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    tele: PropTypes.string,
  })
};

export default Address;
