/* eslint-disable */
import React from 'react';
import * as PurchaseStates from '../../../redux/reducers/reducer-helpers/purchaseStates';
import PropTypes from 'prop-types';
import * as BreadcrumbStatus from './breadcrumbStatus';
// Components
import {MdsText} from '../../mds';
import ImageContainer from '../../common/images/image-container';
// Styling
import styles from './breadcrumbElement.module.scss';
// Assets
import svgDivider from './status-divider.svg';

const breadcrumbElement = (props) => {
    let circleFillColor;
    let circleOutlineColor;
    let circleText;
    let circleTextColor = '#FFFFFF';
    let className;
    let clickHandler = null;

    const handleClick = () => {
        props.handleClick(props.purchaseState);
    };

    switch (props.breadcrumbStatus) {
        case BreadcrumbStatus.PENDING:
            circleFillColor = '#FFFFFF';
            circleOutlineColor = '#767676';
            circleTextColor = '#767676';
            circleText = props.step;
            className = styles.pending;
            break;
        case BreadcrumbStatus.IN_PROGRESS:
            circleFillColor = '#000000';
            circleOutlineColor = '#000000';
            circleText = props.step;
            className = styles.inProgress;
            break;
        case BreadcrumbStatus.COMPLETE:
            circleFillColor = '#1AA847';
            circleOutlineColor = '#1AA847';
            circleText = String.fromCharCode(parseInt('2714', 16));
            circleText = (<i className="MMM--icn MMM--icn_checkmark"></i>)
            className = styles.complete;
            clickHandler = handleClick;
            break;
    }

    const bcStyle = `breadcrumb_step__marker--${props.breadcrumbStatus.toLowerCase()}`;
    const testid = `${props.breadcrumbStatus.toLowerCase()}-${props.step}`;

    let separator = null;
    if (props.purchaseState !== PurchaseStates.CHECKOUT_REVIEW) {
        separator = (
            <div className={styles.breadcrumb_step__divider}>
                <ImageContainer src={svgDivider} />
            </div>
        );
    }
    const breadCrumbElement = (
        <div className={styles.breadcrumb_step} data-testid={testid}>
            <div className={styles.breadcrumb_step__content}>
                <div
                    className={`${styles.breadcrumb_step__marker} ${styles[bcStyle]}`}
                    data-testid="icon"
                >
                    <MdsText mdsType="body" data-testid="iconText">
                        {circleText}
                    </MdsText>
                    {/* <text className={className} onClick={clickHandler} textAnchor="middle" x="75" y="25" dy=".3em">{props.label}</text> */}
                </div>
                <MdsText
                    el="span"
                    className={`${styles.breadcrumb_step__label} ${className}`}
                    onClick={clickHandler}
                    isInline={true}
                >
                    {props.label}
                </MdsText>
            </div>
            {/* <svg className={styles.breadcrumb_step__divider}>
          {separator}
        </svg> */}
            {separator}
        </div>
    );
    return breadCrumbElement;
};

breadcrumbElement.propTypes = {
    handleClick: PropTypes.func,
};

export default breadcrumbElement;
