import React from 'react';
import styles from './addPaymentButton.module.scss';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const addPaymentButton = (props) => {
    const { t } = useTranslation();
    const baseTranslationKey = 'translation.checkoutReview.checkoutReviewPayment'
    const newClassname =
        styles.IconVerticalLineContainer + ' ' + styles.IconStackTopContainer;

    return (
        <div
            className={`${styles.EditPaymentButton} ${styles.edit_shipping__button}`}
            data-testid="clickable"
            onClick={() => props.handleAddPaymentButtonClick()}
        >
            <div className={styles.AddNewPaymentMessage}>
                <div className={styles.IconContainer}>
                    <div className={styles.IconVerticalLineContainer}>
                        <div className={styles.IconVerticalLine}></div>
                    </div>
                    <div className={newClassname}>
                        <div className={styles.IconHorizontalLne}></div>
                    </div>
                </div>
                <div className={styles.AddPaymentText}>{t(`${baseTranslationKey}.addPaymentButtonLabel`)}</div>
            </div>
        </div>
    );
};

addPaymentButton.propTypes = {
    handleAddPaymentButtonClick: PropTypes.func,
};

export default addPaymentButton;
