/* eslint-disable max-len */
/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'rebass';
import { MdsHeader4 } from '../mds';
import { useTranslation } from 'react-i18next';
import BoxContentHeader from '../atomics/text/boxContentHeader';
import ContentBox from '../containers/contentBox';
import CardDetailsForm from '../forms/stripe/cardDetailsForm';
import styles from './checkout.module.scss';
import EditShipping from '../editShipping/editShipping.js';
import * as UserDefinitions from '../common/userSettings/userDefinitions.js';
import * as PurchaseStates from '../../redux/reducers/reducer-helpers/purchaseStates.js';
import { useDispatch, useSelector } from 'react-redux';
import { tealiumView } from '../../redux/actions/trackingActions.js';
import {
    loadAllPaymentMethods,
    getCardUUIDSuccess,
    setPaymentCardSuccess,
    resetPaymentCard,
    removePaymentMethod
} from '../../redux/actions/payment/paymentActions';
import CardDetails from '../myaccount/payments/cardDetails';
import AddPaymentButton from '../myaccount/payments/addPaymentButton';
import PONumber from './ponumber';
import { clearBillingAddressGuest, clearBillingAddressRegisteredUser, updateBillingAddressSame, updateSameBillingCheckbox } from '../../redux/actions/selectedAddressActions';
import LazyLoader from '../../../public/images/lazy-preloader-clear.gif';

const CheckboxAddress = (props) => {
    const { t } = useTranslation();
    const baseTranslationKey = 'translation.views.checkoutview';
    const isSameBillingChecked = useSelector((state) => state.selectedAddress.isSameBillingChecked);
    const userAddressList = useSelector((state) => state.signIn.address);
    const signInType = useSelector((state) => state.signIn.signInType);
    const dispatch = useDispatch();

    const handleCheck = (isSameBillingChecked) => {
        dispatch(updateSameBillingCheckbox(isSameBillingChecked));
        if (isSameBillingChecked) {
            dispatch(updateBillingAddressSame(true
            ));
        }
        else {
            if (signInType === UserDefinitions.REGISTERED_USER && userAddressList.length > 0) {
                dispatch(clearBillingAddressRegisteredUser(true));
            }
            else {
                dispatch(clearBillingAddressGuest(true));
            }
        }
    }


    return (
        <>
            <input
                type="checkbox"
                onChange={() => { handleCheck(!isSameBillingChecked) }}
                checked={isSameBillingChecked}
                id="use_shipping_address"
                className={styles.billing_address_checkBox}
            />

            <label htmlFor="use_shipping_address">   {t(`${baseTranslationKey}.checkboxAddress`)}</label>
            <div style={{ display: isSameBillingChecked ? 'none' : '' }} >
                <EditShipping
                    key={isSameBillingChecked}
                    isForBilling={true}
                    userType={props.isGuest ? UserDefinitions.GUEST : UserDefinitions.REGISTERED_USER}
                />
            </div>
        </>
    )
}


const CheckoutView = (props) => {
    const { t } = useTranslation();
    const baseTranslationKey = 'translation.views.checkoutview';
    const dispatch = useDispatch();

    const purchaseState = useSelector(state => state.cart.purchaseState);
    const paymentList = useSelector(state => state.paymentList.paymentList);
    const paymentMethod = useSelector(state => state.paymentList.paymentMethod);
    const userId = useSelector(state => state.signIn.id);
    const oldPaymentMethod=useSelector(state => state.paymentList.oldPaymentMethod);
    const [showStripeForm, setShowForm] = useState(false);
    const [isLoading , setIsLoading] = useState(false);


    const addAnotherPaymentMethod = () => {
        setShowForm(true);
        dispatch(resetPaymentCard());
    }

    useEffect(() => {
        if (purchaseState === PurchaseStates.CHECKOUT_EDIT_PAYMENT) {
            dispatch(tealiumView({
                funnel_stage: PurchaseStates.CHECKOUT_EDIT_PAYMENT
            }));
            dispatch(loadAllPaymentMethods(userId));
            setIsLoading(false);
        }
    }, []);

    const deletePaymentMethod = (payment) => {
        let index = -1;
        paymentList.map((pm, idx) => { if (payment.id === pm.id) index = idx; });
        dispatch(resetPaymentCard());
        dispatch(removePaymentMethod(userId, payment.id, index));
    }
    const PaymentCardList = () => {
        if (paymentList) {
            return (paymentList).filter(payment=>payment.savedCard===true||payment.id === paymentMethod
                ||payment.id === oldPaymentMethod).map((payment, index) => {
                return (
                    <Box key={payment.id} onClick={() => {
                        dispatch(getCardUUIDSuccess({ id: payment.id }));
                        dispatch(setPaymentCardSuccess(payment.id));
                        setShowForm(false)
                    }}>
                        <CardDetails card={payment} index={index} key={payment.id} selected={payment.id === paymentMethod} />
                    </Box>
                )
            });
        } else {
            return null;
        }
    }
    const PaymentSection = () => {
        const guestPayment = props.isGuest && paymentMethod ?
            paymentList.filter(p => p.id === paymentMethod)[0] : null;
        return (
            <>
                <ContentBox title={t(`${baseTranslationKey}.paymentMethod`)}>
                    {!props.isGuest && paymentList && paymentList.length > 0 ?
                        (
                            <>
                                <PaymentCardList />
                                {showStripeForm ? (
                                    <CardDetailsForm />
                                ) : (
                                    <AddPaymentButton handleAddPaymentButtonClick={addAnotherPaymentMethod} />
                                )}
                            </>
                        ) : (
                            <>
                                {!paymentMethod ? (
                                    <CardDetailsForm />
                                ) : (

                                    <Box>
                                        {guestPayment ?
                                            <CardDetails card={guestPayment}
                                                index={0} key={1} selected={false}
                                                removeAction={() => { deletePaymentMethod(guestPayment) }} />
                                                : setIsLoading(true)}
                                    </Box>
                                )}
                            </>
                        )}
                    <PONumber />
                </ContentBox>

            </>
        )
    }

    return (
        <Box className={styles.viewContainer}>
            <MdsHeader4 className={styles.checkout__header}>
                {t(`${baseTranslationKey}.header`)}
            </MdsHeader4>
            {isLoading ?
               <div className = {`${styles.viewContainer__coverSpinner_loadingPayment}`} >
                   <img src={LazyLoader} alt="loading Payment ..."></img>
                </div> :
                (
                    <>
                        {props.title && <BoxContentHeader>{props.title}</BoxContentHeader>}
                        <Box>{props.children}</Box>
                        <PaymentSection />
                        <CheckboxAddress {...props} />
                    </>
                )
            }
        </Box>
    );
};

CheckoutView.propTypes = {
    title: PropTypes.string,
    children: PropTypes.element,
    isGuest: PropTypes.bool,
    shippingAddress: PropTypes.object,
    userId: PropTypes.string
};
CheckboxAddress.propTypes = {
    isGuest: PropTypes.bool,
}
export default CheckoutView;
