import * as types from '../actions/actionTypes.js';
// import * as cartActions from '../actions/payment/paymentActions.js';


const defaultState = {
    paymentList: [],    
    cardUUIDData: {
      data : null, 
      errors: null
    }   
};

export default function paymentListReducer(state = defaultState, action) {
  switch (action.type) {
      case types.LOAD_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        paymentList: [...action.paymentList],
      };
      case types.REMOVE_PAYMENT_METHOD_SUCCESS:
        return {
          ...state,
          paymentList: removeFromPaymentList(action.index, state.paymentList),
        };
      case types.CREATE_SETUP_INTENT_SUCCESS:
        return {
          ...state, 
          cardUUIDData : {
            data : {...action.data},
            errors : null
          }
        };

        case types.CREATE_SETUP_INTENT_FAILURE:
          return {
            ...state, 
            cardUUIDData : {
              data : null,
              errors: {...action.errors}
            }
          };  
      case types.PAYMENT_METHOD_SETUP_SUCCESS: 
        return {
          ...state, 
          paymentMethod : action.paymentMethod
        };
      case types.PAYMENT_METHOD_SETUP_RESET: 
        return {
          ...state, 
          oldPaymentMethod:state.paymentMethod,
          paymentMethod : null,
        };  
      case types.PAYMENT_CARD_FORM_COMPLETE: 
        return {
          ...state, 
          cardFormComplete : action.isComplete
        }
      case types.PAYMENT_CARD_PROCESSING: 
        return {
          ...state,
          cardSetupInProgress : action.isProcessing
        }    
      default:
        return state;
  }
}

function removeFromPaymentList(index, paymentList) {
    const changedPaymentList = [...paymentList];
    changedPaymentList.splice(index, 1);
    return changedPaymentList;
}