/* eslint-disable max-len */
/* eslint max-lines: ["error", 250] */
import React, {useState} from 'react';
import PropTypes from 'prop-types';

import RequiredLabel from './generators/requiredLabel';

import styles from './checkbox.module.scss';

const CheckboxWrapper = (props) => {
  const {uniqueID} = props;
  const {name, fieldName, type, required, value, disabled, className, size, checked, labelClass, ...otherProps} = props.fields;
  const containerClasses = (className !== undefined ? className : '') + ' ' + (size !== undefined ? 'mds-form_checkbox--' + size : 'mds-form_checkbox--x-small');

  const [isChecked, setIsChecked] = useState(typeof checked !== 'undefined' ? checked : false);

  let checkboxRef = null;

  const setMyInputRef = element => {
    checkboxRef = element;
  };

  const clickMyInput = () => {
    if (checkboxRef) checkboxRef.click();
  };

  const onChangeEventHandler = (e) => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    if (props.onChange) props.onChange(e);
    if (newValue) onCheckedEventHandler(e);
  }

  const onCheckedEventHandler = (e) => {
    if (props.onChecked) props.onChecked(e);
  }

  const onBlurEventHandler = (e) => {
    if (props.onBlur) props.onBlur(e);
  }

  const onFocusEventHandler = (e) => {
    if (props.onFocus) props.onFocus(e);
  }

  const onClickEventHandler = (e) => {
    if (props.onClick) props.onClick(e);
  }

  const customClass = props.className ? props.className : '';

  return (
    <div className={`mds-form_checkbox ${containerClasses} ${customClass}`}>
      <input
        ref={setMyInputRef}
        type="checkbox"
        name={name}
        placeholder=" "
        id={uniqueID}
        required={ required }
        aria-labelledby={`${uniqueID}-Label ${uniqueID}-requiredLabel`}
        defaultValue={value}
        disabled={disabled}
        checked={!!isChecked}
        onChange={onChangeEventHandler}
        onBlur={onBlurEventHandler}
        onFocus={onFocusEventHandler}
        onClick={onClickEventHandler}
      ></input>
      <span className={type !== undefined
        ? `mds-form_checkboxBox ${'mds-form_checkboxBox--' + type} ${styles.checkbox__faux_box}`
        : `mds-form_checkboxBox  ${styles.checkbox__faux_box}`}
        onClick={clickMyInput}
        >
      </span>
      <label
        htmlFor={uniqueID}
        className={`mds-form_checkboxLabel ${labelClass !== undefined ?
        labelClass : ''}`}
        id={`${uniqueID}-Label`}
      >
        {`${fieldName}${required ? '*' : ''}`}
      </label>
      <RequiredLabel { ...props } {...otherProps} />
    </div>
  );
};

CheckboxWrapper.propTypes = {
  className: PropTypes.string,
  uniqueID: PropTypes.string,
  onChange: PropTypes.func,
  onChecked: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  fields: PropTypes.shape({
    name: PropTypes.string,
    fieldName: PropTypes.string,
    type: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    checked: PropTypes.bool,
    labelClass: PropTypes.string,
  })
};

export default CheckboxWrapper;
