import React from 'react';
import PropTypes from 'prop-types';
import ImageContainer from './image-container.js';

import vendorIcons from '../../../data/ccVendors.js';

const CreditCardLogo = (props) => {
  const ico = vendorIcons[props.vendor] ? vendorIcons[props.vendor] : vendorIcons['default'];
  return (
    <ImageContainer className={props.className} src={ico} alt={`${props.vendor} Logo`}/>
  );
};

CreditCardLogo.propTypes = {
  className: PropTypes.string,
  vendor: PropTypes.string,
};

export default CreditCardLogo;
