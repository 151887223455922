import React from 'react';
import LazyLoader from '../../../public/images/lazy-preloader-clear.gif';
import './loader.scss';

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="mkpl-loader">
          <img src={LazyLoader} alt="Content loading..."></img>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Loader;
