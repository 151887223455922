import React, {useState} from 'react';
import PropTypes from 'prop-types';
// import {Textarea} from '../../../mds/index';
import styles from './message-controls.module.scss';
import sendMessage from '../../../../assets/icons/send-message/Send Message-1.png';
import sendMessageActive from '../../../../assets/icons/send-message-active/Send Message Active-1.png';
import attachment from '../../../../assets/icons/attachment/Attachment-1.png';
import {Flex, Box} from 'rebass';

const MessageControls = (props) => {
    const {
        formData = {
            description: '',
        },
        onChange = () => {},
    } = props;
    const [data, setData] = useState({...formData});

    const onChangeHandler = (fieldName, e) => {
        const newData = {...data};
        newData[fieldName] = e.target.value;
        setData({...newData});
        onChange(fieldName, e.target.value);
    };
    return (
        <Flex className={styles.message_controls}>
            <form style={{width: '100%'}}>
                <Box className={styles.message_controls_text_area}>
                    <textarea
                        uniqueID="description"
                        dataTestId="newIncidentForm_description"
                        name="description"
                        required={false}
                        type="text"
                        fields={{fieldName: 'Type your message'}}
                        placeholder={'Type your message'}
                        value={data.description}
                        onChange={(e) => {
                            onChangeHandler('description', e);
                        }}
                        style={{width: '100%', border: 0}}
                    />
                </Box>
                <Box className={styles.message_controls_buttons}>
                    <button type="button">
                        <img src={attachment} />
                    </button>
                    <button
                        type="button"
                        disabled={data.description.length === 0}
                    >
                        <img
                            src={
                                data.description.length > 0
                                    ? sendMessageActive
                                    : sendMessage
                            }
                        />
                    </button>
                </Box>
            </form>
        </Flex>
    );
};

MessageControls.propTypes = {
    children: PropTypes.any,
    formData: PropTypes.object,
    onChange: PropTypes.func,
};

export default MessageControls;
