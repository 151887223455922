/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import ThreadTile, {ThreadTileData} from './common/thread-tile';

import styles from './threads-list.module.scss';

const ThreadsList = (props) => {
  const threads = props.threads ? props.threads : [];

  const {incidentInitiator = () => {}} = props;
  const initiateNewIncident = () => {
    incidentInitiator()
  }

  return (
    <div className={styles.threads_container}>
      <ThreadTile dataTestId='initateIncidentButton' onClick={() => initiateNewIncident()}>
       + Initiate Incident / Return
      </ThreadTile>
      {threads.map((thread, i) => (
        <ThreadTile key={`${thread.label.length}-${i}`}>
          <ThreadTileData
            label={thread.label}
            description={thread.description}
            lastUpdate={thread.lastUpdate}
            status={thread.status}
            active={thread.active}
          />
        </ThreadTile>
      ))}
    </div>
  );
};

ThreadsList.propTypes = {
  threads: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string,
      lastUpdate: PropTypes.string,
      status: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
};

export default ThreadsList;
