import React from 'react';
import PropTypes from 'prop-types';

class NavbarLogos extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ul id={`${this.props.list.title.toLowerCase()}-list`} className="l-dropdown-list_container" role="menu"
      aria-hidden={!this.props.navbarLinksOpened} style={this.props.navbarLinksOpened ? {display: 'block'} : {display: 'none'}}>
        {
          this.props.list.links.map((link, index) => {
            const lastItem = this.props.list.links.length - 1 === index;
            return (
              <li key={index} className={`l-dropdown-list l-dropdown-list_${lastItem ? 'viewbrands' : 'brands'}`} role="menuitem">
                {!lastItem &&
                  <React.Fragment>
                  <div className="l-dropdown-logo_container">
                    <div className="l-dropdown-logo_sprite">
                      <img src={link.imgURL} />
                    </div>
                    <label className="l-dropdown-logo_label">{link.label}</label>
                  </div>
                  <div className="l-dropdown-links_container">
                    <a className="m-btn m-btn--red m-btn--fullWidth" href={link.link1} aria-label={`${link.label} ${this.props.list.link1Label}`}>
                      {this.props.list.link1Label}
                    </a>
                    <a href={link.link2} className="m-btn m-btn--light m-btn--fullWidth" aria-label={`${link.label} ${this.props.list.link2Label}`}>
                      {this.props.list.link2Label}<i className="MMM--icn MMM--icn_arrowJump"></i>
                    </a>
                  </div>
                  </React.Fragment>
                }
                {lastItem &&
                  <a href={link.link}>{link.label}</a>
                }
              </li>
            );
          })
        }
      </ul>
    );
  }
}

NavbarLogos.propTypes = {
  list: PropTypes.object,
  navbarLinksOpened: PropTypes.bool,
};

NavbarLogos.defaultProps = {
  list: {},
  navbarLinksOpened: false,
};

export default NavbarLogos;
