/* eslint-disable max-lines */
export const LOAD_CART_SUCCESS = 'LOAD_CART_SUCCESS';
export const LOAD_CART_REQUEST = 'LOAD_CART_REQUEST';
export const NEXT_PURCHASE_STATE = 'NEXT_PURCHASE_STATE';
export const UPDATE_BASKETID = 'UPDATE_BASKETID';
export const UPDATE_BASKET_ACTION = 'UPDATE_BASKET_ACTION';
export const UPDATE_DELIVERY_OPTIONS = 'UPDATE_DELIVERY_OPTIONS';
export const UPDATE_ORDER_RESULTS = 'UPDATE_ORDER_RESULTS';
export const ORDER_CONTINUE = 'ORDER_CONTINUE';
export const ORDER_API_STATE = 'ORDER_API_CONTINUE';
export const UPDATE_EMAILOPTIN = 'UPDATE_EMAILOPTIN';
export const UPDATE_CERTIFYSAFETY = 'UPDATE_CERTIFYSAFETY';
export const LOAD_CART_OFFERS_SUCCESS = "LOAD_CART_OFFERS_SUCCESS";

export const UPDATE_CART_QUANTITY = 'UPDATE_CART_QUANTITY';
export const UPDATE_CART = 'UPDATE_CART';
export const UPDATE_LINEITEM_OFFER = 'UPDATE_LINEITEM_OFFER';
export const REMOVE_CART_LINE_ITEM = 'REMOVE_CART_LINE_ITEM';
export const REMOVE_ALL_CART_LINE_ITEM = 'REMOVE_ALL_CART_LINE_ITEM';
export const SAVE_SORT_ORDER = 'SAVE_SORT_ORDER';
export const UPDATE_DISTRIBUTOR_AMOUNT = 'UPDATE_DISTRIBUTOR_AMOUNT';
export const REMOVE_EMPTY_DISTRIBUTOR = 'REMOVE_EMPTY_DISTRIBUTOR';
export const UPDATE_SHIPPING_TYPE = 'UPDATE_SHIPPING_TYPE';
export const UPDATE_SHIPPING_ADDRESS = 'UPDATE_SHIPPING_ADDRESS';
export const UPDATE_SHIPPING_PRICES = 'UPDATE_SHIPPING_PRICES';
export const ADDRESS_VALIDATION = 'ADDRESS_VALIDATION';
export const CHANGE_DISTRIBUTOR_DELIVERY_METHOD =
    'CHANGE_DISTRIBUTOR_DELIVERY_METHOD';
export const UPDATE_PO_NUMBER = 'UPDATE_PO_NUMBER';

export const LOAD_OFFER_REQUEST = 'LOAD_OFFER_REQUEST';
export const LOAD_OFFER_SUCCESS = 'LOAD_OFFER_SUCCESS';
export const LOAD_OFFER_AGGREGATES = 'LOAD_OFFER_AGGREGATES';
export const UPDATE_OFFER_AGGREGATES = 'UPDATE_OFFER_AGGREGATES';
export const SELECT_QUANTITY = 'SELECT_QUANTITY';
export const SELECT_ZIPCODE = 'SELECT_ZIPCODE';
export const SELECT_ESTIMATED_DELIVERY_OPTION = 'SELECT_ESTIMATED_DELIVERY_OPTION';
export const SELECT_OFFER = 'SELECT_OFFER';
export const DESELECT_OFFER = 'DESELECT_OFFER';
export const LOAD_UPSELL_OFFER_SUCCESS = 'LOAD_UPSELL_OFFER_SUCCESS';
export const REMOVE_ADDED_UPSELL_OFFER = 'REMOVE_ADDED_UPSELL_OFFER';

export const REMOVE_ALL_OFFER = 'REMOVE_ALL_OFFER';
export const CLEAR_SHIPPING_DATA= 'CLEAR_SHIPPING_DATA';
export const ADD_PROMO_CODE = 'ADD_PROMO_CODE';
export const UPDATE_PROMOCODE_ERROR = 'UPDATE_PROMOCODE_ERROR';
export const UPDATE_CART_ERROR = 'UPDATE_CART_ERROR';

export const UPDATE_NETWORK_ERROR_STATUS = 'UPDATE_NETWORK_ERROR_STATUS';
export const UPDATE_IS_OFFERS_AVAILABLE = "UPDATE_IS_OFFERS_AVAILABLE";

export const SIGN_IN_USER = 'SIGN_IN_USER';
export const SIGN_IN_GUEST = 'SIGN_IN_GUEST';
export const INVALID_USER = 'INVALID_USER';

export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';
export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_GUEST_USER_SUCCESS = 'LOAD_GUEST_USER_SUCCESS'

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const UPDATE_BASKET_ID_SUCCESS = 'UPDATE_BASKET_ID_SUCCESS';
export const UPDATE_BASKET_ID_REQUEST = 'UPDATE_BASKET_ID_REQUEST';

export const ADD_USER_ADDRESS_SUCCESS =
    'ADD_USER_ADDRESS_SUCCESS';
export const ADD_USER_ADDRESS_REQUEST =
    'ADD_USER_ADDRESS_REQUEST';

export const ADD_GUEST_ADDRESS_SUCCESS =
    'ADD_GUEST_ADDRESS_SUCCESS';
export const ADD_GUEST_ADDRESS_REQUEST =
    'ADD_GUEST_ADDRESS_REQUEST';

export const ADD_GUEST_SUCCESS = 'ADD_GUEST_SUCCESS';
export const ADD_GUEST_REQUEST = 'ADD_GUEST_REQUEST';

export const APPEND_ORDER_TO_USER_REQUEST = 'APPEND_ORDER_TO_USER_REQUEST';
export const APPEND_ORDER_TO_USER_SUCCESS = 'APPEND_ORDER_TO_USER_SUCCESS';

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_IS_VALID_ADDRESS = 'UPDATE_IS_VALID_ADDRESS';
export const UPDATE_ADDRESS_RESIDENTIAL_INDICATOR = 'UPDATE_ADDRESS_RESIDENTIAL_INDICATOR';
export const SET_NEW_ADDRESS_SELECTED = 'SET_NEW_ADDRESS_SELECTED';
export const SET_NEW_EMPTY_ADDRESS_SELECTED = 'SET_NEW_EMPTY_ADDRESS_SELECTED';
export const UPDATE_BILLING_ADDRESS_SAME = 'UPDATE_BILLING_ADDRESS_SAME';
export const CLEAR_BILLING_ADDRESS_GUEST = 'CLEAR_BILLING_ADDRESS_GUEST';
export const CLEAR_BILLING_ADDRESS_REGISTERED_USER = 'CLEAR_BILLING_ADDRESS_REGISTERED_USER';
export const VALIDATE_ADDRESS = 'VALIDATE_ADDRESS';
export const UPDATE_ZIP_CODE = 'UPDATE_ZIP_CODE';
export const UPDATE_SAME_BILLING_CHECKBOX = 'UPDATE_SAME_BILLING_CHECKBOX';
// Order History Flow
export const LOAD_USER_ORDERS_SUCCESS = 'LOAD_USER_ORDERS_SUCCESS';
export const LOAD_USER_ORDERS_REQUEST = 'LOAD_USER_ORDERS_REQUEST';

// Order Details Flow
export const LOAD_ORDER_DETAILS_SUCCESS = 'LOAD_ORDER_DETAILS_SUCCESS';
export const LOAD_ORDER_DETAILS_REQUEST = 'LOAD_ORDER_DETAILS_REQUEST';

export const INITIATE_RETURN_SUCCESS = 'INITIATE_RETURN_ITEM_SUCCESS';
export const INITIATE_RETURN_REQUEST = 'INITIATE_RETURN_ITEM_REQUEST';

export const INITIATE_CUSTOMER_CANCELLATION_SUCCESS = "INITIATE_CUSTOMER_CANCELLATION_SUCCESS";
export const INITIATE_CUSTOMER_CANCELLATION_REQUEST = "INITIATE_CUSTOMER_CANCELLATION_REQUEST";

export const APPEND_ERROR = 'APPEND_ERROR';

export const LOAD_MY_LIST_SUCCESS = 'LOAD_MY_LIST_SUCCESS';
export const LOAD_MY_LIST = 'LOAD_MY_LIST';
export const LOAD_MY_LIST_USER_SUCCESS = 'LOAD_MY_LIST_USER_SUCCESS';
export const REMOVE_MY_LIST_ITEM = 'REMOVE_MY_LIST_ITEM';
export const EMPTY_MY_LIST_ITEM = 'EMPTY_MY_LIST_ITEM';

//  Payment List
export const LOAD_PAYMENT_METHODS_SUCCESS = 'LOAD_PAYMENT_METHODS_SUCCESS';
export const REMOVE_PAYMENT_METHOD_REQUEST = 'REMOVE_PAYMENT_METHOD_REQUEST';
export const REMOVE_PAYMENT_METHOD_SUCCESS = 'REMOVE_PAYMENT_METHOD_SUCCESS';
export const REMOVE_PAYMENT_METHOD_FAILURE = 'REMOVE_PAYMENT_METHOD_FAILURE';

export const CREATE_SETUP_INTENT_SUCCESS = 'FETCH_CARD_UUID_SUCCESS';
export const CREATE_SETUP_INTENT_FAILURE = 'FETCH_CARD_UUID_FAILURE';

export const PAYMENT_METHOD_SETUP_SUCCESS = 'PAYMENT_METHOD_SETUP_SUCCESS';
export const PAYMENT_METHOD_SETUP_RESET = 'PAYMENT_METHOD_SETUP_RESET';

export const PAYMENT_CARD_FORM_COMPLETE = 'PAYMENT_CARD_FORM_COMPLETE';
export const PAYMENT_CARD_PROCESSING = 'PAYMENT_CARD_PROCESSING';

export const LOAD_MY_ADDRESSES_USER_SUCCESS = 'LOAD_MY_ADDRESSES_USER_SUCCESS';
export const ADD_ADDRESS_TO_MY_ADDRESSES_SUCCESS = 'ADD_ADDRESS_TO_MY_ADDRESSES_SUCCESS';
export const EDIT_ADDRESS_IN_MY_ADDRESSES_SUCCESS = 'EDIT_ADDRESS_IN_MY_ADDRESSES_SUCCESS';
export const REMOVE_ADDRESS_FROM_MY_ADDRESSES_SUCCESS = 'REMOVE_ADDRESS_FROM_MY_ADDRESSES_SUCCESS';

// File Uploads
export const PUT_INCIDENT_FILE_IN_AWS_BUCKET_SUCCESS = 'PUT_INCIDENT_FILE_IN_AWS_BUCKET_SUCCESS';
export const INCIDENT_LOAD_FILE_INITIATED = 'INCIDENT_LOAD_FILE_INITIATED';
export const PUT_TAX_EXEMPT_AWS_SUCCESS = 'PUT_TAX_EXEMPT_AWS_SUCCESS';
export const TAX_EXEMPT_DOC_INITIATED = 'TAX_EXEMPT_DOC_INITIATED';
export const LOAD_TAX_EXEMPTS = 'LOAD_TAX_EXEMPTS';
export const SET_TAX_EXEMPT_ERR = 'SET_TAX_EXEMPT_ERR';
export const CLEAR_PENDING_EXEMPT = 'CLEAR_PENDING_EXEMPT';
export const SET_EXEMPT_FOR_PROCESSING = 'SET_EXEMPT_FOR_PROCESSING'

// Message Center
export const LOAD_MESSAGES_SUCCESS = 'LOAD_MESSAGES_SUCCESS';
export const LOAD_MESSAGES_REQUEST = 'LOAD_MESSAGES-REQUEST';
export const MESSAGE_FILE_INITIATED = 'MESSAGE_FILE_INITIATED';
export const MESSAGE_FILE_AWS_SUCCESS = 'MESSAGE_FILE_AWS_SUCCESS';

// Message Vendor
export const MESSAGE_VENDOR_REQUEST = 'MESSAGE_VENDOR_REQUEST';
export const MESSAGE_VENDOR_SUCCESS = 'MESSAGE_VENDOR_SUCCESS';