

import React, { useEffect, useRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@3mcom/mds-library/dist/node/index';
import CarouselModal from '../../carouselModal';
import ItemSummary from './ItemSummary';
import './AddedToCartModal.scss';
import { setAddedUpsellProducts } from '../../../connectivity/localStorage';

const AddedToCartModal = (props, ref) => {
    const baseTranslationKey = 'translation.cart.addToCart.AddedToCartModal';
    const { t } = useTranslation();
    const modalRef = useRef();
    const product = props.product;
    const offer = props.offer;
    const quantity = props.quantity;

    const popupToggle = () => {
        modalRef.current.toggle();
    }
    useImperativeHandle(ref, () => ({
        popupToggle
    }));

    useEffect(() => {
        modalRef.current.toggle();
    }, []);

    const addUpsellCallback = (upsellProduct) => {
        setAddedUpsellProducts(upsellProduct);
    }

    return (
        <div className={'addToCartModel'}>
            <Modal.Container
                size="small"
                closeLabel={
                    t(`${baseTranslationKey}.close`)}
                ref={modalRef}
            >
                <Modal.Heading
                    title={t(`${baseTranslationKey}.addedItemHeader`)}
                    tag="h3"
                    style={{
                        marginBlockStart: '0.3em',
                        marginBlockEnd: '0em',
                    }}
                />
                <Modal.Content
                    style={{
                        padding: '0.3em',
                        minHeight: '500px',
                    }}
                >
                    <ItemSummary product={product} offer={offer} quantity={quantity} />
                    <CarouselModal upsellSkusList={product.upsellSkus} addUpsellCallback = {addUpsellCallback}/>
                </Modal.Content>
            </Modal.Container>
        </div>
    );


};

AddedToCartModal.propTypes = {
    product: Object.isRequired,
    offer: Object.isRequired,
    quantity: Number.isRequired,
}

export default React.forwardRef(AddedToCartModal);